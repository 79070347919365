import { Card, CardContent, CardHeader, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DetailsTable from './components';

const ClinicalProtocol = ({ demand, type }) => {
  const { t } = useTranslation(['demands', 'common']);

  return (
    <Card>
      <CardHeader
        title={t('demands:labels.viewDemand_clinicalProtocol_title')}
        subheader={t('demands:labels.viewDemand_clinicalProtocol_subtitle')}
      />
      <Divider />
      <CardContent>
        <DetailsTable type={type} demand={demand} />
      </CardContent>
    </Card>
  );
};

ClinicalProtocol.propTypes = {
  demand: PropTypes.object,
  type: PropTypes.string,
};

export default ClinicalProtocol;
