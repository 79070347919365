import { Button, Card, CardContent, CardHeader, Divider, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useGoBack } from 'src/hooks/common';
import DetailsTable from './components';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 650,
    margin: '20px auto',
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const PatientDetails = ({ patient, company }) => {
  const classes = useStyles();
  const { t } = useTranslation(['patients', 'common']);
  const goBack = useGoBack('/app/patients');

  return (
    <Card className={classes.card}>
      <CardHeader
        title={t('labels.viewPatient_title')}
        subheader={t('labels.viewPatient_subtitle')}
      />
      <Divider />
      <CardContent>
        <DetailsTable patient={patient} company={company} />

        <Button className={classes.button} variant="outlined" onClick={goBack}>
          {t('common:buttons.back')}
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          component={RouterLink}
          to={`/app/patients/${patient.id}/edit`}
        >
          {t('buttons.edit_patient')}
        </Button>
      </CardContent>
    </Card>
  );
};

PatientDetails.propTypes = {
  patient: PropTypes.object,
  company: PropTypes.object,
};

export default PatientDetails;
