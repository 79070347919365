/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { login } from 'src/services/api/auth';
import { getUserMe } from 'src/services/api/users';

import { logOut } from '../actions/auth';

const defaultUserState = {
  loading: false,
  error: null,
  user: null,
};

export const fetchLogin = createAsyncThunk('app/fetchLogin', async (params) => {
  const response = await login(params.email, params.password, params.token);
  if (response.data) {
    return response.data;
  } else {
    return response.response?.data;
  }
});

export const reloadUser = createAsyncThunk('app/reloadUser', async () => {
  const response = await getUserMe({
    include:
      'avatar,role,company,doctors,assistants,supervisorDoctors,supervisorCenters,permissions,provinces',
  });

  return response.data;
});

const authSlice = createSlice({
  name: 'user',
  initialState: defaultUserState,
  reducers: {
    updateUser: {
      reducer(state, action) {
        state.user = { ...state.user, ...action.payload };
      },
    },
  },
  extraReducers: {
    [fetchLogin.pending]: (state) => {
      state.loading = true;
      state.user = null;
      state.error = null;
    },
    [fetchLogin.fulfilled]: (state, action) => {
      state.user = action.payload;
      state.loading = false;
    },
    [fetchLogin.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [reloadUser.pending]: (state) => {
      state.loading = true;
    },
    [reloadUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action.payload;
    },
    [reloadUser.rejected]: (state, action) => {
      state.loading = false;
      state.user = null;
      state.error = action.error.message;
    },
    [logOut]: (state) => {
      state.loading = false;
      state.user = null;
      state.error = null;
    },
  },
});

export const { updateUser } = authSlice.actions;

export default authSlice.reducer;
