import { Box, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { NavLink as RouterLink } from 'react-router-dom';

const ToolbarActions = () => {
  const { t } = useTranslation('patients');

  return (
    <Box display="flex" justifyContent="flex-end">
      <Button color="primary" variant="contained" component={RouterLink} to="/app/patients/create">
        {t('buttons.patients_list_create_patient')}
      </Button>
    </Box>
  );
};

ToolbarActions.propTypes = {};

export default ToolbarActions;
