import { httpInstance } from './http.instance';
import { logOut } from 'src/store/actions/auth';
import { passwordExpired, requireResetPassword, requireAcceptTerms } from 'src/store/actions/app';

const configureInitialInterceptors = (appStore) => {
  httpInstance.interceptors.request.use((conf) => {
    const state = appStore.getState();
    const updatedConf = conf;

    if (state.app.currentCompany) {
      updatedConf.headers.subdomain = state.app.currentCompany;
    }

    const { token } = state.auth;
    if (token) {
      updatedConf.headers.Authorization = `JWT ${token}`;
    }

    return updatedConf;
  });

  httpInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        appStore.dispatch(logOut());

        return error;
      } else if (
        error.response.status === 403 &&
        error.response.data?.message === 'PASSWORDEXPIRED'
      ) {
        appStore.dispatch(passwordExpired());

        return error;
      } else if (
        error.response.status === 403 &&
        error.response.data?.message === 'REQUIRERESETPASSWORD'
      ) {
        appStore.dispatch(requireResetPassword());

        return error;
      } else if (
        error.response.status === 403 &&
        error.response.data?.message === 'REQUIREACCEPTTERMS'
      ) {
        appStore.dispatch(requireAcceptTerms());

        return error;
      } else {
        return Promise.reject(error);
      }
    },
  );
};

export { configureInitialInterceptors };
