const STATUS = {
  DRAFT: 'DRAFT',
  ACTIVE: 'ACTIVE',
  SENDED: 'SENDED',
  CANCELED: 'CANCELED',
  FINISHED: 'FINISHED',
};

const STATUS_FILTER = {
  DRAFT: 'draft',
  ACTIVE: 'active',
  SENDED: 'sended',
  CANCELED: 'canceled',
  PENDING_ANALYSIS: 'pending_analysis',
  PENDING_MISSING_INFO: 'pending_missing_info',
  PENDING_COMPANY_ACCEPT: 'pending_company_accept',
  PENDING_COMPANY_DENY: 'pending_company_deny',
  PENDING_EVALUATION: 'pending_evaluation',
  PENDING_CONTINUE: 'pending_continue',
  SENDED_ACCEPTED: 'sended_accepted',
  SENDED_DENIED: 'sended_denied',
  FINISHED: 'finished',
  PENDING_VALIDATION: 'pending_validation',
};

const TYPE = {
  MAMA: 'Mama',
  GASTRICO: 'Gástrico',
  MIELOPROLIFERATIVOS: 'Mieloproliferativos',
  PULMON: 'Pulmón',
  COLORECTAL: 'Colo-rectal',
  PROSTATA: 'Próstata',
  OVARIO: 'Ovario',
  PANCREAS: 'Páncreas',
  VEJIGA: 'Vejiga',
  OTROS: 'Otros',
};

const LOCATION = {
  COMMON: {
    NO_REALIZADO: 'No realizado',
    POSITIVO: '+',
    NEGATIVO: '-',
  },
  MAMA: {
    HER_2_NEU: {
      PLUSPLUS: '++',
      PLUSPLUSPLUS: '+++',
    },
  },
  GASTRICO: {
    HERCEPTEST: {
      NO_REALIZADO: 'No realizado',
      MINUS: '-',
      PLUS: '+',
      PLUSPLUS: '++',
      PLUSPLUSPLUS: '+++',
    },
    FISH_HER2: {
      NO_REALIZADO: 'No realizado',
      MINUS: '-',
      PLUS: '+',
    },
  },
  COLORECTAL: {
    TIPO: {
      NO_ESPECIFICADO: 'No especificado',
      COLON_IZQUIERDO: 'Colon izquierdo',
      COLON_DERECHO: 'Colon derecho',
      RECTO: 'Recto',
    },
  },
  PULMON: {
    TIPO: {
      MICROCITICO: 'Microcítico',
      NO_MICROCITICO_ADENOCARCINOMA: 'No Microcítico Adenocarcinoma',
      NO_MICROCITICO_ESCAMOSO: 'No microcítico Escamoso',
      NO_MICROCITICO_ADENOESCAMOSO: 'No microcítico Adenoescamoso',
      NO_MICROCITICO_LARGE_CELL: 'No microcítico Large cell carcinoma',
      NO_MICROCITICO_SARCOMATOIDE: 'No microcítico Sarcomatoide',
      OTROS: 'Otros',
    },
    PD_LI: {
      LESS_1: '< 1',
      MORE_1_49: '>= 1-49%',
      MORE_50: '>= 50%',
    },
  },
  PROSTATA: {
    TIPO: {
      ADENOCARCINOMA: 'Adenocarcinoma',
      SMALL_CELL_NEUROENDOCRINO: 'Small cell/neuroendocrino',
      OTROS: 'Otros',
    },
  },
  MIELOPROLIFERATIVOS: {
    CD20: {
      NO_REALIZADO: 'No realizado',
      MINUS: '-',
      PLUS: '+',
    },
  },
  VEJIGA: {
    TIPO: {
      T_UROTELIALES: 'T. Uroteliales',
      ESCAMOSO: 'Escamoso',
      GLANDULAR: 'Glandular',
      URACHAL: 'Urachal',
      MULLERIANO: 'Mulleriano',
      NEUROENDOCRINO: 'Neuroendocrino',
      MELANOCITICO: 'Melanocitico',
      MESENQUIMAL: 'Mesenquimal',
      HEMATOPOYETICO_LINFOIDE: 'Hematopoyetico o linfoide',
      MISCELAREOS: 'Miscelareos',
      OTROS: 'Otros',
    },
    PD_LI: {
      LESS_1: '< 1',
      MORE_1_49: '>= 1-49%',
      MORE_50: '>= 50%',
    },
  },
};

const T = {
  TX: 'Tx',
  T0: 'T0',
  TIS: 'Tis',
  T1: 'T1',
  T2: 'T2',
  T3: 'T3',
  T4: 'T4',
};

const N = {
  NX: 'Nx',
  N0: 'N0',
  N1: 'N1',
  N2: 'N2',
  N3: 'N3',
};

const M = {
  MX: 'Mx',
  M0: 'M0',
  M1: 'M1',
};

const ESTADIO = {
  ZERO: '0',
  ONE: '1',
  IIA: 'IIA',
  IIB: 'IIB',
  IIIA: 'IIIA',
  IIIB: 'IIIB',
  IV: 'IV',
};

const PREVIOUS_TREATMENTS = {
  TIPO: 'Tipo de cirugía',
  RADIOTERAPIA: 'Radioterapia',
  HORMONOTERAPIA_INMUNOTERAPIA: 'Hormonoterapia/Inmunoterapia',
  QUIMIOTERAPIA_TTODIANA: 'Quimioterapia/TTO Diana',
  NEO_ADYUVANTE: 'Neo Adyuvante',
  ADYUVANTE: 'Adyuvante',
  AVANZADO: 'Avanzado',
};

const TRATAMIENTO_QUIMIOTERAPICO_INTENCION = {
  NEO_ADYUVANTE: 'Neo Adyuvante',
  ADYUVANTE: 'Adyuvante',
  PALIATIVO: 'Paliativo',
  CURATIVO: 'Curativo',
  RADICAL: 'Radical',
  NO_SE_INDICA: 'No se indica',
};

const TRATAMIENTO_RADIOTERAPICO_INTENCION = {
  NEO_ADYUVANTE: 'Neo Adyuvante',
  ADYUVANTE: 'Adyuvante',
  PALIATIVO: 'Paliativo',
  CURATIVO: 'Curativo',
  RADICAL: 'Radical',
  NO_SE_INDICA: 'No se indica',
};

const MANAGE_DEMAND_ACTIONS = {
  REQUEST: 'REQUEST',
  REQUEST_MISSING_INFO: 'REQUEST_MISSING_INFO',
  REQUEST_AUTHORIZATION: 'REQUEST_AUTHORIZATION',
  PROPOSE_AUTHORIZATION: 'PROPOSE_AUTHORIZATION',
  PROPOSE_DENIAL: 'PROPOSE_DENIAL',
  ACCEPT: 'ACCEPT',
  DENY: 'DENY',
};

const MANAGE_DEMAND_REASONS = {
  AUTORIZADO: 'Autorizado',
  AUTORIZADONOCOSTEEFICIENTE: 'Autorizado no coste eficiente',
  USOFUERADEINDICACIONDENEGADO: 'Uso fuera de Indicación - Denegado',
  USOFUERADEINDICACIONAUTORIZACION: 'Uso fuera de Indicación - Propuesto Autorización',
  NOCUBIERTODENEGADO: 'No cubierto - Denegado',
  NOCUBIERTOAUTORIZACION: 'No cubierto - Propuesta autorización',
};

const MANAGE_DEMAND_FINAL_REASONS = {
  SOLICITUDAUTORIZADA: 'Solicitud autorizada',
  DENEGADAUSOFUERAINDICACIONCOBERTURA:
    'Solicitud denegada por ser un uso fuera de indicación',
  DENEGADAUSOFUERAINDICACION:
    'Solicitud denegada por ser un uso fuera de indicación no cubierto por la póliza',
  DENAGADATRATAMIENTONOCUBIERTO:
    'Solicitud denegada por ser un tratamiento no cubierto por la póliza',
  AUTORIZADAEXCEPCIONALRECOMENDADO:
    'Solicitud autorizada de forma excepcional al ser un uso fuera de indicación no cubierto por la póliza, pero recomendado',
  AUTORIZADAEXCEPCIONALNORECOMENDADO:
    'Solicitud autorizada de forma excepcional al ser un uso fuera de indicación no cubierto por la póliza',
  AUTORIZADAFARMACONOCUBIERTO:
    'Solicitud autorizada de forma excepcional al ser un fármaco no cubierto por la póliza',
};

export {
  STATUS,
  STATUS_FILTER,
  TYPE,
  LOCATION,
  T,
  N,
  M,
  ESTADIO,
  PREVIOUS_TREATMENTS,
  TRATAMIENTO_QUIMIOTERAPICO_INTENCION,
  TRATAMIENTO_RADIOTERAPICO_INTENCION,
  MANAGE_DEMAND_ACTIONS,
  MANAGE_DEMAND_REASONS,
  MANAGE_DEMAND_FINAL_REASONS,
};
