import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import ListSubheader from '@material-ui/core/ListSubheader';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { Button, makeStyles } from '@material-ui/core';

const MarkAllAsReadButton = withStyles({
  root: {
    textTransform: 'none',
  },
})(Button);

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function NotificationsHeader({ handleMarkAllAsReadClick }) {
  const classes = useStyles();
  const { t } = useTranslation('notifications');

  return (
    <ListSubheader className={classes.header} component="div">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <span>{t('labels.notifications')}</span>
        {handleMarkAllAsReadClick && (
          <MarkAllAsReadButton
            disableRipple
            size="small"
            color="secondary"
            onClick={handleMarkAllAsReadClick}
          >
            {t('buttons.mark_all_as_read')}
          </MarkAllAsReadButton>
        )}
      </Box>
    </ListSubheader>
  );
}

NotificationsHeader.propTypes = {
  handleMarkAllAsReadClick: PropTypes.func.isRequired,
};
