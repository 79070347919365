import { Card, CardContent, CardHeader } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AlertDialog from 'src/components/AlertDialog';
import { useGoBack } from 'src/hooks/common';
import useUpdateCompany from 'src/hooks/company/useUpdateCompany';
import { fetchAppData } from 'src/store/reducers/app';
import CompanyDetailsForm from './components';

const ProfileDetails = ({ company }) => {
  const goBack = useGoBack('/app/companies');
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [updateCompany, { isLoading, data: updatedCompany, isError, error }] = useUpdateCompany();
  const { t } = useTranslation('companies');
  const history = useHistory();

  const handleSubmit = async (companyId, values) => {
    const res = await updateCompany({ company: companyId, data: values });
    if (res) {
      goBack();
      dispatch(fetchAppData());
    }
  };

  const handleCancel = () => {
    history.push('/app/companies', { replace: true });
  };

  useEffect(() => {
    if (!isLoading && !isError && updatedCompany) {
      enqueueSnackbar(t('modals.success.text_update_company'), { variant: 'success' });
    }
  }, [enqueueSnackbar, isError, isLoading, t, updatedCompany]);

  return (
    <Card>
      <CardHeader
        subheader={t('labels.updateCompany_subtitle')}
        title={t('labels.updateCompany_title')}
      />
      <CardContent>
        <CompanyDetailsForm
          handleFormSubmit={handleSubmit}
          company={company.data}
          handleCancel={handleCancel}
        />

        {isError && (
          <AlertDialog
            title={t('modals.error.title_update_company')}
            text={error?.message || t('modals.error.text_update_company')}
            type="error"
          />
        )}
      </CardContent>
    </Card>
  );
};

ProfileDetails.propTypes = {
  company: PropTypes.object,
};

export default ProfileDetails;
