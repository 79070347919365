import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  makeStyles,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Info as InfoIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import AlertDialog from 'src/components/AlertDialog';
import Loader from 'src/components/Loader';
import { POLICY_TYPES } from 'src/constants/companies';
import { useGetCompanyPolicy } from 'src/hooks/company';
import { useAcceptTermsConditions } from 'src/hooks/users';
import { logOut } from 'src/store/actions/auth';
import { setRequireAcceptTerms } from 'src/store/reducers/app';

const useStyles = makeStyles({
  dialog: {
    margin: '1.5rem',
  },
  icon: {
    width: '5rem',
    height: '5rem',
    margin: '0 auto',
  },
  terms: {
    maxHeight: '50vh',
  },
  actions: {
    justifyContent: 'center',
  },
});

const TermsConditionsDialog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(['auth', 'common', 'companies']);
  const [open, setOpen] = useState(true);
  const { isLoading, data, isError, error } = useGetCompanyPolicy(POLICY_TYPES.TERMS_CONDITIONS);
  const [acceptTerms, acceptQuery] = useAcceptTermsConditions();

  const cancelAction = () => {
    dispatch(logOut());
  };

  const acceptAction = () => {
    acceptTerms();
  };

  const closeDialog = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!acceptQuery.isLoading && !acceptQuery.isError && acceptQuery.data) {
      dispatch(setRequireAcceptTerms(false));
      closeDialog();
    }
  }, [acceptQuery.data, acceptQuery.isError, acceptQuery.isLoading, dispatch]);

  if (acceptQuery.isError) {
    return (
      <AlertDialog
        title={t('modals.errors.title_accept_terms_conditions')}
        text={error?.message || t('modals.errors.text_accept_terms_conditions')}
        type="error"
        acceptAction={cancelAction}
      />
    );
  }
  if (isError) {
    return (
      <AlertDialog
        title={t('companies:modals.error.title_get_terms_conditions')}
        text={error?.message || t('companies:modals.error.text_get_terms_conditions')}
        type="error"
        acceptAction={cancelAction}
      />
    );
  }

  return (
    <Dialog open={open} maxWidth="lg">
      {acceptQuery.isLoading && <LinearProgress color="primary" />}

      <Box className={classes.dialog} display="flex" flexDirection="column">
        <SvgIcon className={classes.icon}>
          <InfoIcon />
        </SvgIcon>

        <DialogTitle disableTypography={true}>
          <Typography variant="h4" align="center">
            {t('modals.errors.title_require_accept_terms')}
          </Typography>
        </DialogTitle>

        <DialogContent>
          {isLoading && (
            <>
              <DialogContentText align="center">
                {t('modals.errors.text_require_accept_terms')}
              </DialogContentText>

              <Loader />
            </>
          )}
          {!isLoading && data?.data?.content && (
            <DialogContentText
              className={classes.terms}
              dangerouslySetInnerHTML={{ __html: data.data?.content }}
            />
          )}
        </DialogContent>

        <DialogActions className={classes.actions}>
          <Button variant="contained" onClick={cancelAction} disabled={acceptQuery.isLoading}>
            {t('common:buttons.cancel')}
          </Button>

          <Button
            color="primary"
            variant="contained"
            onClick={acceptAction}
            disabled={isLoading || acceptQuery.isLoading}
          >
            {t('common:buttons.accept')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default TermsConditionsDialog;
