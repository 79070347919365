import PropTypes from 'prop-types';

import ToolbarActions from './components/ToolbarActions';
import ToolbarFilters from './components/ToolbarFilters';

const Toolbar = ({
  resetPage,
  name,
  setName,
  email,
  setEmail,
  dni,
  setDni,
  company,
  setCompany,
  role,
  setRole,
}) => {
  return (
    <div>
      <ToolbarActions />

      <ToolbarFilters
        resetPage={resetPage}
        name={name}
        setName={setName}
        email={email}
        setEmail={setEmail}
        dni={dni}
        setDni={setDni}
        company={company}
        setCompany={setCompany}
        role={role}
        setRole={setRole}
      />
    </div>
  );
};

Toolbar.propTypes = {
  resetPage: PropTypes.func,
  name: PropTypes.string,
  setName: PropTypes.func,
  email: PropTypes.string,
  setEmail: PropTypes.func,
  dni: PropTypes.string,
  setDni: PropTypes.func,
  company: PropTypes.object,
  setCompany: PropTypes.func,
  role: PropTypes.object,
  setRole: PropTypes.func,
};

export default Toolbar;
