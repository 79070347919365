import { useMutation, useQueryCache } from 'react-query';
import { removeCenters } from 'src/services/api/users';

function useRemoveCenters() {
  const queryCache = useQueryCache();

  return useMutation(({ user, data }) => removeCenters(user, data), {
    onSuccess: (data) => queryCache.setQueryData(['user', data.data?.id], data),
  });
}

export default useRemoveCenters;
