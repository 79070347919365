import { Box, makeStyles, Typography } from '@material-ui/core';
import format from 'date-fns/format';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: '#ecf0f1',
  },
  privateComment: {
    backgroundColor: '#fab1a0',
    display: 'inline-block',
    color: '#000',
    padding: '1px 5px 1px 5px',
    borderRadius: '3px',
  },
  date: {
    color: '#636e72',
    fontSize: '12px',
  },
}));

const Comment = ({
  author,
  comment = null,
  privateComment = null,
  date,
  displayPrivateComment,
}) => {
  const classes = useStyles();

  return (
    <Box my={1}>
      <Box>
        <Typography variant="h5">
          {author}{' '}
          <span className={classes.date}>{format(new Date(date), 'dd/MM/yyyy HH:mm:ss')}</span>
        </Typography>
        <Box>
          <Typography component="strong">{comment}</Typography>
        </Box>
        {displayPrivateComment && privateComment && (
          <Box py={1} mb={1} className={classes.privateComment}>
            <Typography component="strong">{privateComment}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

Comment.propTypes = {
  author: PropTypes.string,
  comment: PropTypes.string,
  privateComment: PropTypes.string,
  displayPrivateComment: PropTypes.bool,
  date: PropTypes.string,
};

export default Comment;
