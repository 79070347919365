import { useMutation, useQueryCache } from 'react-query';
import { postInvitationUse } from 'src/services/api/invitations';

function usePostInvitationUse() {
  const queryCache = useQueryCache();

  return useMutation(({ token, data }) => postInvitationUse(token, data), {
    onSuccess: (data) => queryCache.setQueryData(['invitation', data.data?.id], data),
  });
}

export default usePostInvitationUse;
