/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import { logOut } from '../actions/auth';

const defaultAuthState = {
  token: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: defaultAuthState,
  reducers: {
    setToken: {
      reducer(state, action) {
        state.token = action.payload.token;
      },
    },
  },
  extraReducers: {
    [logOut]: (state) => {
      state.token = defaultAuthState.token;
    },
  },
});

export const { setToken } = authSlice.actions;

export default authSlice.reducer;
