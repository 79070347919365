import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { GENDERS } from 'src/constants/genders';
const useStyles = makeStyles((theme) => ({
  table_no_border: {
    '& th': {
      padding: '4px 5px',
      borderBottom: 'none',
      width: '40%',
    },
    '& td': {
      padding: '4px 5px',
      borderBottom: 'none',
      color: theme.palette.tertiary.main,
    },
    '& th, & th strong, & td': {
      lineHeight: 1,
    },
  },
}));
const DetailsTable = ({ patient }) => {
  const { t } = useTranslation('patients');
  const classes = useStyles();
  return (
    <TableContainer>
      <Table className={classes.table_no_border}>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.firstName')}</Typography>
            </TableCell>
            <TableCell>{patient.firstName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.lastName')}</Typography>
            </TableCell>
            <TableCell>{patient.lastName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.cardNumber')}</Typography>
            </TableCell>
            <TableCell>{patient.cardNumber}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.email')}</Typography>
            </TableCell>
            <TableCell>{patient.email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.type')}</Typography>
            </TableCell>
            <TableCell>{patient.type}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.gender')}</Typography>
            </TableCell>
            <TableCell>
              {patient.gender === GENDERS.male
                ? t('fields.genders.male')
                : t('fields.genders.female')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.birthday')}</Typography>
            </TableCell>
            <TableCell>{format(new Date(patient.birthday), 'dd/MM/yyyy')}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

DetailsTable.propTypes = {
  patient: PropTypes.object,
};

export default DetailsTable;
