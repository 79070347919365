import get from 'lodash/get';
import { useQuery } from 'react-query';
import * as notificationsService from 'src/services/api/notifications';

const DEFAULT_NOTIFICATIONS_LIMIT = 10;

const getNotifications = async (pageSize) => {
  const { data } = await notificationsService.getNotifications({
    pageSize,
  });

  return get(data, 'rows', []);
};

function useGetNotifications(limit = DEFAULT_NOTIFICATIONS_LIMIT) {
  return useQuery(['notifications'], () => getNotifications(limit));
}

export default useGetNotifications;
