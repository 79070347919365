import { getRequest, postRequest, putRequest, deleteRequest } from 'src/utils/http';

import { codesController } from './consts';

export const getCodeById = async (id, params) => getRequest(`${codesController}/${id}`, params);

export const createCode = async (body) => postRequest(`${codesController}`, body);

export const updateCode = async (id, data) => putRequest(`${codesController}/${id}`, data);

export const listCodes = async (params) => getRequest(`${codesController}`, params);

export const deleteCode = async (code) => deleteRequest(`${codesController}/${code}`);
