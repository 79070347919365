import { usePaginatedQuery } from 'react-query';
import * as presentationsService from '../../services/api/presentations';

function useListPresentations(query, enabled = true) {
  return usePaginatedQuery(
    ['presentations', query],
    (key, query) => presentationsService.listPresentations(query),
    {
      enabled,
    },
  );
}

export default useListPresentations;
