import { usePaginatedQuery } from 'react-query';
import * as presentationsService from '../../services/api/presentations';

function useGetAllPresentations(query, enabled = true) {
  return usePaginatedQuery(
    ['presentations', query],
    (key, query) => presentationsService.getPresentations(query),
    {
      enabled,
    },
  );
}

export default useGetAllPresentations;
