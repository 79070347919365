import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  makeStyles,
  Typography,
} from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import {
  Briefcase as BriefcaseIcon,
  Clipboard as ClipboardIcon,
  Droplet as DropletIcon,
  FileText as FileTextIcon,
  Home as HomeIcon,
  MapPin as MapPinIcon,
  Send as SendIcon,
  User as UserIcon,
  Users as UsersIcon,
  Code as CodeIcon,
  Airplay as PresentationIcon,
} from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useQueryCache } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import CenterSelector from 'src/components/Centers/CenterSelector';
import ManagerCompanySelector from 'src/components/Company/ManagerCompanySelector';
import DoctorSelector from 'src/components/Doctors/DoctorSelector';
import { ALL } from 'src/constants/common';
import { logOut } from 'src/store/actions/auth';
import {
  isCenterSelected as isCenterSelectedSelector,
  isCompanySelected as isCompanySelectedSelector,
  isDoctorSelected as isDoctorSelectedSelector,
} from 'src/store/selectors/entity';
import {
  isAdmin as isAdminSelector,
  isAssistant as isAssistantSelector,
  isDoctor as isDoctorSelector,
  isManager as isManagerSelector,
  isSuperAdmin as isSuperAdminSelector,
} from 'src/store/selectors/user';
import { getMediaUrl } from 'src/utils/getMediaUrl';

import NavItem from '../NavItem';
import Notifications from '../Notifications';

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
    overflowY: 'visible',
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
}));

const NavBar = ({ onMobileClose, openMobile, disabled }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryCache = useQueryCache();
  const { t } = useTranslation(['common', 'account', 'rolesNames']);
  const location = useLocation();
  const user = useSelector((state) => state.user.user);
  const isAssistant = useSelector(isAssistantSelector);
  const isDoctor = useSelector(isDoctorSelector);
  const isManager = useSelector(isManagerSelector);
  const isSuperAdmin = useSelector(isSuperAdminSelector);
  const isAdmin = useSelector(isAdminSelector);
  const isDoctorSelected = useSelector(isDoctorSelectedSelector);
  const isCenterSelected = useSelector(isCenterSelectedSelector);
  const isCompanySelected = useSelector(isCompanySelectedSelector);

  const avatarSrc = user?.avatar ? getMediaUrl(user.avatar.id) : null;

  const refetchDemands = () => {
    queryCache.invalidateQueries('demands');
  };

  const items = [
    {
      href: '/app/home',
      icon: HomeIcon,
      title: 'nav.home',
      permissions: ALL,
    },
    {
      href: '/app/account',
      icon: UserIcon,
      title: 'nav.account',
      permissions: ALL,
    },
    {
      href: '/app/companies',
      icon: BriefcaseIcon,
      title: 'nav.companies',
      permissions: isSuperAdmin || isAdmin,
    },
    {
      href: '/app/users',
      icon: UsersIcon,
      title: 'nav.users',
      permissions: isSuperAdmin || isAdmin,
    },
    {
      href: '/app/invitations',
      icon: SendIcon,
      title: 'nav.invitations',
      permissions: isSuperAdmin || isAdmin,
    },
    {
      href: '/app/patients',
      icon: ClipboardIcon,
      title: 'nav.patients',
      permissions: isSuperAdmin || isAdmin,
    },
    {
      href: '/app/drugs',
      icon: DropletIcon,
      title: 'nav.drugs',
      permissions: isSuperAdmin || isAdmin,
    },
    {
      href: '/app/presentations',
      icon: PresentationIcon,
      title: 'nav.drugs_presentations',
      permissions: isSuperAdmin || isAdmin,
    },
    {
      href: '/app/centers',
      icon: MapPinIcon,
      title: 'nav.centers',
      permissions: isSuperAdmin || isAdmin,
    },
    {
      href: '/app/codes',
      icon: CodeIcon,
      title: 'nav.codes',
      permissions: isSuperAdmin || isAdmin,
    },
    {
      href: '/app/demands',
      icon: FileTextIcon,
      title: 'nav.demands',
      permissions: ALL,
      onClick: refetchDemands,
    },
  ];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
        component={RouterLink}
        to="/app/account"
      >
        <Avatar className={classes.avatar} src={avatarSrc} />
        <Typography className={classes.name} color="textPrimary" variant="h5" align="center">
          {user.name}
        </Typography>
        <Typography color="textSecondary" variant="body2" align="center">
          {t('account:labels.roleCompany', {
            role: t(`rolesNames:${user.role.permissions}`),
            company: user.company.name,
          })}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item) =>
            item.permissions === ALL || item.permissions ? (
              <NavItem
                href={item.href}
                key={item.title}
                title={t(`common:${item.title}`)}
                icon={item.icon}
                onClick={item.onClick}
                disabled={disabled}
              />
            ) : null,
          )}
        </List>
      </Box>

      <Hidden lgUp>
        {isAssistant && isDoctorSelected && isCenterSelected ? (
          <Box>
            <DoctorSelector />
          </Box>
        ) : null}
        {(isAssistant && isDoctorSelected && isCenterSelected) || (isDoctor && isCenterSelected) ? (
          <Box>
            <CenterSelector />
          </Box>
        ) : null}
        {isManager && isCompanySelected && (
          <Box>
            <ManagerCompanySelector />
          </Box>
        )}
      </Hidden>

      <Hidden lgUp>
        <Box p={2} display="flex" justifyContent="space-around">
          <Notifications disabled={disabled} />
          <IconButton color="inherit" onClick={() => dispatch(logOut())}>
            <InputIcon />
          </IconButton>
        </Box>
      </Hidden>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  disabled: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default NavBar;
