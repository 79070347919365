import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  CircularProgress,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useListCompanies } from 'src/hooks/company';

const Container = ({ children }) => {
  return (
    <Box mt={3}>
      <Card>
        <CardContent>{children}</CardContent>
      </Card>
    </Box>
  );
};

const ToolbarFilters = ({ resetPage, company, setCompany }) => {
  const { t } = useTranslation('codes');
  const { isLoading, data: companies } = useListCompanies({ all: true, default: false });

  const handleReset = () => {
    setCompany(null);
    resetPage();
  };

  const handleCompanyChange = (e, value) => {
    setCompany(value ? { id: value.id, name: value.name } : null);
    resetPage();
  };

  return (
    <Container>
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs={12} sm={9} md={9}>
          <Autocomplete
            openOnFocus={true}
            selectOnFocus={false}
            options={companies?.data.rows || []}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            value={company}
            loading={isLoading}
            disableClearable={isLoading}
            onChange={handleCompanyChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('fields.company')}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  style: { cursor: 'pointer' },
                }}
                InputProps={{
                  ...params.InputProps,
                  readOnly: true,
                  endAdornment: (
                    <>
                      {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Button fullWidth color="primary" variant="contained" onClick={handleReset}>
            {t('buttons.codes_list_clear_filters')}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

ToolbarFilters.propTypes = {
  setPage: PropTypes.func,
  company: PropTypes.object,
  setCompany: PropTypes.func,
};

export default ToolbarFilters;
