import { fade, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { User as UserIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setDoctorEntity } from 'src/store/reducers/entity';
import { isAssistant as isAssistantSelector } from 'src/store/selectors/user';

import DoctorSelectorForm from './components/DoctorSelectorForm';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  doctorIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '20ch',
      '&:focus': {
        width: '28ch',
      },
    },
  },
}));

const DoctorSelector = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isAssistant = useSelector(isAssistantSelector);
  const { t } = useTranslation('users');
  const { enqueueSnackbar } = useSnackbar();
  const doctorSelected = useSelector((state) => state.entity?.doctor);
  const [doctor, setDoctor] = useState(doctorSelected);
  const doctors = useSelector((state) =>
    isAssistant ? state.user?.user?.doctors : state.user?.user?.supervisorDoctors,
  );

  const handleOnChangeDoctor = () => {
    enqueueSnackbar(t('modals.success.doctor_selected'), { variant: 'success' });
  };

  useEffect(() => {
    dispatch(setDoctorEntity({ doctor }));
  }, [dispatch, doctor]);

  useEffect(() => {
    setDoctor(doctorSelected);
  }, [doctorSelected]);

  return (
    <div className={classes.search}>
      <div className={classes.doctorIcon}>
        <UserIcon />
      </div>

      <DoctorSelectorForm
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        doctors={doctors ? doctors : []}
        setDoctor={setDoctor}
        handleOnChangeDoctor={handleOnChangeDoctor}
        doctor={doctor}
      />
    </div>
  );
};

export default DoctorSelector;
