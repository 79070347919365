import PropTypes from 'prop-types';

import ToolbarActions from './components/ToolbarActions';
import ToolbarFilters from './components/ToolbarFilters';

const Toolbar = ({ resetPage, name, setName, company, setCompany, cardNumber, setCardNumber }) => {
  return (
    <div>
      <ToolbarActions />

      <ToolbarFilters
        resetPage={resetPage}
        name={name}
        setName={setName}
        company={company}
        setCompany={setCompany}
        cardNumber={cardNumber}
        setCardNumber={setCardNumber}
      />
    </div>
  );
};

Toolbar.propTypes = {
  resetPage: PropTypes.func,
  name: PropTypes.string,
  setName: PropTypes.func,
  company: PropTypes.object,
  setCompany: PropTypes.func,
  cardNumber: PropTypes.string,
  setCardNumber: PropTypes.func,
};

export default Toolbar;
