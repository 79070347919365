import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import get from 'lodash-es/get';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import {
  getRoleCurrentUser as getRoleCurrentUserSelector,
  isAdmin as isAdminSelector,
  isSuperAdmin as isSuperAdminSelector,
  isAssistant as isAssistantSelector,
  isDoctor as isDoctorSelector,
  isManager as isManagerSelector,
} from 'src/store/selectors/user';
import { isDoctorSelected as isDoctorSelectedSelector } from 'src/store/selectors/entity';
import { useHistory } from 'react-router-dom';
import { MANAGE_DEMAND_ACTIONS, STATUS, STATUS_FILTER } from 'src/constants/demands';
import { ROLES } from 'src/constants/roles';
import { useEffect, useState } from 'react';
import AlertDialog from 'src/components/AlertDialog';
import { useTranslation } from 'react-i18next';
import { useCancelDemand, useDeleteDemand, useDuplicateDemand } from 'src/hooks/demands';
import { useSnackbar } from 'notistack';
import ManageDemandModal from 'src/views/demands/ListDemandsView/components/Results/components/ManageDemandModal';
import IncreaseCyclesModal from './components/IncreaseCyclesModal';
import DemandsActionsButtons from './components/DemandsActionsButtons';
import ChangeDatesDemandModal from './components/ChangeDatesDemandModal';
import useFinishDemand from 'src/hooks/demands/useFinishDemand';
import useUpdateDemandCycles from 'src/hooks/demands/useUpdateDemandCycles';
import { checkDemandWasSended } from '../../../utils/demands';
import UploadAttachmentModal from './components/UploadAttachmentModal';
import InsertClosingCommentModal from './components/InsertClosingCommentModal';

const DemandsActions = ({ demand, roles, isLoading, refetch, listActions }) => {
  const { t } = useTranslation('demands', 'roles', 'common');
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const userId = useSelector((state) => state.user.user?.id);
  const userZones = useSelector((state) => state.user.user?.provinces).map(({ id }) => id);
  const userRole = useSelector(getRoleCurrentUserSelector);
  const isDoctor = useSelector(isDoctorSelector);
  const isAssistant = useSelector(isAssistantSelector);
  const isManager = useSelector(isManagerSelector);
  const isAdmin = useSelector(isAdminSelector);
  const isSuperAdmin = useSelector(isSuperAdminSelector);
  const doctorSelected = useSelector(isDoctorSelectedSelector);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [manageDemandModalOpen, setManageDemandModalOpen] = useState(false);
  const [changeDatesModalOpen, setChangeDatesModalOpen] = useState(false);
  const [insertClosingDemandModalOpen, setInsertClosingDemandModalOpen] = useState(false);
  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);
  const [duplicateAllModalOpen, setDuplicateAllModalOpen] = useState(false);
  const [increaseCyclesModalOpen, setIncreaseCyclesModalOpen] = useState(false);
  const [uploadAttachmentModalOpen, setUploadAttachmentModalOpen] = useState(false);
  const [deleteDemand, deleteQuery] = useDeleteDemand();
  const [cancelDemand, cancelQuery] = useCancelDemand();
  const [finishDemand, finishQuery] = useFinishDemand();
  const [duplicateDemand, duplicateQuery] = useDuplicateDemand();
  const [finishModalOpen, setFinishModalOpen] = useState(false);
  const [updateDemandCycles, updateDemandCyclesQuery] = useUpdateDemandCycles();
  const decisionReason = demand.decisionReason;

  const displayManageModal = (demand) => {
    const userRoleId = roles?.find(({ permissions }) => permissions === userRole)?.id;
    const doctorRoleId = roles?.find(({ permissions }) => permissions === ROLES.DOCTOR)?.id;

    if (demand.status === STATUS.ACTIVE && !demand.continue) {
      if (demand.assignedRoleId === userRoleId && demand.assignedRoleId !== doctorRoleId) {
        if (userRole === ROLES.CONSULTANT) {
          if (demand.assignedUserId === userId) {
            return true;
          }
        } else if (userRole === ROLES.REPRESENTATIVE) {
          if (
            !demand.company.tramitByZones ||
            (demand.company.tramitByZones &&
              (userZones.length === 0 ||
                userZones.includes(demand.centerPrescriptor.provinceId) ||
                userZones.includes(demand.centerRealizator.provinceId)))
          ) {
            return true;
          }
        } else {
          return true;
        }
      }
    }

    return false;
  };

  const displayContinueAction = (demand) => {
    const doctorRoleId = roles?.find(({ permissions }) => permissions === ROLES.DOCTOR)?.id;

    if (demand.status === STATUS.ACTIVE && demand.continue) {
      if (demand.assignedRoleId === doctorRoleId) {
        return true;
      }
    }

    return false;
  };

  const displayChangeDatesAction = (demand) => {
    const lastAction = demand.versions[0]?.histories[0]?.action;
    const doctorRoleId = roles?.find(({ permissions }) => permissions === ROLES.DOCTOR)?.id;
    return (
      !!demand.tratamientoQuimioterapico &&
      demand.status === STATUS.SENDED &&
      demand.assignedRoleId === doctorRoleId &&
      lastAction === MANAGE_DEMAND_ACTIONS.ACCEPT
    ) || isManager;
  };

  const displayDuplicateAction = (demand) => {
    if (![STATUS.SENDED, STATUS.CANCELED, STATUS.FINISHED].includes(demand.status)) {
      return false;
    }
    if (demand.status === STATUS.SENDED) {
      const lastAction = get(demand, 'versions[0].histories[0].action');

      if (lastAction !== MANAGE_DEMAND_ACTIONS.DENY) {
        if (demand.tratamientoQuimioterapico) {
          const lastSession =
            demand.chemotherapySessions[demand.chemotherapySessions.length - 1].date;

          if (
            demand.quimioterapicoCiclo < demand.quimioterapicoNumeroCiclos ||
            isSameDay(new Date(), new Date(lastSession)) ||
            isBefore(new Date(), new Date(lastSession))
          ) {
            return false;
          }
        }

        if (demand.tratamientoRadioterapico) {
          const treatmentFound = demand.radiotherapyTreatments.find(
            ({ fechaInicio }) =>
              isSameDay(new Date(), new Date(fechaInicio)) ||
              isBefore(new Date(), new Date(fechaInicio)),
          );

          if (treatmentFound) {
            return false;
          }
        }
      }
    }

    return true;
  };

  const displayEditAction = (demand) => {
    if (isDoctor || isAssistant) {
      if (demand.status === STATUS.DRAFT) {
        return true;
      } else if (demand.status === STATUS.ACTIVE && !demand.continue) {
        const doctorRoleId = roles?.find(({ permissions }) => permissions === ROLES.DOCTOR)?.id;

        if (demand.assignedRoleId === doctorRoleId) {
          return true;
        }
      }
    }

    return false;
  };

  const displayFinishAction = (demand) => {
    if (
      (isDoctor || isAssistant) &&
      [STATUS_FILTER.PENDING_CONTINUE, STATUS_FILTER.PENDING_MISSING_INFO].includes(
        demand.displayStatus,
      )
    ) {   
      return true;
    }

    if (
      (isManager || isAdmin || isSuperAdmin) &&
      ((demand.displayStatus === STATUS_FILTER.SENDED_ACCEPTED && 
        demand.quimioterapicoCiclo < demand.quimioterapicoNumeroCiclos) ||
        demand.displayStatus === STATUS_FILTER.PENDING_CONTINUE ||
        ([
          STATUS_FILTER.PENDING_ANALYSIS, 
          STATUS_FILTER.PENDING_VALIDATION,
          STATUS_FILTER.PENDING_EVALUATION,
          STATUS_FILTER.PENDING_COMPANY_ACCEPT,
          STATUS_FILTER.PENDING_COMPANY_DENY,
          STATUS_FILTER.PENDING_MISSING_INFO,
        ].includes(demand.displayStatus) &&
          checkDemandWasSended(demand.versions)))
    ) {
      return true;
    }

    return false;
  };

  const displayCancelModal = (demand) => {
    if (demand.status !== STATUS.SENDED && demand.status !== STATUS.CANCELED) {
      const managerRoleId = roles?.find(({ permissions }) => permissions === ROLES.MANAGER)?.id;

      if (isSuperAdmin || isAdmin || (isManager && demand.assignedRoleId === managerRoleId)) {
        return true;
      }
    }

    return false;
  };

  const handleDeleteDemand = () => {
    const request = { demand: demand.id };
    if (isAssistant && doctorSelected) {
      request.doctorId = doctorSelected.id;
    }

    setDeleteModalOpen(false);
    deleteDemand(request);
  };

  const handleCancelDemand = () => {
    setCancelModalOpen(false);
    cancelDemand(demand.id);
  };

  const handleFinishDemand = (comment) =>  {
    setInsertClosingDemandModalOpen(false);
    finishDemand({ demand: demand.id, comment: comment });
  };

  const handleDuplicateDemand = (all=false) => {
    const request = { demandId: demand.id, all };
    if (isAssistant && doctorSelected) {
      request.doctorId = doctorSelected.id;
    }

    if(all)
      setDuplicateAllModalOpen(false);
    else
      setDuplicateModalOpen(false);
  
    console.log("Request a enviar: ", request);
    duplicateDemand(request);
  };

  useEffect(() => {
    if (!deleteQuery.isLoading && deleteQuery.data && !deleteQuery.isError) {
      enqueueSnackbar(t('modals.success.text_delete_demand'), { variant: 'success' });
      if (listActions) {
        refetch();
      } else {
        history.push('/app/demands');
      }
    }
  }, [
    deleteQuery.data,
    deleteQuery.isError,
    deleteQuery.isLoading,
    enqueueSnackbar,
    history,
    listActions,
    refetch,
    t,
  ]);

  useEffect(() => {
    if (!cancelQuery.isLoading && cancelQuery.data && !cancelQuery.isError) {
      enqueueSnackbar(t('modals.success.text_cancel_demand'), { variant: 'success' });

      if (listActions) {
        refetch();
      } else {
        history.push('/app/demands');
      }
    }
  }, [
    cancelQuery.data,
    cancelQuery.isError,
    cancelQuery.isLoading,
    enqueueSnackbar,
    history,
    listActions,
    refetch,
    t,
  ]);

  useEffect(() => {
    if (!duplicateQuery.isLoading && duplicateQuery.data && !duplicateQuery.isError) {
      history.push(`/app/demands/${duplicateQuery.data.data?.id}/edit`);
    }
  }, [duplicateQuery.data, duplicateQuery.isError, duplicateQuery.isLoading, history]);

  useEffect(() => {
    if (!finishQuery.isLoading && finishQuery.isSuccess && !finishQuery.isError) {
      enqueueSnackbar(t('modals.success.text_finished_demand'), { variant: 'success' });
      if (listActions) {
        refetch();
      } else {
        history.push('/app/demands');
      }
    }
  }, [
    finishQuery.data,
    finishQuery.isError,
    finishQuery.isLoading,
    enqueueSnackbar,
    history,
    listActions,
    refetch,
    t,
  ]);

  return (
    <>
      <DemandsActionsButtons
        demand={demand}
        isLoading={isLoading || deleteQuery.isLoading || duplicateQuery.isLoading}
        isDoctor={isDoctor}
        isAssistant={isAssistant}
        isManager={isManager}
        isAdmin={isAdmin}
        isSuperAdmin={isSuperAdmin}
        listActions={listActions}
        displayContinueAction={displayContinueAction}
        displayChangeDatesAction={displayChangeDatesAction}
        displayDuplicateAction={displayDuplicateAction}
        displayEditAction={displayEditAction}
        displayFinishAction={displayFinishAction}
        displayManageModal={displayManageModal}
        displayCancelModal={displayCancelModal}
        setManageDemandModalOpen={setManageDemandModalOpen}
        setChangeDatesModalOpen={setChangeDatesModalOpen}
        setDuplicateModalOpen={setDuplicateModalOpen}
        setDuplicateAllModalOpen={setDuplicateAllModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
        setCancelModalOpen={setCancelModalOpen}
        setIncreaseCyclesModalOpen={setIncreaseCyclesModalOpen}
        setFinishModalOpen={setFinishModalOpen}
        setUploadAttachmentModalOpen={setUploadAttachmentModalOpen}
      />

      {/* Modals */}
      {deleteModalOpen && (
        <AlertDialog
          title={t('modals.confirmation.title_delete_demand')}
          text={t('modals.confirmation.text_delete_demand')}
          type="warning"
          showAcceptButton={false}
          showDeleteButton={true}
          deleteAction={handleDeleteDemand}
          showCancelButton={true}
          cancelAction={() => setDeleteModalOpen(false)}
        />
      )}
      {deleteQuery.isError && (
        <AlertDialog
          title={t('modals.error.title_delete_demand')}
          text={deleteQuery.error?.message || t('modals.error.text_delete_demand')}
          type="error"
        />
      )}
      {manageDemandModalOpen && (
        <ManageDemandModal
          demand={demand}
          closeDialog={() => setManageDemandModalOpen(false)}
          refetchDemands={refetch}
          decisionReason={decisionReason}
        />
      )}
      {changeDatesModalOpen && (
        <ChangeDatesDemandModal
          demandId={demand.id}
          closeDialog={() => setChangeDatesModalOpen(false)}
        />
      )}
      {cancelModalOpen && (
        <AlertDialog
          title={t('modals.confirmation.title_cancel_demand')}
          text={t('modals.confirmation.text_cancel_demand')}
          type="warning"
          showAcceptButton={false}
          showDeleteButton={true}
          deleteText={t('common:buttons.cancel')}
          deleteAction={handleCancelDemand}
          showCancelButton={true}
          cancelText={t('common:buttons.back')}
          cancelAction={() => setCancelModalOpen(false)}
        />
      )}
      {cancelQuery.isError && (
        <AlertDialog
          title={t('modals.error.title_cancel_demand')}
          text={cancelQuery.error?.message || t('modals.error.text_cancel_demand')}
          type="error"
        />
      )}
      {duplicateModalOpen && (
        <AlertDialog
          title={t('modals.confirmation.title_duplicate_demand')}
          text={t('modals.confirmation.text_duplicate_demand')}
          type="warning"
          acceptText={t('demands:buttons.duplicate')}
          acceptAction={() => handleDuplicateDemand(false)}
          showCancelButton={true}
          cancelText={t('common:buttons.cancel')}
          cancelAction={() => setDuplicateModalOpen(false)}
        />
      )}
      {duplicateAllModalOpen && (
        <AlertDialog
          title={t('modals.confirmation.title_duplicate_all_demand')}
          text={t('modals.confirmation.text_duplicate_all_demand')}
          type="warning"
          acceptText={t('demands:buttons.duplicate')}
          acceptAction={() => handleDuplicateDemand(true)}
          showCancelButton={true}
          cancelText={t('common:buttons.cancel')}
          cancelAction={() => setDuplicateAllModalOpen(false)}
        />
      )}
      {increaseCyclesModalOpen && (
        <IncreaseCyclesModal
          cycles={demand.quimioterapicoNumeroCiclos}
          demandId={demand.id}
          updateDemandCycles={updateDemandCycles}
          closeDialog={() => setIncreaseCyclesModalOpen(false)}
        />
      )}
      {updateDemandCyclesQuery.isError && (
        <AlertDialog
          title={t('modals.error.title_increment_cycles_demand')}
          text={t('modals.error.text_increment_cycles_demand')}
          type="error"
        />
      )}

      {duplicateQuery.isError && (
        <AlertDialog
          type="error"
          title={t('modals.error.title_duplicate_demand')}
          text={duplicateQuery.error?.message || t('modals.error.text_duplicate_demand')}
        />
      )}
      
      {insertClosingDemandModalOpen && (
        <InsertClosingCommentModal
          demandId={demand.id}
          finishDemandAction={handleFinishDemand}
          closeDialog={() => setInsertClosingDemandModalOpen(false)}
        />
      )}
      {finishModalOpen && (
        <AlertDialog
          title={t('modals.confirmation.title_finish_demand')}
          text={t('modals.confirmation.text_cancel_demand')}
          type="warning"
          showAcceptButton={false}
          showDeleteButton={true}
          deleteText={t('common:buttons.finish')}
          deleteAction={() => { setInsertClosingDemandModalOpen(true); setFinishModalOpen(false) }}
          showCancelButton={true}
          cancelText={t('common:buttons.back')}
          cancelAction={() => setFinishModalOpen(false)}
        />
      )}

      {uploadAttachmentModalOpen && (
        <UploadAttachmentModal
          demand={demand}
          closeDialog={() => setUploadAttachmentModalOpen(false)}
          refetchDemands={refetch}
        />
      )}
    </>
  );
};

DemandsActions.propTypes = {
  demand: PropTypes.object,
  roles: PropTypes.array,
};

export default DemandsActions;
