export const patientToOption = (item) => {
  let name = item.firstName;

  if (item.lastName) {
    name += ` ${item.lastName}`;
  }

  return {
    ...item,
    name,
  };
};

export const getRoleByPermissions = (roles, role) => {
  return roles?.data?.rows.find(({ permissions }) => permissions === role);
};
