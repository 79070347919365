import { useMutation, useQueryCache } from 'react-query';
import { updateCompany } from 'src/services/api/companies';

function useUpdateCompany() {
  const queryCache = useQueryCache();

  return useMutation(({ company, data }) => updateCompany(company, data), {
    onSuccess: (data) => queryCache.setQueryData(['company', data.data?.id], data),
  });
}

export default useUpdateCompany;
