import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core';
import uniq from 'lodash-es/uniq';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DetailsTable from './components';

const useStyles = makeStyles((theme) => ({
  cardContent: {
    paddingBottom: '12px !important',
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  empty: {
    margin: 20,
  },
}));

const PreviousTreatments = ({ previousTreatments }) => {
  const classes = useStyles();
  const { t } = useTranslation(['demands', 'common']);

  const prevTreatments = uniq(previousTreatments.map(({ type }) => type)).map((treatment) => ({
    type: treatment,
    treatments: previousTreatments.filter(({ type }) => type === treatment),
  }));

  return (
    <Card>
      <CardHeader
        title={t('demands:labels.viewDemand_previousTreatments_title')}
        subheader={t('demands:labels.viewDemand_previousTreatments_subtitle')}
      />
      <Divider />
      <CardContent className={classes.cardContent}>
        {prevTreatments.length ? (
          <DetailsTable previousTreatments={prevTreatments} />
        ) : (
          <Box className={classes.empty} display="flex" flexDirection="column" alignItems="center">
            <Typography color="secondary" variant="h5">
              {t('labels.viewDemand_empty_text')}
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

PreviousTreatments.propTypes = {
  previousTreatments: PropTypes.array,
};

export default PreviousTreatments;
