import { Card, CardContent, CardHeader, Container, Divider, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import AlertDialog from 'src/components/AlertDialog';
import CreateEditCodeForm from 'src/components/Codes/CreateEditCodeForm';
import Loader from 'src/components/Loader';
import Page from 'src/components/Page';
import { useGetCode, useUpdateCode } from 'src/hooks/codes';
import { useGoBack } from 'src/hooks/common';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const EditCodeView = () => {
  const { t } = useTranslation('codes');
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const goBack = useGoBack('/app/codes');
  const [
    updateCode,
    { isLoading: isLoadingUpdate, data: updatedData, isError: isErrorUpdate, error: errorUpdate },
  ] = useUpdateCode();
  const history = useHistory();
  const { codeId } = useParams();
  const {
    isLoading: isLoadingCode,
    data: code,
    isError: isErrorCode,
    error: errorCode,
  } = useGetCode(codeId);

  const handleSubmit = async (values) => {
    const data = {
      ...values,
      companyId: values.companyId.id,
    };
    const res = await updateCode({ code: codeId, data: data });

    if (res) {
      goBack();
    }
  };

  const handleCancel = () => {
    history.push('/app/codes', { replace: true });
  };

  useEffect(() => {
    if (!isLoadingUpdate && !isErrorUpdate && updatedData) {
      enqueueSnackbar(t('modals.success.text_update_code'), { variant: 'success' });
    }
  }, [enqueueSnackbar, isErrorUpdate, isLoadingUpdate, t, updatedData]);

  if (isErrorCode) {
    return (
      <AlertDialog
        title={t('modals.error.title_get_code')}
        text={errorCode?.message || t('modals.error.text_get_code')}
        type="error"
        acceptAction={() => history.push('/app/codes', { replace: true })}
      />
    );
  }

  return (
    <Page className={classes.root}>
      <Container maxWidth="lg">
        <Card>
          <CardHeader
            subheader={t('labels.updateCode_subtitle')}
            title={t('labels.updateCode_title')}
          />
          <Divider />
          <CardContent>
            {isLoadingCode && <Loader />}

            {!isLoadingCode && (
              <CreateEditCodeForm
                handleFormSubmit={handleSubmit}
                handleCancel={handleCancel}
                code={code}
                action="edit"
              />
            )}

            {isErrorUpdate && (
              <AlertDialog
                title={t('modals.error.title_update_code')}
                text={errorUpdate?.message || t('modals.error.text_update_code')}
                type="error"
              />
            )}
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default EditCodeView;
