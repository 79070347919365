import { Box, Dialog, DialogTitle, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ChangeDatesDemandForm from './components/ChangeDatesDemandForm';

const useStyles = makeStyles({
  dialog: {
    margin: '1.5rem',
  },
});

const initialValues = {
  changeDatesReason: '',
};

const ChangeDatesDemandModal = ({ demandId, closeDialog }) => {
  const classes = useStyles();
  const { t } = useTranslation('demands');
  const history = useHistory();

  const handleChangeDatesDemand = async (values) => {
    closeDialog();
    history.push(`/app/demands/${demandId}/edit`, { isChangeDates: true, ...values });
  };

  return (
    <Dialog open={true}>
      <Box className={classes.dialog} display="flex" flexDirection="column">
        <DialogTitle disableTypography={true}>
          <Typography variant="h4" align="center">
            {t('labels.change_dates_demand')}
          </Typography>
        </DialogTitle>

        <ChangeDatesDemandForm
          initialValues={initialValues}
          handleChangeDatesDemand={handleChangeDatesDemand}
          closeDialog={closeDialog}
        />
      </Box>
    </Dialog>
  );
};

ChangeDatesDemandModal.propTypes = {
  demandId: PropTypes.number,
  closeDialog: PropTypes.func,
};

export default ChangeDatesDemandModal;
