import { postRequest } from 'src/utils/http';

import { authController } from './consts';

export const login = async (email, password, token) =>
  postRequest(`${authController}/login`, { email, password, token });

export const forgotPassword = async (email, dni) =>
  postRequest(`${authController}/forgot`, { email, dni });

export const resetPassword = async (token, password) =>
  postRequest(`${authController}/recovery`, { token, password });
