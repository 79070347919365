import { Button, Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AlertDialog from 'src/components/AlertDialog';
import useRollbackDemand from 'src/hooks/demands/useRollbackDemand';
import { useSelector } from 'react-redux';
import {
  isAdmin as isAdminSelector,
  isManager as isManagerSelector,
  isRepresentive as isRepresentiveSelector,
  isSuperAdmin as isSuperAdminSelector,
} from 'src/store/selectors/user';

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: '7px !important',
  },
  button: {
    marginRight: theme.spacing(2),
  },
}));

const RollbackLastAction = ({ demandId, versions }) => {
  const classes = useStyles();
  //const history = useHistory();
  const isSuperAdmin = useSelector(isSuperAdminSelector);
  const isAdmin = useSelector(isAdminSelector);
  const isManager = useSelector(isManagerSelector);
  const displayRollback = isSuperAdmin || isAdmin || isManager ;
  
  const { t } = useTranslation('demands');
  const [confirmRollbackModal, setConfirmRollbackModal] = useState(false);
  const [
    rollbackDemand,
    { isLoading: isLoadingRollbackDemand, data: dataRollback, isError: isErrorRollback, error: errorRollback },
  ] = useRollbackDemand();

  const handleRollback = () => {
    setConfirmRollbackModal(false);
    rollbackDemand({demandId});
  };

  useEffect(() => {
    if (!isLoadingRollbackDemand && !isErrorRollback && dataRollback) {
      window.location.reload();
    }
  }, [dataRollback, isErrorRollback, isLoadingRollbackDemand]);
  //return null;
  console.log("¿Puede rollback?",displayRollback);

  return displayRollback && (
    <Grid item xs={12} align="right" className={classes.grid}>
      <Button
          color="primary"
          className={classes.button}
          variant="outlined"
          title={t('demands:buttons.rollback')}
          onClick={() => setConfirmRollbackModal(true)}
          disabled={versions.length < 2}
        >
          {t('demands:buttons.rollback')}
      </Button>
      {confirmRollbackModal && (
        <AlertDialog
          title={t('modals.confirmation.title_rollback_last_action')}
          text={t('modals.confirmation.text_rollback_last_action')}
          type="warning"
          showAcceptButton={false}
          showDeleteButton={true}
          deleteAction={handleRollback}
          showCancelButton={true}
          cancelAction={() => setConfirmRollbackModal(false)}
        />
      )}
      {isErrorRollback && (
        <AlertDialog
          title={t('modals.error.title_rollback_last_action')}
          text={errorRollback?.message || t('modals.error.text_rollback_last_action')}
          type="error"
        />
      )}
    </Grid>
  );
  
};

RollbackLastAction.propTypes = {
  demandId: PropTypes.number,
  versions: PropTypes.array
};

export default RollbackLastAction;
