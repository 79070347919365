import { Button, Card, CardContent, CardHeader, Divider, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useGoBack } from 'src/hooks/common';

import DetailsTable from './components';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 650,
    margin: '20px auto',
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  deleteButton: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
  },
}));

const PresentationDetails = ({ presentation, deletePresentation, disableActionButtons }) => {
  const classes = useStyles();
  const { t } = useTranslation(['presentations', 'common']);
  const goBack = useGoBack('/app/presentations');

  return (
    <Card className={classes.card}>
      <CardHeader
        title={t('labels.presentation_details_title')}
        subheader={t('labels.presentation_details_subheader')}
      />
      <Divider />
      <CardContent>
        <DetailsTable presentation={presentation} />

        <Button className={classes.button} variant="outlined" onClick={goBack}>
          {t('common:buttons.back')}
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          component={RouterLink}
          to={`/app/presentations/${presentation.id}/edit`}
          disabled={disableActionButtons}
        >
          {t('buttons.presentation_details_edit')}
        </Button>
        <Button
          className={clsx(classes.button, classes.deleteButton)}
          variant="contained"
          onClick={deletePresentation}
          disabled={disableActionButtons}
        >
          {t('buttons.presentation_details_remove')}
        </Button>
      </CardContent>
    </Card>
  );
};

PresentationDetails.propTypes = {
  presentation: PropTypes.object,
  deletePresentation: PropTypes.func,
  disableActionButtons: PropTypes.bool,
};

export default PresentationDetails;
