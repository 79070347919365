import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TYPE } from 'src/constants/centers';
import { isCenterSelected as isCenterSelectedSelector } from 'src/store/selectors/entity';

import Step5Form from './components/Step5Form';
import { useGetDemand } from 'src/hooks/demands';

const Step5 = ({
  demand,
  setDemand,
  demandExtras,
  setDemandExtras,
  disableForm,
  setDisableNext,
  eliminarFicherosAdjuntos,
  setEliminarFicherosAdjuntos,
  isContinue,
  isChangeDates,
  reloadAttachments,
  status
}) => {
  const [isFullfilled] = useState(!isEmpty(demand.step5));
  const [newFicherosAdjuntos] = useState(reloadAttachments() || []);
  const [ficherosAdjuntos, setFicherosAdjuntos] = useState(demand.step5.ficherosAdjuntos || []);
  console.log("-Ficheros cargados: ", newFicherosAdjuntos);
  

  const isCenterSelected = useSelector(isCenterSelectedSelector);
  const step5Data = demand.step5;
  const initialValues = {
    evolutivo: step5Data.evolutivo || false,
    esquemaTratamientoPrevio: step5Data.esquemaTratamientoPrevio || '',
    centerRealizatorId:
      step5Data.centerRealizatorId ?
        step5Data.centerRealizatorId 
        :
        (isCenterSelected.type === TYPE.AMBOS)
          ? isCenterSelected.id
          : '',
    ficherosAdjuntos: step5Data.ficherosAdjuntos || [],
    observaciones: step5Data.observaciones || '',
    comentariosOficiales: step5Data.comentariosOficiales || '',
  };
  const [step5Demand, setStep5Demand] = useState(demand.step5 || initialValues);

  useEffect(() => {
    console.log("### init ###");
    if (!isFullfilled) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }
  }, [isFullfilled, setDisableNext]);


  useEffect(() => {
    setDemand({
      ...demand,
      step5: {
        evolutivo: step5Demand.evolutivo,
        esquemaTratamientoPrevio: step5Demand.esquemaTratamientoPrevio,
        centerRealizatorId: step5Demand.centerRealizatorId,
        ficherosAdjuntos: step5Demand.ficherosAdjuntos,
        observaciones: step5Demand.observaciones,
        comentariosOficiales: step5Demand.comentariosOficiales,
        status: step5Demand.status
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step5Demand]);

/*
  useEffect(async () => {
    console.log("Inicializando valores...");
    const ficherosAdjuntos = await reloadAttachments();
    console.log("...Termino de cargar valores: ", ficherosAdjuntos);
    // Omit empty strings, but accept 0's
    
    const step5 = pickBy(
      step5Demand,
      (value) => value?.length || value?.length === undefined || isArray(value),
    );
    if(step5.ficherosAdjuntos == null)
      step5.ficherosAdjuntos = []
    //Array.prototype.push.apply(step5.ficherosAdjuntos, ficherosAdjuntos || []);
    initialValues.ficherosAdjuntos = step5.ficherosAdjuntos || [];
    console.log("Esto es step5: ", step5Demand);

    setDemand({ ...demand, step5 });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step5Demand, setDemand]);
*/

  return (
    <Step5Form
      initialValues={initialValues}
      setStep5Demand={setStep5Demand}
      demandExtras={demandExtras}
      setDemandExtras={setDemandExtras}
      isFullfilled={isFullfilled}
      disableForm={disableForm || isChangeDates}
      setDisableNext={setDisableNext}
      isContinue={isContinue}
      eliminarFicherosAdjuntos={eliminarFicherosAdjuntos}
      setEliminarFicherosAdjuntos={setEliminarFicherosAdjuntos}
      status={status}
    />
  );
};

Step5.propTypes = {
  demand: PropTypes.object,
  setDemand: PropTypes.func,
  demandExtras: PropTypes.object,
  setDemandExtras: PropTypes.func,
  disableForm: PropTypes.bool,
  setDisableNext: PropTypes.func,
  isContinue: PropTypes.bool,
  isChangeDates: PropTypes.bool,
  reloadAttachments: PropTypes.func,
  status: PropTypes.string
};

export default Step5;
