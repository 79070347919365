import {
  Avatar,
  Box,
  Card,
  Hidden,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  Edit as EditIcon,
  Eye as EyeIcon,
  Check as CheckIcon,
  X as XIcon,
  Users as UsersIcon,
  FileText as FileTextIcon,
} from 'react-feather';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import EmptyList from 'src/components/EmptyList';
import { ROLES } from 'src/constants/roles';
import { setFilters } from 'src/store/reducers/lists';
import { getInitials } from 'src/utils/getInitials';
import { getMediaUrl } from 'src/utils/getMediaUrl';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  table_list: {
    '& th': {
      padding: '5px',
    },
    '& td': {
      padding: '5px',
      color: theme.palette.tertiary.main,
      '& a,button': {
        padding: '0px 5px',
      },
    },
  },
}));

const Results = ({ users, page, setPage, pageSize, setPageSize, isLoading }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const authUser = useSelector((state) => state.user.user);
  const { t: tUsers } = useTranslation('users');
  const { t: tRoles } = useTranslation('rolesNames');

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage + 1);
  };

  const viewDoctorDemands = (user) => {
    const doctor = { id: user.id, name: user.name };
    dispatch(setFilters({ list: 'demands', filters: { doctor } }));
    history.push('/app/demands');
  };

  return (
    <Card>
      <PerfectScrollbar>
        <Box>
          <Table className={classes.table_list}>
            <TableHead>
              <TableRow>
                <TableCell>{tUsers('fields.name')}</TableCell>
                <TableCell>{tUsers('fields.id_card')}</TableCell>
                <Hidden smDown>
                  <TableCell>{tUsers('fields.email')}</TableCell>
                </Hidden>
                <TableCell>{tUsers('fields.role')}</TableCell>
                <TableCell>{tUsers('fields.company')}</TableCell>
                <TableCell align="center">{tUsers('fields.active')}</TableCell>
                <TableCell align="right">{tUsers('fields.actions')}</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {users?.rows.map((user) => (
                <TableRow hover key={user.id}>
                  <TableCell>
                    <Box alignItems="center" display="flex">
                      <Avatar
                        className={classes.avatar}
                        src={user.avatarId ? getMediaUrl(user.avatarId) : null}
                      >
                        {getInitials(user.name)}
                      </Avatar>
                      <Typography color="textPrimary" variant="body1">
                        {user.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{user.dni}</TableCell>
                  <Hidden smDown>
                    <TableCell>{user.email}</TableCell>
                  </Hidden>
                  <TableCell>{tRoles(user.role.permissions)}</TableCell>
                  <TableCell>{user.company.name}</TableCell>
                  <TableCell align="center">
                    {user.active ? <CheckIcon></CheckIcon> : <XIcon></XIcon>}
                  </TableCell>
                  <TableCell align="right">
                    {user.role?.permissions === ROLES.DOCTOR && (
                      <IconButton
                        color="primary"
                        disabled={isLoading}
                        onClick={() => viewDoctorDemands(user)}
                      >
                        <FileTextIcon />
                      </IconButton>
                    )}
                    <IconButton
                      color="primary"
                      component={RouterLink}
                      to={authUser.id === user.id ? '/app/account' : `/app/users/${user.id}/edit`}
                      disabled={isLoading}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="primary"
                      component={RouterLink}
                      to={
                        authUser.id === user.id ? '/app/account' : `/app/users/profile/${user.id}`
                      }
                      disabled={isLoading}
                    >
                      <EyeIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {!isLoading && !users?.rows.length && (
            <EmptyList text={tUsers('labels.empty_list_text')}>
              <UsersIcon />
            </EmptyList>
          )}
        </Box>
      </PerfectScrollbar>

      <TablePagination
        component="div"
        count={users?.count || 0}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePageSizeChange}
        page={users?.count ? page - 1 : 0}
        rowsPerPage={pageSize}
        rowsPerPageOptions={[25, 50, 100]}
      />
    </Card>
  );
};

Results.propTypes = {
  users: PropTypes.object,
  page: PropTypes.number,
  setPage: PropTypes.func,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default Results;
