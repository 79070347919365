import { Card, CardContent, CardHeader, Container, Divider, makeStyles } from '@material-ui/core';
import difference from 'lodash/difference';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import AlertDialog from 'src/components/AlertDialog';
import Loader from 'src/components/Loader';
import Page from 'src/components/Page';
import { useGoBack } from 'src/hooks/common';
import {
  useAddAssistants,
  useAddCenters,
  useAddDoctors,
  useAddZones,
  useGetUser,
  useRemoveAssistants,
  useRemoveCenters,
  useRemoveDoctors,
  useRemoveZones,
  useUpdateUser,
} from 'src/hooks/users';
import { getMediaUrl } from 'src/utils/getMediaUrl';

import EditUserForm from './components/EditUserForm';
import { ROLES } from '../../../constants/roles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const EditUserView = () => {
  const classes = useStyles();
  const goBack = useGoBack('/app/users');
  const { t } = useTranslation('users');
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingUpdates, setLoadingUpdates] = useState(false);
  const { userId } = useParams();
  const [
    updateUser,
    { data: dataUpdate, isError: isErrorUpdate, error: errorUpdate },
  ] = useUpdateUser();
  const [addAssistants, { isError: isErrorAddAssistants }] = useAddAssistants();
  const [removeAssistants, { isError: isErrorRemoveAssistants }] = useRemoveAssistants();
  const [addZones, { isError: isErrorAddZones }] = useAddZones();
  const [removeZones, { isError: isErrorRemoveZones }] = useRemoveZones();
  const [addCenters, { isError: isErrorAddCenters }] = useAddCenters();
  const [removeCenters, { isError: isErrorRemoveCenters }] = useRemoveCenters();
  const [addDoctors, { isError: isErrorAddDoctors }] = useAddDoctors();
  const [removeDoctors, { isError: isErrorRemoveDoctors }] = useRemoveDoctors();
  const { isLoading, data: user, isError, error } = useGetUser(userId, {
    include:
      'avatar,role,company,assistants,permissions,supervisorDoctors,supervisorCenters,centers,doctors,provinces',
  });
  const [avatar, setAvatar] = useState(null);

  const initialValues = {
    ...pick(user?.data, ['email', 'name', 'dni', 'role', 'active']),
    companyId: pick(user?.data.company, 'id', 'name', 'tramitByZones'),
    membershipNumber: user?.data.membershipNumber || '',
    companies: user?.data.permissions?.map(({ id, name }) => ({ id, name })) || [],
    assistants: user?.data.assistants?.map(({ id, name }) => ({ id, name })) || [],
    centers: user?.data.supervisorCenters?.length
      ? user?.data.supervisorCenters?.map(({ id, name }) => ({ id, name })) || []
      : user?.data.centers?.map(({ id, name }) => ({ id, name })) || [],
    doctors: user?.data.supervisorDoctors?.length
      ? user?.data.supervisorDoctors?.map(({ id, name }) => ({ id, name })) || []
      : user?.data.doctors?.map(({ id, name }) => ({ id, name })) || [],
    provinces: user?.data.provinces?.map(({ id, name }) => ({ id, name })) || [],
    showPasswordForm: false,
    password: '',
    confirmPassword: '',
  };

  const handleSubmit = async (values) => {
    setLoadingUpdates(true);

    const data = omit(values, 'assistants', 'centers', 'doctors', 'provinces');
    const res = await updateUser({ user: userId, data });

    if (res?.data) {
      const queries = [];
      queries.push(
        updateUserRelation(
          'assistants',
          user.data.assistants,
          values.assistants,
          addAssistants,
          removeAssistants,
        ),
      );
      queries.push(
        updateUserRelation('zones', user.data.provinces, values.provinces, addZones, removeZones),
      );
      queries.push(
        updateUserRelation(
          'centers',
          user.data.role.permissions === ROLES.SUPERVISOR
            ? user.data.supervisorCenters
            : user.data.centers,
          values.centers,
          addCenters,
          removeCenters,
        ),
      );
      queries.push(
        updateUserRelation(
          'doctors',
          user.data.role.permissions === ROLES.SUPERVISOR
            ? user.data.supervisorDoctors
            : user.data.doctors,
          values.doctors,
          addDoctors,
          removeDoctors,
        ),
      );

      await Promise.all(queries);

      setLoadingUpdates(false);
    }
  };

  const updateUserRelation = async (key, currentValues, newValues, add, remove) => {
    const previousValues = currentValues.map(({ id }) => id);
    const removedElements = difference(previousValues, newValues);
    const addedElements = difference(newValues, previousValues);

    if (removedElements.length) {
      await remove({ user: userId, data: { [key]: removedElements } });
    }
    if (addedElements.length) {
      await add({ user: userId, data: { [key]: addedElements } });
    }
  };

  useEffect(() => {
    setAvatar(getMediaUrl(user?.data?.avatarId));
  }, [user?.data?.avatarId]);

  useEffect(() => {
    if (
      !loadingUpdates &&
      !isErrorUpdate &&
      !isErrorAddAssistants &&
      !isErrorRemoveAssistants &&
      !isErrorAddCenters &&
      !isErrorRemoveCenters &&
      !isErrorAddDoctors &&
      !isErrorRemoveDoctors &&
      !isErrorAddZones &&
      !isErrorRemoveZones &&
      dataUpdate
    ) {
      enqueueSnackbar(t('modals.success.text_edit_user'), { variant: 'success' });

      goBack();
    }
  }, [
    dataUpdate,
    enqueueSnackbar,
    goBack,
    isErrorAddAssistants,
    isErrorAddCenters,
    isErrorAddDoctors,
    isErrorAddZones,
    isErrorRemoveAssistants,
    isErrorRemoveCenters,
    isErrorRemoveDoctors,
    isErrorRemoveZones,
    isErrorUpdate,
    loadingUpdates,
    t,
  ]);

  if (isError) {
    return (
      <AlertDialog
        title={t('modals.error.title_get_user')}
        text={error.message}
        type="error"
        acceptAction={() => history.push('/app/users', { replace: true })}
      />
    );
  }

  return (
    <Page className={classes.root}>
      <Container maxWidth="lg">
        <Card>
          <CardHeader
            subheader={t('labels.editUser_subtitle')}
            title={t('labels.editUser_title')}
          />
          <Divider />
          <CardContent>
            {isLoading && <Loader />}

            {!isLoading && (
              <EditUserForm
                initialValues={initialValues}
                handleFormSubmit={handleSubmit}
                user={user?.data}
                avatar={avatar}
                setAvatar={setAvatar}
                goBack={goBack}
              />
            )}

            {(isErrorUpdate ||
              isErrorAddAssistants ||
              isErrorRemoveAssistants ||
              isErrorAddZones ||
              isErrorRemoveZones ||
              isErrorAddCenters ||
              isErrorRemoveCenters ||
              isErrorAddDoctors ||
              isErrorRemoveDoctors) && (
              <AlertDialog
                title={t('modals.error.title_edit_user')}
                text={errorUpdate?.message || t('modals.error.text_edit_user')}
                type="error"
              />
            )}
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default EditUserView;
