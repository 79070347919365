import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Container,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Formik } from 'formik';
import get from 'lodash/get';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import AlertDialog from 'src/components/AlertDialog';
import Page from 'src/components/Page';
import { getMediaUrl } from 'src/utils/getMediaUrl';
import { ROLES } from 'src/constants/roles';
import { useCreateInvitation } from 'src/hooks/invitations';
import { useGetRoles } from 'src/hooks/roles';
import { isAuthenticated as isAuthenticatedSelector } from 'src/store/selectors/auth';
import FilesField from 'src/components/FilesField';
import * as Yup from 'yup';
import useListCentersCatalog from 'src/hooks/catalogs/useListCentersCatalog';
import omit from 'lodash/omit';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: theme.spacing(3),
  },
  headerContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%',
  },
  cardContent: {
    flexGrow: 1,
  },
}));

const RequestInvitationView = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const [
    createInvitation,
    { isLoading: isLoadingCreate, data: newInvitation, isError: isErrorCreate, error: errorCreate },
  ] = useCreateInvitation();
  const { isLoading: isLoadingRoles, data: roles, isError: isErrorRoles } = useGetRoles({
    all: true,
  });
  const history = useHistory();
  const appState = useSelector((state) => state.app);
  const companyName = get(appState, 'data.name');
  const companySlogan = get(appState, 'data.slogan');
  const isDefaultCompany = get(appState, 'data.default');
  const cardsImages = get(appState, 'data.homeCards');

  const { t } = useTranslation(['invitations', 'rolesNames', 'roles']);

  const toLogin = () => {
    history.push('/login');
  };

  const cards = [
    {
      title: 'Paciente',
      image: cardsImages && cardsImages[0].id,
      content:
        'Evaluamos una amplia gama de tecnologías médicas para determinar el impacto en la seguridad del paciente, los resultados de salud y la utilización de recursos',
    },
    {
      title: 'Cliente',
      image: cardsImages && cardsImages[1].id,
      content:
        'Ayudamos a nuestros clientes a integrar la evidencia científica en sus procesos y decisiones, dotando a nuestros clientes de herramientas para el uso adecuado y racional de todos los recursos disponibles actualmente para el tratamiento de los pacientes',
    },
    {
      title: 'Aseguradoras Médicas',
      image: cardsImages && cardsImages[2].id,
      content:
        'Permite desarrollar políticas de coberturas defendibles y transparentes, mejorar los protocolos de gestión y, avanzar en el seguimiento de las guías de buenas prácticas',
    },
  ];

  const centersQuery = useListCentersCatalog({ all: true });

  const getRolesOptions = () => {
    if (!isLoadingRoles && !isErrorRoles && roles.data.rows.length) {
      const availableRoles = roles.data.rows.filter(
        (role) => role.permissions === ROLES.ASSISTANT || role.permissions === ROLES.DOCTOR,
      );

      const translatedAvailableRoles = availableRoles.map((role) => {
        const name = t(`rolesNames:${role.permissions}`);
        return { ...role, name };
      });

      return translatedAvailableRoles;
    }

    return [];
  };

  const onSubmit = async (values, formikBag) => {
    const invitation = await createInvitation({ 
      ...omit(values, ['centers']), 
      type: values.type.permissions,
      centersNames: values.centers.map(({ name }) => name).join(', ')
    });
    formikBag.setSubmitting(false);

    if (invitation) {
      formikBag.resetForm();
    }
  };

  useEffect(() => {
    if (isErrorRoles) {
      enqueueSnackbar(t('roles:modals.error.title_get_roles'), { variant: 'error' });
    }
  }, [enqueueSnackbar, isErrorRoles, t]);

  useEffect(() => {
    if (centersQuery.isError) {
      enqueueSnackbar(t('centers:modals.error.title_list_centers'), { variant: 'error' });
    }
  }, [enqueueSnackbar, centersQuery.isError, t]);

  if (isAuthenticated) {
    return <Redirect to="/app" />;
  }

  return (
    <Page className={classes.root}>
      <div className={classes.headerContent}>
        <Container maxWidth="sm">
          <Typography component="h1" variant="h1" align="center" color="textPrimary">
            {companyName}
          </Typography>
          <Typography component="h5" variant="h5" align="center" color="textPrimary" gutterBottom>
            {companySlogan || ''}
          </Typography>
          <Typography variant="h5" align="center" color="textSecondary" paragraph>
            Nuestro esfuerzo está enfocado a la transformación de la asistencia sanitaria y a la
            mejora de los resultados médicos de los pacientes, a través de la integración de las
            evidencias científicas con el desarrollo de políticas y la toma de decisiones
          </Typography>
          <div className={classes.heroButtons}>
            <Grid container spacing={2} justify="center">
              {!isDefaultCompany && (
                <Grid item>
                  <Link to="#request">
                    <Button variant="contained" color="primary">
                      Solicita tu cuenta
                    </Button>
                  </Link>
                </Grid>
              )}
              <Grid item>
                <Button variant="outlined" color="primary" onClick={toLogin}>
                  Accede a tu cuenta
                </Button>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>

      <Container className={classes.cardGrid} maxWidth="md">
        <Grid container spacing={4}>
          {cards.map((card) => (
            <Grid item key={card.title} xs={12} sm={6} md={4}>
              <Card className={classes.card}>
                <CardMedia className={classes.cardMedia} image={getMediaUrl(card.image)} />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant="h5" component="h2">
                    {card.title}
                  </Typography>
                  <Typography>{card.content}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {!isDefaultCompany && (
        <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
          <Container maxWidth="sm">
            <Formik
              enableReinitialize
              initialValues={{
                email: '',
                name: '',
                phone: '',
                type: null,
                centers: [],
                attachments: [],
                doctorsDni: '',
                reason: '',
              }}
              validationSchema={Yup.object().shape({
                type: Yup.object().nullable().required(t('errors.role.required')),
                email: Yup.string()
                  .email(t('errors.email.valid'))
                  .max(255, t('errors.email.max'))
                  .required(t('errors.email.required')),
                name: Yup.string()
                  .min(3, t('errors.name.min'))
                  .max(200, t('errors.name.max', { max: 200 }))
                  .required(t('errors.name.required')),
                phone: Yup.string()
                  .min(9, t('errors.phone.min'))
                  .max(12, t('errors.phone.max'))
                  .required(t('errors.phone.required')),
                centers: Yup.string()
                  .required(t('errors.centers.required')),
                doctorsDni: Yup.string().when('type', {
                  is: (type) => type && type.permissions === ROLES.ASSISTANT,
                  then: Yup.string().required(t('errors.doctors.required')),
                  otherwise: Yup.string(),
                }),
                attachments: Yup.array().of(
                  Yup.object().shape({
                    descripcion: Yup.string().required(t('errors.attachments_values.descripcion.required')),
                    archivo: Yup.string().required(t('errors.attachments_values.archivo.required')),
                  }),
                ),
                reason: Yup.string()
                  .min(10, t('errors.reason.min'))
                  .max(255, t('errors.reason.max'))
                  .required(t('errors.reason.required')),
              })}
              onSubmit={onSubmit}
            >
              {({
                errors,
                handleBlur,
                setFieldTouched,
                handleChange,
                setFieldValue,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form id="request" name="request" onSubmit={handleSubmit}>
                  <Box mb={3}>
                    <Typography color="textPrimary" variant="h2">
                      {t('labels.invitations_request_title')}
                    </Typography>
                    <Typography color="textSecondary" gutterBottom variant="body2">
                      {t('labels.invitations_request_subtitle')}
                    </Typography>
                  </Box>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <TextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        label={t('fields.email')}
                        name="email"
                        margin="normal"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        disabled={isSubmitting}
                        type="email"
                        value={values.email}
                        variant="outlined"
                      />
                      <TextField
                        error={Boolean(touched.name && errors.name)}
                        fullWidth
                        helperText={touched.name && errors.name}
                        label={t('fields.name')}
                        margin="normal"
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        error={Boolean(touched.phone && errors.phone)}
                        fullWidth
                        helperText={touched.phone && errors.phone}
                        label={t('fields.phone')}
                        margin="normal"
                        name="phone"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.phone}
                        variant="outlined"
                      />

                      <Autocomplete
                        openOnFocus={true}
                        selectOnFocus={false}
                        fullWidth
                        options={getRolesOptions()}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) => option.id === value.id}
                        loading={isLoadingRoles}
                        name="type"
                        value={values.type}
                        onChange={(e, value) => setFieldValue('type', value)}
                        onBlur={() => setFieldTouched('type', true)}
                        disabled={isSubmitting}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t('fields.role')}
                            variant="outlined"
                            margin="normal"
                            error={Boolean(touched.type && errors.type)}
                            helperText={touched.type && errors.type}
                            inputProps={{
                              ...params.inputProps,
                              style: { cursor: 'pointer' },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              readOnly: true,
                              endAdornment: (
                                <>
                                  {isLoadingRoles ? (
                                    <CircularProgress color="inherit" size={20} />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  
                  <Autocomplete
                    openOnFocus={true}
                    options={centersQuery.data?.data?.rows || []}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => option.id === value.id}
                    multiple
                    filterSelectedOptions
                    value={values.centers}
                    name="centers"
                    loading={centersQuery.isLoading}
                    disabled={isSubmitting}
                    onChange={(e, value) => setFieldValue('centers', value)}
                    onBlur={() => setFieldTouched('centers', true)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('fields.centers')}
                        variant="outlined"
                        error={Boolean(touched.centers && errors.centers)}
                        helperText={touched.centers && errors.centers}
                      />
                    )}
                  />
                  {(values.type && (values.type.permissions === ROLES.ASSISTANT 
                      || values.type.permissions === ROLES.DOCTOR)) && (
                    <TextField
                        error={Boolean(touched.doctorsDni && errors.doctorsDni)}
                        fullWidth
                        helperText={Boolean(touched.doctorsDni && errors.doctorsDni) ? 'DNI de doctores es requerido, Ej. 12345678A, A1234567B,...' : ''}
                        label={(values.type && values.type.permissions === ROLES.DOCTOR) ? t('fields.doctor') : t('fields.doctors')}
                        margin="normal"
                        name="doctorsDni"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.doctorsDni}
                        variant="outlined"
                      />
                  )}
      
                  <TextField
                    error={Boolean(touched.reason && errors.reason)}
                    fullWidth
                    helperText={touched.reason && errors.reason}
                    label={t('fields.reason')}
                    multiline
                    rows={4}
                    margin="normal"
                    name="reason"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.reason}
                    variant="outlined"
                  />

                  <br></br>                  
                  {(values.type && values.type.permissions === ROLES.ASSISTANT) && (
                    <>
                      <Typography color="textPrimary" variant="h3">
                       Modelo de Representación
                      </Typography>
                      <Typography color="textSecondary" gutterBottom variant="body2">
                        <p>Debe incorporar el modelo de representación correctamente cumplimentado.</p>
                        <br /><a target='_BLANK' href='https://www.orgoa.es/wp-content/PGO_ModeloRepresentacion.pdf'>Descargar Modelo</a>
                        <br />
                        <br />
                      </Typography>
                    </>
                  )}

                  {(values.type && values.type.permissions === ROLES.ASSISTANT) && (
                    <FilesField
                      field="attachments"
                      values={values}
                      errors={errors}
                      touched={touched}
                      setFieldTouched={setFieldTouched}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      disabled={isSubmitting}
                    />
                  )}
                  <Box my={2}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      {t('buttons.send')}
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        </Box>
      )}

      {!isLoadingCreate && !isErrorCreate && newInvitation && (
        <AlertDialog
          title={t('modals.success.title_request_invitation')}
          text={t('modals.success.text_request_invitation')}
          type="success"
        />
      )}
      {isErrorCreate && (
        <AlertDialog
          title={t('modals.error.title_request_invitation')}
          text={errorCreate?.message || t('modals.error.text_request_invitation')}
          type="error"
        />
      )}
    </Page>
  );
};

export default RequestInvitationView;
