import { makeStyles } from '@material-ui/core';
import { Route, Redirect, Switch, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import RequestInvitationView from 'src/views/invitations/RequestInvitationView';
import UseInvitationView from 'src/views/invitations/UseInvitationView';
import TopBar from './components/TopBar';
import ForgotPasswordView from 'src/views/auth/ForgotPasswordView';
import ResetPasswordView from 'src/views/auth/ResetPasswordView';
import CompanyPolicyView from 'src/views/companies/CompanyPolicyView';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
}));

const Container = ({ classes, children }) => (
  <div className={classes.root}>
    <TopBar />
    <div className={classes.wrapper}>
      <div className={classes.contentContainer}>
        <div className={classes.content}>{children}</div>
      </div>
    </div>
  </div>
);

const MainLayout = () => {
  const classes = useStyles();
  const { path } = useRouteMatch();

  return (
    <Container classes={classes}>
      <Switch>
        <Route exact path={path}>
          <RequestInvitationView />
        </Route>
        <Route path={`${path}login`}>
          <LoginView />
        </Route>
        <Route path={`${path}forgot-password`}>
          <ForgotPasswordView />
        </Route>
        <Route path={`${path}reset-password/:token`}>
          <ResetPasswordView />
        </Route>
        <Route path={`${path}invitations/use/:token`}>
          <UseInvitationView />
        </Route>
        <Route path={`${path}policies/:companyPolicy`}>
          <CompanyPolicyView />
        </Route>
        <Route path={`${path}404`}>
          <NotFoundView />
        </Route>
        <Route path={`${path}*`}>
          <Redirect to="/404" />
        </Route>
      </Switch>
    </Container>
  );
};

Container.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.object,
};

export default MainLayout;
