import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  LinearProgress,
  makeStyles,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryCache } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import AlertDialog from 'src/components/AlertDialog';
import Loader from 'src/components/Loader';
import { useGoBack } from 'src/hooks/common';
import { useRejectInvitation, useValidateInvitation } from 'src/hooks/invitations';
import { useGetInvitation } from 'src/hooks/invitations';
import { DetailsTable } from './components/DetailsTable';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 650,
    margin: '20px auto',
  },
  backButton: {
    marginTop: theme.spacing(2),
  },
}));

const InvitationDetailView = () => {
  const queryCache = useQueryCache();
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { t: tInvitations } = useTranslation('invitations');
  const { t: tCommon } = useTranslation('common');
  const { invitationId } = useParams();
  const { isLoading, data: invitation, isError, error } = useGetInvitation(invitationId);
  const [
    validateInvitationQuery,
    {
      isLoading: isLoadingValidate,
      data: invitationValidated,
      isError: isErrorValidate,
      error: errorValidate,
    },
  ] = useValidateInvitation();
  const [
    rejectInvitationQuery,
    {
      isLoading: isLoadingReject,
      data: invitationRejected,
      isError: isErrorReject,
      error: errorReject,
    },
  ] = useRejectInvitation();
  const goBack = useGoBack('/app/invitations/list');
  const validateInvitation = async () => {
    const res = await validateInvitationQuery(invitationId);
    if (res) {
      queryCache.setQueryData(['invitations', invitationId], {
        data: {
          ...invitation.data,
          status: res.data.status,
        },
      });
    }
  };

  const rejectInvitation = async () => {
    const res = await rejectInvitationQuery(invitationId);
    if (res) {
      queryCache.setQueryData(['invitations', invitationId], {
        data: {
          ...invitation.data,
          status: res.data.status,
        },
      });
    }
  };

  useEffect(() => {
    if (!isLoadingValidate && !isErrorValidate && invitationValidated) {
      enqueueSnackbar(tInvitations('modals.success.text_validate_invitation'), {
        variant: 'success',
      });
    }
  }, [enqueueSnackbar, invitationValidated, isErrorValidate, isLoadingValidate, tInvitations]);

  useEffect(() => {
    if (!isLoadingReject && !isErrorReject && invitationRejected) {
      enqueueSnackbar(tInvitations('modals.success.text_reject_invitation'), {
        variant: 'success',
      });
    }
  }, [enqueueSnackbar, invitationRejected, isErrorReject, isLoadingReject, tInvitations]);

  if (isError) {
    return (
      <AlertDialog
        title={tInvitations('modals.error.title_details_invitation')}
        text={error?.message || tInvitations('modals.error.text_details_invitation')}
        type="error"
        acceptAction={() => history.push('/app/invitations', { replace: true })}
      />
    );
  }

  return (
    <Card className={classes.card}>
      <CardHeader
        title={tInvitations('labels.invitations_details_title')}
        subheader={tInvitations('labels.invitations_details_subtitle')}
      />

      <Divider />

      <CardContent>
        {isLoading && <Loader />}

        {(isLoadingValidate || isLoadingReject) && <LinearProgress color="primary" />}

        {!isLoading && (
          <DetailsTable
            invitation={invitation?.data}
            validateInvitation={validateInvitation}
            rejectInvitation={rejectInvitation}
            disableActionButtons={isLoadingValidate || isLoadingReject}
          />
        )}

        <Button className={classes.backButton} variant="outlined" onClick={goBack}>
          {tCommon('buttons.back')}
        </Button>

        {isErrorValidate && (
          <AlertDialog
            title={tInvitations('modals.error.title_validate_invitation')}
            text={errorValidate?.message || tInvitations('modals.error.text_validate_invitation')}
            type="error"
          />
        )}
        {isErrorReject && (
          <AlertDialog
            title={tInvitations('modals.error.title_reject_invitation')}
            text={errorReject?.message || tInvitations('modals.error.text_reject_invitation')}
            type="error"
          />
        )}
      </CardContent>
    </Card>
  );
};

export default InvitationDetailView;
