import { Button, Card, CardContent, CardHeader, Divider, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useGoBack } from 'src/hooks/common';
import DetailsTable from './components';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 650,
    margin: '20px auto',
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const CodeDetails = ({ code }) => {
  const classes = useStyles();
  const { t } = useTranslation(['codes', 'common']);
  const goBack = useGoBack('/app/codes');

  return (
    <Card className={classes.card}>
      <CardHeader
        title={t('labels.detailsCode_title')}
        subheader={t('labels.detailsCode_subtitle')}
      />
      <Divider />
      <CardContent>
        <DetailsTable code={code} />

        <Button className={classes.button} variant="outlined" onClick={goBack}>
          {t('common:buttons.back')}
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          component={RouterLink}
          to={`/app/codes/${code.id}/edit`}
        >
          {t('buttons.edit_code')}
        </Button>
      </CardContent>
    </Card>
  );
};

CodeDetails.propTypes = {
  code: PropTypes.object,
};

export default CodeDetails;
