import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Avatar, Box, Card, CardContent, Typography, makeStyles } from '@material-ui/core';
import { getMediaUrl } from 'src/utils/getMediaUrl';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {},
  userDetails: {
    wordBreak: 'break-all',
  },
  avatar: {
    height: 100,
    width: 100,
  },
}));

const Profile = ({ className, user, ...rest }) => {
  const classes = useStyles();
  const { t } = useTranslation(['account', 'rolesNames']);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Box alignItems="center" display="flex" flexDirection="column">
          <Avatar className={classes.avatar} src={getMediaUrl(user?.avatar?.id)} />
          <Typography color="textPrimary" gutterBottom variant="h3" align="center">
            {user.name}
          </Typography>
          <Typography
            className={clsx(classes.userDetails, className)}
            color="textSecondary"
            variant="body1"
            align="center"
          >
            {user.email}
          </Typography>
          <Typography
            className={classes.dateText}
            color="textSecondary"
            variant="body1"
            align="center"
          >
            {t('labels.roleCompany', {
              role: t(`rolesNames:${user.role.permissions}`),
              company: user.company.name,
            })}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.object,
  user: PropTypes.object,
};

export default Profile;
