const STATUS = {
  0: 'PENDING',
  1: 'ACCEPTED',
  2: 'REJECTED',
  3: 'USED',
  PENDING: 0,
  ACCEPTED: 1,
  REJECTED: 2,
  USED: 3,
};

export { STATUS };
