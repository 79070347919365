import { Box, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink as RouterLink } from 'react-router-dom';
import { isCenterSelected as isCenterSelectedSelector, isDoctorSelected as isDoctorSelectedSelector } from 'src/store/selectors/entity';
import { isAssistant as isAssistantSelector, isDoctor as isDoctorSelector } from 'src/store/selectors/user';

const ToolbarActions = () => {
  const { t } = useTranslation('demands');
  const isAssistant = useSelector(isAssistantSelector);
  const isDoctor = useSelector(isDoctorSelector);
  const isDoctorSelected = useSelector(isDoctorSelectedSelector);
  const isCenterSelected = useSelector(isCenterSelectedSelector);

  return (
    <Box display="flex" justifyContent="flex-end">
      {(isAssistant && isDoctorSelected && isCenterSelected) || (isDoctor && isCenterSelected) ? (
        <Button color="primary" variant="contained" component={RouterLink} to="/app/demands/create">
          {t('buttons.create_demand')}
        </Button>
      ) : null}
    </Box>
  );
};

ToolbarActions.propTypes = {};

export default ToolbarActions;
