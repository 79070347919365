import { useMutation, useQueryCache } from 'react-query';
import { updatePatient } from 'src/services/api/patients';

function useUpdatePatient() {
  const queryCache = useQueryCache();

  return useMutation(({ patient, data }) => updatePatient(patient, data), {
    onSuccess: (data) => queryCache.setQueryData(['patient', data.data?.id], data),
  });
}

export default useUpdatePatient;
