import { Card, CardContent, CardHeader, Container, Divider, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import AlertDialog from 'src/components/AlertDialog';
import Loader from 'src/components/Loader';
import Page from 'src/components/Page';
import CreateEditPatientForm from 'src/components/Patients/CreateEditPatientForm';
import { useGoBack } from 'src/hooks/common';
import { useGetPatient } from 'src/hooks/patients';
import { useUpdatePatient } from 'src/hooks/patients';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const EditPatientView = () => {
  const { t } = useTranslation('patients');
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const goBack = useGoBack('/app/patients');
  const [
    updatePatient,
    { isLoading: isLoadingUpdate, data: updatedData, isError: isErrorUpdate, error: errorUpdate },
  ] = useUpdatePatient();
  const history = useHistory();
  const { patientId } = useParams();
  const {
    isLoading: isLoadingPatient,
    data: patient,
    isError: isErrorPatient,
    error: errorPatient,
  } = useGetPatient(patientId);

  const handleSubmit = async ({ patient, values }) => {
    const res = await updatePatient({ patient: patient.data.id, data: values });

    if (res) {
      goBack();
    }
  };

  const handleCancel = () => {
    history.push('/app/patients', { replace: true });
  };

  useEffect(() => {
    if (!isLoadingUpdate && !isErrorUpdate && updatedData) {
      enqueueSnackbar(t('modals.success.text_update_patient'), { variant: 'success' });
    }
  }, [enqueueSnackbar, isErrorUpdate, isLoadingUpdate, t, updatedData]);

  if (isErrorPatient) {
    return (
      <AlertDialog
        title={t('modals.error.title_get_patient')}
        text={errorPatient?.message || t('modals.error.text_get_patient')}
        type="error"
        acceptAction={() => history.push('/app/patients', { replace: true })}
      />
    );
  }

  return (
    <Page className={classes.root}>
      <Container maxWidth="lg">
        <Card>
          <CardHeader
            subheader={t('labels.updatePatient_subtitle')}
            title={t('labels.updatePatient_title')}
          />
          <Divider />
          <CardContent>
            {isLoadingPatient && <Loader />}

            {!isLoadingPatient && (
              <CreateEditPatientForm
                handleFormSubmit={handleSubmit}
                handleCancel={handleCancel}
                patient={patient}
                action="edit"
              />
            )}

            {isErrorUpdate && (
              <AlertDialog
                title={t('modals.error.title_update_patient')}
                text={errorUpdate?.message || t('modals.error.text_update_patient')}
                type="error"
              />
            )}
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default EditPatientView;
