import { deleteRequest, getRequest, postRequest, putRequest } from 'src/utils/http';

import { presentationsController } from './consts';

export const getPresentationById = async (id, params) =>
  getRequest(`${presentationsController}/${id}`, params);

export const createPresentation = async (body) => postRequest(`${presentationsController}`, body);

export const updatePresentation = async (id, data) =>
  putRequest(`${presentationsController}/${id}`, data);

export const listPresentations = async (params) => getRequest(`${presentationsController}`, params);

export const deletePresentation = async (presentation) =>
  deleteRequest(`${presentationsController}/${presentation}`);

export const getPresentations = async () => getRequest(`${presentationsController}/all`);
