const TYPE = {
  FUNCIONARIO: 'Funcionario',
  ISFAS: 'ISFAS',
  MUFACE: 'MUFACE',
  MUGEJU: 'MUGEJU',
  PRIVADA: 'Privada',
  UNDEFINED: 'Sin determinar',
};

export { TYPE };
