import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, CardHeader, Container, Divider, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import CreateDrugForm from './components';
import { useCreateDrug } from 'src/hooks/drugs';
import { useGoBack } from 'src/hooks/common';
import { useSnackbar } from 'notistack';
import AlertDialog from 'src/components/AlertDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const initialValues = {
  name: '',
  activePrinciple: '',
  dosage: '',
  administration: '',
};

const CreateDrugView = () => {
  const classes = useStyles();
  const { t } = useTranslation('drugs');
  const { enqueueSnackbar } = useSnackbar();
  const goBack = useGoBack('/app/drugs');
  const [createDrug, { data, isError, error }] = useCreateDrug();

  const handleSubmit = (values) => createDrug(values);

  useEffect(() => {
    if (data?.data) {
      enqueueSnackbar(t('modals.create_drug.success.text'), { variant: 'success' });

      goBack();
    }
  }, [data?.data, enqueueSnackbar, goBack, t]);

  return (
    <Page className={classes.root}>
      <Container maxWidth="lg">
        <Card>
          <CardHeader
            subheader={t('labels.create_drug_subheader')}
            title={t('labels.create_drug_title')}
          />
          <Divider />
          <CardContent>
            <CreateDrugForm initialValues={initialValues} handleFormSubmit={handleSubmit} />

            {isError && (
              <AlertDialog
                title={t('modals.create_drug.error.title')}
                text={error?.message || t('modals.create_drug.error.text')}
                type="error"
              />
            )}
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default CreateDrugView;
