import { Card, CardContent, CardHeader, Container, Divider, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import AlertDialog from 'src/components/AlertDialog';
import CreateEditCenterForm from 'src/components/Centers/CreateEditCenterForm';
import Loader from 'src/components/Loader';
import Page from 'src/components/Page';
import { ROLES } from 'src/constants/roles';
import { useGetCenter } from 'src/hooks/centers';
import { useUpdateCenter } from 'src/hooks/centers';
import { useGoBack } from 'src/hooks/common';
import { useGetRoles } from 'src/hooks/roles';
import { useListUsers } from 'src/hooks/users';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const EditCenterView = () => {
  const { t } = useTranslation('centers');
  const goBack = useGoBack('/app/centers');
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const history = useHistory();
  const { centerId } = useParams();
  const { isLoading, data: center, isError: isErrorGet, error: errorGet } = useGetCenter(centerId, {
    include: 'doctors',
  });

  const [
    updateCenter,
    { isLoading: isLoadingUpdate, data: dataUpdate, isError: isErrorUpdate, error: errorUpdate },
  ] = useUpdateCenter();

  const { isLoading: isLoadingRoles, data: roles, isErrorRoles } = useGetRoles({
    all: true,
  });

  const getRoleByPermissions = (role) => {
    return roles?.data.rows.find(({ permissions }) => permissions === role);
  };

  const { isLoading: isLoadingDoctors, resolvedData: users } = useListUsers(
    {
      all: true,
      roleId: getRoleByPermissions(ROLES.DOCTOR)?.id,
    },
    !isLoadingRoles && !isErrorRoles,
  );

  const handleSubmit = async (values) => {
    const doctors = values.doctors.map(({ id }) => id);
    values.doctors = doctors;

    const center = await updateCenter({ center: centerId, data: values });

    if (center.data) {
      goBack();
    }
  };

  const handleCancel = () => {
    history.push('/app/centers', { replace: true });
  };

  useEffect(() => {
    if (!isLoadingUpdate && !isErrorUpdate && dataUpdate) {
      enqueueSnackbar(t('modals.success.text_update_center'), {
        variant: 'success',
      });
    }
  }, [dataUpdate, enqueueSnackbar, isErrorUpdate, isLoadingUpdate, t]);

  if (isErrorGet) {
    return (
      <AlertDialog
        title={t('modals.error.title_get_center')}
        text={errorGet?.message || t('modals.error.text_get_center')}
        type="error"
        acceptAction={() => history.push('/app/centers', { replace: true })}
      />
    );
  }

  return (
    <Page className={classes.root}>
      <Container maxWidth="lg">
        <Card>
          <CardHeader
            subheader={t('labels.updateCenter_subtitle')}
            title={t('labels.updateCenter_title')}
          />
          <Divider />
          <CardContent>
            {isLoading && <Loader />}

            {!isLoading && !isErrorGet && (
              <CreateEditCenterForm
                handleFormSubmit={handleSubmit}
                handleCancel={handleCancel}
                center={center}
                users={users}
                operation="edit"
                isLoadingDoctors={isLoadingDoctors}
              />
            )}

            {isErrorUpdate && (
              <AlertDialog
                title={t('modals.error.title_update_center')}
                text={errorUpdate?.message || t('modals.error.text_update_center')}
                type="error"
              />
            )}
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default EditCenterView;
