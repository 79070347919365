import i18next from 'i18next';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import { persistor, store } from './store/configureStore';
import { fetchAppData, initCompany } from './store/reducers/app';
import accountEn from './translations/en/account.json';
import authEn from './translations/en/auth.json';
import centersEn from './translations/en/centers.json';
import usersEn from './translations/en/users.json';
import commonEn from './translations/en/common.json';
import companiesEn from './translations/en/companies.json';
import invitationsEn from './translations/en/invitations.json';
import patientsEn from './translations/en/patients.json';
import rolesEn from './translations/en/roles.json';
import rolesNamesEn from './translations/en/rolesNames.json';
import drugsEn from './translations/en/drugs.json';
import notificationsEn from './translations/en/notifications.json';
import demandsEn from './translations/en/demands.json';
import provincesEn from './translations/en/provinces.json';
import accountEs from './translations/es/account.json';
import authEs from './translations/es/auth.json';
import centersEs from './translations/es/centers.json';
import usersEs from './translations/es/users.json';
import commonEs from './translations/es/common.json';
import companiesEs from './translations/es/companies.json';
import invitationsEs from './translations/es/invitations.json';
import patientsEs from './translations/es/patients.json';
import rolesEs from './translations/es/roles.json';
import rolesNamesEs from './translations/es/rolesNames.json';
import drugsEs from './translations/es/drugs.json';
import notificationsEs from './translations/es/notifications.json';
import demandsEs from './translations/es/demands.json';
import provincesEs from './translations/es/provinces.json';
import codesEn from './translations/en/codes.json';
import codesEs from './translations/es/codes.json';
import presentationsEn from './translations/en/presentations.json';
import presentationsEs from './translations/es/presentations.json';
import { getSubdomain } from './utils/getSubdomain';
import { configureInitialInterceptors } from './utils/http.interceptors';

// How to use translations:
// import {useTranslation} from "react-i18next";
// const {t, i18n} = useTranslation('common');
// return <h1>{t('welcome.title')}</h1>

i18next.init({
  defaultNS: 'common',
  interpolation: { escapeValue: false }, // React already does escaping
  fallbackLng: 'es',
  lng: ['es', 'en'],
  resources: {
    en: {
      common: commonEn, // 'common' is our custom namespace
      auth: authEn,
      users: usersEn,
      roles: rolesEn,
      rolesNames: rolesNamesEn,
      invitations: invitationsEn,
      account: accountEn,
      companies: companiesEn,
      drugs: drugsEn,
      centers: centersEn,
      patients: patientsEn,
      notifications: notificationsEn,
      demands: demandsEn,
      provinces: provincesEn,
      codes: codesEn,
      presentations: presentationsEn,
    },
    es: {
      common: commonEs,
      auth: authEs,
      users: usersEs,
      roles: rolesEs,
      rolesNames: rolesNamesEs,
      invitations: invitationsEs,
      account: accountEs,
      companies: companiesEs,
      drugs: drugsEs,
      centers: centersEs,
      patients: patientsEs,
      notifications: notificationsEs,
      demands: demandsEs,
      provinces: provincesEs,
      codes: codesEs,
      presentations: presentationsEs,
    },
  },
});

configureInitialInterceptors(store);

const company = getSubdomain(window.location.hostname);
store.dispatch(initCompany(company));
store.dispatch(fetchAppData());

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      cacheTime: 0,
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ReactQueryCacheProvider queryCache={queryCache}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ReactQueryCacheProvider>
        </PersistGate>
      </Provider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
