import { Container, LinearProgress, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import PresentationDetails from './components/PresentationDetails';
import { useHistory, useParams } from 'react-router-dom';
import Loader from 'src/components/Loader';
import { useDeletePresentation, useGetPresentation } from 'src/hooks/presentations';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import AlertDialog from 'src/components/AlertDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const PresentationDetailsView = () => {
  const classes = useStyles();
  const { t } = useTranslation(['presentations', 'common']);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { presentationId } = useParams();
  const { isLoading, data: presentation, isError, error } = useGetPresentation(presentationId, {
    include: 'drug',
  });
  const [
    deletePresentation,
    { isLoading: isLoadingDelete, data: dataDelete, isError: isErrorDelete, error: errorDelete },
  ] = useDeletePresentation();

  const handleDeletePresentation = () => {
    setShowDeleteModal(false);
    deletePresentation(presentationId);
  };

  useEffect(() => {
    if (!isLoadingDelete && dataDelete && !isErrorDelete) {
      enqueueSnackbar(t('modals.delete.success_title'), { variant: 'success' });

      history.push('/app/presentations');
    }
  }, [dataDelete, enqueueSnackbar, history, isErrorDelete, isLoadingDelete, t]);

  if (isError) {
    return (
      <AlertDialog
        title={t('modals.get_presentation.error.title')}
        text={error.message || t('modals.get_presentation.error.text')}
        type="error"
        acceptAction={() => history.push('/app/presentations', { replace: true })}
      />
    );
  }

  return (
    <Page className={classes.root}>
      {isLoading && <Loader />}

      {!isLoading && (
        <Container maxWidth="lg">
          {isLoadingDelete && <LinearProgress color="primary" />}

          <PresentationDetails
            presentation={presentation.data}
            deletePresentation={() => setShowDeleteModal(true)}
            disableActionButtons={isLoadingDelete}
          />

          {showDeleteModal && (
            <AlertDialog
              title={t('modals.delete.title')}
              text={t('modals.delete.subtitle')}
              type="warning"
              showAcceptButton={false}
              showDeleteButton={true}
              deleteAction={handleDeletePresentation}
              showCancelButton={true}
              cancelAction={() => setShowDeleteModal(false)}
            />
          )}
          {isErrorDelete && (
            <AlertDialog
              title={t('modals.delete.error_title')}
              text={errorDelete?.message || t('modals.delete.error_subtitle')}
              type="error"
            />
          )}
        </Container>
      )}
    </Page>
  );
};

export default PresentationDetailsView;
