import { Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import CodeDetails from './components/CodeDetails';
import { useHistory, useParams } from 'react-router-dom';
import Loader from 'src/components/Loader';
import { useTranslation } from 'react-i18next';
import AlertDialog from 'src/components/AlertDialog';
import { useGetCode } from 'src/hooks/codes';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const ViewCodeView = () => {
  const classes = useStyles();
  const { t } = useTranslation('code');
  const history = useHistory();
  const { codeId } = useParams();
  const { isLoading, data: code, isError, error } = useGetCode(codeId, {
    include: 'company',
  });

  if (isError) {
    return (
      <AlertDialog
        title={t('modals.error.title_get_code')}
        text={error?.message || t('modals.error.text_get_code')}
        type="error"
        acceptAction={() => history.push('/app/codes', { replace: true })}
      />
    );
  }

  return (
    <Page className={classes.root}>
      {isLoading && <Loader />}

      {!isLoading && (
        <Container maxWidth="lg">
          <CodeDetails code={code?.data} />
        </Container>
      )}
    </Page>
  );
};

export default ViewCodeView;
