import { useMutation, useQueryCache } from 'react-query';
import { createUser } from 'src/services/api/users';

function useCreateUser() {
  const queryCache = useQueryCache();

  return useMutation((data) => createUser(data), {
    onSuccess: (data) => queryCache.setQueryData(['user', data.data?.id], data),
  });
}

export default useCreateUser;
