import { createMuiTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';
import { esES } from '@material-ui/core/locale';

const generateTheme = (appData) =>
  createMuiTheme(
    {
      palette: {
        background: {
          dark: '#F4F6F8',
          default: colors.common.white,
          paper: colors.common.white,
        },
        primary: {
          main: `#${appData.primaryColor}`,
        },
        secondary: {
          main: `#${appData.secondaryColor}`,
        },
        tertiary: {
          main: appData.tertiaryColor ? `#${appData.tertiaryColor}` : '#6d6875',
        },
        text: {
          primary: `#${appData.primaryColor}`,
          secondary: `#${appData.secondaryColor}`,
          tertiary: `#${appData.primaryColor}`,
        },
      },
      shadows,
      typography,
    },
    esES,
  );

export default generateTheme;
