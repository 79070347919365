import { Card, CardContent, CardHeader, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DetailsTable from './components';

const InfoPatient = ({ patient }) => {
  const { t } = useTranslation('demands');

  return (
    <Card>
      <CardHeader
        title={t('labels.viewDemand_patient_title')}
        subheader={t('labels.viewDemand_patient_subtitle')}
      />
      <Divider />
      <CardContent>
        <DetailsTable patient={patient} />
      </CardContent>
    </Card>
  );
};

InfoPatient.propTypes = {
  patient: PropTypes.object,
};

export default InfoPatient;
