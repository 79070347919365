import { Box, Button, Grid, makeStyles, TextField } from '@material-ui/core';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ADMINISTRATION, DOSAGE } from 'src/constants/drugs';
import { useGoBack } from 'src/hooks/common';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const CreateDrugForm = ({ initialValues, handleFormSubmit }) => {
  const classes = useStyles();
  const { t } = useTranslation('drugs');
  const goBack = useGoBack('/app/drugs');

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('errors.name.required')),
    activePrinciple: Yup.string().required(t('errors.activePrinciple.required')),
    dosage: Yup.string()
      .oneOf([DOSAGE.MGM2, DOSAGE.MGKG, DOSAGE.AUC, DOSAGE.MG], t('errors.dosage.value'))
      .required(t('errors.dosage.required')),
    administration: Yup.string()
      .oneOf(Object.values(ADMINISTRATION), t('errors.administration.value'))
      .required(t('errors.administration.required')),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.name && errors.name)}
                fullWidth
                helperText={touched.name && errors.name}
                label={t('fields.name')}
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting}
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.activePrinciple && errors.activePrinciple)}
                fullWidth
                helperText={touched.activePrinciple && errors.activePrinciple}
                label={t('fields.activePrinciple')}
                name="activePrinciple"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting}
                value={values.activePrinciple}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.dosage && errors.dosage)}
                helperText={touched.dosage && errors.dosage}
                fullWidth
                label={t('fields.select_dosage')}
                name="dosage"
                onChange={handleChange}
                disabled={isSubmitting}
                select
                SelectProps={{ native: true }}
                value={values.dosage}
                variant="outlined"
                onBlur={handleBlur}
              >
                <option value="" hidden disabled></option>
                <option value={DOSAGE.MGM2}>{t('names.dosage.MGM2')}</option>
                <option value={DOSAGE.MGKG}>{t('names.dosage.MGKG')}</option>
                <option value={DOSAGE.AUC}>{t('names.dosage.AUC')}</option>
                <option value={DOSAGE.MG}>{t('names.dosage.MG')}</option>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.administration && errors.administration)}
                helperText={touched.administration && errors.administration}
                fullWidth
                label={t('fields.select_administration')}
                name="administration"
                onChange={handleChange}
                disabled={isSubmitting}
                select
                SelectProps={{ native: true }}
                value={values.administration}
                variant="outlined"
                onBlur={handleBlur}
              >
                <option value="" hidden disabled></option>
                {Object.entries(ADMINISTRATION).map(([key, value]) => (
                  <option key={key} value={value}>
                    {t(`names.administration.${key}`)}
                  </option>
                ))}
              </TextField>
            </Grid>

            <Box display="flex" p={2}>
              <Button className={classes.button} variant="outlined" onClick={goBack}>
                {t('common:buttons.back')}
              </Button>

              <Button
                className={classes.button}
                color="primary"
                disabled={isSubmitting}
                type="submit"
                variant="contained"
              >
                {t(`buttons.create_drug_create`)}
              </Button>
            </Box>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

CreateDrugForm.propTypes = {
  initialValues: PropTypes.object,
  handleFormSubmit: PropTypes.func,
};

export default CreateDrugForm;
