/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import { logOut } from '../actions/auth';

const defaultListsState = {};

const listsSlice = createSlice({
  name: 'lists',
  initialState: defaultListsState,
  reducers: {
    setFilters: {
      reducer(state, action) {
        if (!state[action.payload.list]) {
          state[action.payload.list] = {};
        }

        state[action.payload.list].filters = action.payload.filters;
      },
    },
    resetFilters: {
      reducer(state, action) {
        state[action.payload].filters = {};
      },
    },
  },
  extraReducers: {
    [logOut]: () => {
      return defaultListsState;
    },
  },
});

export const { setFilters, resetFilters } = listsSlice.actions;

export default listsSlice.reducer;
