import { Grid, InputBase } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const DoctorSelectorForm = ({ classes, doctors, doctor, setDoctor, handleOnChangeDoctor }) => {
  const { t } = useTranslation('users');

  return (
    <Grid className={classes.inputRoot}>
      <Autocomplete
        name="doctor"
        options={doctors}
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option, value) => option.id === value.id}
        value={doctor}
        onChange={(e, value) => {
          value ? setDoctor(value) : setDoctor(null);
          handleOnChangeDoctor();
        }}
        renderInput={(params) => (
          <InputBase
            placeholder={t('labels.doctor_selection')}
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            classes={{
              root: classes.root,
              input: classes.input,
            }}
          />
        )}
      />
    </Grid>
  );
};

DoctorSelectorForm.propTypes = {
  classes: PropTypes.object,
  doctors: PropTypes.array,
  doctor: PropTypes.object,
  setDoctor: PropTypes.func,
  handleOnChangeDoctor: PropTypes.func,
};

export default DoctorSelectorForm;
