import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  InputAdornment,
  SvgIcon,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useEffect } from 'react';
import { Search as SearchIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useListCompanies } from 'src/hooks/company';
import { useGetRoles } from 'src/hooks/roles';
import { useDebounce } from 'use-debounce/lib';

const Container = ({ children }) => {
  return (
    <Box mt={3}>
      <Card>
        <CardContent>{children}</CardContent>
      </Card>
    </Box>
  );
};

const ToolbarFilters = ({
  resetPage,
  name,
  setName,
  email,
  setEmail,
  dni,
  setDni,
  company,
  setCompany,
  role,
  setRole,
}) => {
  const { t } = useTranslation(['users', 'rolesNames', 'roles', 'companies']);
  const { enqueueSnackbar } = useSnackbar();
  const [nameValue, setNameValue] = useState(name);
  const [emailValue, setEmailValue] = useState(email);
  const [dniValue, setDniValue] = useState(dni);
  const [nameDebouncedValue] = useDebounce(nameValue, 500);
  const [emailDebouncedValue] = useDebounce(emailValue, 500);
  const [dniDebouncedValue] = useDebounce(dniValue, 500);
  const { isLoading, data: companies, isError } = useListCompanies({ all: true });
  const { isLoading: isLoadingRoles, data: roles, isError: isErrorRoles } = useGetRoles({
    all: true,
  });

  const handleReset = () => {
    resetPage();
    setName('');
    setNameValue('');
    setEmail('');
    setEmailValue('');
    setDni('');
    setDniValue('');
    setCompany(null);
    setRole(null);
  };

  const getRolesOptions = () => {
    if (!isLoadingRoles && !isErrorRoles && roles.data.rows.length) {
      const translatedAvailableRoles = roles.data.rows.map((role) => {
        const name = t(`rolesNames:${role.permissions}`);
        return { ...role, name };
      });

      return translatedAvailableRoles;
    }

    return [];
  };

  const handleCompanyChange = (e, value) => {
    setCompany(value ? { id: value.id, name: value.name } : null);
    resetPage();
  };

  const handleRoleChange = (e, value) => {
    setRole(value ? { id: value.id, name: value.name } : null);
    resetPage();
  };

  useEffect(() => {
    if (isErrorRoles) {
      enqueueSnackbar(t('roles:modals.error.title_get_roles'), { variant: 'error' });
    }
  }, [enqueueSnackbar, isErrorRoles, t]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(t('companies:modals.error.title_list_companies'), { variant: 'error' });
    }
  }, [enqueueSnackbar, isError, t]);

  useEffect(() => {
    setName(nameDebouncedValue);
    setEmail(emailDebouncedValue);
    setDni(dniDebouncedValue);
    resetPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameDebouncedValue, setName, emailDebouncedValue, setEmail, dniDebouncedValue, setDni]);

  return (
    <Container>
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="primary">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            placeholder={t('fields.search')}
            value={nameValue}
            onBlur={(e) => setName(e.target.value)}
            onChange={(e) => {
              setNameValue(e.target.value);
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="primary">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            name="email"
            placeholder={t('fields.search_by_email')}
            value={emailValue}
            onBlur={(e) => setEmail(e.target.value)}
            onChange={(e) => {
              setEmailValue(e.target.value);
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="primary">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            name="dni"
            placeholder={t('fields.search_by_dni')}
            value={dniValue}
            onBlur={(e) => setDni(e.target.value)}
            onChange={(e) => {
              setDniValue(e.target.value);
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete
            openOnFocus={true}
            selectOnFocus={false}
            options={companies?.data.rows || []}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            value={company}
            loading={isLoading}
            disableClearable={isLoading}
            onChange={handleCompanyChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('fields.company')}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  style: { cursor: 'pointer' },
                }}
                InputProps={{
                  ...params.InputProps,
                  readOnly: true,
                  endAdornment: (
                    <>
                      {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete
            openOnFocus={true}
            selectOnFocus={false}
            options={getRolesOptions() || []}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            value={role}
            loading={isLoadingRoles}
            disableClearable={isLoadingRoles}
            onChange={handleRoleChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('fields.role')}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  style: { cursor: 'pointer' },
                }}
                InputProps={{
                  ...params.InputProps,
                  readOnly: true,
                  endAdornment: (
                    <>
                      {isLoadingRoles ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button color="primary" variant="contained" onClick={handleReset}>
              {t('buttons.users_list_clear_filters')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

ToolbarFilters.propTypes = {
  setPage: PropTypes.func,
  name: PropTypes.string,
  setName: PropTypes.func,
  email: PropTypes.string,
  setEmail: PropTypes.func,
  dni: PropTypes.string,
  setDni: PropTypes.func,
  company: PropTypes.object,
  setCompany: PropTypes.func,
  role: PropTypes.object,
  setRole: PropTypes.func,
};

export default ToolbarFilters;
