import { Button, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { removeBase64Data } from 'src/utils/removeBase64Data';

const useStyles = makeStyles(() => ({
  root: {},
  media: {
    height: 100,
    width: 100,
  },
  mediaLabel: {
    marginLeft: '0 !important',
    width: '100%',
  },
  mediaInput: {
    display: 'none',
  },
}));

const MediaUpload = ({ handleMediaUpload, isUploading, media }) => {
  const classes = useStyles();
  const { t } = useTranslation('companies');

  const handleImageUpload = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];

    // Callback for load event
    reader.onloadend = () => {
      const imageBase64 = reader.result;
      handleMediaUpload(removeBase64Data(imageBase64));
    };

    // Read image
    reader.readAsDataURL(file);
  };

  return (
    <>
      <input
        className={classes.mediaInput}
        id={`${media}-input`}
        onChange={handleImageUpload}
        disabled={isUploading}
        accept="image/*"
        type="file"
      />
      <label className={classes.mediaLabel} htmlFor={`${media}-input`}>
        <Button variant="text" color="primary" fullWidth component="span" disabled={isUploading}>
          {t(`buttons.${media}Upload`)}
        </Button>
      </label>
    </>
  );
};

MediaUpload.propTypes = {
  handleMediaUpload: PropTypes.func,
  isUploading: PropTypes.bool,
  media: PropTypes.string,
};

export default MediaUpload;
