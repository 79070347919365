import { useQuery } from 'react-query';
import { getPdfDemandByVersionId } from 'src/services/api/demands';

function useGetPdfDemand(demandPDF, versionPDF, doctorId) {
  return useQuery(
    ['demandPDF', demandPDF, versionPDF],
    (key, demandPDF) => getPdfDemandByVersionId(demandPDF, versionPDF, { doctorId }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  );
}

export default useGetPdfDemand;
