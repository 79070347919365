import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { withStyles } from '@material-ui/styles';
import { FieldArray, Formik, useFormikContext } from 'formik';
import forEachRight from 'lodash-es/forEachRight';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PREVIOUS_TREATMENTS } from 'src/constants/demands';
import { useDebouncedCallback } from 'use-debounce/lib';
import * as Yup from 'yup';
import TreatmentField from './components/TreatmentField';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const EMPTY_TREATMENT = {
  date: null,
  description: '',
};

const FormContext = ({ setPreviousTreatments, setDisableNext, isFullfilled }) => {
  const { values, errors, isValid, dirty, submitForm } = useFormikContext();
  const { callback: debounceUpdate } = useDebouncedCallback(() => updateDemand(), 600);
  const { callback: debounceDisableNext } = useDebouncedCallback(() => updateDisableNext(), 600);

  const updateDisableNext = () => {
    setDisableNext(isFullfilled ? !isValid : !(dirty && isValid));
  };

  const updateDemand = () => {
    if (isValid) {
      setPreviousTreatments(values);
    }
  };

  // If user has already fullfilled the form and came back to this step, submit the form to show validation errors
  useEffect(() => {
    if (isFullfilled) {
      submitForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Disable "next" button if form has errors or is not dirty
  useEffect(() => {
    if (isValid) {
      debounceDisableNext();
    } else {
      updateDisableNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFullfilled, isValid, debounceDisableNext]);

  // Update demand on every field valid change
  useEffect(() => {
    debounceUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, setPreviousTreatments, values]);

  return null;
};

const Step3Form = ({
  initialValues,
  setPreviousTreatments,
  disableForm,
  setDisableNext,
  isFullfilled,
}) => {
  const { t } = useTranslation('demands');

  const validationSchema = Yup.object().shape({
    tratamientosPrevios: Yup.array().of(
      Yup.object().shape({
        type: Yup.string().required(),
        date: Yup.date()
          .typeError(t('errors.tratamientosPreviosFecha.date_format'))
          .required(t('errors.tratamientosPreviosFecha.required'))
          .max(new Date(), t('errors.tratamientosPreviosFecha.max')),
        description: Yup.string().required(t('errors.tratamientosPreviosDatos.required')),
      }),
    ),
  });

  const handleSubmit = (values, formikBag) => {
    formikBag.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
        setFieldTouched,
      }) => (
        <form onSubmit={handleSubmit}>
          <FieldArray
            name="tratamientosPrevios"
            render={(arrayHelpers) => (
              <Grid>
                {Object.entries(PREVIOUS_TREATMENTS).map((treatment, i) => (
                  <Accordion
                    square
                    expanded={
                      !!values?.tratamientosPrevios.filter(({ type }) => type === treatment[1])
                        .length
                    }
                    key={i}
                  >
                    <AccordionSummary>
                      <FormControlLabel
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        control={
                          <Checkbox
                            disabled={isSubmitting || disableForm}
                            checked={
                              !!values?.tratamientosPrevios.filter(
                                ({ type }) => type === treatment[1],
                              ).length
                            }
                            onChange={(e) => {
                              if (e.target.checked) {
                                arrayHelpers.push({ ...EMPTY_TREATMENT, type: treatment[1] });
                              } else {
                                forEachRight(values?.tratamientosPrevios, (t, index) => {
                                  if (t.type === treatment[1]) {
                                    arrayHelpers.remove(index);
                                  }
                                });
                              }
                            }}
                          />
                        }
                        label={t('fields.previous_treatments.' + treatment[0].toLowerCase())}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      {values.tratamientosPrevios.filter(({ type }) => type === treatment[1])
                        .length && (
                        <Grid item xs={12}>
                          <TreatmentField
                            treatment={treatment[1]}
                            EMPTY_TREATMENT={EMPTY_TREATMENT}
                            values={values}
                            touched={touched}
                            errors={errors}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            arrayHelpers={arrayHelpers}
                            handleBlur={handleBlur}
                            setFieldTouched={setFieldTouched}
                            disabled={isSubmitting || disableForm}
                          />
                        </Grid>
                      )}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Grid>
            )}
          />
          <FormContext
            setPreviousTreatments={setPreviousTreatments}
            isFullfilled={isFullfilled}
            setDisableNext={setDisableNext}
          />
        </form>
      )}
    </Formik>
  );
};

Step3Form.propTypes = {
  initialValues: PropTypes.object,
  setPreviousTreatments: PropTypes.func,
  setDisableNext: PropTypes.func,
  disableForm: PropTypes.bool,
  isFullfilled: PropTypes.bool,
};

export default Step3Form;
