import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  InputAdornment,
  SvgIcon,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Search as SearchIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TYPE as CENTER_TYPES } from 'src/constants/centers';
import { ROLES } from 'src/constants/roles';
import { useListCenters } from 'src/hooks/centers';
import { useListCompanies } from 'src/hooks/company';
import { useListPatients } from 'src/hooks/patients';
import { useListUsers } from 'src/hooks/users';
import { isCompanySelected } from 'src/store/selectors/entity';
import {
  getRoleCurrentUser as getRoleCurrentUserSelector,
  isAssistant as isAssistantSelector,
  isConsultant as isConsultantSelector,
  isManager as isManagerSelector,
  isRepresentive as isRepresentiveSelector,
  isSupervisor as isSupervisorSelector,
} from 'src/store/selectors/user';
import { useDebounce } from 'use-debounce';
import { STATUS_FILTER } from 'src/constants/demands';

import { getRoleByPermissions, patientToOption } from './ToolbarFiltersUtils';
import { useGetProvinces } from 'src/hooks/provinces';

const Container = ({ children }) => {
  return (
    <Box mt={3}>
      <Card>
        <CardContent>{children}</CardContent>
      </Card>
    </Box>
  );
};

const FilterAutocomplete = ({
  options,
  label,
  optionLabel,
  value,
  loading,
  handleChange,
  handleSearchChange,
  inputOnBlur,
  disabled = false,
  ...rest
}) => {
  const { t } = useTranslation('demands');

  return (
    <Autocomplete
      openOnFocus={true}
      selectOnFocus={false}
      options={options || []}
      getOptionLabel={optionLabel || ((option) => option.name)}
      getOptionSelected={(option, value) => option.id === value.id}
      value={value}
      loading={loading}
      disableClearable={loading}
      disabled={disabled}
      onChange={handleChange}
      {...rest}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t(`fields.${label}`)}
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            style: handleSearchChange ? {} : { cursor: 'pointer' },
          }}
          InputProps={{
            ...params.InputProps,
            readOnly: !handleSearchChange,
            onChange: handleSearchChange || params.InputProps.onChange,
            onBlur: inputOnBlur || params.InputProps.onBlur,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

const COMMON_ROLES = [ROLES.SUPERADMIN, ROLES.ADMIN];
const centerPrescriptorRoles = [
  ...COMMON_ROLES,
  ROLES.DOCTOR,
  ROLES.ASSISTANT,
  ROLES.MANAGER,
  ROLES.CONSULTANT,
  ROLES.REPRESENTATIVE,
  ROLES.SUPERVISOR,
];
const centerRealizatorRoles = COMMON_ROLES;
const patientRoles = [
  ...COMMON_ROLES,
  ROLES.DOCTOR,
  ROLES.ASSISTANT,
  ROLES.MANAGER,
  ROLES.CONSULTANT,
  ROLES.REPRESENTATIVE,
  ROLES.SUPERVISOR,
];
const doctorRoles = [
  ...COMMON_ROLES,
  ROLES.ASSISTANT,
  ROLES.MANAGER,
  ROLES.CONSULTANT,
  ROLES.REPRESENTATIVE,
  ROLES.SUPERVISOR,
];
const companyRoles = [...COMMON_ROLES, ROLES.CONSULTANT];
const pendingDemandRoles = [
  ROLES.DOCTOR,
  ROLES.ASSISTANT,
  ROLES.MANAGER,
  ROLES.REPRESENTATIVE,
  ROLES.CONSULTANT,
];

// Use user company in list patients and doctor filters
const filtersUserCompany = [
  ROLES.DOCTOR,
  ROLES.ASSISTANT,
  ROLES.MANAGER,
  ROLES.REPRESENTATIVE,
  ROLES.SUPERVISOR,
];

const filtersUserZones = [...COMMON_ROLES, ROLES.REPRESENTATIVE];

const ToolbarFilters = ({
  resetPage,
  reference,
  centerPrescriptor,
  centerRealizator,
  zone,
  company,
  doctor,
  patient,
  status,
  pendingDemands,
  setReference,
  setCenterPrescriptor,
  setCenterRealizator,
  setZone,
  setCompany,
  setDoctor,
  setPatient,
  setStatus,
  setPendingDemands,
  rolesQuery,
}) => {
  const { t } = useTranslation(['demands', 'provinces']);
  const { enqueueSnackbar } = useSnackbar();
  const userRole = useSelector(getRoleCurrentUserSelector);
  const isManager = useSelector(isManagerSelector);
  const isConsultant = useSelector(isConsultantSelector);
  const isRepresentive = useSelector(isRepresentiveSelector);
  const isAssistant = useSelector(isAssistantSelector);
  const isSupervisor = useSelector(isSupervisorSelector);
  const companySelected = useSelector(isCompanySelected);
  const userState = useSelector((state) => state.user.user);
  const userCompanyId = userState?.company?.id;
  const companyTramitByZones = userState?.company.tramitByZones;
  const userDoctors = userState?.doctors;
  const supervisorDoctors = userState?.supervisorDoctors;
  const supervisorCenters = userState?.supervisorCenters;

  const debounceTime = 500;
  const [referenceValue, setReferenceValue] = useState(reference);
  const [referenceDebouncedValue] = useDebounce(referenceValue, debounceTime);
  const [patientSearch, setPatientSearch] = useState('');
  const [patientSearchDebouncedValue] = useDebounce(patientSearch, debounceTime);
  const [prescriptorSearch, setPrescriptorSearch] = useState('');
  const [prescriptorSearchDebouncedValue] = useDebounce(prescriptorSearch, debounceTime);
  const [realizatorSearch, setRealizatorSearch] = useState('');
  const [realizatorSearchDebouncedValue] = useDebounce(realizatorSearch, debounceTime);
  const [zoneSearch, setZoneSearch] = useState('');
  const [zoneSearchDebouncedValue] = useDebounce(zoneSearch, debounceTime);
  const [companySearch, setCompanySearch] = useState('');
  const [companySearchDebouncedValue] = useDebounce(companySearch, debounceTime);
  const [doctorSearch, setDoctorSearch] = useState('');
  const [doctorSearchDebouncedValue] = useDebounce(doctorSearch, debounceTime);

  const getCompanyForFilter = () => {
    if (filtersUserCompany.includes(userRole)) {
      if (isManager) {
        return companySelected?.id;
      } else {
        return userCompanyId;
      }
    } else {
      // Return the company selected in filters
      return company?.id;
    }
  };

  const companiesQuery = useListCompanies(
    { all: true, default: false, sort: 'name', name: companySearchDebouncedValue },
    companyRoles.includes(userRole),
  );
  const patientsQuery = useListPatients(
    {
      all: true,
      filterByDoctor: true,
      companyId: getCompanyForFilter(),
      sort: 'firstName',
      search: patientSearchDebouncedValue,
    },
    Boolean(
      patientRoles.includes(userRole) &&
        getCompanyForFilter() &&
        patientSearchDebouncedValue?.length,
    ),
  );
  const centersPrescriptorQuery = useListCenters(
    {
      type: CENTER_TYPES.PRESCRIPTOR,
      doctorId: isAssistant ? doctor.id : undefined,
      all: true,
      sort: 'name',
      name: prescriptorSearchDebouncedValue,
      zone: zone?.id,
    },
    !isSupervisor && centerPrescriptorRoles.includes(userRole),
  );
  const centersRealizatorQuery = useListCenters(
    {
      type: CENTER_TYPES.REALIZADOR,
      all: true,
      sort: 'name',
      name: realizatorSearchDebouncedValue,
      zone: zone?.id,
    },
    centerRealizatorRoles.includes(userRole),
  );
  const zoneQuery = useGetProvinces({
    all: true,
    sort: 'name',
    name: zoneSearchDebouncedValue,
  });
  const doctorsQuery = useListUsers(
    {
      all: true,
      roleId: getRoleByPermissions(rolesQuery.data, ROLES.DOCTOR)?.id,
      companyId: getCompanyForFilter(),
      sort: 'name',
      name: doctorSearchDebouncedValue,
    },
    !isAssistant &&
      (!isSupervisor || !supervisorDoctors.length) &&
      !rolesQuery.isLoading &&
      !rolesQuery.isError &&
      doctorRoles.includes(userRole) &&
      !!getCompanyForFilter(),
  );

  const handleReset = () => {
    resetPage();
    setReferenceValue('');
    setCenterPrescriptor(null);
    setCenterRealizator(null);
    setZone(null);
    setCompany(companySelected || null);
    setPatient(null);
    setStatus('');
    setPendingDemands(!!isManager || !!isRepresentive || !!isConsultant);

    if (!isAssistant) {
      setDoctor(null);
    }
  };

  const handleChange = (handler, searchHandler, extraFields) => (e, value, reason) => {
    if (searchHandler && reason === 'clear') {
      searchHandler('');
    }

    let filter = null;
    if (value) {
      filter = { id: value.id, name: value.name };

      if (extraFields) {
        extraFields.forEach((field) => {
          filter[field] = value[field];
        });
      }
    }

    handler(filter);
    resetPage();
  };

  const handleSearchChange = (handler) => (e) => {
    handler(e.target.value);
  };

  useEffect(() => {
    setReference(referenceDebouncedValue);
    resetPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referenceDebouncedValue, setReference]);

  useEffect(() => {
    if (companiesQuery.isError) {
      enqueueSnackbar(t('companies:modals.error.title_list_companies'), { variant: 'error' });
    }
  }, [enqueueSnackbar, companiesQuery.isError, t]);

  useEffect(() => {
    if (patientsQuery.isError) {
      enqueueSnackbar(t('patients:modals.error.title_list_patients'), { variant: 'error' });
    }
  }, [enqueueSnackbar, patientsQuery.isError, t]);

  useEffect(() => {
    if (centersPrescriptorQuery.isError) {
      enqueueSnackbar(t('centers:modals.error.title_list_centers'), { variant: 'error' });
    }
  }, [enqueueSnackbar, centersPrescriptorQuery.isError, t]);

  useEffect(() => {
    if (centersRealizatorQuery.isError) {
      enqueueSnackbar(t('centers:modals.error.title_list_centers'), { variant: 'error' });
    }
  }, [enqueueSnackbar, centersRealizatorQuery.isError, t]);

  useEffect(() => {
    if (zoneQuery.isError) {
      enqueueSnackbar(t('provinces:modals.error.title_get_provinces'), { variant: 'error' });
    }
  }, [enqueueSnackbar, t, zoneQuery.isError]);

  useEffect(() => {
    if (doctorsQuery.isError) {
      enqueueSnackbar(t('users:modals.error.title_list_users'), { variant: 'error' });
    }
  }, [enqueueSnackbar, doctorsQuery.isError, t]);

  const companyOptions = companiesQuery.data?.data.rows;
  const doctorOptions =
    isSupervisor && supervisorDoctors.length ? supervisorDoctors : doctorsQuery.data?.data.rows;
  const patientOptions = patientsQuery.data?.data.rows.map(patientToOption);
  const centerPrescriptorOptions = isSupervisor
    ? supervisorCenters
    : centersPrescriptorQuery.data?.data.rows;
  const centerRealizatorOptions = centersRealizatorQuery.data?.data.rows;
  const zoneOptions = zoneQuery.data?.data.rows;

  return (
    <Container>
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="primary">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            name="reference"
            placeholder={t('fields.reference')}
            value={referenceValue}
            onBlur={(e) => setReference(e.target.value)}
            onChange={(e) => {
              setReferenceValue(e.target.value);
            }}
            variant="outlined"
          />
        </Grid>
        {centerPrescriptorRoles.includes(userRole) && (
          <Grid item xs={12} sm={6} md={4}>
            <FilterAutocomplete
              label="centerPrescriptor"
              options={centerPrescriptorOptions}
              value={centerPrescriptor}
              loading={centersPrescriptorQuery.isFetching}
              handleChange={handleChange(setCenterPrescriptor, setPrescriptorSearch)}
              handleSearchChange={handleSearchChange(setPrescriptorSearch)}
              inputOnBlur={() => setPrescriptorSearch('')}
            />
          </Grid>
        )}
        {centerRealizatorRoles.includes(userRole) && (
          <Grid item xs={12} sm={6} md={4}>
            <FilterAutocomplete
              label="centerRealizator"
              options={centerRealizatorOptions}
              value={centerRealizator}
              loading={centersRealizatorQuery.isFetching}
              handleChange={handleChange(setCenterRealizator, setRealizatorSearch)}
              handleSearchChange={handleSearchChange(setRealizatorSearch)}
              inputOnBlur={() => setRealizatorSearch('')}
            />
          </Grid>
        )}
        {filtersUserZones.includes(userRole) && (!isRepresentive || companyTramitByZones) && (
          <Grid item xs={12} sm={6} md={4}>
            <FilterAutocomplete
              label="zone"
              options={zoneOptions}
              value={zone}
              loading={zoneQuery.isLoading}
              handleChange={handleChange(setZone, setZoneSearch)}
              handleSearchChange={handleSearchChange(setZoneSearch)}
              inputOnBlur={() => setZoneSearch('')}
            />
          </Grid>
        )}
        {companyRoles.includes(userRole) && (
          <Grid item xs={12} sm={6} md={4}>
            <FilterAutocomplete
              label="company"
              options={companyOptions}
              value={company}
              loading={companiesQuery.isFetching}
              handleChange={(e, value, reason) => {
                if (reason === 'clear') {
                  setCompanySearch('');
                }

                setCompany(value ? { id: value.id, name: value.name } : null);
                setPatient(null);
                resetPage();
              }}
              handleSearchChange={handleSearchChange(setCompanySearch)}
              inputOnBlur={() => setCompanySearch('')}
            />
          </Grid>
        )}
        {doctorRoles.includes(userRole) && (
          <Grid item xs={12} sm={6} md={4}>
            <FilterAutocomplete
              label="doctor"
              options={isAssistant ? userDoctors : doctorOptions}
              value={doctor}
              loading={doctorsQuery.isLoading}
              handleChange={handleChange(setDoctor, setDoctorSearch)}
              disabled={!getCompanyForFilter()}
              handleSearchChange={handleSearchChange(setDoctorSearch)}
              inputOnBlur={() => setDoctorSearch('')}
              disableClearable={isAssistant}
            />
          </Grid>
        )}
        {patientRoles.includes(userRole) && (
          <Grid item xs={12} sm={6} md={4}>
            <FilterAutocomplete
              label="patient"
              options={patientSearchDebouncedValue?.length || patient ? patientOptions : []}
              optionLabel={(option) =>
                `${option.name} (${option.cardNumber})`
              }
              value={patient}
              loading={patientsQuery.isFetching}
              handleChange={handleChange(setPatient, setPatientSearch, ['cardNumber'])}
              disabled={!getCompanyForFilter()}
              handleSearchChange={handleSearchChange(setPatientSearch)}
              inputOnBlur={() => setPatientSearch('')}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            select
            name="type"
            label={t('fields.status')}
            SelectProps={{ native: true }}
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
              setPendingDemands(false);
              resetPage();
            }}
            variant="outlined"
          >
            <option value="" hidden />
            {!isConsultant && !isRepresentive && (
              <option value={STATUS_FILTER.DRAFT}>{t('status.draft')}</option>
            )}
            <option value={STATUS_FILTER.CANCELED}>{t('status.canceled')}</option>
            <option value={STATUS_FILTER.PENDING_VALIDATION}>{t('status.pending_validation')}</option>
            <option value={STATUS_FILTER.PENDING_ANALYSIS}>{t('status.pending_analysis')}</option>
            <option value={STATUS_FILTER.PENDING_MISSING_INFO}>
              {t('status.pending_missing_info')}
            </option>
            <option value={STATUS_FILTER.PENDING_COMPANY_ACCEPT}>
              {t('status.pending_company_accept')}
            </option>
            <option value={STATUS_FILTER.PENDING_COMPANY_DENY}>
              {t('status.pending_company_deny')}
            </option>
            <option value={STATUS_FILTER.PENDING_EVALUATION}>
              {t('status.pending_evaluation')}
            </option>
            <option value={STATUS_FILTER.PENDING_CONTINUE}>{t('status.pending_continue')}</option>
            <option value={STATUS_FILTER.SENDED_ACCEPTED}>{t('status.sended_accepted')}</option>
            <option value={STATUS_FILTER.SENDED_DENIED}>{t('status.sended_denied')}</option>
            <option value={STATUS_FILTER.FINISHED}>{t('status.finished')}</option>
          </TextField>
        </Grid>
        {pendingDemandRoles.includes(userRole) && (
          <Grid item xs={12} sm={6} md={4}>
            <FormControlLabel
              control={
                <Checkbox
                  size="medium"
                  name="pendingDemands"
                  onChange={(e) => {
                    setStatus('');
                    setPendingDemands(e.target.checked);
                    resetPage();
                  }}
                  checked={pendingDemands}
                />
              }
              label={t('labels.pending_demands')}
              labelPlacement="start"
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              color="primary"
              variant="contained"
              title={t('buttons.demands_list_clear_filters')}
              onClick={handleReset}
            >
              {t('buttons.demands_list_clear_filters')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

ToolbarFilters.propTypes = {
  setPage: PropTypes.func,
  reference: PropTypes.string,
  centerPrescriptor: PropTypes.object,
  setCenterPrescriptor: PropTypes.func,
  centerRealizator: PropTypes.object,
  setCenterRealizator: PropTypes.func,
  zone: PropTypes.object,
  setZone: PropTypes.func,
  company: PropTypes.object,
  status: PropTypes.string,
  pendingDemands: PropTypes.bool,
  setReference: PropTypes.func,
  setCompany: PropTypes.func,
  doctor: PropTypes.object,
  setDoctor: PropTypes.func,
  patient: PropTypes.object,
  setPatient: PropTypes.func,
  setStatus: PropTypes.func,
  setPendingDemands: PropTypes.func,
  rolesQuery: PropTypes.object,
};

export default ToolbarFilters;
