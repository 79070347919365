/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { logOut } from '../actions/auth';

const defaultEntityState = {
  doctor: null,
  center: null,
  company: null,
};

const entitySlice = createSlice({
  name: 'entity',
  initialState: defaultEntityState,
  reducers: {
    setDoctorEntity: {
      reducer(state, action) {
        state.doctor = action.payload.doctor;
      },
    },
    setCenterEntity: {
      reducer(state, action) {
        state.center = action.payload.center;
      },
    },
    setCompanyEntity: {
      reducer(state, action) {
        state.company = action.payload.company;
      },
    },
    cleanEntity: {
      reducer(state) {
        state.doctor = defaultEntityState.doctor;
        state.center = defaultEntityState.center;
        state.company = defaultEntityState.company;
      },
    },
  },
  extraReducers: {
    [logOut]: (state) => {
      state.doctor = defaultEntityState.doctor;
      state.center = defaultEntityState.center;
      state.company = defaultEntityState.company;
    },
  },
});

export const {
  setDoctorEntity,
  setCenterEntity,
  setCompanyEntity,
  cleanEntity,
} = entitySlice.actions;

export default entitySlice.reducer;
