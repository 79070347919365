import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DemandsActions from 'src/components/Demands/DemandsActions';
import { useGetRoles } from 'src/hooks/roles';
import ClinicalProtocol from './components/ClinicalProtocol';
import DemandAttachments from './components/DemandAttachments';
import DemandHistories from 'src/components/Demands/DemandHistories';
import DemandVersions from './components/DemandVersions';
import InfoDemand from './components/InfoDemand';
import InfoPatient from './components/InfoPatient';
import PreviousTreatments from './components/PreviousTreatments/PreviousTreatments';
import ProposedTreatments from './components/ProposedTreatments';
import DemandDetailsActions from './components/DemandDetailsActions';
import { Alert, AlertTitle } from '@material-ui/lab';
import DemandComments from './components/DemandComments';

const useStyles = makeStyles((theme) => ({
  headerGrid: {
    paddingBottom: '0 !important',
  },
  title: {
    marginRight: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  alertContainer: {
    margin: '0 auto',
  },
}));

const DemandDetails = ({ demand, version, refetch }) => {
  const classes = useStyles();
  const { t } = useTranslation('demands');

  const { data: roles } = useGetRoles({ all: true });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} className={classes.headerGrid}>
        <Box display="flex" alignItems="center">
          <Typography className={classes.title} color="textPrimary" variant="h3" display="inline">
            {version
              ? `${t('labels.viewDemand')}: ${t('labels.viewDemandVersion')} ${
                  version.description ?? ''
                }`
              : t('labels.viewDemand')}
          </Typography>

          <DemandsActions
            demand={demand}
            roles={roles?.data?.rows}
            refetch={refetch}
            listActions={false}
          />
        </Box>
      </Grid>

      <DemandDetailsActions demand={demand} version={version} />

      {(version?.changeDatesReason || (!version && demand.versions[0]?.changeDatesReason)) && (
        <Grid className={classes.alertContainer} item sm={10}>
          <Alert severity="info">
            <AlertTitle>{t('labels.changed_dates_alert')}</AlertTitle>
            {version?.changeDatesReason || demand.versions[0]?.changeDatesReason}
          </Alert>
        </Grid>
      )}

      {!version ? (
        <Grid item sm={12} md={6}>
          <InfoDemand demand={demand} roles={roles} />
        </Grid>
      ) : (
        <Grid item sm={12}>
          <InfoDemand demand={demand} version={version} roles={roles} />
        </Grid>
      )}

      {!version && (
        <Grid item sm={12} md={6}>
          <InfoPatient patient={demand.patient} />
        </Grid>
      )}

      <Grid item sm={12}>
        <DemandComments demand={demand} version={version} />
      </Grid>

      <Grid item sm={12}>
        <ClinicalProtocol type={demand.type} demand={version ? version : demand} />
      </Grid>

      <Grid item sm={12}>
        <PreviousTreatments
          previousTreatments={version ? version.previousTreatments : demand.previousTreatments}
        />
      </Grid>

      <Grid item sm={12}>
        <ProposedTreatments demand={version ? version : demand} />
      </Grid>

      <Grid item sm={12}>
        <DemandAttachments attachments={demand.attachments} />
      </Grid>

      {!version && (
        <Grid item sm={12}>
          <DemandVersions demand={demand} versions={demand.versions} />
        </Grid>
      )}

      <Grid item sm={12}>
        <DemandHistories roles={roles} demand={demand} version={version} />
      </Grid>

      <DemandDetailsActions demand={demand} version={version} />
    </Grid>
  );
};

DemandDetails.propTypes = {
  demand: PropTypes.object,
  version: PropTypes.object,
  refetch: PropTypes.func,
};

export default DemandDetails;
