import {
  Box,
  Card,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Edit as EditIcon, Eye as EyeIcon, MapPin as MapPinIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink } from 'react-router-dom';
import EmptyList from 'src/components/EmptyList';

const useStyles = makeStyles((theme) => ({
  root: {},
  table_list: {
    '& th': {
      padding: '5px',
    },
    '& td': {
      padding: '5px',
      color: theme.palette.tertiary.main,
      '& a,button': {
        padding: '0px 5px',
      },
    },
  },
}));

const Results = ({
  className,
  centers,
  page,
  setPage,
  resetPage,
  pageSize,
  setPageSize,
  sort,
  setSort,
  isLoading,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('centers');
  const { t: tCommon } = useTranslation('common');

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleSortChange = (order) => {
    resetPage();

    if (sort === order) {
      setSort(`-${order}`);
    } else {
      setSort(order);
    }
  };

  return (
    <Card className={clsx(classes.root, className)}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table className={classes.table_list}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={sort === 'name' || sort === '-name'}
                    direction={sort === 'name' ? 'asc' : 'desc'}
                    onClick={() => {
                      handleSortChange('name');
                    }}
                  >
                    {t('fields.name')}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sort === 'address' || sort === '-address'}
                    direction={sort === 'address' ? 'asc' : 'desc'}
                    onClick={() => {
                      handleSortChange('address');
                    }}
                  >
                    {t('fields.address')}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sort === 'locality' || sort === '-locality'}
                    direction={sort === 'locality' ? 'asc' : 'desc'}
                    onClick={() => {
                      handleSortChange('locality');
                    }}
                  >
                    {t('fields.locality')}
                  </TableSortLabel>
                </TableCell>
                <TableCell>{t('fields.province')}</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sort === 'type' || sort === '-type'}
                    direction={sort === 'type' ? 'asc' : 'desc'}
                    onClick={() => {
                      handleSortChange('type');
                    }}
                  >
                    {t('fields.type')}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">{tCommon('tables.rows.actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {centers?.rows.map((center) => (
                <TableRow hover key={center.id}>
                  <TableCell>{center.name}</TableCell>
                  <TableCell>{center.address}</TableCell>
                  <TableCell>{center.locality}</TableCell>
                  <TableCell>{center.province?.name}</TableCell>
                  <TableCell>{center.type}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      color="primary"
                      value={center.id}
                      component={RouterLink}
                      to={`/app/centers/${center.id}/edit`}
                      disabled={isLoading}
                    >
                      <EditIcon></EditIcon>
                    </IconButton>
                    <IconButton
                      color="primary"
                      value={center.id}
                      component={RouterLink}
                      to={`/app/centers/${center.id}`}
                    >
                      <EyeIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {!isLoading && !centers?.rows.length && (
            <EmptyList text={t('labels.empty_list_text')}>
              <MapPinIcon />
            </EmptyList>
          )}
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={centers?.count || 0}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePageSizeChange}
        page={centers?.count ? page - 1 : 0}
        rowsPerPage={pageSize}
        rowsPerPageOptions={[25, 50, 100]}
      />
    </Card>
  );
};

Results.propTypes = {
  centers: PropTypes.object,
  page: PropTypes.number,
  setPage: PropTypes.func,
  resetPage: PropTypes.func,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
  sort: PropTypes.string,
  setSort: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default Results;
