import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Paper,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const DetailsTable = ({ center }) => {
  const { t } = useTranslation('centers');

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.name')}</Typography>
            </TableCell>
            <TableCell>{center.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.address')}</Typography>
            </TableCell>
            <TableCell>{center.address}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.locality')}</Typography>
            </TableCell>
            <TableCell>{center.locality}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.province')}</Typography>
            </TableCell>
            <TableCell>{center.province?.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.type')}</Typography>
            </TableCell>
            <TableCell>{center.type}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

DetailsTable.propTypes = {
  center: PropTypes.object,
};

export default DetailsTable;
