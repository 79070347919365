import { useMutation, useQueryCache } from 'react-query';
import { updateDrug } from 'src/services/api/drugs';

function useUpdateDrug() {
  const queryCache = useQueryCache();

  return useMutation(({ drug, data }) => updateDrug(drug, data), {
    onSuccess: (data) => queryCache.setQueryData(['drug', data.data?.id], data),
  });
}

export default useUpdateDrug;
