import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { FieldArray } from 'formik';
import get from 'lodash/get';
import { PropTypes } from 'prop-types';
import { useEffect } from 'react';
import { MinusCircle as MinusCircleIcon, PlusCircle as PlusCircleIcon } from 'react-feather';
import { File as FileIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useImmer } from 'use-immer';

import FileUploader from './components/FileUploader';

const useStyles = makeStyles((theme) => ({
  cardMargin: {
    margin: theme.spacing(2),
    marginTop: 0,
  },
  iconMargin: {
    marginRight: theme.spacing(1),
  },
  removeFileButton: {
    marginLeft: 'auto',
    color: theme.palette.error.main,
  },
  filenameMargin: {
    marginLeft: theme.spacing(3),
  },
  filenameTextMargin: {
    marginLeft: theme.spacing(1),
  },
}));

const EMPTY_FILE = {
  descripcion: '',
  archivo: null,
};

const FilesField = ({
  field,
  values,
  errors,
  touched,
  setFieldTouched,
  handleBlur,
  setFieldValue,
  handleChange,
  disabled,
  demandExtras,
  setDemandExtras,
  setFicherosAdjuntos,
  eliminarFicherosAdjuntos,
  setEliminarFicherosAdjuntos,
  cantDeleteAttachments
}) => {
  const classes = useStyles();
  const { t } = useTranslation('demands');
  const [filesnames, setFilesnames] = useImmer(get(demandExtras, `${field}.filesnames`) || []);

  const handleAddFile = async (index, fileBase64) => {
    await setFieldTouched(`${field}[${index}].archivo`, true);
    await setFieldValue(`${field}[${index}].archivo`, fileBase64);
    handleAttachment(index, fileBase64);
  };

  const handleAttachment = (index, fileBase64) => {
    const attachments = values[field];
    const description = attachments[index].descripcion;
    const file = fileBase64 || attachments[index].archivo;
    if(file && description) {
      const tmpFicherosAdjuntos = [...attachments];
      tmpFicherosAdjuntos[index].archivo = file;
      tmpFicherosAdjuntos[index].descripcion = description;
      setFicherosAdjuntos(tmpFicherosAdjuntos);
    }
  }

  const handleRemoveFile = (fileId) => {
    const tmpFicherosAdjuntosAEliminar = [...eliminarFicherosAdjuntos];
    tmpFicherosAdjuntosAEliminar.push(fileId);
    setEliminarFicherosAdjuntos(tmpFicherosAdjuntosAEliminar);
  }
  
  useEffect(() => {
    if (setDemandExtras) {
      setDemandExtras((draft) => {
        draft[field] = { filesnames };
      });
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesnames, setDemandExtras]);

  return (
    <Card>
      <CardHeader title={t(`fields.${field}`)} />
      <CardContent>
        <FieldArray
          name={field}
          render={(arrayHelpers) => (
            <>
              {values[field]?.map((file, index) => (
                <Card className={classes.cardMargin} key={index}>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        {file.previous ? (
                          <Box
                            className={classes.filenameMargin}
                            display="flex"
                            alignItems="center"
                          >
                            <FileIcon size={18} />
                            <Typography className={classes.filenameTextMargin}>
                              {filesnames[index] || file.descripcion}
                            </Typography>
                          </Box>
                        ) : (
                          <FileUploader
                            fileIndex={index}
                            filesnames={filesnames}
                            setFilesnames={setFilesnames}
                            handleAddFile={handleAddFile}
                            demandExtras={demandExtras}
                            setDemandExtras={setDemandExtras}
                          />
                        )}
                      </Grid>

                      {!file.previous && (
                        <Grid item xs={12}>
                          <TextField
                            error={Boolean(
                              get(touched, `${field}[${index}].descripcion`) &&
                                get(errors, `${field}[${index}].descripcion`),
                            )}
                            helperText={
                              get(touched, `${field}[${index}].descripcion`) &&
                              get(errors, `${field}[${index}].descripcion`)
                            }
                            fullWidth
                            multiline
                            required
                            label={t(`fields.${field}_labels.descripcion`)}
                            name={`${field}[${index}].descripcion`}
                            onBlur={(e) => {
                              handleBlur(e);
                              handleAttachment(index);
                            }}
                            onChange={handleChange}
                            disabled={disabled}
                            value={file.descripcion}
                            variant="outlined"
                          />
                        </Grid>
                      )}

                      <Button
                        className={classes.removeFileButton}
                        size="small"
                        onClick={() => {
                          arrayHelpers.remove(index);
                          setFilesnames((draft) => {
                            draft.splice(index, 1);
                          });

                          if (file.previous) {
                            handleRemoveFile(file.archivo);
                          }
                        }}
                        disabled={disabled || cantDeleteAttachments}
                      >
                        <MinusCircleIcon className={classes.iconMargin} size={18} />
                        {t('buttons.remove_file')}
                      </Button>
                    </Grid>
                  </CardContent>
                </Card>
              ))}

              <Button
                onClick={() => {
                  arrayHelpers.push(EMPTY_FILE);
                  setFilesnames((draft) => {
                    draft.push('');
                  });
                }}
                disabled={disabled}
              >
                <PlusCircleIcon className={classes.iconMargin} /> {t('buttons.add_file')}
              </Button>
            </>
          )}
        />
      </CardContent>
    </Card>
  );
};

FilesField.propTypes = {
  field: PropTypes.string,
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  setFieldTouched: PropTypes.func,
  handleBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
  setFicherosAdjuntos: PropTypes.func,
  eliminarFicherosAdjuntos: PropTypes.array,
  setEliminarFicherosAdjuntos: PropTypes.func,
  demandExtras: PropTypes.object,
  setDemandExtras: PropTypes.func,
  cantDeleteAttachments: PropTypes.bool
};

export default FilesField;
