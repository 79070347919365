import { Box, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { NavLink as RouterLink } from 'react-router-dom';

const ToolbarActions = () => {
  const { t } = useTranslation('drugs');

  return (
    <Box display="flex" justifyContent="flex-end">
      <Button color="primary" variant="contained" component={RouterLink} to="/app/drugs/create">
        {t('buttons.drugs_list_create')}
      </Button>
    </Box>
  );
};

export default ToolbarActions;
