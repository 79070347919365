import { Box, Button, Container, makeStyles, TextField, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Formik } from 'formik';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import Page from 'src/components/Page';
import { setToken } from 'src/store/reducers/auth';
import { fetchLogin } from 'src/store/reducers/user';
import { isAuthenticated as isAuthenticatedSelector } from 'src/store/selectors/auth';
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';

import Loader from 'src/components/Loader';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  bottomLinks: {
    '& p:nth-child(2)': {
      textAlign: 'end',
    },
  },
}));

const LoginView = () => {
  const classes = useStyles();
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation('auth');
  const appState = useSelector((state) => state.app);
  const isDefaultCompany = get(appState, 'data.default');
  const dispatch = useDispatch();   
  
  const [loading, setLoading] = useState(true);
  const [loginError, setLoginError] = useState('');

  const fetchData = async () => {
    try {
      const params = new URLSearchParams(location.search);
      const receivedToken = params.get('jwt');

      if (receivedToken) {
        console.log("Token recibido: ", receivedToken);
        const response = await dispatch(fetchLogin({ 'token': receivedToken }));
        console.log("Respuesta obtenida: ", response);
        if (response?.payload?.token) {
          const { token } = response.payload;
          dispatch(setToken({ token }));
          
        } else if (response?.payload?.message) {
          setLoginError(response.payload.message);
          
        } else if (response?.error?.message) {
          setLoginError(response.error.message);
          
        } else {
          setLoginError(t('errors.common.login'));
        }
        setLoading(false);
      
      } else {
        console.log("No hay token");
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(async () => {
    await fetchData();
  }, []); 
  

  if (isAuthenticated) {
    return <Redirect to="/app" />;
  } else if (
          window.location.host.indexOf('orgoa.plataformaoncologia.es') != -1
  ){
    // window.location.href='https://platform.orgaconsulting.es';
  }

  return (
    <Page className={classes.root}>
      {loading ? (
        <Loader />
      ) : 
      isAuthenticated ? (
        <Redirect to="/app" />
      ) : (
        <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
          <Container maxWidth="sm">
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email(t('errors.email.valid'))
                  .required(t('errors.email.required')),
                password: Yup.string().required(t('errors.password.required')),
              })}
              onSubmit={async (values) => {
                const { email, password } = values;

                const response = await dispatch(fetchLogin({ email, password }));

                if (response?.payload?.token) {
                  const { token } = response.payload;
                  dispatch(setToken({ token }));
                  const from = get(location, 'state.from', '/app/home');
                  history.push(from);
                } else if (response?.payload?.message) {
                  setLoginError(response.payload.message);
                } else if (response?.error?.message) {
                  setLoginError(response.error.message);
                } else {
                  setLoginError(t('errors.common.login'));
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form id='myForm' onSubmit={handleSubmit}>
                  {(window.location.host.indexOf('orgoa.plataformaoncologia.es') == -1) && (
                  <>
                      <Box mb={3}>
                      <Typography color="textPrimary" variant="h2">
                        {t('labels.signin_title')}
                      </Typography>
                      <Typography color="textSecondary" gutterBottom variant="body2">
                        {t('labels.signin_subtitle')}
                      </Typography>
                    </Box>
                    {loginError !== '' ? (
                      <Box mb={3}>
                        <Alert variant="filled" severity="error">
                          {loginError}
                        </Alert>
                      </Box>
                    ) : (
                      ''
                    )}
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label={t('fields.email')}
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                      disabled={isSubmitting}
                    />
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      label={t('fields.password')}
                      margin="normal"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      variant="outlined"
                      disabled={isSubmitting}
                    />
                    <Box my={2}>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        {t('buttons.login')}
                      </Button>
                    </Box>

                    <Box className={classes.bottomLinks} display="flex" justifyContent="space-between">
                      {!isDefaultCompany && (
                        <Typography color="textSecondary" variant="body1">
                          {t('labels.request_account')}{' '}
                          <Link to="/#request" variant="h6">
                            {t('labels.request_account_link')}
                          </Link>
                        </Typography>
                      )}

                      <Typography color="textSecondary" variant="body1">
                        <Link to="/forgot-password" variant="h6">
                          {t('labels.forgot_password_link')}
                        </Link>
                      </Typography>
                    </Box>
                  </>
                  )}
                </form>
              )}
            </Formik>
          </Container>
        </Box>
      )}
    </Page>
  );

};

export default LoginView;