import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Paper,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import format from 'date-fns/format';
import { GENDERS } from 'src/constants/genders';

const DetailsTable = ({ patient, company }) => {
  const { t } = useTranslation('patients');

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.firstName')}</Typography>
            </TableCell>
            <TableCell>{patient.firstName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.lastName')}</Typography>
            </TableCell>
            <TableCell>{patient.lastName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.gender')}</Typography>
            </TableCell>
            <TableCell>
              {patient.gender === GENDERS.male
                ? t('fields.genders.male')
                : t('fields.genders.female')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.birthday')}</Typography>
            </TableCell>
            <TableCell>{format(new Date(patient.birthday), 'dd/MM/yyyy')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.cardNumber')}</Typography>
            </TableCell>
            <TableCell>{patient.cardNumber}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.email')}</Typography>
            </TableCell>
            <TableCell>{patient.email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.type')}</Typography>
            </TableCell>
            <TableCell>{patient.type}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.company')}</Typography>
            </TableCell>
            <TableCell>{company.name}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

DetailsTable.propTypes = {
  patient: PropTypes.object,
};

export default DetailsTable;
