import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import { LOCATION } from 'src/constants/demands';

import Step2Form from './components/Step2Form';

const Step2 = ({ demand, setDemand, disableForm, setDisableNext, isContinue, isChangeDates }) => {
  const [isFullfilled] = useState(!isEmpty(demand.step2));

  const step2Data = demand.step2;
  const initialValues = {
    valoracionGeriatrica: step2Data.valoracionGeriatrica || '',
    diagnosticoHistologico: step2Data.diagnosticoHistologico || '',
    type: step2Data.type || '',
    mamaTipo: step2Data.mamaTipo || '',
    mamaEr: step2Data.mamaEr || LOCATION.COMMON.NO_REALIZADO,
    mamaEp: step2Data.mamaEp || LOCATION.COMMON.NO_REALIZADO,
    mamaHer2neu: step2Data.mamaHer2neu || LOCATION.COMMON.NO_REALIZADO,
    mamaFishHer2neu: step2Data.mamaFishHer2neu || LOCATION.COMMON.NO_REALIZADO,
    mamaKi67: get(step2Data, 'mamaKi67', ''),
    gastricoHerceptest: step2Data.gastricoHerceptest || LOCATION.COMMON.NO_REALIZADO,
    gastricoFishHer2: step2Data.gastricoFishHer2 || LOCATION.COMMON.NO_REALIZADO,
    colorectalTipo: step2Data.colorectalTipo || LOCATION.COLORECTAL.TIPO.NO_ESPECIFICADO,
    colorectalKras: step2Data.colorectalKras || LOCATION.COMMON.NO_REALIZADO,
    colorectalNras: step2Data.colorectalNras || LOCATION.COMMON.NO_REALIZADO,
    colorectalBraf: step2Data.colorectalBraf || LOCATION.COMMON.NO_REALIZADO,
    colorectalMmr: step2Data.colorectalMmr || LOCATION.COMMON.NO_REALIZADO,
    colorectalMi: step2Data.colorectalMi || LOCATION.COMMON.NO_REALIZADO,
    pulmonTipo: step2Data.pulmonTipo || '',
    pulmonEgfr: step2Data.pulmonEgfr || LOCATION.COMMON.NO_REALIZADO,
    pulmonAlk: step2Data.pulmonAlk || LOCATION.COMMON.NO_REALIZADO,
    pulmonRosI: step2Data.pulmonRosI || LOCATION.COMMON.NO_REALIZADO,
    pulmonBrafV600E: step2Data.pulmonBrafV600E || LOCATION.COMMON.NO_REALIZADO,
    pulmonNtrk1: step2Data.pulmonNtrk1 || LOCATION.COMMON.NO_REALIZADO,
    pulmonNtrk2: step2Data.pulmonNtrk2 || LOCATION.COMMON.NO_REALIZADO,
    pulmonNtrk3: step2Data.pulmonNtrk3 || LOCATION.COMMON.NO_REALIZADO,
    pulmonMetEx14: step2Data.pulmonMetEx14 || LOCATION.COMMON.NO_REALIZADO,
    pulmonRet: step2Data.pulmonRet || LOCATION.COMMON.NO_REALIZADO,
    pulmonPdLi: step2Data.pulmonPdLi || '',
    prostataTipo: step2Data.prostataTipo || '',
    prostataPsaActual: step2Data.prostataPsaActual || '',
    prostataPsaPrevio: step2Data.prostataPsaPrevio || '',
    prostataEstratificacionRiesgo: step2Data.prostataEstratificacionRiesgo || '',
    prostataGradoGleason: step2Data.prostataGradoGleason || '',
    mieloproliferativosCd20: step2Data.mieloproliferativosCd20 || LOCATION.COMMON.NO_REALIZADO,
    mieloproliferativosSds: step2Data.mieloproliferativosSds || '',
    vejigaTipo: step2Data.vejigaTipo || '',
    vejigaPdLi: step2Data.vejigaPdLi || '',
    vejigaDescripcionHistologica: step2Data.vejigaDescripcionHistologica || '',
    vejigaSubtipo: step2Data.vejigaSubtipo || '',
    otrosDescription: step2Data.otrosDescription || '',
    t: get(step2Data, 't', ''),
    n: get(step2Data, 'n', ''),
    m: get(step2Data, 'm', ''),
    estadio: step2Data.estadio || '',
    ecog: get(step2Data, 'ecog', ''),
  };
  const [clinicalProtocol, setClinicalProtocol] = useState(demand.step2 || initialValues);

  useEffect(() => {
    if (!isFullfilled) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }
  }, [isFullfilled, setDisableNext]);

  useEffect(() => {
    // Omit empty strings, but accept 0's
    const step2 = pickBy(clinicalProtocol, (value) => value?.length || value?.length === undefined);
    setDemand({ ...demand, step2 });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicalProtocol, setDemand]);

  return (
    <Step2Form
      initialValues={initialValues}
      setClinicalProtocol={setClinicalProtocol}
      isFullfilled={isFullfilled}
      disableForm={disableForm || isContinue || isChangeDates}
      setDisableNext={setDisableNext}
    />
  );
};

Step2.propTypes = {
  demand: PropTypes.object,
  setDemand: PropTypes.func,
  disableForm: PropTypes.bool,
  setDisableNext: PropTypes.func,
  isContinue: PropTypes.bool,
  isChangeDates: PropTypes.bool,
};

export default Step2;
