import {
  Button,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import useGetMedia from 'src/hooks/demands/useGetMedia';
import { useTranslation } from 'react-i18next';
import { STATUS } from 'src/constants/invitations';
import { useEffect, useState } from 'react';
import { Download as DownloadIcon } from 'react-feather';
import { downloadFile } from 'src/utils/downloadFile';
import { useSnackbar } from 'notistack';
import { ROLES } from 'src/constants/roles';

const useStyles = makeStyles((theme) => ({
  rejectButton: {
    marginRight: theme.spacing(1),
  },
  acceptButton: {
    marginRight: theme.spacing(1),
  },
  wordBreak: {
    wordBreak: 'break-all',
  },
}));

const DetailsTable = ({
  invitation,
  validateInvitation,
  rejectInvitation,
  disableActionButtons,
}) => {
  const classes = useStyles();
  const { t: tInvitations } = useTranslation('invitations');
  const { t: tRoles } = useTranslation('rolesNames');
  const { enqueueSnackbar } = useSnackbar();
  const [attachment, setAttachment] = useState(null);

  const { isLoading, data: media, isError, refetch: fetchAttachment } = useGetMedia(
    attachment?.id,
    attachment?.mediaType,
  );

  useEffect(() => {
    if (attachment) {
      fetchAttachment();
    }
  }, [attachment, fetchAttachment]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(tInvitations('modals.error.download_attachment'), { variant: 'error' });
    }
    if (!isLoading && !isError && media) {
      downloadFile(media.data, attachment.mediaType, attachment.originalFilename);
      setAttachment(null);
    }
  }, [
    media,
    enqueueSnackbar,
    isError,
    isLoading,
    tInvitations,
    attachment?.mediaType,
    attachment?.originalFilename,
  ]);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{tInvitations('fields.name')}</Typography>
            </TableCell>
            <TableCell>{invitation.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{tInvitations('fields.email')}</Typography>
            </TableCell>
            <TableCell className={classes.wordBreak}>{invitation.email}</TableCell>
          </TableRow>
          {invitation.phone && (
            <TableRow>
              <TableCell component="th" scope="row">
                <Typography component="strong">{tInvitations('fields.phone')}</Typography>
              </TableCell>
              <TableCell>{invitation.phone}</TableCell>
            </TableRow>
          )}
          {invitation.reason && (
            <TableRow>
              <TableCell component="th" scope="row">
                <Typography component="strong">{tInvitations('fields.reason')}</Typography>
              </TableCell>
              <TableCell>{invitation.reason}</TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{tInvitations('fields.type')}</Typography>
            </TableCell>
            <TableCell>{tRoles(invitation.type)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{tInvitations('fields.centers')}</Typography>
            </TableCell>
            <TableCell>{invitation.centersNames}</TableCell>
          </TableRow>
          {invitation.doctorsDni && (
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{(invitation.type === ROLES.DOCTOR) ? tInvitations('fields.doctor') : tInvitations('fields.doctors')}</Typography>
            </TableCell>
            <TableCell>{invitation.doctorsDni}</TableCell>
          </TableRow>
          )}{invitation.attachments && invitation.attachments.length > 0 && (
            <TableRow>
              <TableCell component="th" scope="row">
                <Typography component="strong">{tInvitations('fields.attachments')}</Typography>
              </TableCell>
              <TableCell>
              <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              {invitation.attachments.map((file) => (
                <ListItem>
                  {file.originalFilename}
                  <IconButton aria-label="download"
                        className={classes.button}
                        color="primary" edge="end"
                        title={file.originalFilename}
                        onClick={async () => setAttachment(file)}
                      >
                      <DownloadIcon />
                    </IconButton>
                </ListItem>
              ))}
              </List>               
              </TableCell>
            </TableRow>
            )}
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{tInvitations('fields.company')}</Typography>
            </TableCell>
            <TableCell>{invitation.company.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{tInvitations('fields.status.name')}</Typography>
            </TableCell>
            <TableCell>{tInvitations(`fields.status.${STATUS[invitation.status]}`)}</TableCell>
          </TableRow>

          {invitation.status === STATUS.PENDING && (
            <TableRow>
              <TableCell component="th" scope="row"></TableCell>
              <TableCell align="right">
                <Button
                  className={classes.rejectButton}
                  onClick={rejectInvitation}
                  disabled={disableActionButtons}
                >
                  {tInvitations('buttons.invitations_list_reject')}
                </Button>

                <Button
                  className={classes.acceptButton}
                  onClick={validateInvitation}
                  disabled={disableActionButtons}
                >
                  {tInvitations('buttons.invitations_list_accept')}
                </Button>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

DetailsTable.propTypes = {
  invitation: PropTypes.object,
  validateInvitation: PropTypes.func,
  rejectInvitation: PropTypes.func,
  disableActionButtons: PropTypes.bool,
};

export { DetailsTable };
