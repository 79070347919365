import { Box, Container, LinearProgress, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Page from 'src/components/Page';
import { usePagination } from 'src/hooks/pagination';
import { useListUsers } from 'src/hooks/users';
import { setFilters } from 'src/store/reducers/lists';

import Results from './components/Results';
import Toolbar from './components/Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const ListUsersView = () => {
  const classes = useStyles();
  const { t } = useTranslation('users');
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.lists.users?.filters);
  const {
    page,
    pageSize,
    setPage,
    setPageSize,
    resetPage,
    fitsInPage,
    navigateToLastPage,
  } = usePagination(filters?.page, filters?.pageSize);
  const [name, setName] = useState(filters?.name || '');
  const [email, setEmail] = useState(filters?.email || '');
  const [dni, setDni] = useState(filters?.dni || '');
  const [company, setCompany] = useState(filters?.company || null);
  const [role, setRole] = useState(filters?.role || null);

  const query = {
    page,
    pageSize,
    name,
    email,
    dni,
    companyId: company?.id,
    roleId: role?.id,
    include: 'role,company',
  };
  const { isFetching, resolvedData: users, isError } = useListUsers(query);

  if (users?.data.count && !fitsInPage(users.data.count)) {
    navigateToLastPage(users.data.count);
  }

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(t('modals.error.title_list_users'), { variant: 'error' });
    }
  }, [enqueueSnackbar, isError, t]);

  useEffect(() => {
    dispatch(
      setFilters({ list: 'users', filters: { page, pageSize, name, email, dni, company, role } }),
    );
  }, [company, dispatch, name, email, dni, page, pageSize, role]);

  return (
    <Page className={classes.root}>
      <Container maxWidth={false}>
        <Toolbar
          resetPage={resetPage}
          name={name}
          setName={setName}
          email={email}
          setEmail={setEmail}
          dni={dni}
          setDni={setDni}
          company={company}
          setCompany={setCompany}
          role={role}
          setRole={setRole}
        />

        <Box mt={3}>
          {isFetching && <LinearProgress color="primary" />}
          <Results
            users={users?.data}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            isLoading={isFetching}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default ListUsersView;
