import { Card, CardContent, CardHeader, Container, Divider, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AlertDialog from 'src/components/AlertDialog';
import Page from 'src/components/Page';
import { useGoBack } from 'src/hooks/common';
import { useCreateInvitation } from 'src/hooks/invitations';

import CreateInvitationForm from './components/CreateInvitationForm';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const CreateInvitationView = () => {
  const { t } = useTranslation('invitations');
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const goBack = useGoBack('/app/invitations');
  const [createInvitation, { isLoading, data, isError, error }] = useCreateInvitation();

  const handleSubmit = (values) => createInvitation(values);

  useEffect(() => {
    if (!isLoading && !isError && data) {
      enqueueSnackbar(t('modals.success.text_create_invitation'), { variant: 'success' });

      goBack();
    }
  }, [data, enqueueSnackbar, goBack, isError, isLoading, t]);

  return (
    <Page className={classes.root}>
      <Container maxWidth="lg">
        <Card>
          <CardHeader
            subheader={t('labels.createInvitation_subtitle')}
            title={t('labels.createInvitation_title')}
          />
          <Divider />
          <CardContent>
            <CreateInvitationForm handleFormSubmit={handleSubmit} />

            {isError && (
              <AlertDialog
                title={t('modals.error.title_create_invitation')}
                text={error?.message || t('modals.error.text_create_invitation')}
                type="error"
              />
            )}
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default CreateInvitationView;
