import { useMutation } from 'react-query';
import { updateDemandCycles } from 'src/services/api/demands';

function useUpdateDemandCycles() {
  return useMutation(({ demand, data, doctorId }) =>
    updateDemandCycles(demand, data, { doctorId }),
  );
}

export default useUpdateDemandCycles;
