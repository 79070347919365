import { Route, Switch } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';

import PrivateRoute from './components/PrivateRoute';

const RoutesList = () => (
  <Switch>
    <PrivateRoute
      path="/app"
      withPasswordExpired={true}
      withResetPassword={true}
      withTermsPending={true}
    >
      <DashboardLayout />
    </PrivateRoute>
    <Route path="/">
      <MainLayout />
    </Route>
  </Switch>
);

export default RoutesList;
