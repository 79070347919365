import { useMutation } from 'react-query';
import { updateDemandContinue } from 'src/services/api/demands';

function useUpdateDemandContinue() {
  return useMutation(({ demand, data, doctorId }) =>
    updateDemandContinue(demand, data, { doctorId }),
  );
}

export default useUpdateDemandContinue;
