import { Box, Button, CircularProgress, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import format from 'date-fns/format';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GENDERS } from 'src/constants/genders';
import { TYPE } from 'src/constants/patients';
import { useListCompanies } from 'src/hooks/company';
import * as Yup from 'yup';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import { KeyboardDatePicker } from '@material-ui/pickers';

const CreateEditPatientForm = ({ patient, handleFormSubmit, handleCancel, action }) => {
  const { t } = useTranslation(['patients', 'companies']);
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, data: companies, isError } = useListCompanies({ all: true, default: false });

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    cardNumber: '',
    birthday: null,
    gender: '',
    type: '',
    companyId: null,
  };

  const getPatientData = () => {
    if (patient?.data) {
      return {
        firstName: patient.data.firstName,
        lastName: patient.data.lastName,
        email: patient.data.email || '',
        cardNumber: patient.data.cardNumber,
        birthday: new Date(patient.data.birthday),
        gender: patient.data.gender,
        type: patient.data.type,
        companyId: companies?.data.rows.find(({ id }) => patient.data.companyId === id) || null,
      };
    }

    return initialValues;
  };

  const onSubmit = (values, formikBag) => {
    const data = omitBy(values, isEmpty);
    data.companyId = values.companyId.id;
    data.birthday = format(values.birthday, 'yyyy-MM-dd');

    if (patient) delete data.companyId;

    return handleFormSubmit({ patient, values: data, formikBag });
  };

  const validationSchema = Yup.object().shape({
    companyId: Yup.object().nullable().required(t('errors.company.required')),
    type: Yup.string().required(t('errors.type.required')),
    gender: Yup.string().required(t('errors.gender.required')),
    cardNumber: Yup.string().required(t('errors.cardNumber.required')),
    birthday: Yup.date()
      .typeError(t('errors.birthday.date_format'))
      .required(t('errors.birthday.required')),
    email: Yup.string().email(t('errors.email.valid')).max(255, t('errors.email.max')),
    firstName: Yup.string()
      .min(3, t('errors.firstName.min'))
      .max(30, t('errors.firstName.max'))
      .required(t('errors.firstName.required')),
    lastName: Yup.string()
      .min(3, t('errors.lastName.min'))
      .max(255, t('errors.lastName.max'))
      .required(t('errors.lastName.required')),
  });

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(t('companies:modals.error.title_get_companies'), { variant: 'error' });
    }
  }, [enqueueSnackbar, isError, t]);

  return (
    <Formik
      enableReinitialize
      initialValues={getPatientData()}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        errors,
        handleBlur,
        setFieldTouched,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <TextField
                error={Boolean(touched.firstName && errors.firstName)}
                fullWidth
                helperText={touched.firstName && errors.firstName}
                label={t('fields.firstName')}
                name="firstName"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting}
                value={values.firstName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                error={Boolean(touched.lastName && errors.lastName)}
                fullWidth
                helperText={touched.lastName && errors.lastName}
                label={t('fields.lastName')}
                name="lastName"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting}
                value={values.lastName}
                variant="outlined"
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <TextField
                error={Boolean(touched.gender && errors.gender)}
                helperText={touched.gender && errors.gender}
                fullWidth
                label={t('fields.gender')}
                name="gender"
                onChange={handleChange}
                disabled={isSubmitting}
                select
                SelectProps={{ native: true }}
                value={values.gender}
                variant="outlined"
                onBlur={handleBlur}
              >
                <option value="" hidden></option>
                <option value={GENDERS.male}>{t('fields.genders.male')}</option>
                <option value={GENDERS.female}>{t('fields.genders.female')}</option>
              </TextField>
            </Grid>

            <Grid item md={8} xs={12}>
              <TextField
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                label={t('fields.email')}
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting}
                type="email"
                value={values.email}
                variant="outlined"
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <KeyboardDatePicker
                fullWidth
                disableToolbar
                format="dd/MM/yyyy"
                label={t('fields.birthday')}
                error={Boolean(touched.birthday && errors.birthday)}
                helperText={touched.birthday && errors.birthday}
                name="birthday"
                value={values.birthday}
                onChange={(date) => setFieldValue('birthday', date)}
                onBlur={() => setFieldTouched('birthday', true)}
                disabled={isSubmitting}
                variant="inline"
                inputVariant="outlined"
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <Autocomplete
                openOnFocus={true}
                selectOnFocus={false}
                options={companies?.data?.rows || []}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                loading={isLoading}
                name="companyId"
                value={values.companyId}
                onChange={(e, value) => setFieldValue('companyId', value)}
                onBlur={() => setFieldTouched('companyId', true)}
                disabled={Boolean(patient?.data?.companyId) || isSubmitting}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('fields.select_company')}
                    variant="outlined"
                    error={Boolean(touched.companyId && errors.companyId)}
                    helperText={touched.companyId && errors.companyId}
                    inputProps={{
                      ...params.inputProps,
                      style: { cursor: 'pointer' },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      readOnly: true,
                      endAdornment: (
                        <>
                          {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                error={Boolean(touched.type && errors.type)}
                helperText={touched.type && errors.type}
                fullWidth
                label={t('fields.type')}
                name="type"
                onChange={handleChange}
                disabled={isSubmitting}
                select
                SelectProps={{ native: true }}
                value={values.type}
                variant="outlined"
                onBlur={handleBlur}
              >
                <option value="" hidden></option>
                <option value={TYPE.Funcionario}>{t('fields.type_select.func')}</option>
                <option value={TYPE.ISFAS}>{t('fields.type_select.isfas')}</option>
                <option value={TYPE.MUFACE}>{t('fields.type_select.muface')}</option>
                <option value={TYPE.MUGEJU}>{t('fields.type_select.mugeju')}</option>
                <option value={TYPE.Privada}>{t('fields.type_select.private')}</option>
                <option value={TYPE.UNDEFINED}>{t('fields.type_select.undefined')}</option>
              </TextField>
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                error={Boolean(touched.cardNumber && errors.cardNumber)}
                fullWidth
                helperText={touched.cardNumber && errors.cardNumber}
                label={t('fields.cardNumber')}
                name="cardNumber"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting}
                value={values.cardNumber}
                variant="outlined"
              />
            </Grid>
            <Box p={2}>
              <Button
                color="primary"
                fullWidth
                disabled={isSubmitting}
                size="large"
                variant="outlined"
                onClick={handleCancel}
              >
                {t('buttons.cancel')}
              </Button>
            </Box>
            <Box p={2}>
              <Button
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                {t(`buttons.${action}`)}
              </Button>
            </Box>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

CreateEditPatientForm.propTypes = {
  initialValues: PropTypes.object,
  handleFormSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  action: PropTypes.string,
};

export default CreateEditPatientForm;
