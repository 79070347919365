import { Button, ButtonGroup, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 45,
    marginTop: theme.spacing(3),
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  footerLink: {
    color: theme.palette.primary.contrastText,
    borderColor: theme.palette.primary.contrastText + '!important',
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const Footer = () => {
  const classes = useStyles();
  const { t } = useTranslation('companies');

  return (
    <div className={classes.footer}>
      <ButtonGroup variant="text" size="small">
        <Button className={classes.footerLink} component={RouterLink} to="/policies/legal_notice">
          {t('fields.legal_notice')}
        </Button>
        <Button className={classes.footerLink} component={RouterLink} to="/policies/privacy_policy">
          {t('fields.privacy_policy')}
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default Footer;
