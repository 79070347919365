import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';
import AlertDialog from 'src/components/AlertDialog';
import PrivateRoute from 'src/components/PrivateRoute';
import { ROLES } from 'src/constants/roles';
import AccountView from 'src/views/account/AccountView';
import CentersListView from 'src/views/centers/CentersListView';
import CreateCenterView from 'src/views/centers/CreateCenterView';
import EditCenterView from 'src/views/centers/EditCenterView';
import ViewCenterView from 'src/views/centers/ViewCenterView';
import CompaniesListView from 'src/views/companies/CompaniesListView';
import CompanyEdit from 'src/views/companies/CompanyEdit';
import CompanyView from 'src/views/companies/CompanyView';
import CreateDemandView from 'src/views/demands/CreateDemandView';
import DetailsDemandView from 'src/views/demands/DetailsDemandView';
import ListDemandsView from 'src/views/demands/ListDemandsView';
import CreateDrugView from 'src/views/drugs/CreateDrugView';
import DrugDetailsView from 'src/views/drugs/DrugDetailsView';
import EditDrugView from 'src/views/drugs/EditDrugView';
import ListDrugsView from 'src/views/drugs/ListDrugsView';
import HomeView from 'src/views/home/HomeView';
import CreateInvitationView from 'src/views/invitations/CreateInvitationView';
import InvitationDetailView from 'src/views/invitations/InvitationDetailView';
import ListInvitationsView from 'src/views/invitations/ListInvitationsView';
import CreatePatientView from 'src/views/patients/CreatePatientView';
import DetailsPatientView from 'src/views/patients/DetailsPatientView';
import EditPatientView from 'src/views/patients/EditPatientView';
import PatientsListView from 'src/views/patients/PatientsListView';
import CreateUserView from 'src/views/users/CreateUserView';
import EditUserView from 'src/views/users/EditUserView';
import ListUsersView from 'src/views/users/ListUsersView';
import UserProfileView from 'src/views/users/UserProfileView';
import ListCodesView from 'src/views/codes/CodesListView';
import CreateCodesView from 'src/views/codes/CreateCodeView';
import EditCodesView from 'src/views/codes/EditCodeView';
import ViewCodesView from 'src/views/codes/ViewCodeView';
import CreatePresentationView from 'src/views/presentations/CreatePresentationView';
import ListPresentationsView from 'src/views/presentations/PresentationsListView';

import NavBar from './components/NavBar';
import TopBar from './components/TopBar';
import TermsConditionsDialog from './components/TermsConditionsDialog';
import EditPresentationView from 'src/views/presentations/EditPresentationView';
import PresentationDetailsView from 'src/views/presentations/PresentationDetailsView';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
}));

const Container = ({ classes, setMobileNavOpen, isMobileNavOpen, children, disableActions }) => (
  <div className={classes.root}>
    <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} disabled={disableActions} />
    <NavBar
      onMobileClose={() => setMobileNavOpen(false)}
      openMobile={isMobileNavOpen}
      disabled={disableActions}
    />
    <div className={classes.wrapper}>
      <div className={classes.contentContainer}>
        <div className={classes.content}>{children}</div>
      </div>
    </div>
  </div>
);

const DashboardLayout = () => {
  const classes = useStyles();
  const { path } = useRouteMatch();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const { t } = useTranslation('auth');
  const passwordExpired = useSelector((state) => state.app.passwordExpired);
  const requireResetPassword = useSelector((state) => state.app.requireResetPassword);
  const requireAcceptTerms = useSelector((state) => state.app.requireAcceptTerms);

  return (
    <Container
      classes={classes}
      setMobileNavOpen={setMobileNavOpen}
      isMobileNavOpen={isMobileNavOpen}
      disableActions={passwordExpired || requireResetPassword || requireAcceptTerms}
    >
      <Switch>
        <PrivateRoute exact path={path} />
        <PrivateRoute
          path={`${path}/account`}
          withPasswordExpired={true}
          withResetPassword={true}
          withTermsPending={true}
        >
          <AccountView />
        </PrivateRoute>
        <PrivateRoute path={`${path}/home`}>
          <HomeView />
        </PrivateRoute>
        <PrivateRoute path={`${path}/companies/:companyId/edit`} roles={[ROLES.SUPERADMIN, ROLES.ADMIN]}>
          <CompanyEdit />
        </PrivateRoute>
        <PrivateRoute path={`${path}/companies/:companyId`} roles={[ROLES.SUPERADMIN, ROLES.ADMIN]}>
          <CompanyView />
        </PrivateRoute>
        <PrivateRoute path={`${path}/companies`} roles={[ROLES.SUPERADMIN, ROLES.ADMIN]}>
          <CompaniesListView />
        </PrivateRoute>
        <PrivateRoute path={`${path}/patients/create`} roles={[ROLES.SUPERADMIN, ROLES.ADMIN]}>
          <CreatePatientView />
        </PrivateRoute>
        <PrivateRoute
          path={`${path}/patients/:patientId/edit`}
          roles={[ROLES.SUPERADMIN, ROLES.ADMIN]}
        >
          <EditPatientView />
        </PrivateRoute>
        <PrivateRoute path={`${path}/patients/:patientId`} roles={[ROLES.SUPERADMIN, ROLES.ADMIN]}>
          <DetailsPatientView />
        </PrivateRoute>
        <PrivateRoute path={`${path}/patients`} roles={[ROLES.SUPERADMIN, ROLES.ADMIN]}>
          <PatientsListView />
        </PrivateRoute>
        <PrivateRoute path={`${path}/invitations/create`} roles={[ROLES.SUPERADMIN, ROLES.ADMIN]}>
          <CreateInvitationView />
        </PrivateRoute>
        <PrivateRoute path={`${path}/users/create`} roles={[ROLES.SUPERADMIN]}>
          <CreateUserView />
        </PrivateRoute>
        <PrivateRoute
          path={`${path}/invitations/:invitationId`}
          roles={[ROLES.SUPERADMIN, ROLES.ADMIN]}
        >
          <InvitationDetailView />
        </PrivateRoute>
        <PrivateRoute path={`${path}/invitations`} roles={[ROLES.SUPERADMIN, ROLES.ADMIN]}>
          <ListInvitationsView />
        </PrivateRoute>
        <PrivateRoute path={`${path}/users/:userId/edit`} roles={[ROLES.SUPERADMIN]}>
          <EditUserView />
        </PrivateRoute>
        <PrivateRoute
          path={`${path}/users/profile/:userId`}
          roles={[ROLES.SUPERADMIN]}
        >
          <UserProfileView />
        </PrivateRoute>
        <PrivateRoute path={`${path}/users`} roles={[ROLES.SUPERADMIN, ROLES.ADMIN]}>
          <ListUsersView />
        </PrivateRoute>
        <PrivateRoute path={`${path}/drugs/create`} roles={[ROLES.SUPERADMIN, ROLES.ADMIN]}>
          <CreateDrugView />
        </PrivateRoute>
        <PrivateRoute path={`${path}/drugs/:drugId/edit`} roles={[ROLES.SUPERADMIN, ROLES.ADMIN]}>
          <EditDrugView />
        </PrivateRoute>
        <PrivateRoute path={`${path}/drugs/:drugId`} roles={[ROLES.SUPERADMIN, ROLES.ADMIN]}>
          <DrugDetailsView />
        </PrivateRoute>
        <PrivateRoute path={`${path}/drugs`} roles={[ROLES.SUPERADMIN, ROLES.ADMIN]}>
          <ListDrugsView />
        </PrivateRoute>
        <PrivateRoute
          path={`${path}/presentations/create`}
          roles={[ROLES.SUPERADMIN, ROLES.ADMIN]}
        >
          <CreatePresentationView />
        </PrivateRoute>
        <PrivateRoute
          path={`${path}/presentations/:presentationId/edit`}
          roles={[ROLES.SUPERADMIN, ROLES.ADMIN]}
        >
          <EditPresentationView />
        </PrivateRoute>
        <PrivateRoute
          path={`${path}/presentations/:presentationId`}
          roles={[ROLES.SUPERADMIN, ROLES.ADMIN]}
        >
          <PresentationDetailsView />
        </PrivateRoute>
        <PrivateRoute
          path={`${path}/presentations`}
          roles={[ROLES.SUPERADMIN, ROLES.ADMIN]}
        >
          <ListPresentationsView />
        </PrivateRoute>
        <PrivateRoute path={`${path}/centers/create`} roles={[ROLES.SUPERADMIN]}>
          <CreateCenterView />
        </PrivateRoute>
        <PrivateRoute path={`${path}/centers/:centerId/edit`} roles={[ROLES.SUPERADMIN]}>
          <EditCenterView />
        </PrivateRoute>
        <PrivateRoute path={`${path}/centers/:centerId`} roles={[ROLES.SUPERADMIN]}>
          <ViewCenterView />
        </PrivateRoute>
        <PrivateRoute path={`${path}/centers`} roles={[ROLES.SUPERADMIN]}>
          <CentersListView />
        </PrivateRoute>
        <PrivateRoute path={`${path}/codes/create`} roles={[ROLES.SUPERADMIN, ROLES.ADMIN]}>
          <CreateCodesView />
        </PrivateRoute>
        <PrivateRoute path={`${path}/codes/:codeId/edit`} roles={[ROLES.SUPERADMIN, ROLES.ADMIN]}>
          <EditCodesView />
        </PrivateRoute>
        <PrivateRoute path={`${path}/codes/:codeId`} roles={[ROLES.SUPERADMIN, ROLES.ADMIN]}>
          <ViewCodesView />
        </PrivateRoute>
        <PrivateRoute path={`${path}/codes`} roles={[ROLES.SUPERADMIN, ROLES.ADMIN]}>
          <ListCodesView />
        </PrivateRoute>
        <PrivateRoute path={`${path}/demands/create`} roles={[ROLES.DOCTOR, ROLES.ASSISTANT, ROLES.MANAGER]}>
          <CreateDemandView />
        </PrivateRoute>
        <PrivateRoute
          path={`${path}/demands/:demandIdEdit/edit`}
          roles={[ROLES.DOCTOR, ROLES.ASSISTANT, ROLES.MANAGER]}
        >
          <CreateDemandView />
        </PrivateRoute>
        <PrivateRoute path={`${path}/demands/:demandId/version/:versionId`}>
          <DetailsDemandView />
        </PrivateRoute>
        <PrivateRoute path={`${path}/demands/:demandId`}>
          <DetailsDemandView />
        </PrivateRoute>
        <PrivateRoute path={`${path}/demands`}>
          <ListDemandsView />
        </PrivateRoute>
        <PrivateRoute path="*">
          <Redirect to="/404" />
        </PrivateRoute>
      </Switch>

      {passwordExpired && (
        <>
          <Redirect to="/app/account" />

          <AlertDialog
            type="error"
            title={t('modals.errors.title_password_expired')}
            text={t('modals.errors.text_password_expired')}
          />
        </>
      )}
      {requireResetPassword && (
        <>
          <Redirect to="/app/account" />

          <AlertDialog
            type="error"
            title={t('modals.errors.title_require_reset_password')}
            text={t('modals.errors.text_require_reset_password')}
          />
        </>
      )}
      {requireAcceptTerms && <TermsConditionsDialog />}
    </Container>
  );
};

Container.propTypes = {
  classes: PropTypes.object,
  setMobileNavOpen: PropTypes.func,
  isMobileNavOpen: PropTypes.bool,
  children: PropTypes.array,
};

export default DashboardLayout;
