import { Box, Button, Container, makeStyles, TextField, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Link, Redirect, useHistory } from 'react-router-dom';
import AlertDialog from 'src/components/AlertDialog';
import Page from 'src/components/Page';
import { PASSWORD_REGEX } from 'src/constants/regex';
import { useResetPassword } from 'src/hooks/auth';
import { isAuthenticated as isAuthenticatedSelector } from 'src/store/selectors/auth';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const initialValues = {
  password: '',
  confirmPassword: '',
};

const ResetPasswordView = () => {
  const classes = useStyles();
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const history = useHistory();
  const { token } = useParams();
  const { t } = useTranslation('auth');
  const [apiError, setApiError] = useState(null);
  const [resetPassword, { isLoading, data, isError, error }] = useResetPassword();

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, t('errors.password.min', { min: 8 }))
      .max(12, t('errors.password.max', { max: 12 }))
      .matches(PASSWORD_REGEX, t('errors.password.pattern'))
      .required(t('errors.password.required')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], t('errors.confirmPassword.match'))
      .required(t('errors.confirmPassword.required')),
  });

  const handleSubmit = async (values, formikBag) => {
    const res = await resetPassword({ token, password: values.password });

    if (res) {
      setApiError(null);
      formikBag.resetForm();
    }
  };

  useEffect(() => {
    if (isError) {
      setApiError(error?.message || t('errors.common.reset_password'));
    }
  }, [error?.message, isError, t]);

  if (isAuthenticated) {
    return <Redirect to="/app" />;
  }

  return (
    <Page className={classes.root}>
      <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
        <Container maxWidth="sm">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography color="textPrimary" variant="h2">
                    {t('labels.reset_password_title')}
                  </Typography>
                  <Typography color="textSecondary" gutterBottom variant="body2">
                    {t('labels.reset_password_subtitle')}
                  </Typography>
                </Box>

                {apiError && (
                  <Box mb={3}>
                    <Alert variant="filled" severity="error">
                      {apiError}
                    </Alert>
                  </Box>
                )}

                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label={t('fields.password')}
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                  disabled={isSubmitting}
                />
                <TextField
                  error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                  fullWidth
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  label={t('fields.confirmPassword')}
                  margin="normal"
                  name="confirmPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.confirmPassword}
                  variant="outlined"
                  disabled={isSubmitting}
                />

                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {t('buttons.reset_password')}
                  </Button>
                </Box>

                <Typography color="textSecondary" variant="body1">
                  <Link to="/login" variant="h6">
                    {t('labels.signin_title')}
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>

          {!isLoading && !isError && data && (
            <AlertDialog
              title={t('modals.success.title_reset_password')}
              text={data.data?.msg || t('modals.success.text_reset_password')}
              type="success"
              acceptAction={() => history.push('/login', { replace: true })}
            />
          )}
        </Container>
      </Box>
    </Page>
  );
};

export default ResetPasswordView;
