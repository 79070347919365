import { useMutation, useQueryCache } from 'react-query';
import { createPatient } from 'src/services/api/patients';

function useCreatePatient() {
  const queryCache = useQueryCache();

  return useMutation((data) => createPatient(data), {
    onSuccess: (data) => queryCache.setQueryData(['patient', data.data?.id], data),
  });
}

export default useCreatePatient;
