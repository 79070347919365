import { CircularProgress, FormControlLabel, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Formik, useFormikContext } from 'formik';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CustomSwitch from 'src/components/CustomSwitch';
import FilesField from 'src/components/FilesField';
import { TYPE } from 'src/constants/centers';
import { STATUS } from 'src/constants/demands';
import { useListCenters } from 'src/hooks/centers';
import { useDebouncedCallback } from 'use-debounce/lib';
import * as Yup from 'yup';

const FORM_DEPENDANTS = {
  evolutivo: ['esquemaTratamientoPrevio'],
};
const OPTIONAL_FIELDS = ['observaciones', 'comentariosOficiales'];

const FormContext = ({ isFullfilled, setStep5Demand, setDisableNext }) => {
  const { values, errors, isValid, dirty, submitForm } = useFormikContext();
  const { callback: debounceUpdateDemand } = useDebouncedCallback(() => updateDemand(), 600);
  const { callback: debounceDisableNext } = useDebouncedCallback(() => updateDisableNext(), 600);

  const updateDisableNext = () => {
    setDisableNext(isFullfilled ? !isValid : !(dirty && isValid));
  };

  const updateDemand = () => {
    const errorsArray = Object.getOwnPropertyNames(errors);

    const valuesToPick = [
      'centerRealizatorId',
      'ficherosAdjuntos',
      'observaciones',
      'comentariosOficiales',
    ];

    // Check if any of the location dependants has errors. In that case, omit the location and the dependants
    const dependants = Object.getOwnPropertyNames(FORM_DEPENDANTS);
    dependants.forEach((field) => {
      if (values[field]) {
        let dependantError = false;

        FORM_DEPENDANTS[field].forEach((dependant) => {
          if (errorsArray.includes(dependant)) {
            dependantError = true;
          }
        });

        if (!dependantError) {
          valuesToPick.push(field, ...FORM_DEPENDANTS[field]);
        }
      } else {
        valuesToPick.push(field);
      }
    });

    const data = omit(pick(values, valuesToPick), errorsArray);
    OPTIONAL_FIELDS.forEach((field) => {
      if (data[field] === '') {
        data[field] = null;
      }
    });

    setStep5Demand(data);
  };

  // If user has already fullfilled the form and came back to this step, submit the form to show validation errors
  useEffect(() => {
    if (isFullfilled) {
      submitForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Disable "next" button if form has errors or is not dirty
  useEffect(() => {
    if (isValid) {
      debounceDisableNext();
    } else {
      updateDisableNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceDisableNext, dirty, isFullfilled, isValid]);

  // Update demand on every field change
  useEffect(() => {
    debounceUpdateDemand();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, setStep5Demand, values]);

  return null;
};

const Step5Form = ({
  initialValues,
  setStep5Demand,
  demandExtras,
  setDemandExtras,
  isFullfilled,
  disableForm,
  setDisableNext,
  eliminarFicherosAdjuntos,
  setEliminarFicherosAdjuntos,
  isContinue,
  status
}) => {
  const { t } = useTranslation(['demands', 'centers']);
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, resolvedData: centers, isError } = useListCenters({
    type: TYPE.REALIZADOR,
    all: true,
  });

  const validationSchema = Yup.object().shape({
    evolutivo: Yup.bool().required(),
    esquemaTratamientoPrevio: Yup.string().when('evolutivo', (evolutivo, schema) =>
      evolutivo ? schema.required(t('errors.esquemaTratamientoPrevio.required')) : schema,
    ),
    centerRealizatorId: Yup.number().integer().required(t('errors.centerRealizatorId.required')),
    ficherosAdjuntos: Yup.array().of(
      Yup.object().shape({
        descripcion: Yup.string().required(
          t('errors.ficherosAdjuntos_values.descripcion.required'),
        ),
        archivo: Yup.string().required(t('errors.ficherosAdjuntos_values.archivo.required')),
      }),
    ),
    observaciones: Yup.string(),
    comentariosOficiales: Yup.string(),
  });

  const handleSubmit = (values, formikBag) => {
    formikBag.setSubmitting(false);
  };

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(t('centers:modals.error.title_list_centers'), { variant: 'error' });
    }
  }, [enqueueSnackbar, isError, t]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        setFieldTouched,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <CustomSwitch
                    name="evolutivo"
                    checked={values.evolutivo}
                    onChange={handleChange}
                    disabled={disableForm || isSubmitting || isContinue}
                  />
                }
                label={t('fields.evolutivo')}
                labelPlacement="start"
              />
            </Grid>
            {values.evolutivo && (
              <Grid item xs={12}>
                <TextField
                  error={Boolean(
                    touched.esquemaTratamientoPrevio && errors.esquemaTratamientoPrevio,
                  )}
                  helperText={touched.esquemaTratamientoPrevio && errors.esquemaTratamientoPrevio}
                  fullWidth
                  multiline
                  required
                  label={t('fields.esquemaTratamientoPrevio')}
                  name="esquemaTratamientoPrevio"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={disableForm || isSubmitting || isContinue}
                  value={values.esquemaTratamientoPrevio}
                  variant="outlined"
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Autocomplete
                openOnFocus={true}
                selectOnFocus={false}
                options={centers?.data?.rows || []}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                loading={isLoading}
                name="centerRealizatorId"
                value={
                  centers?.data?.rows.find(({ id }) => values.centerRealizatorId === id) || null
                }
                onChange={(e, value) => setFieldValue('centerRealizatorId', value?.id)}
                onBlur={() => setFieldTouched('centerRealizatorId', true)}
                disabled={disableForm || isSubmitting || isContinue}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('fields.centerRealizatorId')}
                    variant="outlined"
                    required
                    error={Boolean(touched.centerRealizatorId && errors.centerRealizatorId)}
                    helperText={touched.centerRealizatorId && errors.centerRealizatorId}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FilesField
                field="ficherosAdjuntos"
                values={values}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                setFieldTouched={setFieldTouched}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                disabled={disableForm || isSubmitting }
                demandExtras={demandExtras}
                setDemandExtras={setDemandExtras}
                eliminarFicherosAdjuntos={eliminarFicherosAdjuntos}
                setEliminarFicherosAdjuntos={setEliminarFicherosAdjuntos}
                cantDeleteAttachments={status != STATUS.DRAFT}
              />
            </Grid>            
            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.observaciones && errors.observaciones)}
                helperText={touched.observaciones && errors.observaciones}
                fullWidth
                multiline
                label={t('fields.observaciones')}
                name="observaciones"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disableForm || isSubmitting}
                value={values.observaciones}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.comentariosOficiales && errors.comentariosOficiales)}
                helperText={touched.comentariosOficiales && errors.comentariosOficiales}
                fullWidth
                multiline
                label={t('fields.comentariosOficiales')}
                name="comentariosOficiales"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disableForm || isSubmitting}
                value={values.comentariosOficiales}
                variant="outlined"
              />
            </Grid>
          </Grid>

          <FormContext
            isFullfilled={isFullfilled}
            setStep5Demand={setStep5Demand}
            setDisableNext={setDisableNext}
          />
        </form>
      )}
    </Formik>
  );
};

Step5Form.propTypes = {
  initialValues: PropTypes.object,
  isFullfilled: PropTypes.bool,
  setStep5Demand: PropTypes.func,
  demandExtras: PropTypes.object,
  setDemandExtras: PropTypes.func,
  disableForm: PropTypes.bool,
  setDisableNext: PropTypes.func,
  isContinue: PropTypes.bool,
  status: PropTypes.string
};

export default Step5Form;
