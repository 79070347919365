import { Box, Container, LinearProgress, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Page from 'src/components/Page';
import { useListCompanies } from 'src/hooks/company';
import usePagination from 'src/hooks/pagination/usePagination';
import { setFilters } from 'src/store/reducers/lists';

import Results from './components/Results';
import Toolbar from './components/Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const CompaniesListView = () => {
  const classes = useStyles();
  const { t } = useTranslation('companies');
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.lists.companies?.filters);
  const {
    page,
    pageSize,
    setPage,
    setPageSize,
    resetPage,
    fitsInPage,
    navigateToLastPage,
  } = usePagination(filters?.page, filters?.pageSize);
  const [name, setName] = useState(filters?.name || '');
  const [defaultCompany, setDefaultCompany] = useState(filters?.defaultCompany || 'all');
  const [sort, setSort] = useState(filters?.sort || '');

  const query = { page, pageSize, sort, name, default: defaultCompany === 'default' ? true : null };
  const { isFetching, resolvedData: companies, isError } = useListCompanies(query);

  if (companies?.data.count && !fitsInPage(companies.data.count)) {
    navigateToLastPage(companies.data.count);
  }

  useEffect(() => {
    dispatch(
      setFilters({
        list: 'companies',
        filters: { page, pageSize, sort, name, defaultCompany },
      }),
    );
  }, [defaultCompany, dispatch, name, page, pageSize, sort]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(t('modals.error.title_list_companies'), { variant: 'error' });
    }
  }, [enqueueSnackbar, isError, t]);

  return (
    <Page className={classes.root}>
      <Container maxWidth={false}>
        <Toolbar
          resetPage={resetPage}
          name={name}
          setName={setName}
          defaultCompany={defaultCompany}
          setDefaultCompany={setDefaultCompany}
        />
        <Box mt={3}>
          {isFetching && <LinearProgress color="primary" />}
          <Results
            companies={companies?.data}
            page={page}
            setPage={setPage}
            resetPage={resetPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            sort={sort}
            setSort={setSort}
            isLoading={isFetching}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default CompaniesListView;
