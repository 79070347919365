import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  multiline: {
    whiteSpace: 'pre-line',
    wordWrap: 'break-word',
  },
  treatmentTypeGrid: {
    marginBottom: 7,
  },
  treatmentTypeCard: {
    paddingTop: 0,
    paddingBottom: '8px !important',
  },
  treatmentCardContent: {
    padding: '0 !important',
  },
  table: {
    '& th': {
      padding: '4px 5px',
      borderBottom: 'none',
    },
    '& td': {
      padding: '4px 5px',
      borderBottom: 'none',
      color: theme.palette.tertiary.main,
    },
    '& th, & th strong, & td': {
      lineHeight: 1,
    },
  },
  noShadow: {
    boxShadow: 'none',
  },
}));

const DetailsTable = ({ previousTreatments }) => {
  const classes = useStyles();
  const { t } = useTranslation('demands');

  return (
    <Grid>
      {previousTreatments.map((treatment, index) => (
        <Grid item xs={12} className={classes.treatmentTypeGrid} key={index}>
          <Card className={classes.noShadow}>
            <CardHeader title={treatment.type} />
            <CardContent className={classes.treatmentTypeCard}>
              {treatment.treatments.map((row, index) => (
                <Grid container alignItems="center" spacing={3} key={index}>
                  <Grid item xs={12}>
                    <Card className={classes.noShadow}>
                      <CardContent className={classes.treatmentCardContent}>
                        <TableContainer>
                          <Table className={classes.table}>
                            <TableBody>
                              <TableRow>
                                <TableCell component="th" scope="row" width="35%">
                                  <Typography component="strong">
                                    {t('fields.tratamientosPreviosDatos')}
                                  </Typography>
                                </TableCell>
                                <TableCell className={classes.multiline}>
                                  {row.description}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell component="th" scope="row" width="35%">
                                  <Typography component="strong">
                                    {t('fields.tratamientosPreviosFecha')}
                                  </Typography>
                                </TableCell>
                                <TableCell>{format(new Date(row.date), 'dd/MM/yyyy')}</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              ))}
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

DetailsTable.propTypes = {
  previousTreatments: PropTypes.array,
};

export default DetailsTable;
