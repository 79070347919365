import isNull from 'lodash-es/isNull';
import omit from 'lodash-es/omit';
import omitBy from 'lodash-es/omitBy';
import pick from 'lodash-es/pick';

const STEP_1_FIELDS = [
  'doctorId', 
  'centerPrescriptorId', 
  'patientId', 
  'ficherosAdjuntos',
  'eliminarFicherosAdjuntos',
  'status'];
const STEP_2_FIELDS = [
  'valoracionGeriatrica',
  'diagnosticoHistologico',
  'type',
  'mamaTipo',
  'mamaEr',
  'mamaEp',
  'mamaHer2neu',
  'mamaFishHer2neu',
  'mamaKi67',
  'gastricoHerceptest',
  'gastricoFishHer2',
  'colorectalTipo',
  'colorectalKras',
  'colorectalNras',
  'colorectalBraf',
  'colorectalMmr',
  'colorectalMi',
  'pulmonTipo',
  'pulmonEgfr',
  'pulmonAlk',
  'pulmonRosI',
  'pulmonBrafV600E',
  'pulmonNtrk1',
  'pulmonNtrk2',
  'pulmonNtrk3',
  'pulmonMetEx14',
  'pulmonRet',
  'pulmonPdLi',
  'prostataTipo',
  'prostataPsaActual',
  'prostataPsaPrevio',
  'prostataEstratificacionRiesgo',
  'prostataGradoGleason',
  'mieloproliferativosCd20',
  'mieloproliferativosSds',
  'vejigaTipo',
  'vejigaDescripcionHistologica',
  'vejigaSubtipo',
  'otrosDescription',
  't',
  'n',
  'm',
  'estadio',
  'ecog',
];
const STEP_3_FIELDS = [
  // Previous Treatments taken below
];
const STEP_4_FIELDS = [
  'tratamientoQuimioterapico',
  'quimioterapicoIntencion',
  'quimioterapicoTalla',
  'quimioterapicoPeso',
  'quimioterapicoCiclo',
  'quimioterapicoNumeroCiclos',
  'quimioterapicoNumeroSesionesCiclo',
  'quimioterapicoFrecuenciaSesiones',
  'quimioterapicoIntervaloCiclos',
  'quimioterapicoFechaPrimeraSesion',
  'quimioterapicoAntiemeticos',
  'quimioterapicoHeparinizacion',
  'quimioterapicoImplantacionInfusor',
  'quimioterapicoRetiradaInfusor',
  'quimioterapicoAntihistaminicos',
  'quimioterapicoCorticoides',
  'quimioterapicoEritropoyetinas',
  'quimioterapicoOtrosBifosfonatos',
  'quimioterapicoEnsayoClinico',
  'quimioterapicoFechasSesiones',
  'tratamientoRadioterapico',
  // Radiotherapy Treatments taken below
];
const STEP_5_FIELDS = [
  'evolutivo',
  'esquemaTratamientoPrevio',
  'centerRealizatorId',
  'ficherosAdjuntos',
  'observaciones',
  'comentariosOficiales',
  'status'
];

const RELATIONS_FIELDS_TO_OMIT = [
  'id',
  'uuid',
  'demandId',
  'demandUuid',
  'createdAt',
  'updatedAt',
  'deletedAt',
];

const mapDemandToInitialValues = (demand) => {
  const values = omitBy(demand, isNull);

  const initialValues = {
    company: pick(values, ['companyId']),
    step1: pick(values, ...STEP_1_FIELDS),
    step2: pick(values, ...STEP_2_FIELDS),
    step3: pick(values, ...STEP_3_FIELDS),
    step4: pick(values, ...STEP_4_FIELDS),
    step5: pick(values, ...STEP_5_FIELDS),
  };
    // Map relations
  let previousTreatments = values.previousTreatments;
  if (previousTreatments) {
    initialValues.step3.tratamientosPrevios = previousTreatments.map((treatment) =>
      omit(treatment, RELATIONS_FIELDS_TO_OMIT),
    );
  }
  let radiotherapyTreatments = values.radiotherapyTreatments;
  if (radiotherapyTreatments) {
    initialValues.step4.tratamientosRadioterapicos = radiotherapyTreatments.map((treatment) => {
      const { treatmentCodes, ...treat } = omit(treatment, RELATIONS_FIELDS_TO_OMIT);
      return {
        ...treat,
        codigo: treatmentCodes?.codeId
      };
    });
  }
  const attachments = demand.attachments;
  if (attachments?.length) {
    const ficherosAdjuntosDemand = attachments.map((at) => ({
      descripcion: at.description,
      archivo: at.id,
      previous: true,
    }));
    initialValues.step1.ficherosAdjuntos = ficherosAdjuntosDemand;
    initialValues.step5.ficherosAdjuntos = ficherosAdjuntosDemand;
  }
  initialValues.step4.quimioterapicoFechasSesiones = demand.chemotherapySessions?.map(
    ({ date }) => date,
  );
  initialValues.step4.quimioterapicoFarmacos = demand.chemotherapyDrugs?.map((drug) => ({
    ...pick(
      drug,
      'drugId',
      'dosificacion',
      'tipoDosificacion',
      'formaAdministracion',
      'price',
      'amount',
      'presentationId',
    ),
    administrarEnSesiones: drug.sessions.map(
      ({ id }) =>
        demand.chemotherapySessions.indexOf(
          demand.chemotherapySessions.find((session) => session.id === id),
        ) + 1,
    ),
  }));

  return initialValues;
};

export { mapDemandToInitialValues };
