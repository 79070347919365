import {
  Box,
  Card,
  Hidden,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import {
  CheckCircle as CheckCircleIcon,
  Eye as EyeIcon,
  Send as SendIcon,
  XCircle as XCircleIcon,
} from 'react-feather';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink } from 'react-router-dom';
import EmptyList from 'src/components/EmptyList';
import { STATUS } from 'src/constants/invitations';

const useStyles = makeStyles((theme) => ({
  table_list: {
    '& th': {
      padding: '5px',
    },
    '& td': {
      padding: '5px',
      color: theme.palette.tertiary.main,
      '& a,button': {
        padding: '0px 5px',
      },
    },
  },
}));

const Results = ({
  invitations,
  page,
  setPage,
  resetPage,
  pageSize,
  setPageSize,
  sort,
  setSort,
  validateInvitation,
  rejectInvitation,
  isLoading,
}) => {
  const { t: tInvitations } = useTranslation('invitations');
  const { t: tRoles } = useTranslation('rolesNames');
  const classes = useStyles();
  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    // Material UI's TablePagination first page is 0
    setPage(newPage + 1);
  };

  const handleSortChange = (order) => {
    resetPage();

    if (sort === order) {
      setSort(`-${order}`);
    } else {
      setSort(order);
    }
  };

  return (
    <Card>
      <PerfectScrollbar>
        <Box>
          <Table className={classes.table_list}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={sort === 'name' || sort === '-name'}
                    direction={sort === 'name' ? 'asc' : 'desc'}
                    onClick={() => {
                      handleSortChange('name');
                    }}
                  >
                    {tInvitations('fields.name')}
                  </TableSortLabel>
                </TableCell>
                <Hidden smDown>
                  <TableCell>
                    <TableSortLabel
                      active={sort === 'email' || sort === '-email'}
                      direction={sort === 'email' ? 'asc' : 'desc'}
                      onClick={() => {
                        handleSortChange('email');
                      }}
                    >
                      {tInvitations('fields.email')}
                    </TableSortLabel>
                  </TableCell>
                </Hidden>
                <Hidden mdDown>
                  <TableCell>
                    <TableSortLabel
                      active={sort === 'phone' || sort === '-phone'}
                      direction={sort === 'phone' ? 'asc' : 'desc'}
                      onClick={() => {
                        handleSortChange('phone');
                      }}
                    >
                      {tInvitations('fields.phone')}
                    </TableSortLabel>
                  </TableCell>
                </Hidden>
                <TableCell>
                  <TableSortLabel
                    active={sort === 'type' || sort === '-type'}
                    direction={sort === 'type' ? 'asc' : 'desc'}
                    onClick={() => {
                      handleSortChange('type');
                    }}
                  >
                    {tInvitations('fields.type')}
                  </TableSortLabel>
                </TableCell>
                <Hidden xsDown>
                  <TableCell>{tInvitations('fields.company')}</TableCell>
                </Hidden>
                <TableCell>
                  <TableSortLabel
                    active={sort === 'status' || sort === '-status'}
                    direction={sort === 'status' ? 'asc' : 'desc'}
                    onClick={() => {
                      handleSortChange('status');
                    }}
                  >
                    {tInvitations('fields.status.name')}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sort === 'createdAt' || sort === '-createdAt'}
                    direction={sort === 'createdAt' ? 'asc' : 'desc'}
                    onClick={() => {
                      handleSortChange('createdAt');
                    }}
                  >
                    {tInvitations('fields.createdAt')}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">{tInvitations('fields.actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invitations?.rows.map((invitation) => (
                <TableRow hover key={invitation.id}>
                  <TableCell>
                    <Typography color="textPrimary" variant="body1">
                      {invitation.name}
                    </Typography>
                  </TableCell>
                  <Hidden smDown>
                    <TableCell>{invitation.email}</TableCell>
                  </Hidden>
                  <Hidden mdDown>
                    <TableCell>{invitation.phone}</TableCell>
                  </Hidden>
                  <TableCell>{tRoles(invitation.type)}</TableCell>
                  <Hidden xsDown>
                    <TableCell>{invitation.company.name}</TableCell>
                  </Hidden>
                  <TableCell>
                    {tInvitations(`fields.status.${STATUS[invitation.status]}`)}
                  </TableCell>
                  <TableCell>
                    {format(new Date(invitation.createdAt), 'dd/MM/yyyy HH:mm:ss')}
                  </TableCell>
                  <TableCell align="center">
                    <Box display="flex" justifyContent="center">
                      {invitation.status === STATUS.PENDING && (
                        <>
                          <IconButton
                            color="primary"
                            onClick={() => validateInvitation(invitation)}
                            disabled={isLoading}
                          >
                            <CheckCircleIcon />
                          </IconButton>
                          <IconButton
                            color="primary"
                            onClick={() => rejectInvitation(invitation)}
                            disabled={isLoading}
                          >
                            <XCircleIcon />
                          </IconButton>
                        </>
                      )}
                      <IconButton
                        color="primary"
                        component={RouterLink}
                        to={`/app/invitations/${invitation.id}`}
                        disabled={isLoading}
                      >
                        <EyeIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {!isLoading && !invitations?.rows.length && (
            <EmptyList text={tInvitations('labels.empty_list_text')}>
              <SendIcon />
            </EmptyList>
          )}
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={invitations?.count || 0}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePageSizeChange}
        page={invitations?.count ? page - 1 : 0}
        rowsPerPage={pageSize}
        rowsPerPageOptions={[25, 50, 100]}
      />
    </Card>
  );
};

Results.propTypes = {
  invitations: PropTypes.object,
  page: PropTypes.number,
  setPage: PropTypes.func,
  resetPage: PropTypes.func,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
  sort: PropTypes.string,
  setSort: PropTypes.func,
  validateInvitation: PropTypes.func,
  rejectInvitation: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default Results;
