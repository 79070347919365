import { getRequest, postRequest, putRequest } from 'src/utils/http';

import { patientsController, demandsController } from './consts';

export const getPatientById = async (id, query) => getRequest(`${patientsController}/${id}`, query);

export const createPatient = async (body) => postRequest(`${patientsController}`, body);

export const updatePatient = async (id, data) => putRequest(`${patientsController}/${id}`, data);

export const listPatients = async (params) => getRequest(`${patientsController}`, params);

export const getPatientActiveTreatments = async (id, demandId, query) =>
  getRequest(
    `${patientsController}/${id}/active-treatments${demandsController}/${demandId}`,
    query,
  );
