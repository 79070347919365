import { getRequest, putRequest } from 'src/utils/http';

import { companiesController } from './consts';

export const getCompany = async () => getRequest(`${companiesController}/me`);

export const getCompanyById = async (id, params) =>
  getRequest(`${companiesController}/${id}`, params);

export const getCompanyPolicy = async (policy) => getRequest(`${companiesController}/me/${policy}`);

export const updateCompany = async (id, data) => putRequest(`${companiesController}/${id}`, data);

export const listCompanies = async (params) => getRequest(`${companiesController}`, params);
