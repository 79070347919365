const mapCompanyToAppConfig = (company) => {
  const { logo, favicon } = company;

  const config = {
    name: company.name,
    subdomain: company.subdomain,
    slogan: company.slogan,
    primaryColor: company.primaryColor,
    secondaryColor: company.secondaryColor,
    tertiaryColor: company.tertiaryColor,
    default: !!company.default,
    homeCards: company.homeCards,
  };

  if (logo && logo.id) {
    config.logo = { id: logo.id };
  }
  if (favicon && favicon.id) {
    config.favicon = { id: favicon.id };
  }

  return config;
};

export { mapCompanyToAppConfig };
