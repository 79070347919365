import { useQuery } from 'react-query';
import { getInvitation } from 'src/services/api/invitations';

function useGetInvitation(invitation) {
  return useQuery(['invitations', invitation], (key, invitation) =>
    getInvitation(invitation, { include: 'company,attachments' }),
  );
}

export default useGetInvitation;
