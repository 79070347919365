import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  SvgIcon,
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { CreditCard as CreditCardIcon, Search as SearchIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce/lib';

const Container = ({ children }) => {
  return (
    <Box mt={3}>
      <Card>
        <CardContent>{children}</CardContent>
      </Card>
    </Box>
  );
};

const ToolbarFilters = ({
  resetPage,
  registerNumber,
  setRegisterNumber,
  nationalCode,
  setNationalCode,
  presentation,
  setPresentation,
}) => {
  const { t } = useTranslation(['presentations', 'companies']);
  const [registerNumberValue, setRegisterNumberValue] = useState(registerNumber);
  const [registerNumberDebouncedValue] = useDebounce(registerNumberValue, 500);
  const [nationalCodeValue, setNationalCodeValue] = useState(nationalCode);
  const [nationalCodeDebouncedValue] = useDebounce(nationalCodeValue, 500);
  const [presentationValue, setPresentationValue] = useState(presentation);
  const [presentationDebouncedValue] = useDebounce(presentationValue, 500);

  const handleReset = () => {
    resetPage();
    setRegisterNumber('');
    setRegisterNumberValue('');
    setNationalCode('');
    setNationalCodeValue('');
    setPresentation('');
    setPresentationValue('');
  };

  useEffect(() => {
    setNationalCode(nationalCodeDebouncedValue);
    setRegisterNumber(registerNumberDebouncedValue);
    setPresentation(presentationDebouncedValue);
    resetPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    registerNumberDebouncedValue,
    nationalCodeDebouncedValue,
    presentationDebouncedValue,
    setNationalCode,
    setRegisterNumber,
    setPresentation,
  ]);

  return (
    <Container>
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs={12} sm={5} md={3}>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="primary">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            name="registerNumber"
            placeholder={t('labels.search_presentations')}
            value={registerNumberValue}
            onBlur={(e) => setRegisterNumber(e.target.value)}
            onChange={(e) => {
              setRegisterNumberValue(e.target.value);
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="primary">
                    <CreditCardIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            name="nationalCode"
            placeholder={t('labels.search_presentations_by_national_code')}
            value={nationalCodeValue}
            onBlur={(e) => setNationalCode(e.target.value)}
            onChange={(e) => {
              setNationalCodeValue(e.target.value);
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="primary">
                    <CreditCardIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            name="presentation"
            placeholder={t('labels.search_presentations_by_presentation')}
            value={presentationValue}
            onBlur={(e) => setPresentation(e.target.value)}
            onChange={(e) => {
              setPresentationValue(e.target.value);
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Button fullWidth color="primary" variant="contained" onClick={handleReset}>
            {t('buttons.presentations_list_clear_filters')}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

ToolbarFilters.propTypes = {
  resetPage: PropTypes.func,
  registerNumber: PropTypes.string,
  setRegisterNumber: PropTypes.func,
  nationalCode: PropTypes.string,
  setNationalCode: PropTypes.func,
  presentation: PropTypes.string,
  setPresentation: PropTypes.func,
};

export default ToolbarFilters;
