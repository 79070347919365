import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  InputAdornment,
  SvgIcon,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { CreditCard as CreditCardIcon, Search as SearchIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useListCompanies } from 'src/hooks/company';
import { useDebounce } from 'use-debounce/lib';

const Container = ({ children }) => {
  return (
    <Box mt={3}>
      <Card>
        <CardContent>{children}</CardContent>
      </Card>
    </Box>
  );
};

const ToolbarFilters = ({
  resetPage,
  name,
  setName,
  company,
  setCompany,
  cardNumber,
  setCardNumber,
}) => {
  const { t } = useTranslation(['patients', 'companies']);
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, data: companies, isError } = useListCompanies({ all: true, default: false });
  const [nameValue, setNameValue] = useState(name);
  const [nameDebouncedValue] = useDebounce(nameValue, 500);
  const [cardNumberValue, setCardNumberValue] = useState(cardNumber);
  const [cardNumberDebouncedValue] = useDebounce(cardNumberValue, 500);

  const handleReset = () => {
    resetPage();
    setName('');
    setNameValue('');
    setCardNumber('');
    setCardNumberValue('');
    setCompany(null);
  };

  const handleCompanyChange = (e, value) => {
    setCompany(value ? { id: value.id, name: value.name } : null);
    resetPage();
  };

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(t('companies:modals.error.title_list_companies'), { variant: 'error' });
    }
  }, [enqueueSnackbar, isError, t]);

  useEffect(() => {
    setName(nameDebouncedValue);
    setCardNumber(cardNumberDebouncedValue);
    resetPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardNumberDebouncedValue, nameDebouncedValue, setCardNumber, setName]);

  return (
    <Container>
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs={12} sm={5} md={4}>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="primary">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            name="name"
            placeholder={t('labels.search_patients')}
            value={nameValue}
            onBlur={(e) => setName(e.target.value)}
            onChange={(e) => {
              setNameValue(e.target.value);
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <Autocomplete
            openOnFocus={true}
            selectOnFocus={false}
            options={companies?.data.rows || []}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            value={company}
            loading={isLoading}
            disableClearable={isLoading}
            onChange={handleCompanyChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('fields.company')}
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  style: { cursor: 'pointer' },
                }}
                InputProps={{
                  ...params.InputProps,
                  readOnly: true,
                  endAdornment: (
                    <>
                      {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="primary">
                    <CreditCardIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            name="cardNumber"
            placeholder={t('labels.search_patients_by_cardNumber')}
            value={cardNumberValue}
            onBlur={(e) => setCardNumber(e.target.value)}
            onChange={(e) => {
              setCardNumberValue(e.target.value);
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Button fullWidth color="primary" variant="contained" onClick={handleReset}>
            {t('buttons.patients_list_clear_filters')}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

ToolbarFilters.propTypes = {
  resetPage: PropTypes.func,
  name: PropTypes.string,
  setName: PropTypes.func,
  company: PropTypes.object,
  setCompany: PropTypes.func,
  cardNumber: PropTypes.string,
  setCardNumber: PropTypes.func,
};

export default ToolbarFilters;
