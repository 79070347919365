import { Box, TableCell, TableRow } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import get from 'lodash/get';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import DrugLetter from 'src/components/Demands/DrugLetter';
import { generateLetterFromIndex } from 'src/utils/demands';

const SessionRow = ({
  edit,
  values,
  errors,
  touched,
  session,
  setFieldTouched,
  setFieldValue,
  disabled,
  drugs
}) => {
  const { t } = useTranslation('demands');

  const getDateForSession = () => {
    const sessionDate = values.quimioterapicoFechasSesiones[session - 1];

    return sessionDate
      ? format(new Date(values.quimioterapicoFechasSesiones[session - 1]), 'dd/MM/yyyy')
      : '';
  };

  const filterSessionDrugs = () => {
    return values.quimioterapicoFarmacos.filter(({ administrarEnSesiones }) =>
      administrarEnSesiones.includes(session),
    );
  };
  const getDrugsFromSession = () => {
    return values.chemotherapySessions[session - 1].drugs;
  };

  const mapSessionDrugs = () => {
    return filterSessionDrugs()
      .filter(({ drugId }) => !!drugId)
      .map(({ drugId }) => drugs?.rows.find(({ id }) => drugId === id));
  };

  const getLetterForDrug = (drug, isForm = false) => {
    let letterIndex;

    if (isForm) {
      letterIndex = values.quimioterapicoFarmacos.indexOf(drug);
    } else {
      letterIndex = values.chemotherapyDrugs.indexOf(
        values.chemotherapyDrugs.find(({ id }) => drug.id === id),
      );
    }
    
    return generateLetterFromIndex(letterIndex);
  };

  return (
    <TableRow key={session}>
      <TableCell>{session}</TableCell>
      <TableCell>
        {/* When is in the Create demand wizard */}
        {drugs?.rows?.length &&
          !!values.quimioterapicoFarmacos?.length &&
          mapSessionDrugs()
            .filter(e => e!=null) 
            .map(({ name }) => name)
            .join(', ')}

        {/* When is in the demand details */}
        {!drugs &&
          !!values.chemotherapySessions?.length &&
          getDrugsFromSession()
            .map(({ nombre }) => nombre)
            .join(', ')}
      </TableCell>
      <TableCell>
        {edit && session !== 1 && values.quimioterapicoFechaPrimeraSesion ? (
          <KeyboardDatePicker
            disableToolbar
            required
            format="dd/MM/yyyy"
            minDate={
              (values.quimioterapicoFechasSesiones[session - 2] &&
                addDays(new Date(values.quimioterapicoFechasSesiones[session - 2]), 1)) ||
              values.quimioterapicoFechaPrimeraSesion ||
              undefined
            }
            maxDate={
              (values.quimioterapicoFechasSesiones[session] &&
                addDays(new Date(values.quimioterapicoFechasSesiones[session]), -1)) ||
              undefined
            }
            label={t('fields.sessions_list_date')}
            error={Boolean(
              get(touched, `quimioterapicoFechasSesiones[${session - 1}]`) &&
                get(errors, `quimioterapicoFechasSesiones[${session - 1}]`),
            )}
            helperText={
              get(touched, `quimioterapicoFechasSesiones[${session - 1}]`) &&
              get(errors, `quimioterapicoFechasSesiones[${session - 1}]`)
            }
            name={`quimioterapicoFechasSesiones[${session - 1}]`}
            value={values.quimioterapicoFechasSesiones[session - 1] || null}
            onChange={(date) => setFieldValue(`quimioterapicoFechasSesiones[${session - 1}]`, date)}
            onBlur={() => setFieldTouched(`quimioterapicoFechasSesiones[${session - 1}]`, true)}
            disabled={disabled}
            variant="inline"
          />
        ) : (
          getDateForSession()
        )}
      </TableCell>
      <TableCell>
        <Box display="flex">
          {/* When is in the Create demand wizard */}
          {drugs?.rows?.length &&
            !!values.quimioterapicoFarmacos?.length &&
            filterSessionDrugs()
              .map((drug) => getLetterForDrug(drug, true))
              .map((letter) => (
                <Box key={letter} pr={1}>
                  <DrugLetter letter={letter} />
                </Box>
              ))}

          {/* When is in the demand details */}
          {!drugs &&
            !!values.chemotherapyDrugs?.length &&
            getDrugsFromSession()
              .map((drug) => getLetterForDrug(drug))
              .map((letter) => (
                <Box key={letter} pr={1}>
                  <DrugLetter letter={letter} />
                </Box>
              ))}
        </Box>
      </TableCell>
    </TableRow>
  );
};

SessionRow.propTypes = {
  edit: PropTypes.bool,
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  session: PropTypes.number,
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  disabled: PropTypes.bool,
  drugs: PropTypes.object
};

export default SessionRow;
