import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DetailsTratamientoQuimio from './components/DetailsTratamientoQuimio';
import DetailsTratamientoRadio from './components/DetailsTratamientoRadio';

const useStyles = makeStyles({
  empty: {
    marginTop: 30,
    marginBottom: 10,
  },
});

const PreviousTreatments = ({ demand }) => {
  const classes = useStyles();
  const { t } = useTranslation(['demands', 'common']);

  return (
    <Card>
      <CardHeader
        title={t('demands:labels.viewDemand_proposedTreatments_title')}
        subheader={t('demands:labels.viewDemand_proposedTreatments_subtitle')}
      />
      <Divider />
      <CardContent>
        <Grid container alignItems="center" spacing={3}>
          <>
            {demand.tratamientoQuimioterapico && (
              <Grid item sm={12}>
                <DetailsTratamientoQuimio demand={demand} />
              </Grid>
            )}
            {demand.tratamientoRadioterapico && (
              <Grid item sm={12}>
                <DetailsTratamientoRadio demand={demand} />
              </Grid>
            )}
          </>
        </Grid>
        {!demand.tratamientoQuimioterapico && !demand.tratamientoRadioterapico && (
          <Box className={classes.empty} display="flex" flexDirection="column" alignItems="center">
            <Typography color="secondary" variant="h5">
              {t('labels.viewDemand_empty_text')}
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

PreviousTreatments.propTypes = {
  demand: PropTypes.object,
};

export default PreviousTreatments;
