import { Box, Button, CircularProgress, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useListCompanies } from 'src/hooks/company';
import { ROLES } from 'src/constants/roles';
import { useGetRoles } from 'src/hooks/roles';
import * as Yup from 'yup';

const initialValues = {
  email: '',
  name: '',
  type: null,
  companyId: null,
};

const CreateInvitationForm = ({ handleFormSubmit }) => {
  const { t } = useTranslation(['invitations', 'rolesNames', 'companies', 'roles']);
  const { enqueueSnackbar } = useSnackbar();
  const {
    isLoading: isLoadingCompanies,
    data: companies,
    isError: isErrorCompanies,
  } = useListCompanies({
    default: false,
    all: true,
  });
  const { isLoading: isLoadingRoles, data: roles, isError: isErrorRoles } = useGetRoles({
    all: true,
  });

  const validationSchema = Yup.object().shape({
    companyId: Yup.object().nullable().required(t('errors.company.required')),
    type: Yup.object().nullable().required(t('errors.role.required')),
    email: Yup.string()
      .email(t('errors.email.valid'))
      .max(255, t('errors.email.max'))
      .required(t('errors.email.required')),
    name: Yup.string()
      .min(3, t('errors.name.min'))
      .max(200, t('errors.name.max', { max: 200 }))
      .required(t('errors.name.required')),
  });

  const getRolesOptions = () => {
    if (!isLoadingRoles && !isErrorRoles && roles.data.rows.length) {
      const availableRoles = roles.data.rows.filter(
        (role) => role.permissions === ROLES.ASSISTANT || role.permissions === ROLES.DOCTOR,
      );

      const translatedAvailableRoles = availableRoles.map((role) => {
        const name = t(`rolesNames:${role.permissions}`);
        return { ...role, name };
      });

      return translatedAvailableRoles;
    }

    return [];
  };

  const handleSubmit = (values, formikBag) => {
    const data = { ...values, type: values.type.permissions, companyId: values.companyId.id };

    return handleFormSubmit(data, formikBag);
  };

  useEffect(() => {
    if (isErrorCompanies) {
      enqueueSnackbar(t('companies:modals.error.title_get_companies'), { variant: 'error' });
    }
  }, [enqueueSnackbar, isErrorCompanies, t]);

  useEffect(() => {
    if (isErrorRoles) {
      enqueueSnackbar(t('roles:modals.error.title_get_roles'), { variant: 'error' });
    }
  }, [enqueueSnackbar, isErrorRoles, t]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        setFieldTouched,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Autocomplete
                openOnFocus={true}
                selectOnFocus={false}
                options={companies?.data?.rows || []}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                loading={isLoadingCompanies}
                name="companyId"
                value={values.companyId}
                onChange={(e, value) => setFieldValue('companyId', value)}
                onBlur={() => setFieldTouched('companyId', true)}
                disabled={isSubmitting}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('fields.select_company')}
                    variant="outlined"
                    error={Boolean(touched.companyId && errors.companyId)}
                    helperText={touched.companyId && errors.companyId}
                    inputProps={{
                      ...params.inputProps,
                      style: { cursor: 'pointer' },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      readOnly: true,
                      endAdornment: (
                        <>
                          {isLoadingCompanies ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Autocomplete
                openOnFocus={true}
                selectOnFocus={false}
                options={getRolesOptions()}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                loading={isLoadingRoles}
                name="type"
                value={values.type}
                onChange={(e, value) => setFieldValue('type', value)}
                onBlur={() => setFieldTouched('type', true)}
                disabled={isSubmitting}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('fields.role')}
                    variant="outlined"
                    error={Boolean(touched.type && errors.type)}
                    helperText={touched.type && errors.type}
                    inputProps={{
                      ...params.inputProps,
                      style: { cursor: 'pointer' },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      readOnly: true,
                      endAdornment: (
                        <>
                          {isLoadingRoles ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                label={t('fields.email')}
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting}
                type="email"
                value={values.email}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.name && errors.name)}
                fullWidth
                helperText={touched.name && errors.name}
                label={t('fields.name')}
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting}
                value={values.name}
                variant="outlined"
              />
            </Grid>

            <Box p={2}>
              <Button
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                {t('buttons.create')}
              </Button>
            </Box>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

CreateInvitationForm.propTypes = {
  handleFormSubmit: PropTypes.func,
};

export default CreateInvitationForm;
