import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ROLES } from 'src/constants/roles';

const DetailsTable = ({ user }) => {
  const { t } = useTranslation(['account', 'users', 'rolesNames']);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.name')}</Typography>
            </TableCell>
            <TableCell>{user.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.id_card')}</Typography>
            </TableCell>
            <TableCell>{user.dni}</TableCell>
          </TableRow>
          {Boolean(user.role.permissions === ROLES.DOCTOR) && (
            <TableRow>
              <TableCell component="th" scope="row">
                <Typography component="strong">{t('users:fields.membership_number')}</Typography>
              </TableCell>
              <TableCell>{user.membershipNumber}</TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.email')}</Typography>
            </TableCell>
            <TableCell>{user.email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.role')}</Typography>
            </TableCell>
            <TableCell>{t(`rolesNames:${user.role.permissions}`)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.company')}</Typography>
            </TableCell>
            <TableCell>{user.company.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.active.label')}</Typography>
            </TableCell>
            <TableCell>
              {user.active ? t('fields.active.isActive') : t('fields.active.isInactive')}
            </TableCell>
          </TableRow>
          {Boolean(user.role.permissions === ROLES.MANAGER) && (
            <TableRow>
              <TableCell component="th" scope="row">
                <Typography component="strong">{t('fields.permissions')}</Typography>
              </TableCell>
              <TableCell>{user.permissions.map(({ name }) => name).join(', ')}</TableCell>
            </TableRow>
          )}
          {Boolean(user.role.permissions === ROLES.DOCTOR) && (
            <>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Typography component="strong">{t('users:fields.centers')}</Typography>
                </TableCell>
                <TableCell>{user.centers.map(({ name }) => name).join(', ')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Typography component="strong">{t('fields.assistants')}</Typography>
                </TableCell>
                <TableCell>{user.assistants.map(({ name }) => name).join(', ')}</TableCell>
              </TableRow>
            </>
          )}
          {Boolean(user.role.permissions === ROLES.ASSISTANT) && (
            <TableRow>
              <TableCell component="th" scope="row">
                <Typography component="strong">{t('users:fields.doctors')}</Typography>
              </TableCell>
              <TableCell>{user.doctors.map(({ name }) => name).join(', ')}</TableCell>
            </TableRow>
          )}
          {Boolean(user.role.permissions === ROLES.REPRESENTATIVE) && user.company?.tramitByZones && (
            <TableRow>
              <TableCell component="th" scope="row">
                <Typography component="strong">{t('fields.zones')}</Typography>
              </TableCell>
              <TableCell>{user.provinces.map(({ name }) => name).join(', ')}</TableCell>
            </TableRow>
          )}
          {Boolean(user.role.permissions === ROLES.SUPERVISOR) && (
            <TableRow>
              <TableCell component="th" scope="row">
                <Typography component="strong">{t('users:fields.doctors')}</Typography>
              </TableCell>
              <TableCell>{user.supervisorDoctors.map(({ name }) => name).join(', ')}</TableCell>
            </TableRow>
          )}
          {Boolean(user.role.permissions === ROLES.SUPERVISOR) && (
            <TableRow>
              <TableCell component="th" scope="row">
                <Typography component="strong">{t('users:fields.centers')}</Typography>
              </TableCell>
              <TableCell>{user.supervisorCenters.map(({ name }) => name).join(', ')}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

DetailsTable.propTypes = {
  user: PropTypes.object,
};

export default DetailsTable;
