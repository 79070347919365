const downloadFile = (file, type, filename) => {
  const media = new Blob([file], { type });

  let link = document.createElement('a');
  link.setAttribute('target', '_blank');
  link.style.display = 'none';

  document.body.appendChild(link);

  link.setAttribute('href', window.URL.createObjectURL(media));
  link.setAttribute('download', filename);
  link.click();

  document.body.removeChild(link);
};

export { downloadFile };
