import { useHistory } from 'react-router-dom';

export default function useGoBack(defaultUrl = '/app') {
  const history = useHistory();

  const goBack = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push(defaultUrl);
    }
  };

  return goBack;
}
