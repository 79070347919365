import PropTypes from 'prop-types';
import ToolbarFilters from './components/ToolbarFilters';
import ToolbarActions from './components/ToolbarActions';

const Toolbar = ({ resetPage, name, setName, activePrinciple, setActivePrinciple }) => {
  return (
    <div>
      <ToolbarActions />

      <ToolbarFilters resetPage={resetPage} name={name} setName={setName} activePrinciple={activePrinciple} setActivePrinciple={setActivePrinciple}  />
    </div>
  );
};

Toolbar.propTypes = {
  resetPage: PropTypes.func,
  name: PropTypes.string,
  setName: PropTypes.func,
  activePrinciple: PropTypes.string,
  setActivePrinciple: PropTypes.func,
};

export default Toolbar;
