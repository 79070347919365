import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { getMediaUrl } from 'src/utils/getMediaUrl';
import { useTranslation } from 'react-i18next';
import useUpdateUserMe from 'src/hooks/users/useUpdateUserMe';
import { useDispatch } from 'react-redux';
import { updateUser } from 'src/store/reducers/user';
import AvatarUpload from './components';
import { useSnackbar } from 'notistack';
import AlertDialog from 'src/components/AlertDialog';

const useStyles = makeStyles(() => ({
  root: {},
  userDetails: {
    wordBreak: 'break-all',
  },
  avatar: {
    height: 100,
    width: 100,
  },
}));

const Profile = ({ className, user, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t: tAccount } = useTranslation('account');
  const { t: tRoles } = useTranslation('rolesNames');
  const [updateUserMe, { isLoading, data, isError }] = useUpdateUserMe();
  const [isUploading, setUploading] = useState(false);
  const avatarSrc = user?.avatar ? getMediaUrl(user.avatar.id) : null;

  const handleAvatarUpload = async (avatar) => {
    setUploading(true);
    const res = await updateUserMe({ avatarImage: avatar });
    if (res) {
      dispatch(updateUser({ avatar: res.data.avatar }));
    }
    setUploading(false);
  };

  useEffect(() => {
    if (!isLoading && !isError && data) {
      enqueueSnackbar(tAccount('modals.avatar.success.text'), { variant: 'success' });
    }
  }, [data, enqueueSnackbar, isError, isLoading, tAccount]);

  return (
    user && (
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardContent>
          <Box alignItems="center" display="flex" flexDirection="column">
            <Avatar className={classes.avatar} src={avatarSrc} />
            <Typography color="textPrimary" gutterBottom variant="h3" align="center">
              {user.name}
            </Typography>
            <Typography
              className={clsx(classes.userDetails, className)}
              color="textSecondary"
              variant="body1"
              align="center"
            >
              {user.email}
            </Typography>
            <Typography
              className={classes.dateText}
              color="textSecondary"
              variant="body1"
              align="center"
            >
              {tAccount('labels.roleCompany', {
                role: tRoles(user.role.permissions),
                company: user.company.name,
              })}
            </Typography>
          </Box>
        </CardContent>

        <Divider />

        <CardActions>
          <AvatarUpload handleAvatarUpload={handleAvatarUpload} isUploading={isUploading} />

          {isError && (
            <AlertDialog
              title={tAccount('modals.avatar.error.title')}
              text={tAccount('modals.avatar.error.text')}
              type="error"
            />
          )}
        </CardActions>
      </Card>
    )
  );
};

Profile.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
};

export default Profile;
