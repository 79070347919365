import { Step, StepButton, Stepper } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const DemandsStepper = ({
  step,
  navigateToStep,
  canNavigateNext,
  completedSteps,
  setCompletedSteps,
}) => {
  const { t } = useTranslation('demands');

  const isStepCompleted = (step) => {
    for (let i = 0; i < step; i++) {
      if (!completedSteps[i]) {
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    setCompletedSteps((draft) => {
      draft[step - 1] = canNavigateNext;
    });
  }, [canNavigateNext, setCompletedSteps, step]);

  const steps = [];
  for (let index = 1; index <= 5; index++) {
    steps.push(
      <Step key={index}>
        <StepButton
          onClick={() => navigateToStep(index)}
          completed={isStepCompleted(index)}
          disabled={!isStepCompleted(index - 1)}
        >
          {t(`labels.step${index}_title`)}
        </StepButton>
      </Step>,
    );
  }

  return (
    <Stepper nonLinear activeStep={step - 1}>
      {steps}
    </Stepper>
  );
};

DemandsStepper.propTypes = {
  step: PropTypes.number,
  navigateToStep: PropTypes.func,
  canNavigateNext: PropTypes.bool,
  completedSteps: PropTypes.array,
  setCompletedSteps: PropTypes.func,
};

export default DemandsStepper;
