import { useState } from 'react';

const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 25;

function usePagination(initialPage = DEFAULT_PAGE, initialPageSize = DEFAULT_PAGE_SIZE) {
  const [page, setPage] = useState(initialPage);
  const [pageSize, setPageSize] = useState(initialPageSize);

  const resetPage = () => {
    setPage(initialPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    resetPage();
    setPageSize(newPageSize);
  };

  const fitsInPage = (count) => {
    return count > (page - 1) * pageSize;
  };

  const navigateToLastPage = (count) => {
    setPage(Math.ceil(count / pageSize));
  };

  return {
    page,
    pageSize,
    setPage,
    setPageSize: handlePageSizeChange,
    resetPage,
    fitsInPage,
    navigateToLastPage,
  };
}

export default usePagination;
