import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Paper,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const DetailsTable = ({ presentation }) => {
  const { t } = useTranslation('presentations');

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.registerNumber')}</Typography>
            </TableCell>
            <TableCell>{presentation.registerNumber}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.nationalCode')}</Typography>
            </TableCell>
            <TableCell>{presentation.nationalCode}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.presentation')}</Typography>
            </TableCell>
            <TableCell>{presentation.presentation}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.drug')}</Typography>
            </TableCell>
            <TableCell>{presentation.drug.name}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

DetailsTable.propTypes = {
  presentation: PropTypes.object,
};

export default DetailsTable;
