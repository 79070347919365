import { useState } from 'react';
import { Box, Container, LinearProgress, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './components/Results';
import Toolbar from './components/Toolbar';
import { usePagination } from 'src/hooks/pagination';
import { useDeleteDrug, useListDrugs } from 'src/hooks/drugs';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { setFilters } from 'src/store/reducers/lists';
import AlertDialog from 'src/components/AlertDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const ListDrugsView = () => {
  const classes = useStyles();
  const { t } = useTranslation('drugs');
  const { enqueueSnackbar } = useSnackbar();
  const [deleteModal, setDeleteModal] = useState({ show: false, drugId: null });
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.lists.drugs?.filters);
  const {
    page,
    pageSize,
    setPage,
    setPageSize,
    resetPage,
    fitsInPage,
    navigateToLastPage,
  } = usePagination(filters?.page, filters?.pageSize);
  const [name, setName] = useState(filters?.name || '');
  const [activePrinciple, setActivePrinciple] = useState(filters?.activePrinciple || '');
  const [sort, setSort] = useState(filters?.sort || 'name');
  const [
    deleteDrug,
    { isLoading: isLoadingDelete, data: dataDelete, isError: isErrorDelete, error: errorDelete },
  ] = useDeleteDrug();
  const { isFetching, resolvedData: drugs, refetch, isError: isErrorList } = useListDrugs({
    page,
    pageSize,
    sort,
    name,
    activePrinciple
  });

  if (drugs?.data.count && !fitsInPage(drugs.data.count)) {
    navigateToLastPage(drugs.data.count);
  }

  const handleDeleteDrug = () => {
    setDeleteModal({ show: false, drugId: null });
    deleteDrug(deleteModal.drugId);
  };

  useEffect(() => {
    dispatch(
      setFilters({
        list: 'drugs',
        filters: { page, pageSize, sort, name, activePrinciple},
      }),
    );
  }, [dispatch, name, activePrinciple, page, pageSize, sort]);

  useEffect(() => {
    if (isErrorList) {
      enqueueSnackbar(t('modals.list_drugs.error.title'), { variant: 'error' });
    }
  }, [enqueueSnackbar, isErrorList, t]);

  useEffect(() => {
    if (!isLoadingDelete && dataDelete && !isErrorDelete) {
      enqueueSnackbar(t('modals.delete_drug.success.text'), { variant: 'success' });

      refetch();
    }
  }, [dataDelete, enqueueSnackbar, isErrorDelete, isLoadingDelete, refetch, t]);

  return (
    <Page className={classes.root}>
      <Container maxWidth={false}>
        <Toolbar resetPage={resetPage} name={name} setName={setName} activePrinciple={activePrinciple} setActivePrinciple={setActivePrinciple} />

        <Box mt={3}>
          {isFetching && <LinearProgress color="primary" />}
          <Results
            drugs={drugs?.data}
            deleteDrug={(drugId) => setDeleteModal({ show: true, drugId })}
            page={page}
            setPage={setPage}
            resetPage={resetPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            sort={sort}
            setSort={setSort}
            isLoading={isFetching || isLoadingDelete}
          />

          {deleteModal.show && (
            <AlertDialog
              title={t('modals.delete_drug.confirmation.title')}
              text={t('modals.delete_drug.confirmation.text')}
              type="warning"
              showAcceptButton={false}
              showDeleteButton={true}
              deleteAction={handleDeleteDrug}
              showCancelButton={true}
              cancelAction={() => setDeleteModal({ show: false, drugId: null })}
            />
          )}
          {isErrorDelete && (
            <AlertDialog
              title={t('modals.delete_drug.error.title')}
              text={errorDelete?.message || t('modals.delete_drug.error.text')}
              type="error"
            />
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default ListDrugsView;
