import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { useState } from 'react';
import { File as FileIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';
import AlertDialog from 'src/components/AlertDialog';
import { removeBase64Data } from 'src/utils/removeBase64Data';
import { FORBIDDEN_FILES_TYPES } from './consts';

const useStyles = makeStyles((theme) => ({
  filenameMargin: {
    marginLeft: theme.spacing(3),
  },
  filenameTextMargin: {
    marginLeft: theme.spacing(1),
  },
}));

const FileUploader = ({ fileIndex, filesnames, setFilesnames, handleAddFile }) => {
  const classes = useStyles();
  const { t } = useTranslation('demands');
  const filename = filesnames[fileIndex];
  const [forbiddenModalOpen, setForbiddenModalOpen] = useState(false);

  const handleFileUpload = (e) => {
    const reader = new FileReader();
    const input = e.target;
    const file = input.files[0];

    if (!file) {
      return;
    }

    if (FORBIDDEN_FILES_TYPES.includes(file.type)) {
      setForbiddenModalOpen(true);
      input.value = null;
      return;
    }

    // Callback for load event
    reader.onloadend = () => {
      const fileBase64 = removeBase64Data(reader.result);
      saveFilename(fileBase64, file.name);
      handleAddFile(fileIndex, `${file.name}#${fileBase64}`);
      input.value = null;
    };

    // Read image
    reader.readAsDataURL(file);
  };

  const saveFilename = (file, name) => {
    setFilesnames((draft) => {
      draft[fileIndex] = name;
    });
  };

  return (
    <Box display="flex" alignItems="center">
      <Button variant="contained" component="label">
        {t('buttons.upload_file')}
        <input type="file" onChange={handleFileUpload} hidden />
      </Button>

      {filename && (
        <Box className={classes.filenameMargin} display="flex" alignItems="center">
          <FileIcon size={18} />
          <Typography className={classes.filenameTextMargin}>{filename}</Typography>
        </Box>
      )}

      {forbiddenModalOpen && (
        <AlertDialog
          title={t('modals.error.title_forbidden_file')}
          text={t('modals.error.text_forbidden_file')}
          type="error"
          acceptAction={() => setForbiddenModalOpen(false)}
        />
      )}
    </Box>
  );
};

FileUploader.propTypes = {
  fileIndex: PropTypes.number,
  filesnames: PropTypes.array,
  setFilesnames: PropTypes.func,
  handleAddFile: PropTypes.func,
};

export default FileUploader;
