import { Box, Container, LinearProgress, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Page from 'src/components/Page';
import { useListCenters } from 'src/hooks/centers';
import { usePagination } from 'src/hooks/pagination';
import { setFilters } from 'src/store/reducers/lists';
import Results from './components/Results';
import Toolbar from './components/Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const CentersListView = () => {
  const classes = useStyles();
  const { t } = useTranslation('centers');
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.lists.centers?.filters);
  const {
    page,
    pageSize,
    setPage,
    setPageSize,
    resetPage,
    fitsInPage,
    navigateToLastPage,
  } = usePagination(filters?.page, filters?.pageSize);
  const [name, setName] = useState(filters?.name || '');
  const [type, setType] = useState(filters?.type || '');
  const [sort, setSort] = useState(filters?.sort || '');
  const query = { page, pageSize, sort, name, type, include: 'province' };
  const { isFetching, resolvedData: centers, isError } = useListCenters(query);

  if (centers?.data.count && !fitsInPage(centers.data.count)) {
    navigateToLastPage(centers.data.count);
  }

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(t('modals.error.title_list_centers'), { variant: 'error' });
    }
  }, [enqueueSnackbar, isError, t]);

  useEffect(() => {
    dispatch(
      setFilters({
        list: 'centers',
        filters: { page, pageSize, sort, name, type },
      }),
    );
  }, [dispatch, name, page, pageSize, sort, type]);

  return (
    <Page className={classes.root}>
      <Container maxWidth={false}>
        <Toolbar
          name={name}
          setName={setName}
          setType={setType}
          type={type}
          resetPage={resetPage}
        />
        <Box mt={3}>
          {isFetching && <LinearProgress color="primary" />}
          <Results
            centers={centers?.data}
            page={page}
            setPage={setPage}
            resetPage={resetPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            sort={sort}
            setSort={setSort}
            isLoading={isFetching}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default CentersListView;
