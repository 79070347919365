import PropTypes from 'prop-types';

import ToolbarActions from './components/ToolbarActions';
import ToolbarFilters from './components/ToolbarFilters';

const Toolbar = ({ setPage, name, setName, setType, type, resetPage }) => {
  return (
    <div>
      <ToolbarActions />

      <ToolbarFilters
        name={name}
        setName={setName}
        setType={setType}
        type={type}
        resetPage={resetPage}
      />
    </div>
  );
};

Toolbar.propTypes = {
  setPage: PropTypes.func,
  name: PropTypes.string,
  setName: PropTypes.func,
  setType: PropTypes.func,
  setCardNumber: PropTypes.func,
  type: PropTypes.string,
};

export default Toolbar;
