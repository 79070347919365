import { getRequest, postRequest, putRequest, deleteRequest } from 'src/utils/http';
import { usersController } from './consts';

export const listUsers = async (params) => getRequest(`${usersController}`, params);
export const getUser = async (user, params) => getRequest(`${usersController}/${user}`, params);
export const getUserMe = async (params) => getRequest(`${usersController}/me`, params);
export const createUser = async (user) => postRequest(`${usersController}`, user);
export const updateUser = async (user, data) => putRequest(`${usersController}/${user}`, data);
export const updateUserMe = async (data) => putRequest(`${usersController}/me`, data);
export const acceptTermsConditions = async () => postRequest(`${usersController}/conditions`, {});
export const addAssistants = async (user, data) =>
  postRequest(`${usersController}/${user}/assistants`, data);
export const removeAssistants = async (user, data) =>
  deleteRequest(`${usersController}/${user}/assistants`, data);
export const addCenters = async (user, data) =>
  postRequest(`${usersController}/${user}/centers`, data);
export const removeCenters = async (user, data) =>
  deleteRequest(`${usersController}/${user}/centers`, data);
export const addDoctors = async (user, data) =>
  postRequest(`${usersController}/${user}/doctors`, data);
export const removeDoctors = async (user, data) =>
  deleteRequest(`${usersController}/${user}/doctors`, data);
export const addZones = async (user, data) => postRequest(`${usersController}/${user}/zones`, data);
export const removeZones = async (user, data) =>
  deleteRequest(`${usersController}/${user}/zones`, data);
