import { useState, useRef, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import BellIcon from '@material-ui/icons/Notifications';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import { useGetNotifications, useMarkAsReadNotification } from 'src/hooks/notifications';
import { useInterval } from 'src/hooks/common';
import NotificationItem from './components/NotificationItem';
import NotificationsHeader from './components/NotificationsHeader';
import { getLink } from './NotificationsUtils';
import { useDispatch, useSelector } from 'react-redux';
import { isAssistant as isAssistantSelector } from 'src/store/selectors/user';
import { NOTIFICATIONS_RESOURCES } from './consts';
import { setDoctorEntity } from 'src/store/reducers/entity';

const useStyles = makeStyles((theme) => ({
  paper: {
    transformOrigin: 'top right',
  },
  list: {
    width: theme.spacing(50),
    maxWidth: '90vw',
    maxHeight: theme.spacing(40),
    overflow: 'auto',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(1, 0),
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  secondary: {
    color: theme.palette.text.secondary,
  },
  emptyText: {
    fontSize: '16px',
    marginTop: theme.spacing(1),
  },
  emptyIcon: {
    fontSize: '30px',
  },
}));

const NOTIFICATIONS_LIMIT = 10;
const NOTIFICATIONS_DELAY = 45000; // in milliseconds

export default function Notifications({ disabled }) {
  const classes = useStyles();
  const history = useHistory();
  const isAssistant = useSelector(isAssistantSelector);
  const userDoctors = useSelector((state) => state.user?.user?.doctors);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const anchorRef = useRef(null);
  const { t } = useTranslation('notifications');
  const { data: notifications, refetch, isLoading } = useGetNotifications(NOTIFICATIONS_LIMIT);
  const [markAsRead] = useMarkAsReadNotification();

  const notificationsCount = Array.isArray(notifications) ? notifications.length : 0;

  // If the count is equal or greater than the limit, show the text like '+9'
  const notificationsCountText =
    notificationsCount >= NOTIFICATIONS_LIMIT ? `+${notificationsCount - 1}` : notificationsCount;

  // Polling for notifications
  useInterval(() => {
    refetch();
  }, NOTIFICATIONS_DELAY);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    setTooltipOpen(false);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleItemClick = (notification) => async () => {
    const link = getLink(notification);

    // Close notifications
    setOpen(false);

    if (isAssistant) {
      selectDemandDoctor(notification);
    }

    if (link) {
      history.push(link);
      await markAsRead(notification.id);
      refetch();
    }
  };

  const handleMarkAllAsReadClick = async () => {
    // Close notifications
    setOpen(false);

    if (notifications) {
      const promises = notifications.map(({ id }) => markAsRead(id));

      await Promise.all(promises);
      refetch();
    }
  };

  const selectDemandDoctor = (notification) => {
    const doctorId = notification.params?.find(({ key }) => key === 'doctorId')?.value;

    if (notification.resource === NOTIFICATIONS_RESOURCES.DEMANDS && doctorId) {
      const doctor = userDoctors.find(({ id }) => id === Number(doctorId));
      dispatch(setDoctorEntity({ doctor }));
    }
  };

  return (
    <Fragment>
      <Tooltip
        open={tooltipOpen}
        onOpen={() => {
          setTooltipOpen(!open);
        }}
        onClose={() => {
          setTooltipOpen(false);
        }}
        title={t('texts.notifications')}
        enterDelay={300}
        disabled={disabled}
      >
        <IconButton color="inherit" ref={anchorRef} onClick={handleToggle}>
          <Badge color="error" badgeContent={notificationsCountText}>
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popper
        id="notifications-popup"
        anchorEl={anchorRef.current}
        open={open}
        placement="bottom-end"
        transition
        disablePortal
        role={undefined}
      >
        {({ TransitionProps }) => (
          <Grow in={open} {...TransitionProps}>
            <Paper className={classes.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                {!isLoading && !notificationsCount ? (
                  <Box p={2} display="flex" flexDirection="column" alignItems="center">
                    <BellIcon color="disabled" className={classes.emptyIcon} />
                    <Typography className={classes.emptyText}>
                      {t('texts.empty_notifications')}
                    </Typography>
                  </Box>
                ) : (
                  <List
                    className={classes.list}
                    subheader={
                      <NotificationsHeader
                        handleMarkAllAsReadClick={!isLoading && handleMarkAllAsReadClick}
                      />
                    }
                  >
                    {isLoading && (
                      <div className={classes.loading}>
                        <CircularProgress size={32} />
                      </div>
                    )}
                    {notifications &&
                      notifications.map((notification, index) => (
                        <Fragment key={notification.id}>
                          <NotificationItem
                            notification={notification}
                            handleClick={handleItemClick(notification)}
                          />
                          {index < notificationsCount - 1 ? (
                            <Divider className={classes.divider} />
                          ) : null}
                        </Fragment>
                      ))}
                  </List>
                )}
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
}
