import { useQuery } from 'react-query';
import { getPresentationById } from 'src/services/api/presentations';

function useGetPresentation(presentation, query, enabled = true) {
  return useQuery(
    ['presentation', presentation],
    (key, presentation) => getPresentationById(presentation, query),
    {
      enabled,
    },
  );
}

export default useGetPresentation;
