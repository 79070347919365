const DOSAGE = {
  MGM2: 'MG/M2',
  'MG/M2': 'MGM2',
  MGKG: 'MG/KG',
  'MG/KG': 'MGKG',
  AUC: 'AUC',
  MG: 'MG',
};

const ADMINISTRATION = {
  ENDOVENOUS: 'Endovenous',
  SURGICALIMPLANT: 'Surgical Implant',
  INTRAMUSCULAR: 'Intramuscular',
  INTRAVENOUS: 'Intravenous',
  INTRAVESICAL: 'Intravesical',
  NASAL: 'Nasal',
  ORAL: 'Oral',
  SUBCUTANEOUS: 'Subcutaneous',
  TOPIC: 'Topic',
};

export { DOSAGE, ADMINISTRATION };
