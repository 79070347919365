import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, makeStyles, SvgIcon, Typography } from '@material-ui/core';

const EmptyList = ({
  className,
  text,
  textSize = '20px',
  children: icon,
  iconBoxSize = '50px',
}) => {
  const useStyles = makeStyles((theme) => ({
    box: {
      margin: '20px',
    },
    icon: {
      color: theme.palette.secondary.main,
      width: iconBoxSize,
      height: iconBoxSize,
    },
    text: {
      fontSize: textSize,
    },
  }));
  const classes = useStyles();

  return (
    <Box
      className={clsx(classes.box, className)}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <SvgIcon className={classes.icon}>{icon}</SvgIcon>

      <Typography className={classes.text} color="secondary" variant="h4" align="center">
        {text}
      </Typography>
    </Box>
  );
};

EmptyList.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.object,
  text: PropTypes.string,
};

export default EmptyList;
