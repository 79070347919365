import { Box, Dialog, DialogTitle, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InsertClosingCommentForm from './components/InsertClosingCommentForm';

const useStyles = makeStyles({
  dialog: {
    margin: '1.5rem',
  },
});

const initialValues = {
  closingReason: '',
};

const InsertClosingCommentModal = ({ demandId, closeDialog, finishDemandAction }) => {
  const classes = useStyles();
  const { t } = useTranslation('demands');

  const handleInsertClosingComment = async (values) => {
    closeDialog();
    finishDemandAction(values?.closingReason);
  };

  return (
    <Dialog open={true}>
      <Box className={classes.dialog} display="flex" flexDirection="column">
        <DialogTitle disableTypography={true}>
          <Typography variant="h4" align="center">
            {t('labels.finish_demand')}
          </Typography>
        </DialogTitle>

        <InsertClosingCommentForm
          initialValues={initialValues}
          handleInsertClosingComment={handleInsertClosingComment}
          closeDialog={closeDialog}
        />
      </Box>
    </Dialog>
  );
};

InsertClosingCommentModal.propTypes = {
  demandId: PropTypes.number,
  closeDialog: PropTypes.func,
};

export default InsertClosingCommentModal;
