import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  cell: {
    padding: 0,
  },
  table: {
    marginTop: '12px',
    '& th': {
      padding: '4px 5px',
    },
    '& td': {
      padding: '4px 5px',
      color: theme.palette.tertiary.main,
    },
    '& th, & th strong, & td': {
      lineHeight: 1,
    },
  },
  button: {
    padding: 4,
  },
}));

const CodesTable = ({ codes }) => {
  const classes = useStyles();
  const { t } = useTranslation('demands');

  const { code } = codes || {};

  return (
    <TableContainer>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>{t('fields.tratamientosRadioterapicos_fields.codigo')}</TableCell>
            <TableCell>{t('fields.tratamientosRadioterapicos_fields.tipo')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {code ? (
          <TableRow>
            <TableCell>{code.code}</TableCell>
            <TableCell>{code.type}</TableCell>
          </TableRow>
        ) : (
          <TableRow>
            <TableCell colSpan={2}>{t('fields.tratamientosRadioterapicos_fields.empty')}</TableCell>
          </TableRow>
        )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

CodesTable.propTypes = {
  codes: PropTypes.object,
};

export default CodesTable;
