import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core';
import format from 'date-fns/format';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useGetPdfDemand } from 'src/hooks/demands';
import { isDoctorSelected } from 'src/store/selectors/entity';
import { isAssistant as isAssistantSelector } from 'src/store/selectors/user';
import { downloadFile } from 'src/utils/downloadFile';

import VersionsTable from './components';

const useStyles = makeStyles({
  card: {
    padding: '0 16px !important',
  },
  empty: {
    margin: 20,
  },
});

const DemandVersions = ({ demand, versions }) => {
  const classes = useStyles();
  const { t } = useTranslation(['demands', 'common']);
  const { enqueueSnackbar } = useSnackbar();
  const [version, setVersion] = useState();
  const isAssistant = useSelector(isAssistantSelector);
  const doctor = useSelector(isDoctorSelected);

  const { data: demandPdf, isErrorPdf, refetch: refetchPdf } = useGetPdfDemand(
    demand?.id,
    version?.id,
    isAssistant ? doctor?.id : undefined,
  );

  const generatePdf = (version) => {
    setVersion(version);
  };

  useEffect(() => {
    if (version) {
      refetchPdf();
    }
  }, [refetchPdf, version]);

  useEffect(() => {
    if (isErrorPdf) {
      enqueueSnackbar(t('modals.error.download_demands'), { variant: 'error' });
    }

    if (demandPdf?.data) {
      downloadFile(
        demandPdf?.data,
        'application/pdf',
        `${demand.reference} - ${format(new Date(version?.createdAt), 'yyyyMMddHHmmss')}.pdf`,
      );
      setVersion(null);
    }
  }, [demand.reference, demandPdf?.data, enqueueSnackbar, isErrorPdf, t, version?.createdAt]);

  return (
    <Card>
      <CardHeader
        title={t('demands:labels.viewDemand_versions_title')}
        subheader={t('demands:labels.viewDemand_versions_subtitle')}
      />
      <Divider />
      <CardContent className={classes.card}>
        {versions.length > 0 ? (
          <VersionsTable versions={versions} generatePdf={generatePdf} />
        ) : (
          <Box className={classes.empty} display="flex" flexDirection="column" alignItems="center">
            <Typography color="secondary" variant="h5">
              {t('labels.viewDemand_empty_text')}
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

DemandVersions.propTypes = {
  demand: PropTypes.object,
  versions: PropTypes.array,
};

export default DemandVersions;
