import { useMutation, useQueryCache } from 'react-query';
import { createCenter } from 'src/services/api/centers';

function useCreateCenter() {
  const queryCache = useQueryCache();

  return useMutation((data) => createCenter(data), {
    onSuccess: (data) => queryCache.setQueryData(['center', data.data?.id], data),
  });
}

export default useCreateCenter;
