import { Box, Button, makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(2),
  },
}));

const StepsNavigation = ({
  step,
  disableNext,
  disableNavigation,
  navigateToStep,
  finish,
  finishDemand,
  finishContinueDemand,
  finishChangeDatesDemand,
  finishEditActive,
  isContinue,
  isChangeDates,
  isEditActive,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(['common', 'demands']);

  return (
    <Box mt={2}>
      <Button
        className={classes.button}
        variant="contained"
        onClick={() => navigateToStep('prev')}
        disabled={disableNavigation || step === 1}
      >
        {t('buttons.back')}
      </Button>

      {step <= 4 && (
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={() => navigateToStep('next')}
          disabled={disableNext || disableNavigation}
        >
          {t('buttons.next')}
        </Button>
      )}

      {step === 5 && (
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={() => {
            if (isChangeDates) {
              finish(finishChangeDatesDemand);
            } else if (isContinue) {
              finish(finishContinueDemand);
            } else if (isEditActive) {
              finish(finishEditActive);
            } else {
              finish(finishDemand);
            }
          }}
          disabled={disableNext || disableNavigation}
        >
          {t('demands:buttons.send_demand')}
        </Button>
      )}
    </Box>
  );
};

StepsNavigation.propTypes = {
  step: PropTypes.number,
  disableNext: PropTypes.bool,
  disableNavigation: PropTypes.bool,
  navigateToStep: PropTypes.func,
  finish: PropTypes.func,
  finishContinueDemand: PropTypes.func,
  finishChangeDatesDemand: PropTypes.func,
  finishDemand: PropTypes.func,
  finishEditActive: PropTypes.func,
  isContinue: PropTypes.bool,
  isChangeDates: PropTypes.bool,
  isEditActive: PropTypes.bool,
};

export default StepsNavigation;
