import PropTypes from 'prop-types';
import ToolbarFilters from './components/ToolbarFilters';
import ToolbarActions from './components/ToolbarActions';

const Toolbar = ({ resetPage, email, setEmail, status, setStatus }) => {
  return (
    <div>
      <ToolbarActions />

      <ToolbarFilters
        resetPage={resetPage}
        email={email}
        setEmail={setEmail}
        status={status}
        setStatus={setStatus}
      />
    </div>
  );
};

Toolbar.propTypes = {
  resetPage: PropTypes.func,
  email: PropTypes.string,
  setEmail: PropTypes.func,
  status: PropTypes.string,
  setStatus: PropTypes.func,
};

export default Toolbar;
