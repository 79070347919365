import { Box, Dialog, DialogTitle, makeStyles, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AlertDialog from 'src/components/AlertDialog';
import { useUpdateDemand, useUpdateDemandSend } from 'src/hooks/demands';
import {
  isAssistant as isAssistantSelector,
  isRepresentive as isRepresentiveSelector,
} from 'src/store/selectors/user';
import { isDoctorSelected } from 'src/store/selectors/entity';
import ManageDemandForm from './components/ManageDemandForm';
import { useSelector } from 'react-redux';
import { ROLES } from 'src/constants/roles';

const useStyles = makeStyles((theme) => ({
  dialog: {
    margin: '1.5rem',
  },
}));

const initialValues = {
  toRoleId: null,
  toUserId: null,
  action: '',
  comment: '',
  decisionReason: null,
  finalReason: null,
  privateComment: '',
  attachments: [],
};

const ManageDemandModal = ({ demand, closeDialog, refetchDemands, decisionReason }) => {
  const classes = useStyles();
  const isAssistant = useSelector(isAssistantSelector);
  const isRepresentive = useSelector(isRepresentiveSelector);
  const doctor = useSelector(isDoctorSelected);
  const { t } = useTranslation(['demands', 'common']);
  const { enqueueSnackbar } = useSnackbar();
  const [updateDemand, updateQuery] = useUpdateDemand();
  const [sendDemand, sendQuery] = useUpdateDemandSend();

  initialValues.decisionReason = decisionReason;

  const handleUpdateDemand = async (values) => {
    const data = { ...values };

    const toRolePermissions = data.toRoleId.permissions;
    data.toRoleId = data.toRoleId.id;

    const request = { demand: demand.id, data };
    if (isAssistant && doctor) {
      request.doctorId = doctor.id;
    }

    let res;
    if (isRepresentive && toRolePermissions === ROLES.DOCTOR) {
      res = await sendDemand(request);
    } else {
      res = await updateDemand(request);
    }

    if (res) {
      closeDialog();
      refetchDemands();
    }
  };

  useEffect(() => {
    if (
      (!updateQuery.isLoading && !updateQuery.isError && updateQuery.data) ||
      (!sendQuery.isLoading && !sendQuery.isError && sendQuery.data)
    ) {
      enqueueSnackbar(t('modals.success.text_edit_demand'), { variant: 'success' });
    }
  }, [
    enqueueSnackbar,
    sendQuery.data,
    sendQuery.isError,
    sendQuery.isLoading,
    t,
    updateQuery.data,
    updateQuery.isError,
    updateQuery.isLoading,
  ]);

  return (
    <Dialog open={true}>
      <Box className={classes.dialog} display="flex" flexDirection="column">
        <DialogTitle disableTypography={true}>
          <Typography variant="h4" align="center">
            {t('labels.manage_modal_title')}
          </Typography>
        </DialogTitle>

        <ManageDemandForm
          demand={demand}
          initialValues={initialValues}
          handleUpdateDemand={handleUpdateDemand}
          closeDialog={closeDialog}
        />

        {(updateQuery.isError || sendQuery.isError) && (
          <AlertDialog
            title={t('modals.error.title_edit_demand')}
            text={
              updateQuery.error?.message ||
              sendQuery.error?.message ||
              t('modals.error.text_edit_demand')
            }
            type="error"
          />
        )}
      </Box>
    </Dialog>
  );
};

ManageDemandModal.propTypes = {
  demand: PropTypes.object,
  closeDialog: PropTypes.func,
  refetchDemands: PropTypes.func,
  decisionReason: PropTypes.string,
};

export default ManageDemandModal;
