import get from 'lodash/get';
import { MANAGE_DEMAND_ACTIONS, STATUS, STATUS_FILTER } from 'src/constants/demands';
import { ROLES,ROLES_ID } from 'src/constants/roles';

const getDemandStatus = (demand, assignedRole) => {
  if (demand.status === STATUS.ACTIVE) {
    if (demand.continue) {
      return STATUS_FILTER.PENDING_CONTINUE;
    }
    // eslint-disable-next-line default-case
    switch (assignedRole) {
      case ROLES.MANAGER:
        return STATUS_FILTER.PENDING_ANALYSIS;
      case ROLES.DOCTOR:
        return STATUS_FILTER.PENDING_MISSING_INFO;
      case ROLES.REPRESENTATIVE: {
        const lastAction = get(demand, 'versions[0].histories[0].action');
        const lastRole = get(demand, 'versions[0].histories[0].fromRoleId');    
    
        if(lastRole === ROLES_ID.DOCTOR || lastRole === ROLES_ID.ASSISTANT ){
          return STATUS_FILTER.PENDING_VALIDATION;
        } else if (lastAction === MANAGE_DEMAND_ACTIONS.ACCEPT) {
          return STATUS_FILTER.PENDING_COMPANY_ACCEPT;
        } else if (lastAction === MANAGE_DEMAND_ACTIONS.DENY) {
          return STATUS_FILTER.PENDING_COMPANY_DENY;
        }

        break;
      }
      case ROLES.CONSULTANT:
        return STATUS_FILTER.PENDING_EVALUATION;
    }
  } else if (demand.status === STATUS.SENDED) {
    const lastAction = get(demand, 'versions[0].histories[0].action');

    if (lastAction === MANAGE_DEMAND_ACTIONS.ACCEPT) {
      return STATUS_FILTER.SENDED_ACCEPTED;
    } else if (lastAction === MANAGE_DEMAND_ACTIONS.DENY) {
      return STATUS_FILTER.SENDED_DENIED;
    }
  }

  return String(demand.status).toLowerCase();
};

export { getDemandStatus };
