import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  SvgIcon,
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Search as SearchIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { TYPE } from 'src/constants/centers';
import { useDebounce } from 'use-debounce/lib';

const Container = ({ children }) => {
  return (
    <Box mt={3}>
      <Card>
        <CardContent>{children}</CardContent>
      </Card>
    </Box>
  );
};

const ToolbarFilters = ({ resetPage, name, setName, setType, type }) => {
  const { t } = useTranslation('centers');
  const [nameValue, setNameValue] = useState(name);
  const [nameDebouncedValue] = useDebounce(nameValue, 500);

  const handleReset = () => {
    resetPage();
    setName('');
    setNameValue('');
    setType('');
  };

  const handleTypeChange = (e) => {
    setType(e.target.value);
    resetPage();
  };

  useEffect(() => {
    setName(nameDebouncedValue);
    resetPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameDebouncedValue, setName]);

  return (
    <Container>
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="primary">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            name="name"
            placeholder={t('labels.search_centers')}
            value={nameValue}
            onBlur={(e) => setName(e.target.value)}
            onChange={(e) => {
              setNameValue(e.target.value);
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            select
            name="type"
            label={t('fields.type')}
            SelectProps={{ native: true }}
            value={type}
            onChange={handleTypeChange}
            variant="outlined"
          >
            <option value="" hidden />
            <option value={TYPE.AMBOS}>{t('fields.type_select.ambos')}</option>
            <option value={TYPE.PRESCRIPTOR}>{t('fields.type_select.prescriptor')}</option>
            <option value={TYPE.REALIZADOR}>{t('fields.type_select.realizador')}</option>
          </TextField>
        </Grid>
        <Grid item xs={12} md={2}>
          <Button fullWidth color="primary" variant="contained" onClick={handleReset}>
            {t('buttons.centers_list_clear_filters')}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

ToolbarFilters.propTypes = {
  setPage: PropTypes.func,
  name: PropTypes.string,
  setName: PropTypes.func,
  setType: PropTypes.func,
  type: PropTypes.string,
};

export default ToolbarFilters;
