import { Box, Button, Grid, TextField, CircularProgress } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useListCompanies } from 'src/hooks/company';
import * as Yup from 'yup';

const CreateEditCodeForm = ({ code, handleFormSubmit, handleCancel, operation }) => {
  const { t } = useTranslation(['codes']);
  const { isLoading, data: companies } = useListCompanies({ all: true, default: false });

  const initialValues = {
    companyId: null,
    code: '',
    type: '',
  };

  const getCodeData = () => {
    if (code?.data) {
      return {
        companyId: companies?.data.rows.find(({ id }) => code.data.companyId === id) || null,
        type: code.data.type,
        code: code.data.code,
      };
    }

    return initialValues;
  };

  const validationSchema = Yup.object().shape({
    companyId: Yup.object().nullable().required(t('errors.company.required')),
    type: Yup.string().required(t('errors.type.required')),
    code: Yup.string().required(t('errors.code.required')),
  });

  return (
    <Formik
      enableReinitialize
      initialValues={getCodeData()}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
        setFieldTouched,
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Autocomplete
                openOnFocus={true}
                selectOnFocus={false}
                options={companies?.data?.rows || []}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                loading={isLoading}
                name="companyId"
                value={values.companyId}
                onChange={(e, value) => setFieldValue('companyId', value)}
                onBlur={() => setFieldTouched('companyId', true)}
                disabled={isSubmitting}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('fields.select_company')}
                    variant="outlined"
                    error={Boolean(touched.companyId && errors.companyId)}
                    helperText={touched.companyId && errors.companyId}
                    inputProps={{
                      ...params.inputProps,
                      style: { cursor: 'pointer' },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      readOnly: true,
                      endAdornment: (
                        <>
                          {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                error={Boolean(touched.code && errors.code)}
                fullWidth
                helperText={touched.code && errors.code}
                label={t('fields.code')}
                name="code"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting}
                value={values.code}
                variant="outlined"
                multiline
                rows={6}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                error={Boolean(touched.type && errors.type)}
                fullWidth
                helperText={touched.type && errors.type}
                label={t('fields.type')}
                name="type"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting}
                value={values.type}
                variant="outlined"
                multiline
                rows={6}
              />
            </Grid>
            <Box p={2}>
              <Button
                color="primary"
                fullWidth
                disabled={isSubmitting}
                size="large"
                variant="outlined"
                onClick={handleCancel}
              >
                {t('buttons.cancel')}
              </Button>
            </Box>
            <Box p={2}>
              <Button
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                {t(`buttons.${operation === 'create' ? 'create_code' : 'save'}`)}
              </Button>
            </Box>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

CreateEditCodeForm.propTypes = {
  initialValues: PropTypes.object,
  handleFormSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  operation: PropTypes.string,
  code: PropTypes.object,
};

export default CreateEditCodeForm;
