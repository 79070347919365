import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  makeStyles,
} from '@material-ui/core';
import isNumber from 'lodash/isNumber';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import AlertDialog from 'src/components/AlertDialog';
import Loader from 'src/components/Loader';
import Page from 'src/components/Page';
import { MANAGE_DEMAND_ACTIONS, STATUS, STATUS_FILTER } from 'src/constants/demands';
import { ROLES } from 'src/constants/roles';
import {
  useCreateDemand,
  useGetDemand,
  useUpdateDemand,
  useUpdateDemandActive,
  useUpdateDemandChangeDates,
  useUpdateDemandContinue,
  useUpdateDemandDraft,
} from 'src/hooks/demands';
import { useGetRoles } from 'src/hooks/roles';
import { isCenterSelected, isDoctorSelected } from 'src/store/selectors/entity';
import {
  isAssistant as isAssistantSelector,
  isDoctor as isDoctorSelector,
  isManager as isManagerSelector,
} from 'src/store/selectors/user';
import { useDebouncedCallback } from 'use-debounce/lib';
import { useImmer } from 'use-immer';

import DemandsStepper from './components/DemandsStepper/';
import SavingStatus from './components/SavingStatus';
import Step1 from './components/Steps/Step1';
import Step2 from './components/Steps/Step2';
import Step3 from './components/Steps/Step3';
import Step4 from './components/Steps/Step4';
import Step5 from './components/Steps/Step5';
import StepsNavigation from './components/StepsNavigation';
import omit from 'lodash/omit';
import DemandHistories from 'src/components/Demands/DemandHistories';
import { mapDemandToInitialValues } from './CreateDemandUtils';
import { checkDemandWasSended } from '../../../utils/demands';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  header: {
    fontSize: '20px',
  },
  histories: {
    marginTop: 20,
  },
}));

const CreateDemandView = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const isDoctor = useSelector(isDoctorSelector);
  const isAssistant = useSelector(isAssistantSelector);
  const isManager = useSelector(isManagerSelector);
  const doctor = useSelector(isDoctorSelected);
  const center = useSelector(isCenterSelected);
  const { t } = useTranslation(['demands', 'roles']);
  const { demandIdEdit } = useParams();
  const enqueueSnackbar = useSnackbar();

  const [demandId, setDemandId] = useState(null);
  const [step, setStep] = useState(1);
  const [disableForm, setDisableForm] = useState(false);
  const [disableNext, setDisableNext] = useState(true);
  const [disableNavigation, setDisableNavigation] = useState(false);
  const [isChangeDates, setIsChangeDates] = useState(false);
  const [isContinue, setIsContinue] = useState(false);
  const [shouldBeContinue, setShouldBeContinue] = useState(false);
  const [isEditActive, setIsEditActive] = useState(false);
  const [demand, setDemand] = useState({ step1: {}, step2: {}, step3: {}, step4: {}, step5: {} });
  const [debouncedDemand, setDebouncedDemand] = useState(demand);
  const [demandExtras, setDemandExtras] = useImmer({});
  const [completedSteps, setCompletedSteps] = useImmer([false, false, false, false, false]);
  const [eliminarFicherosAdjuntos, setEliminarFicherosAdjuntos] = useState([]);

  const [
    createDemand,
    { isLoading: isLoadingCreate, isError: isErrorCreate, error: errorCreate },
  ] = useCreateDemand();
  const [
    updateDemand,
    { isLoading: isLoadingUpdate, data: updatedDemand, isError: isErrorUpdate, error: errorUpdate },
  ] = useUpdateDemand();
  const [
    updateDemandDraft,
    { isLoading: isLoadingUpdateDraft, isError: isErrorUpdateDraft },
  ] = useUpdateDemandDraft();
  const [
    updateDemandActive,
    { isLoading: isLoadingActive, data: activeDemand, isError: isErrorActive, error: errorActive },
  ] = useUpdateDemandActive();
  const [
    updateDemandContinue,
    {
      isLoading: isLoadingContinue,
      data: continueDemand,
      isError: isErrorContinue,
      error: errorContinue,
    },
  ] = useUpdateDemandContinue();
  const [
    updateDemandChangeDates,
    {
      isLoading: isLoadingChangeDates,
      data: changeDatesDemandData,
      isError: isErrorChangeDates,
      error: errorChangeDates,
    },
  ] = useUpdateDemandChangeDates();
  const { isLoading: isLoadingRoles, data: roles, isErrorRoles } = useGetRoles({
    all: true,
  });
  const {
    isLoading: isLoadingGet,
    data: demandData,
    isError: isErrorGet,
    error: errorGet,
    refetch: refetchGet,
  } = useGetDemand(
    demandIdEdit,
    {
      doctorId: doctor?.id,
      include:
        'centerPrescriptor,centerRealizator,patient,company,versions,doctor,user,role,createdBy,attachments,previousTreatments,radiotherapyTreatments,chemotherapySessions,chemotherapyDrugs',
    },
    false,
  );
  const {
    isLoading: isLoadingGetAttachments,
    data: demandAttachmentData,
    isError: isErrorGetAttachments,
    error: errorGetAttachments,
    refetch: refetchGetAttachments,
  } = useGetDemand(
    demandIdEdit,
    {
      doctorId: doctor?.id,
      include:
        'attachments',
    },
    false,
  );

  useEffect(() => {
    if (demandIdEdit && !demandData?.data && !isLoadingRoles && roles?.data) {
      refetchGet();
    }
  }, [demandData?.data, isLoadingRoles, refetchGet, roles?.data, demandIdEdit]);

  if (!isLoadingGet && demandData?.data && !demandId) {
    const doctorRoleId = roles?.data?.rows?.find(({ permissions }) => permissions === ROLES.DOCTOR)
      ?.id;
    const isChangeDatesDemand = !!location.state?.isChangeDates;
    const isContinueDemand = demandData?.data.status === STATUS.ACTIVE && demandData?.data.continue;
    const shouldBeContinueTreatment =
      demandData?.data.displayStatus === STATUS_FILTER.PENDING_MISSING_INFO &&
      demandData?.data.versions[0].histories[1].action === MANAGE_DEMAND_ACTIONS.REQUEST &&
      checkDemandWasSended(demandData?.data.versions);

    const isEditActiveDemand =
      !shouldBeContinueTreatment &&
      demandData?.data.status === STATUS.ACTIVE &&
      !demandData?.data.continue &&
      demandData?.data.assignedRoleId === doctorRoleId;
    setIsChangeDates(isChangeDatesDemand);
    setIsContinue(isContinueDemand || shouldBeContinueTreatment);
    setShouldBeContinue(shouldBeContinueTreatment);
    setIsEditActive(isEditActiveDemand);

    if (
      !isChangeDatesDemand &&
      !isContinueDemand &&
      !isEditActiveDemand &&
      demandData?.data.status !== STATUS.DRAFT &&
      (demandData?.data.status !== STATUS.ACTIVE ||
        (demandData?.data.assignedRoleId !== doctorRoleId && !isManager)
      )
    ) {
      history.push('/app/demands');
    } else {
      setDemandId(Number(demandIdEdit));
      setDemand(mapDemandToInitialValues(demandData.data));

      if (isContinueDemand || isChangeDatesDemand || shouldBeContinueTreatment) {
        setCompletedSteps((draft) => {
          draft[0] = true;
          draft[1] = true;
          draft[2] = true;
        });
        setStep(4);
      }
    }
  }

  const finishDemand = async () => {
    // Update ficheros adjuntos
    const demandStep5 = { ficherosAdjuntos: demand.step5.ficherosAdjuntos || [] };
    demandStep5.ficherosAdjuntos = demandStep5.ficherosAdjuntos?.filter((e) => !e.previous);
    demandStep5.eliminarFicherosAdjuntos = eliminarFicherosAdjuntos;

    if (demandStep5.ficherosAdjuntos.length || demandStep5.eliminarFicherosAdjuntos.length) {
      await updateDemandDraft({ demand: demandId, data: demandStep5 });
    }

    // Update Active Demand
    const data = {};
    if (isAssistant) {
      data.doctorId = doctor?.id;
    }

    return await updateDemandActive({ demand: demandId, data });
  };

  const finishContinueDemand = async () => {
    const dataStep4 = pick(
      demand?.step4,
      'quimioterapicoPeso',
      'quimioterapicoFechaPrimeraSesion',
      'quimioterapicoNumeroSesionesCiclo',
      'quimioterapicoFechasSesiones',
      'quimioterapicoFarmacos',
    );

    const dataStep5 = pick(demand?.step5, 'observaciones', 'comentariosOficiales','ficherosAdjuntos');
    
    const data = {
      ...dataStep4,
      ...dataStep5,
    };

    return await updateDemandContinue({
      demand: demandId,
      data,
      doctorId: isAssistant ? doctor?.id : undefined,
    });
  };

  const finishChangeDatesDemand = async () => {
    const data = pick(
      demand?.step4,
      'quimioterapicoPeso',
      'quimioterapicoFechaPrimeraSesion',
      'quimioterapicoNumeroSesionesCiclo',
      'quimioterapicoFechasSesiones',
      'quimioterapicoFarmacos',
    );
    data.changeDatesReason = location.state?.changeDatesReason;

    return await updateDemandChangeDates({
      demand: demandId,
      data,
      doctorId: isAssistant ? doctor?.id : undefined,
    });
  };

  const finishEditActive = async () => {
    const data = { ...demand.step2, ...demand.step3, ...demand.step4, ...demand.step5 };

    data.ficherosAdjuntos = data?.ficherosAdjuntos?.filter((e) => !e.previous);
    data.eliminarFicherosAdjuntos = eliminarFicherosAdjuntos;

    return await updateDemand({
      demand: demandId,
      data,
      doctorId: isAssistant ? doctor?.id : undefined,
    });
  };

  const reloadAttachments = async() => {
    const attachments = demandAttachmentData?.data?.attachments;
    if (attachments?.length) {
      const ficherosAdjuntosDemand = await attachments.map((at) => ({
        descripcion: at.description,
        archivo: at.id,
        previous: true,
      }));
      return ficherosAdjuntosDemand;
    } else {
      return [];
    }
  }

  const saveDraft = async (isCreate) => {
    console.log('Ejecutando saveDraft');
    setDebouncedDemand(demand);
    if (!demandData || demandData?.data?.status === STATUS.DRAFT) {
      console.log('Ingresando al proceso');
      if (step === 1) {
        console.log('Ingresando a Step1');
        if (demandId) {
          console.log('Ingresando a demandId');
          // Update demand patient
          const patientId = demand.step1.patientId;
          const ficherosAdjuntos = demand.step1?.ficherosAdjuntos?.filter((e) => !e.previous);
          const eliminarFicherosAdjuntos = demand.step1?.eliminarFicherosAdjuntos;
          console.log('Ficheros adjuntos a actualizar: ', ficherosAdjuntos);
          console.log('Ficheros adjuntos a eliminar: ', eliminarFicherosAdjuntos);
          if (patientId && !isEqual(demand, debouncedDemand)) {
            console.log("Actualizando solicitud...");
            const res = await updateDemandDraft({ demand: demandId, data: { patientId, ficherosAdjuntos, eliminarFicherosAdjuntos } });
            console.log("Solicitud actualizada: ", res);
            if(ficherosAdjuntos?.length > 0 || eliminarFicherosAdjuntos?.length > 0) {
              await refetchGetAttachments();
            }
            return res;
          }
        } else if (isCreate) {
          // Create demand
          let data = demand.step1;

          if (((isAssistant && data.doctorId) || (isDoctor && !data.doctorId)) &&
            data.centerPrescriptorId &&
            data.patientId
          ) {
            console.log("Creando solicitud...");
            data = omit(data, 'eliminarFicherosAdjuntos');
            const res = await createDemand(data);
            setDemandId(res?.data?.id);
            demand.company = {companyId: res?.data?.companyId};
            history.push(`/app/demands/${res?.data?.id}/edit`);
            return res;
          }
        }
      } else {
        // Update demand draft
        let data = demand[`step${step}`];

        if (step === 4) {
          data?.quimioterapicoFarmacos?.forEach((f) => {
            if (f.price === '') {
              f.price = 0;
            }
          });
        }

        if (step === 5) {
          data = omit(data, 'ficherosAdjuntos');
        }

        if (!isEmpty(data) && !isEqual(demand, debouncedDemand)) {
          return updateDemandDraft({ demand: demandId, data });
        }
      }
    }
  };

  const debounceSave = useDebouncedCallback(() => saveDraft(), 5000);

  const hasUnsavedChanges = () => {
    console.log("Son iguales: ", isEqual(demand, debouncedDemand));
    return (
      !isEqual(demand, debouncedDemand) ||
      isLoadingCreate ||
      isLoadingUpdateDraft ||
      (step === 1 && !demandId)
    );
  };

  const navigateToStep = async (newStep) => {
    const hasUnsavedChangess = hasUnsavedChanges();
    if (hasUnsavedChangess) {
      setDisableForm(true);
      setDisableNavigation(true);

      await saveDraft(step === 1);

      setDisableForm(false);
      setDisableNavigation(false);
    }

    if (newStep === 'prev') {
      setStep(step - 1);
    }
    if (newStep === 'next') {
      setStep(step + 1);
    }
    if (isNumber(newStep)) {
      setStep(newStep);
    }
  };

  // Send active demand, continue demand or edit active demand request
  const finish = async (handler) => {
    setDisableForm(true);
    setDisableNavigation(true);

    if (hasUnsavedChanges()) {
      await saveDraft();
    }

    const res = await handler();
    if (!res) {
      setDisableForm(false);
      setDisableNavigation(false);
    }
  };

  useEffect(() => {
    debounceSave.callback();
  }, [debounceSave, demand]);

  useEffect(() => {
    if (!isLoadingActive && !isErrorActive && activeDemand) {
      history.push('/app/demands');
    }
  }, [activeDemand, history, isErrorActive, isLoadingActive]);

  useEffect(() => {
    if (!isLoadingContinue && !isErrorContinue && continueDemand) {
      history.push('/app/demands');
    }
  }, [continueDemand, history, isErrorContinue, isLoadingContinue]);

  useEffect(() => {
    if (!isLoadingChangeDates && !isErrorChangeDates && changeDatesDemandData) {
      history.push('/app/demands');
    }
  }, [changeDatesDemandData, history, isErrorChangeDates, isLoadingChangeDates]);

  useEffect(() => {
    if (!isLoadingUpdate && !isErrorUpdate && updatedDemand) {
      history.push('/app/demands');
    }
  }, [history, isErrorUpdate, isLoadingUpdate, updatedDemand]);

  useEffect(() => {
    if (isErrorRoles) {
      enqueueSnackbar(t('roles:modals.error.title_get_roles'), { variant: 'error' });
    }
  }, [enqueueSnackbar, isErrorRoles, t]);

  if ((!center && !isManager) || (isAssistant && !doctor)) {
    return <Redirect to="/app/home" />;
  }

  if (demandIdEdit && isErrorGet) {
    return (
      <AlertDialog
        title={t('modals.error.title_get_demand')}
        text={errorGet?.message || t('modals.error.text_get_demand')}
        type="error"
        acceptAction={() => history.push('/app/demands', { replace: true })}
      />
    );
  }

  return (
    <Page className={classes.root}>
      <Container maxWidth="lg">
        <Card>
          <CardHeader
            component={() => (
              <SavingStatus
                pendingChanges={
                  hasUnsavedChanges() ||
                  isLoadingActive ||
                  isLoadingContinue ||
                  isLoadingChangeDates
                }
                isDemandCreated={!!demandId}
                isError={isErrorCreate || isErrorUpdateDraft}
              />
            )}
          />
          <CardHeader
            component={() => (
              <DemandsStepper
                step={step}
                navigateToStep={navigateToStep}
                canNavigateNext={!disableNext}
                completedSteps={completedSteps}
                setCompletedSteps={setCompletedSteps}
              />
            )}
          />
          <CardHeader
            classes={{ title: classes.header, subheader: classes.header }}
            title={t(`labels.step${step}_title`)}
            subheader={t(`labels.step${step}_subtitle`)}
          />
          <Divider />
          <CardContent>
            {step === 1 && isLoadingGet && <Loader />}
            {step === 1 && !isLoadingGet && (
              <Step1
                demand={demand}
                setDemand={setDemand}
                disableForm={disableForm || isEditActive}
                setDisableNext={setDisableNext}
                isContinue={isContinue}
                isChangeDates={isChangeDates}
                demandExtras={demandExtras}
                setDemandExtras={setDemandExtras}
                status={demandData?.data?.status}
              />
            )}
            {step === 2 && (
              <Step2
                demand={demand}
                setDemand={setDemand}
                disableForm={disableForm}
                setDisableNext={setDisableNext}
                isContinue={isContinue}
                isChangeDates={isChangeDates}
              />
            )}
            {step === 3 && (
              <Step3
                demand={demand}
                setDemand={setDemand}
                disableForm={disableForm}
                setDisableNext={setDisableNext}
                isContinue={isContinue}
                isChangeDates={isChangeDates}
              />
            )}
            {step === 4 && (
              <Step4
                demand={demand}
                demandId={demandId}
                setDemand={setDemand}
                disableForm={disableForm}
                setDisableNext={setDisableNext}
                isContinue={isContinue}
                isChangeDates={isChangeDates}
                shouldBeContinue={shouldBeContinue}
                originalFirstSessionDate={demandData.data.quimioterapicoFechaPrimeraSesion}
              />
            )}
            {step === 5 && (
              <Step5
                demand={demand}
                setDemand={setDemand}
                demandExtras={demandExtras}
                setDemandExtras={setDemandExtras}
                disableForm={disableForm}
                setDisableNext={setDisableNext}
                eliminarFicherosAdjuntos={eliminarFicherosAdjuntos}
                setEliminarFicherosAdjuntos={setEliminarFicherosAdjuntos}
                isContinue={isContinue}
                isChangeDates={isChangeDates}
                reloadAttachments={reloadAttachments}
                status={demandData?.data?.status}
              />
            )}

            <StepsNavigation
              step={step}
              disableNext={disableNext}
              disableNavigation={disableNavigation}
              navigateToStep={navigateToStep}
              finish={finish}
              finishDemand={finishDemand}
              finishContinueDemand={finishContinueDemand}
              finishChangeDatesDemand={finishChangeDatesDemand}
              finishEditActive={finishEditActive}
              isContinue={isContinue}
              isChangeDates={isChangeDates}
              isEditActive={isEditActive}
            />

            {isErrorCreate && (
              <AlertDialog
                title={t('modals.error.title_create_demand')}
                text={errorCreate?.message || t('modals.error.text_create_demand')}
                type="error"
              />
            )}
            {isErrorUpdate && (
              <AlertDialog
                title={t('modals.error.title_update_demand_draft')}
                text={errorUpdate?.message || t('modals.error.text_update_demand_draft')}
                type="error"
              />
            )}
            {isErrorActive && (
              <AlertDialog
                title={t('modals.error.title_update_demand_active')}
                text={errorActive?.message || t('modals.error.text_update_demand_active')}
                type="error"
              />
            )}
            {isErrorContinue && (
              <AlertDialog
                title={t('modals.error.title_update_demand_continue')}
                text={errorContinue?.message || t('modals.error.text_update_demand_continue')}
                type="error"
              />
            )}
            {isErrorChangeDates && (
              <AlertDialog
                title={t('modals.error.title_update_demand_change_dates')}
                text={
                  errorChangeDates?.message || t('modals.error.text_update_demand_change_dates')
                }
                type="error"
              />
            )}
          </CardContent>
        </Card>

        {(isEditActive || shouldBeContinue) && (
          <Box className={classes.histories}>
            <DemandHistories demand={demandData?.data} roles={roles} onlyLastEntry={true} />
          </Box>
        )}
      </Container>
    </Page>
  );
};

export default CreateDemandView;
