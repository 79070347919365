import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton } from '@material-ui/core';
import {
  Info as InfoIcon,
} from 'react-feather';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 500,
    width: 1000,
    height: 500,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

export default function ImageTooltip() {
  return (
    <div>
      <HtmlTooltip
        title={
          <React.Fragment>
            <img
              src={"/static/card_model.png"}
              alt="Placeholder"
              style={{ width: 'auto', height: 'auto', marginTop: '10px' }}
            />
          </React.Fragment>
        }
      >
        <IconButton>
          <InfoIcon />
        </IconButton>
      </HtmlTooltip>
    </div>
  );
}