import { Button, Grid, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { STATUS } from 'src/constants/demands';
import { useGoBack } from 'src/hooks/common';
import { useGetPdfDemand } from 'src/hooks/demands';
import { isDoctorSelected } from 'src/store/selectors/entity';
import { isAssistant as isAssistantSelector } from 'src/store/selectors/user';
import { downloadFile } from 'src/utils/downloadFile';
import format from 'date-fns/format';

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: '7px !important',
  },
  button: {
    marginRight: theme.spacing(2),
  },
}));

const DemandDetailsActions = ({ demand, version }) => {
  const classes = useStyles();
  const { t } = useTranslation(['demands', 'common', 'rolesNames']);
  const { enqueueSnackbar } = useSnackbar();
  const isAssistant = useSelector(isAssistantSelector);
  const doctor = useSelector(isDoctorSelected);
  const [pdfRequest, setPdfRequest] = useState(false);
  const goBack = useGoBack('/app/demands');

  const { data: demandPdf, isErrorPdf, refetch: refetchPdf } = useGetPdfDemand(
    demand?.id,
    version ? version.id : demand.versions[0]?.id,
    isAssistant ? doctor?.id : undefined,
  );

  const generatePdf = () => {
    setPdfRequest(true);
    refetchPdf();
  };

  useEffect(() => {
    if (isErrorPdf) {
      enqueueSnackbar(t('modals.error.download_demands'), { variant: 'error' });
    }
    if (pdfRequest && demandPdf?.data) {
      downloadFile(
        demandPdf.data,
        'application/pdf',
        `${demand.reference} - ${format(
          new Date(version ? version.createdAt : demand.versions[0].createdAt),
          'yyyyMMddHHmmss',
        )}.pdf`,
      );
      setPdfRequest(false);
    }
  }, [demandPdf, demand, enqueueSnackbar, isErrorPdf, t, pdfRequest, version]);

  return (
    <Grid item xs={12} align="right" className={classes.grid}>
      {version && (
        <Button
          color="primary"
          className={classes.button}
          variant="outlined"
          onClick={() => generatePdf(demand, version)}
          title={t('demands:buttons.printPdfVersion')}
        >
          {t('demands:buttons.printPdfVersion')}
        </Button>
      )}
      {demand.status !== STATUS.DRAFT && (
        <Button
          color="primary"
          className={classes.button}
          variant="outlined"
          onClick={() => generatePdf(demand, demand.versions[0])}
          title={t('demands:buttons.printPdf')}
        >
          {t('demands:buttons.printPdf')}
        </Button>
      )}

      <Button
        className={classes.button}
        variant="outlined"
        onClick={goBack}
        title={t('common:buttons.back')}
      >
        {t('common:buttons.back')}
      </Button>
    </Grid>
  );
};

DemandDetailsActions.propTypes = {
  demand: PropTypes.object,
  version: PropTypes.object,
};

export default DemandDetailsActions;
