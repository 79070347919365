import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Grid,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import FilesField from 'src/components/FilesField';
import * as Yup from 'yup';
import pickBy from 'lodash/pickBy';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  actions: {
    justifyContent: 'center',
  },
  btn_container: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
  },
  btn: {
    color: '#fff',
  },
  btn_no_selected: {
    backgroundColor: '#fff',
  },
  autorizar_btn: {
    backgroundColor: '#00b894',
  },
  autorizar_btn_no_selected: {
    color: '#00b894',
    border: '1px solid #00b894',
    '&:hover': {
      backgroundColor: '#00b894',
      color: '#fff',
    },
  },
  denegar_btn: {
    backgroundColor: '#d63031',
  },
  denegar_btn_no_selected: {
    color: '#d63031',
    border: '1px solid #d63031',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#d63031',
      color: '#fff',
    },
  },
  info_btn: {
    backgroundColor: '#0984e3',
  },
  info_btn_no_selected: {
    color: '#0984e3',
    border: '1px solid #0984e3',
    '&:hover': {
      backgroundColor: '#0984e3',
      color: '#fff',
    },
  },
  evaluar_btn: {
    backgroundColor: '#74b9ff',
  },
  evaluar_btn_no_selected: {
    color: '#74b9ff',
    border: '1px solid #74b9ff',
    '&:hover': {
      backgroundColor: '#74b9ff',
      color: '#fff',
    },
  },
}));


const stylesPopUp = makeStyles((theme) =>({
  actions: {
    fontSize: '16px',
    color: '#f44336',
    textAlign: 'center',
    backgroundColor: 'lightyellow'
  },
}));

const UploadAttachmentForm = ({ initialValues, handleUpdateDemand, closeDialog }) => {
  const classes = useStyles();
  const classesPopUp = stylesPopUp();
  const { t } = useTranslation(['demands', 'roles', 'rolesNames', 'users', 'common']);
  const [ficherosAdjuntos, setFicherosAdjuntos] = useState([]);
  const [eliminarFicherosAdjuntos, setEliminarFicherosAdjuntos] = useState([]);
  
  const validationSchema = Yup.object().shape({
    ficherosAdjuntos: Yup.array().of(
      Yup.object().shape({
        descripcion: Yup.string().required(t('errors.attachments_values.descripcion.required')),
        archivo: Yup.string().required(t('errors.attachments_values.archivo.required')),
      }),
    )
  });

  const onSubmit = (values) => {
    const data = pickBy(values);
    console.log("Data actualizar: ", data);
    return handleUpdateDemand(data);
  };

  return (
    <Formik 
      initialValues={initialValues} 
      validationSchema={validationSchema} 
      onSubmit={onSubmit} >
      {({
        errors,
        handleBlur,
        setFieldTouched,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Box p={1}>
              <Grid container spacing={3}>
                
                <Grid item xs={12}>
                  <FilesField
                    field="attachments"
                    values={values}
                    errors={errors}
                    touched={touched}
                    setFieldTouched={setFieldTouched}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    disabled={isSubmitting}
                    setFicherosAdjuntos={setFicherosAdjuntos}
                    eliminarFicherosAdjuntos={eliminarFicherosAdjuntos}
                    setEliminarFicherosAdjuntos={setEliminarFicherosAdjuntos}
                    cantDeleteAttachments={true}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>

          <DialogActions className={classes.actions}>
            <Button variant="contained" onClick={closeDialog}>
              {t('common:buttons.cancel')}
            </Button>
            <Button color="primary" variant="contained" onClick={handleSubmit}>
              {t('buttons.update_mange_demand')}
            </Button>
          </DialogActions>
        </form>
      )}
    </Formik>
  );
};

UploadAttachmentForm.propTypes = {
  initialValues: PropTypes.object,
  handleUpdateDemand: PropTypes.func,
  closeDialog: PropTypes.func,
};

export default UploadAttachmentForm;
