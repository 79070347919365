import { Container, LinearProgress, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import DrugDetails from './components/ProfileDetails';
import { useHistory, useParams } from 'react-router-dom';
import Loader from 'src/components/Loader';
import { useDeleteDrug, useGetDrug } from 'src/hooks/drugs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import AlertDialog from 'src/components/AlertDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const DrugDetailsView = () => {
  const classes = useStyles();
  const { t } = useTranslation(['drugs', 'common']);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { drugId } = useParams();
  const { isLoading, data: drug, isError, error } = useGetDrug(drugId);
  const [
    deleteDrug,
    { isLoading: isLoadingDelete, data: dataDelete, isError: isErrorDelete, error: errorDelete },
  ] = useDeleteDrug();

  const handleDeleteDrug = () => {
    setShowDeleteModal(false);
    deleteDrug(drugId);
  };

  useEffect(() => {
    if (!isLoadingDelete && dataDelete && !isErrorDelete) {
      enqueueSnackbar(t('modals.delete_drug.success.text'), { variant: 'success' });

      history.push('/app/drugs');
    }
  }, [dataDelete, enqueueSnackbar, history, isErrorDelete, isLoadingDelete, t]);

  if (isError) {
    return (
      <AlertDialog
        title={t('modals.get_drug.error.title')}
        text={error.message || t('modals.get_drug.error.text')}
        type="error"
        acceptAction={() => history.push('/app/drugs', { replace: true })}
      />
    );
  }

  return (
    <Page className={classes.root}>
      {isLoading && <Loader />}

      {!isLoading && (
        <Container maxWidth="lg">
          {isLoadingDelete && <LinearProgress color="primary" />}

          <DrugDetails
            drug={drug.data}
            deleteDrug={() => setShowDeleteModal(true)}
            disableActionButtons={isLoadingDelete}
          />

          {showDeleteModal && (
            <AlertDialog
              title={t('modals.delete_drug.confirmation.title')}
              text={t('modals.delete_drug.confirmation.text')}
              type="warning"
              showAcceptButton={false}
              showDeleteButton={true}
              deleteAction={handleDeleteDrug}
              showCancelButton={true}
              cancelAction={() => setShowDeleteModal(false)}
            />
          )}
          {isErrorDelete && (
            <AlertDialog
              title={t('modals.delete_drug.error.title')}
              text={errorDelete?.message || t('modals.delete_drug.error.text')}
              type="error"
            />
          )}
        </Container>
      )}
    </Page>
  );
};

export default DrugDetailsView;
