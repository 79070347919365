import {
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import { Download as DownloadIcon, Eye as EyeIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  cell: {
    padding: 0,
  },
  table: {
    '& th': {
      padding: '4px 5px',
    },
    '& td': {
      padding: '4px 5px',
      color: theme.palette.tertiary.main,
    },
    '& th, & th strong, & td': {
      lineHeight: 1,
    },
  },
  button: {
    padding: 4,
  },
}));

const VersionsTable = ({ versions, generatePdf }) => {
  const classes = useStyles();
  const { t } = useTranslation('demands');

  return (
    <TableContainer>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>{t('fields.demandUpdateDate')}</TableCell>
            <TableCell>{t('fields.description')}</TableCell>
            <TableCell align="right">{t('fields.actions')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {versions.map((row, i) => (
            <TableRow key={row.id}>
              <TableCell>
                {row.createdAt && format(new Date(row.createdAt), 'dd/MM/yyyy HH:mm:ss')}
              </TableCell>
              <TableCell>{row.description ?? ''}</TableCell>
              <TableCell align="right" className={classes.cell}>
                <IconButton
                  className={classes.button}
                  color="primary"
                  value={row.id}
                  component={RouterLink}
                  to={`/app/demands/${row.demandId}/version/${row.id}`}
                  title={t('labels.view_details')}
                >
                  <EyeIcon />
                </IconButton>
                <IconButton
                  className={classes.button}
                  color="primary"
                  title={t('labels.download_version')}
                  onClick={async () => generatePdf(row)}
                >
                  <DownloadIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

VersionsTable.propTypes = {
  versions: PropTypes.array,
};

export default VersionsTable;
