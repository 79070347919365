import React from 'react';
import { Button, Card, CardContent, Grid, makeStyles, TextField } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import startOfToday from 'date-fns/startOfToday';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { MinusCircle as MinusCircleIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  cardMargin: {
    margin: theme.spacing(2),
    marginTop: 0,
  },
  iconMargin: {
    marginRight: theme.spacing(1),
  },
  removeTreatmentButton: {
    marginLeft: 'auto',
    color: theme.palette.error.main,
  },
}));

const TreatmentCard = ({
  treatmentIndex,
  touched,
  errors,
  setFieldValue,
  setFieldTouched,
  treatment,
  arrayHelpers,
  handleBlur,
  handleChange,
  disabled,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('demands');

  return (
    <Card className={classes.cardMargin}>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <KeyboardDatePicker
              fullWidth
              disableToolbar
              required
              format="dd/MM/yyyy"
              label={t('fields.tratamientosPreviosFecha')}
              maxDate={startOfToday()}
              error={Boolean(
                get(touched, `tratamientosPrevios.${treatmentIndex}.date`) &&
                  get(errors, `tratamientosPrevios.${treatmentIndex}.date`),
              )}
              helperText={
                get(touched, `tratamientosPrevios.${treatmentIndex}.date`) &&
                get(errors, `tratamientosPrevios.${treatmentIndex}.date`)
              }
              name={`tratamientosPrevios.${treatmentIndex}.date`}
              value={treatment.date}
              onChange={(date) => {
                setFieldValue(`tratamientosPrevios.${treatmentIndex}.date`, date);
              }}
              onBlur={() => setFieldTouched(`tratamientosPrevios.${treatmentIndex}.date`, true)}
              disabled={disabled}
              variant="inline"
              inputVariant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              error={Boolean(
                get(touched, `tratamientosPrevios.${treatmentIndex}.description`) &&
                  get(errors, `tratamientosPrevios.${treatmentIndex}.description`),
              )}
              helperText={
                get(touched, `tratamientosPrevios.${treatmentIndex}.description`) &&
                get(errors, `tratamientosPrevios.${treatmentIndex}.description`)
              }
              multiline
              rows={4}
              label={t('fields.tratamientosPreviosDatos')}
              onBlur={handleBlur}
              onChange={handleChange}
              name={`tratamientosPrevios.${treatmentIndex}.description`}
              value={treatment.description}
              disabled={disabled}
              variant="outlined"
            />
          </Grid>

          <Button
            className={classes.removeTreatmentButton}
            size="small"
            onClick={() => arrayHelpers.remove(treatmentIndex)}
            disabled={disabled}
          >
            <MinusCircleIcon className={classes.iconMargin} size={18} />
            {t('buttons.remove_previous_treatment')}
          </Button>
        </Grid>
      </CardContent>
    </Card>
  );
};

TreatmentCard.propTypes = {
  treatmentIndex: PropTypes.number,
  treatment: PropTypes.object,
  EMPTY_TREATMENT: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  arrayHelpers: PropTypes.object,
  handleBlur: PropTypes.func,
  setFieldTouched: PropTypes.func,
  disabled: PropTypes.bool,
};

export default TreatmentCard;
