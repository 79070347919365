import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import HistoriesTable from './components';
import RollbackLastAction from './components/RollbackLastAction';

const useStyles = makeStyles({
  card: {
    padding: '0 16px !important',
  },
  empty: {
    margin: 20,
  },
});

const DemandHistories = ({ demand, roles, version, onlyLastEntry = false }) => {
  const classes = useStyles();
  const { t } = useTranslation(['demands', 'common']);

  let histories = [];

  if (demand.versions) {
    for (let i = 0; i < demand.versions.length; i++) {
      const version = demand.versions[i];
      if (version.histories) {
        for (let j = 0; j < version.histories.length; j++) {
          const history = version.histories[j];

          histories.push(history);
        }
      }
    }

    if (version) {
      histories = histories.filter((e) => e.createdAt <= version.histories[0]?.createdAt);
    }

    histories.sort((a, b) => new Date(a.createdAt) + new Date(b.createdAt));

    if (onlyLastEntry) {
      histories = [histories[0]];
    }
  }

  return (
    <Card>
      <CardHeader
        title={t('demands:labels.viewDemand_histories_title')}
        subheader={t('demands:labels.viewDemand_histories_subtitle')}
      />
      <CardHeader
        component={() => (
          <RollbackLastAction 
            demandId={demand.id} 
            versions={demand.versions} />
        )}
      />
      <Divider />
      <CardContent className={classes.card}>
        {histories.length > 0 ? (
          <HistoriesTable roles={roles} histories={histories} />
        ) : (
          <Box className={classes.empty} display="flex" flexDirection="column" alignItems="center">
            <Typography color="secondary" variant="h5">
              {t('labels.viewDemand_empty_text')}
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

DemandHistories.propTypes = {
  demand: PropTypes.object,
  roles: PropTypes.object,
  version: PropTypes.object,
};

export default DemandHistories;
