import { Box, makeStyles, Paper, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { PropTypes } from 'prop-types';

const useStyles = makeStyles((theme) => ({
  drugLetters: {
    width: 'fit-content',
    color: theme.palette.error.contrastText,
  },
  drugLetterA: {
    backgroundColor: '#ff595e',
    color: theme.palette.error.contrastText,
  },
  drugLetterB: {
    backgroundColor: '#ffca3a',
  },
  drugLetterC: {
    backgroundColor: '#8ac926',
  },
  drugLetterD: {
    backgroundColor: '#1982c4',
    color: theme.palette.error.contrastText,
  },
  drugLetterE: {
    backgroundColor: '#6a4c93',
    color: theme.palette.error.contrastText,
  },
  drugLetterRandom: {
    backgroundColor: props => props.color,
    color: theme.palette.error.contrastText,
  },
}));

const generateUniqueColor = (letter) => {
  // Obtener el código ASCII de la letra
  const charCode = letter.charCodeAt(0);

  // Generar un hash único a partir del código ASCII
  let hash = charCode;
  hash = ((hash >> 16) ^ hash) * 0x45d9f3b;
  hash = ((hash >> 16) ^ hash) * 0x45d9f3b;
  hash = (hash >> 16) ^ hash;

  // Formatear el hash como un color hexadecimal
  const color = '#' + ('00000' + (hash & 0xFFFFFF).toString(16)).slice(-6);
  return color;
}

const DrugLetter = ({ letter }) => {
  const classes = useStyles({color: generateUniqueColor(letter)});
  const classColor = classes[`drugLetter${letter}`] || classes[`drugLetterRandom`];
  return (
    <Box
      px={2}
      py={1}
      component={Paper}
      className={clsx(classes.drugLetters, classColor)}
    >
      <Typography>{letter}</Typography>
    </Box>
  );
};

DrugLetter.propTypes = {
  letter: PropTypes.string,
};

export default DrugLetter;
