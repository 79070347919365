import { MANAGE_DEMAND_ACTIONS, TYPE } from '../constants/demands';
import { ROLES } from '../constants/roles';

const checkDemandWasSended = (versions) => {
  const allHistories = versions.flatMap(({ histories }) => histories);
  const sendedAcceptedHistory = allHistories.find(
    ({ action, fromRole }) =>
      action === MANAGE_DEMAND_ACTIONS.ACCEPT && fromRole.permissions === ROLES.REPRESENTATIVE,
  );

  return Boolean(sendedAcceptedHistory);
};

const getLocationTranslation = (location) => {
  switch (location) {
    case TYPE.MAMA:
      return 'fields.type_options.mama';
    case TYPE.GASTRICO:
      return 'fields.type_options.gastrico';
    case TYPE.MIELOPROLIFERATIVOS:
      return 'fields.type_options.sindrome_mieloproliferativo';
    case TYPE.PULMON:
      return 'fields.type_options.pulmon';
    case TYPE.COLORECTAL:
      return 'fields.type_options.colo_rectal';
    case TYPE.PROSTATA:
      return 'fields.type_options.prostata';
    case TYPE.OVARIO:
      return 'fields.type_options.ovario';
    case TYPE.PANCREAS:
      return 'fields.type_options.pancreas';
    case TYPE.VEJIGA:
      return 'fields.type_options.vejiga';
    default:
      return location;
  }
};

const generateLetterFromIndex = (index) => {
  let letras = "";
  let n = index;
  while (true) {
      letras = String.fromCharCode(97 + (n % 26)) + letras; // 97 es el código ASCII de 'a'
      n = Math.floor(n / 26) - 1;
      if (n < 0) {
          break;
      }
  }
  return letras.toUpperCase();
}


export { checkDemandWasSended, getLocationTranslation, generateLetterFromIndex };
