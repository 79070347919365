import { useMutation, useQueryCache } from 'react-query';
import { createCode } from 'src/services/api/codes';

function useCreateCode() {
  const queryCache = useQueryCache();

  return useMutation((data) => createCode(data), {
    onSuccess: (data) => queryCache.setQueryData(['code', data.data?.id], data),
  });
}

export default useCreateCode;
