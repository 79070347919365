import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import storage from 'localforage';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';

import rootReducer from './reducers';
import { reloadUser } from './reducers/user';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'user', 'entity'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});
const persistor = persistStore(store, null, () => {
  if (store.getState().auth.token) {
    store.dispatch(reloadUser());
  }
});

export { store, persistor };
