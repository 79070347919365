import {
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  Droplet as DropletIcon,
  Edit as EditIcon,
  Eye as EyeIcon,
  Trash as TrashIcon,
} from 'react-feather';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink } from 'react-router-dom';
import EmptyList from 'src/components/EmptyList';
import { ADMINISTRATION, DOSAGE } from 'src/constants/drugs';

const useStyles = makeStyles((theme) => ({
  table_list: {
    '& th': {
      padding: '5px',
    },
    '& td': {
      padding: '5px',
      color: theme.palette.tertiary.main,
      '& a,button': {
        padding: '0px 5px',
      },
    },
  },
}));

const Results = ({ 
    drugs, 
    deleteDrug, 
    page, 
    setPage, 
    resetPage, 
    pageSize, 
    setPageSize, 
    sort,
    setSort, 
    isLoading 
  }) => {
  const { t } = useTranslation('drugs');
  const classes = useStyles();
  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleSortChange = (order) => {
    resetPage();
    if (sort === order) {
      setSort(`-${order}`);
    } else {
      setSort(order);
    }
  };

  return (
    <Card>
      <PerfectScrollbar>
        <Box>
          <Table className={classes.table_list}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={sort === 'name' || sort === '-name'}
                    direction={sort === 'name' ? 'asc' : 'desc'}
                    onClick={() => {
                      handleSortChange('name');
                    }}
                  >
                    {t('fields.name')}
                  </TableSortLabel>
                </TableCell>
                <TableCell>{t('fields.activePrinciple')}</TableCell>
                <TableCell>{t('fields.dosage')}</TableCell>
                <TableCell>{t('fields.administration')}</TableCell>
                <TableCell align="right">{t('fields.actions')}</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {drugs?.rows.map((drug) => (
                <TableRow hover key={drug.id}>
                  <TableCell>
                    <Box alignItems="center" display="flex">
                      <Typography color="textPrimary" variant="body1">
                        {drug.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{drug.activePrinciple}</TableCell>
                  <TableCell>{t(`names.dosage.${DOSAGE[drug.dosage]}`)}</TableCell>
                  <TableCell>
                    {t(
                      `names.administration.${Object.keys(ADMINISTRATION).find(
                        (key) => ADMINISTRATION[key] === drug.administration,
                      )}`,
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      color="primary"
                      component={RouterLink}
                      to={`/app/drugs/${drug.id}/edit`}
                      disabled={isLoading}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="primary"
                      component={RouterLink}
                      to={`/app/drugs/${drug.id}`}
                      disabled={isLoading}
                    >
                      <EyeIcon />
                    </IconButton>
                    <IconButton
                      color="primary"
                      onClick={() => deleteDrug(drug.id)}
                      disabled={isLoading}
                    >
                      <TrashIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {!isLoading && !drugs?.rows.length && (
            <EmptyList text={t('labels.empty_list_text')}>
              <DropletIcon />
            </EmptyList>
          )}
        </Box>
      </PerfectScrollbar>

      <TablePagination
        component="div"
        count={drugs?.count || 0}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePageSizeChange}
        page={drugs?.count ? page - 1 : 0}
        rowsPerPage={pageSize}
        rowsPerPageOptions={[25, 50, 100]}
      />
    </Card>
  );
};

Results.propTypes = {
  drugs: PropTypes.object,
  deleteDrug: PropTypes.func,
  page: PropTypes.number,
  setPage: PropTypes.func,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default Results;
