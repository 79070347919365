import { useMutation, useQueryCache } from 'react-query';
import { updateCode } from 'src/services/api/codes';

function useUpdateCode() {
  const queryCache = useQueryCache();

  return useMutation(({ code, data }) => updateCode(code, data), {
    onSuccess: (data) => queryCache.setQueryData(['code', data.data?.id], data),
  });
}

export default useUpdateCode;
