import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Paper,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const DetailsTable = ({ code }) => {
  const { t } = useTranslation('codes');

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.company')}</Typography>
            </TableCell>
            <TableCell>{code.company.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.code')}</Typography>
            </TableCell>
            <TableCell>{code.code}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.type')}</Typography>
            </TableCell>
            <TableCell>{code.type}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

DetailsTable.propTypes = {
  code: PropTypes.object,
};

export default DetailsTable;
