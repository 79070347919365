import { AppBar, Box, Hidden, IconButton, makeStyles, Toolbar } from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import CenterSelector from 'src/components/Centers/CenterSelector';
import DoctorSelector from 'src/components/Doctors/DoctorSelector/DoctorSelector';
import ManagerCompanySelector from 'src/components/Company/ManagerCompanySelector';
import Logo from 'src/components/Logo';
import { logOut } from 'src/store/actions/auth';
import {
  isCenterSelected as isCenterSelectedSelector,
  isDoctorSelected as isDoctorSelectedSelector,
  isCompanySelected as isCompanySelectedSelector,
} from 'src/store/selectors/entity';
import {
  isAssistant as isAssistantSelector,
  isSupervisor as isSupervisorSelector,
} from 'src/store/selectors/user';
import { isDoctor as isDoctorSelector } from 'src/store/selectors/user';
import { isManager as isManagerSelector } from 'src/store/selectors/user';
import Notifications from '../Notifications';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60,
  },
}));

const TopBar = ({ className, onMobileNavOpen, disabled, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isAssistant = useSelector(isAssistantSelector);
  const isDoctor = useSelector(isDoctorSelector);
  const isManager = useSelector(isManagerSelector);
  const isSupervisor = useSelector(isSupervisorSelector);
  const isDoctorSelected = useSelector(isDoctorSelectedSelector);
  const isCenterSelected = useSelector(isCenterSelectedSelector);
  const isCompanySelected = useSelector(isCompanySelectedSelector);

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden mdDown>
          {(isAssistant || isSupervisor) && isDoctorSelected && isCenterSelected ? (
            <DoctorSelector />
          ) : null}
          {((isAssistant || isSupervisor) && isDoctorSelected && isCenterSelected) ||
          (isDoctor && isCenterSelected) ? (
            <CenterSelector />
          ) : null}
          {isManager && isCompanySelected && <ManagerCompanySelector />}
          <Notifications disabled={disabled} />
          <IconButton color="inherit" onClick={() => {dispatch(logOut());
              if(
                window.location.host.indexOf("orgoa.plataformaoncologia.es")!= -1 
                ){
                window.location.href="https://platform.orgaconsulting.es";
              }
              }
          }>
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
  disabled: PropTypes.bool,
};

export default TopBar;
