import { usePaginatedQuery } from 'react-query';
import * as patientsService from 'src/services/api/patients';

function useListPatients(query, enabled = true) {
  return usePaginatedQuery(
    ['patients', query],
    (key, query) => patientsService.listPatients(query),
    { enabled },
  );
}

export default useListPatients;
