import { getRequest, postRequest } from 'src/utils/http';
import { invitationsController } from './consts';

export const getInvitationUse = async (token) =>
  getRequest(`${invitationsController}/use?token=${token}`);

export const postInvitationUse = async (token, body) =>
  postRequest(`${invitationsController}/use?token=${token}`, body);

export const createInvitation = async (body) => postRequest(`${invitationsController}`, body);

export const validateInvitation = async (invitation) =>
  postRequest(`${invitationsController}/${invitation}/validate`);

export const rejectInvitation = async (invitation) =>
  postRequest(`${invitationsController}/${invitation}/reject`);

export const listInvitations = async (params) => getRequest(`${invitationsController}`, params);

export const getInvitation = async (invitation, params) =>
  getRequest(`${invitationsController}/${invitation}`, params);
