/* eslint-disable no-param-reassign */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { mapCompanyToAppConfig } from 'src/utils/models/transforms/company';
import { getCompany } from '../../services/api/companies';
import { passwordExpired, requireResetPassword, requireAcceptTerms } from '../actions/app';

const defaultAppState = {
  loading: true,
  error: false,
  currentCompany: '',
  data: {},
  passwordExpired: false,
  requireResetPassword: false,
  requireAcceptTerms: false,
};

export const fetchAppData = createAsyncThunk('app/fetchAppData', async () => {
  const response = await getCompany();
  return response.data;
});

const appSlice = createSlice({
  name: 'app',
  initialState: defaultAppState,
  reducers: {
    initCompany: {
      reducer(state, action) {
        state.currentCompany = action.payload;
      },
    },
    setPasswordExpired: {
      reducer(state, action) {
        state.passwordExpired = action.payload.passwordExpired;
      },
    },
    setRequireResetPassword: {
      reducer(state, action) {
        state.requireResetPassword = action.payload.requireResetPassword;
      },
    },
    setRequireAcceptTerms: {
      reducer(state, action) {
        state.requireAcceptTerms = action.payload.requireAcceptTerms;
      },
    },
  },
  extraReducers: {
    [fetchAppData.pending]: (state) => {
      state.loading = true;
    },
    [fetchAppData.fulfilled]: (state, action) => {
      const appData = mapCompanyToAppConfig(action.payload);

      state.data = appData;
      state.currentCompany = appData.subdomain;
      state.loading = false;
    },
    [fetchAppData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [passwordExpired]: (state) => {
      state.passwordExpired = true;
    },
    [requireResetPassword]: (state) => {
      state.requireResetPassword = true;
    },
    [requireAcceptTerms]: (state) => {
      state.requireAcceptTerms = true;
    },
  },
});

export const {
  initCompany,
  setPasswordExpired,
  setRequireResetPassword,
  setRequireAcceptTerms,
} = appSlice.actions;

export default appSlice.reducer;
