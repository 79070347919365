import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { PropTypes } from 'prop-types';
import { useState } from 'react';
import {
  AlertCircle as AlertCircleIcon,
  CheckCircle as CheckCircleIcon,
  XCircle as XCircleIcon,
} from 'react-feather';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  dialog: {
    margin: '1.5rem',
  },
  icon: {
    width: '5rem',
    height: '5rem',
    margin: '0 auto',
  },
  iconSuccess: {
    color: '#4BB543',
  },
  iconWarning: {
    color: '#ffcc00',
  },
  iconError: {
    color: theme.palette.error.main,
  },
  actions: {
    justifyContent: 'center',
  },
  deleteButton: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
  },
}));

const AlertDialog = ({
  className,
  title,
  text,
  type,
  showAcceptButton = true,
  acceptText,
  acceptAction,
  showCancelButton,
  cancelText,
  cancelAction,
  showDeleteButton,
  deleteText,
  deleteAction,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(true);

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open}>
      <Box className={clsx(classes.dialog, className)} display="flex" flexDirection="column">
        <SvgIcon className={classes.icon}>
          {type === 'success' && <CheckCircleIcon className={classes.iconSuccess} />}
          {type === 'warning' && <AlertCircleIcon className={classes.iconWarning} />}
          {type === 'error' && <XCircleIcon className={classes.iconError} />}
        </SvgIcon>
        <DialogTitle disableTypography={true}>
          <Typography variant="h4" align="center">
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText align="center">{text}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.actions}>
          {showCancelButton && (
            <Button variant="contained" onClick={cancelAction || closeDialog}>
              {cancelText || t('buttons.cancel')}
            </Button>
          )}
          {showAcceptButton && (
            <Button color="primary" variant="contained" onClick={acceptAction || closeDialog}>
              {acceptText || t('buttons.ok')}
            </Button>
          )}
          {showDeleteButton && (
            <Button className={classes.deleteButton} variant="contained" onClick={deleteAction}>
              {deleteText || t('buttons.delete')}
            </Button>
          )}
        </DialogActions>
      </Box>
    </Dialog>
  );
};

AlertDialog.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['success', 'warning', 'error']),
  title: PropTypes.string,
  text: PropTypes.string,
  showAcceptButton: PropTypes.bool,
  acceptText: PropTypes.string,
  acceptAction: PropTypes.func,
  showCancelButton: PropTypes.bool,
  cancelText: PropTypes.string,
  cancelAction: PropTypes.func,
  showDeleteButton: PropTypes.bool,
  deleteText: PropTypes.string,
  deleteAction: PropTypes.func,
};

export default AlertDialog;
