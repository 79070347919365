import { Box, Button, CircularProgress, Grid, makeStyles, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import format from 'date-fns/format';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { GENDERS } from 'src/constants/genders';
import { TYPE } from 'src/constants/patients';
import { useListCompanies } from 'src/hooks/company';
import * as Yup from 'yup';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce/lib';
import { useSelector } from 'react-redux';
import {
  isAssistant as isAssistantSelector,
  isDoctor as isDoctorSelector,
} from 'src/store/selectors/user';
import { KeyboardDatePicker } from '@material-ui/pickers';
import FilesField from 'src/components/FilesField';
import { STATUS } from 'src/constants/demands';
import { COMPANY_IDS } from 'src/constants/companies';
import { useValidateCardNumber } from 'src/hooks/utils';
import ImageTooltip from './ImageTooltip';

const useStyles = makeStyles({
  fullHeight: {
    height: '100%',
  },
});

const SearchPatientForm = ({
  isLoadingPatients,
  patients,
  patient,
  handleFormSubmit,
  setCardNumber,
  setPatient,
  setStep1Patient,
  disableForm,
  demandExtras,
  setDemandExtras,
  ficherosAdjuntos,
  setFicherosAdjuntos,
  eliminarFicherosAdjuntos,
  setEliminarFicherosAdjuntos,
  status
}) => {
  const classes = useStyles();
  const userCompany = useSelector((state) => state.user.user?.company);
  const isDoctor = useSelector(isDoctorSelector);
  const isAssistant = useSelector(isAssistantSelector);
  const { enqueueSnackbar } = useSnackbar();
  const { t: tForm } = useTranslation(['patients', 'companies']);
  const { t } = useTranslation(['demands']);
  const [cardNumberValue, setCardNumberValue] = useState('');
  const [isInvalidCardNumber, setIsInvalidCardNumber] = useState(false);
  const [debouncedCardNumber] = useDebounce(cardNumberValue, 500);
  const { isLoading, data: companies, isError } = useListCompanies(
    { all: true, default: false },
    !isDoctor && !isAssistant,
  );
  const [
    validateCardNumber,
    { isLoading: isLoadingValidation, data: responseValidation, 
      isError: isErrorValidation, error: errorValidation },
  ] = useValidateCardNumber();

  useEffect(() => {
    if (isErrorValidation) {
      enqueueSnackbar(errorValidation.message, {
        variant: 'error',
      });

    }
  }, [enqueueSnackbar, isErrorValidation]);

  useEffect(() => {
    if (!isLoadingValidation) {
      if(isErrorValidation) {
        console.log("Tarjeta inválida: ", errorValidation);
        enqueueSnackbar(errorValidation.message, {
          variant: 'error',
        });
        setIsInvalidCardNumber(true);

      } else {
        console.log("Tarjeta válida: ", responseValidation);
        setIsInvalidCardNumber(false);
        setCardNumber(debouncedCardNumber);
      }
    }
  }, [responseValidation, isErrorValidation, isLoadingValidation]);
  
  useEffect(() => {
    if([COMPANY_IDS.FIATC].includes(userCompany.id)) {
      if(debouncedCardNumber?.length == 12 || debouncedCardNumber?.length == 13) {
        const values = { 'cardNumber': cardNumberValue }
        validateCardNumber(values);
      } else {
        setIsInvalidCardNumber(true);  
      }
    } else {
      setIsInvalidCardNumber(false);
      setCardNumber(debouncedCardNumber);
    }
  }, [debouncedCardNumber, setCardNumber]);

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    cardNumber: '',
    birthday: null,
    gender: '',
    companyId: isDoctor || isAssistant ? userCompany : null,
    type: '',
    ficherosAdjuntos: []
  };

  const getPatientData = () => {
    if (patient) {
      return {
        firstName: patient.firstName,
        lastName: patient.lastName,
        email: patient.email || '',
        cardNumber: patient.cardNumber,
        birthday: new Date(patient.birthday),
        gender: patient.gender,
        companyId: patient.company,
        type: patient.type,
        ficherosAdjuntos: ficherosAdjuntos,
        eliminarFicherosAdjuntos: []
      };
    }

    return initialValues;
  };

  const onSubmit = (values, formikBag) => {
    const data = omitBy(omit(values, 'companyId'), isEmpty);
    data.birthday = format(values.birthday, 'yyyy-MM-dd');

    if (!patient?.id) {
      data.companyId = values.companyId.id;
    }

    return handleFormSubmit(patient?.id, data, formikBag);
  };

  const validationSchema = Yup.object().shape({
    type: Yup.string().required(tForm('errors.type.required')),
    gender: Yup.string().required(tForm('errors.gender.required')),
    cardNumber: Yup.string().required(tForm('errors.cardNumber.required')),
    birthday: Yup.date()
      .typeError(tForm('errors.birthday.date_format'))
      .required(tForm('errors.birthday.required')),
    email: Yup.string().email(tForm('errors.email.valid')).max(255, tForm('errors.email.max')),
    companyId: Yup.object().nullable().required(tForm('errors.company.required')),
    firstName: Yup.string()
      .min(3, tForm('errors.firstName.min'))
      .max(30, tForm('errors.firstName.max'))
      .required(tForm('errors.firstName.required')),
    lastName: Yup.string()
      .min(3, tForm('errors.lastName.min'))
      .max(255, tForm('errors.lastName.max'))
      .required(tForm('errors.lastName.required')),
    ficherosAdjuntos: Yup.array().of(
      Yup.object().shape({
        descripcion: Yup.string().required(
          t('errors.ficherosAdjuntos_values.descripcion.required'),
        ),
        archivo: Yup.string().required(t('errors.ficherosAdjuntos_values.archivo.required')),
      }),
    ),
  });

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(tForm('companies:modals.error.title_list_companies'), {
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, isError, tForm]);

  return (
    <Formik
      enableReinitialize
      initialValues={getPatientData()}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        touched,
        errors,
        handleBlur,
        setFieldTouched,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Autocomplete
                    openOnFocus={true}
                    clearOnBlur={false}
                    autoSelect={true}
                    name="cardNumber"
                    options={debouncedCardNumber?.length || patient ? patients : []}
                    getOptionLabel={(option) => option.cardNumber}
                    getOptionSelected={(option, value) => option.id === value?.id}
                    value={patient || null}
                    onChange={(e, value, reason) => {
                      value ? setPatient(value) : setPatient(null);
                      setFieldValue(
                        'cardNumber',
                        value !== null ? value : initialValues.cardNumber,
                      );

                      if (reason === 'clear') {
                        setCardNumberValue('');
                        setCardNumber('');
                        setStep1Patient('');
                        resetForm();
                      }
                    }}
                    onBlur={() => setFieldTouched('cardNumber', true)}
                    loading={isLoadingPatients}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.cardNumber && errors.cardNumber)}
                        helperText={touched.cardNumber && errors.cardNumber}
                        label={tForm('fields.cardNumber')}
                        onChange={(e) => {
                          setCardNumberValue(e.target.value);
                          setFieldValue('cardNumber', e.target.value);
                        }}
                        onBlur={() => setFieldTouched('cardNumber', true)}
                        value={values.cardNumber}
                        fullWidth
                        required
                        name="cardNumber"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {isLoadingPatients ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                    disabled={disableForm || isSubmitting}
                  />
                </Grid>
                {([COMPANY_IDS.FIATC].includes(userCompany.id)) && (
                <Grid item >
                  <Box className={classes.fullHeight} display="flex" alignItems="center">
                    <ImageTooltip></ImageTooltip>
                  </Box>
                </Grid>
                )}
                <Grid item xs={3}>
                  <Box className={classes.fullHeight} display="flex" alignItems="center">
                    <Button
                      disabled={disableForm || isSubmitting || !patient?.id}
                      size="large"
                      type="reset"
                      variant="contained"
                      onClick={() => {
                        setCardNumberValue('');
                        setCardNumber('');
                        setPatient(null);
                        setStep1Patient('');
                        resetForm();
                      }}
                    >
                      {tForm('buttons.clear_patient')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                error={Boolean(touched.firstName && errors.firstName)}
                fullWidth
                required
                helperText={touched.firstName && errors.firstName}
                label={tForm('fields.firstName')}
                name="firstName"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={
                  disableForm || isSubmitting || Boolean(patient?.id) || !touched.cardNumber
                }
                value={values.firstName}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                error={Boolean(touched.lastName && errors.lastName)}
                fullWidth
                required
                helperText={touched.lastName && errors.lastName}
                label={tForm('fields.lastName')}
                name="lastName"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={
                  disableForm || isSubmitting || Boolean(patient?.id) || !touched.cardNumber
                }
                value={values.lastName}
                variant="outlined"
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <TextField
                error={Boolean(touched.gender && errors.gender)}
                helperText={touched.gender && errors.gender}
                fullWidth
                required
                label={tForm('fields.gender')}
                name="gender"
                onChange={handleChange}
                disabled={
                  disableForm || isSubmitting || Boolean(patient?.id) || !touched.cardNumber
                }
                select
                SelectProps={{ native: true }}
                value={values.gender}
                variant="outlined"
                onBlur={handleBlur}
              >
                <option value="" hidden></option>
                <option value={GENDERS.male}>{tForm('fields.genders.male')}</option>
                <option value={GENDERS.female}>{tForm('fields.genders.female')}</option>
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                label={tForm('fields.email')}
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={
                  disableForm || isSubmitting || Boolean(patient?.id) || !touched.cardNumber
                }
                type="email"
                value={values.email}
                variant="outlined"
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <KeyboardDatePicker
                fullWidth
                disableToolbar
                required
                format="dd/MM/yyyy"
                label={tForm('fields.birthday')}
                error={Boolean(touched.birthday && errors.birthday)}
                helperText={touched.birthday && errors.birthday}
                name="birthday"
                value={values.birthday}
                onChange={(date) => setFieldValue('birthday', date)}
                onBlur={() => setFieldTouched('birthday', true)}
                disabled={
                  disableForm || isSubmitting || Boolean(patient?.id) || !touched.cardNumber
                }
                variant="inline"
                inputVariant="outlined"
              />
            </Grid>

            {!isDoctor && !isAssistant && (
              <Grid item md={4} xs={12}>
                <Autocomplete
                  openOnFocus={true}
                  selectOnFocus={false}
                  options={
                    isDoctor || isAssistant
                      ? [patient?.company || userCompany]
                      : companies?.data?.rows || []
                  }
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) => option.id === value.id}
                  loading={isLoading}
                  name="companyId"
                  value={values.companyId}
                  onChange={(e, value) => setFieldValue('companyId', value)}
                  onBlur={() => setFieldTouched('companyId', true)}
                  disabled={
                    isDoctor ||
                    isAssistant ||
                    Boolean(patient?.companyId) ||
                    disableForm ||
                    isSubmitting ||
                    Boolean(patient?.id) ||
                    !touched.cardNumber
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={tForm('fields.select_company')}
                      variant="outlined"
                      error={Boolean(touched.companyId && errors.companyId)}
                      required
                      helperText={touched.companyId && errors.companyId}
                      inputProps={{
                        ...params.inputProps,
                        style: { cursor: 'pointer' },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        readOnly: true,
                        endAdornment: (
                          <>
                            {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            )}

            <Grid item md={4} xs={12}>
              <TextField
                error={Boolean(touched.type && errors.type)}
                helperText={touched.type && errors.type}
                fullWidth
                required
                label={tForm('fields.type')}
                name="type"
                onChange={handleChange}
                disabled={
                  disableForm || isSubmitting || Boolean(patient?.id) || !touched.cardNumber
                }
                select
                SelectProps={{ native: true }}
                value={values.type}
                variant="outlined"
                onBlur={handleBlur}
              >
                <option value="" hidden></option>
                <option value={TYPE.Funcionario}>{tForm('fields.type_select.func')}</option>
                <option value={TYPE.ISFAS}>{tForm('fields.type_select.isfas')}</option>
                <option value={TYPE.MUFACE}>{tForm('fields.type_select.muface')}</option>
                <option value={TYPE.MUGEJU}>{tForm('fields.type_select.mugeju')}</option>
                <option value={TYPE.Privada}>{tForm('fields.type_select.private')}</option>
                <option value={TYPE.UNDEFINED}>{tForm('fields.type_select.undefined')}</option>
              </TextField>
            </Grid>

            {((!isAssistant && !isDoctor) || !patient?.id) && (
              <Grid item md={4} xs={12}>
                <Box
                  className={classes.fullHeight}
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Button
                    color="primary"
                    disabled={
                      disableForm || isSubmitting || Boolean(patient?.id) || !touched.cardNumber || isInvalidCardNumber
                    }
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {tForm(`buttons.${patient?.id ? 'edit' : 'create'}`)}
                  </Button>
                </Box>
                  { touched.cardNumber && isInvalidCardNumber && 
                      <div style={{textAlign: 'right', color: 'red' }} >Formato de tarjeta no es correcto</div>}
              </Grid>
            )}
            
            <Grid item xs={12}>
              <FilesField
                field="ficherosAdjuntos"
                values={values}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                setFieldTouched={setFieldTouched}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                disabled={disableForm || isSubmitting }
                demandExtras={demandExtras}
                setDemandExtras={setDemandExtras}
                ficherosAdjuntos={ficherosAdjuntos}
                setFicherosAdjuntos={setFicherosAdjuntos}
                eliminarFicherosAdjuntos={eliminarFicherosAdjuntos}
                setEliminarFicherosAdjuntos={setEliminarFicherosAdjuntos}
                cantDeleteAttachments={status != STATUS.DRAFT}
              />
            </Grid>   
          </Grid>
        </form>
      )}
    </Formik>
  );
};

SearchPatientForm.propTypes = {
  isLoadingPatients: PropTypes.bool,
  patients: PropTypes.array,
  patient: PropTypes.object,
  handleFormSubmit: PropTypes.func,
  setCardNumber: PropTypes.func,
  setPatient: PropTypes.func,
  setStep1Patient: PropTypes.func,
  disableForm: PropTypes.bool,
  demandExtras: PropTypes.object,
  setDemandExtras: PropTypes.func,
  ficherosAdjuntos: PropTypes.array,
  setFicherosAdjuntos: PropTypes.func,
  eliminarFicherosAdjuntos: PropTypes.array,
  setEliminarFicherosAdjuntos: PropTypes.func,
  status: PropTypes.string,
};

export default SearchPatientForm;
