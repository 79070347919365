import { ROLES } from 'src/constants/roles';

export const isAssistant = (state) => state.user.user.role.permissions === ROLES.ASSISTANT;
export const isDoctor = (state) => state.user.user.role.permissions === ROLES.DOCTOR;
export const isSuperAdmin = (state) => state.user.user.role.permissions === ROLES.SUPERADMIN;
export const isAdmin = (state) => state.user.user.role.permissions === ROLES.ADMIN;
export const isManager = (state) => state.user.user.role.permissions === ROLES.MANAGER;
export const isConsultant = (state) => state.user.user.role.permissions === ROLES.CONSULTANT;
export const isRepresentive = (state) => state.user.user.role.permissions === ROLES.REPRESENTATIVE;
export const isSupervisor = (state) => state.user.user.role.permissions === ROLES.SUPERVISOR;

export const getRoleCurrentUser = (state) => state.user.user?.role.permissions;
