import { Card, CardContent, CardHeader, Container, Divider, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import AlertDialog from 'src/components/AlertDialog';
import Loader from 'src/components/Loader';
import Page from 'src/components/Page';
import { useGoBack } from 'src/hooks/common';
import { useGetDrug, useUpdateDrug } from 'src/hooks/drugs';

import EditDrugForm from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const EditDrugView = () => {
  const classes = useStyles();
  const { t } = useTranslation('drugs');
  const history = useHistory();
  const goBack = useGoBack('/app/drugs');
  const { enqueueSnackbar } = useSnackbar();
  const { drugId } = useParams();
  const { isLoading, data: drug, isError: isErrorGet, error: errorGet } = useGetDrug(drugId);
  const [
    updateDrug,
    { isLoading: isLoadingUpdate, data: dataUpdate, isError: isErrorUpdate, error: errorUpdate },
  ] = useUpdateDrug();

  const initialValues = {
    name: drug?.data?.name,
    activePrinciple: drug?.data?.activePrinciple,
    dosage: drug?.data?.dosage,
    administration: drug?.data?.administration,
  };

  const handleSubmit = async (values) => {
    const drug = await updateDrug({ drug: drugId, data: values });

    if (drug.data) {
      goBack();
    }
  };

  useEffect(() => {
    if (!isLoadingUpdate && !isErrorUpdate && dataUpdate) {
      enqueueSnackbar(t('modals.edit_drug.success.text'), { variant: 'success' });
    }
  }, [dataUpdate, enqueueSnackbar, isErrorUpdate, isLoadingUpdate, t]);

  if (isErrorGet) {
    return (
      <AlertDialog
        title={t('modals.get_drug.error.title')}
        text={errorGet?.message || t('modals.get_drug.error.text')}
        type="error"
        acceptAction={() => history.push('/app/drugs', { replace: true })}
      />
    );
  }

  return (
    <Page className={classes.root}>
      <Container maxWidth="lg">
        <Card>
          <CardHeader
            subheader={t('labels.edit_drug_subheader')}
            title={t('labels.edit_drug_title')}
          />
          <Divider />
          <CardContent>
            {isLoading && <Loader />}

            {!isLoading && !isErrorGet && (
              <EditDrugForm initialValues={initialValues} handleFormSubmit={handleSubmit} />
            )}

            {isErrorUpdate && (
              <AlertDialog
                title={t('modals.edit_drug.error.title')}
                text={errorUpdate?.message || t('modals.edit_drug.error.text')}
                type="error"
              />
            )}
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default EditDrugView;
