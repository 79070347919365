import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CommentCards from './components/CommentCards';

const useStyles = makeStyles({
  card: {
    padding: '0 16px !important',
  },
  empty: {
    margin: 20,
  },
});

const DemandComments = ({ demand, version, onlyLastEntry = false }) => {
  const classes = useStyles();
  const { t } = useTranslation(['demands', 'common']);

  let histories = [];
  let historiesOnlyComments = [];

  if (demand.versions) {
    for (let i = 0; i < demand.versions.length; i++) {
      const version = demand.versions[i];
      if (version.histories) {
        for (let j = 0; j < version.histories.length; j++) {
          const history = version.histories[j];

          histories.push(history);
        }
      }
    }

    if (version) {
      histories = histories.filter((e) => e.createdAt <= version.histories[0]?.createdAt);
    }

    if (onlyLastEntry) {
      histories = [histories[0]];
    }

    historiesOnlyComments = histories.filter((h) => h?.comment || h?.privateComment);
  }

  return (
    <Card>
      <CardHeader
        title={t('demands:labels.viewDemand_histories_comments_title')}
        subheader={t('demands:labels.viewDemand_histories_comments_subtitle')}
      />
      <Divider />
      <CardContent className={classes.card}>
        {historiesOnlyComments.length > 0 ? (
          <CommentCards histories={historiesOnlyComments} />
        ) : (
          <Box className={classes.empty} display="flex" flexDirection="column" alignItems="center">
            <Typography color="secondary" variant="h5">
              {t('labels.viewDemand_empty_text')}
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

DemandComments.propTypes = {
  demand: PropTypes.object,
  version: PropTypes.object,
};

export default DemandComments;
