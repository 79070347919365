import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  card: {
    width: 'fit-content',
  },
  label: {
    marginLeft: '0 !important',
  },
  input: {
    display: 'none',
  },
  avatar: {
    width: 65,
    height: 65,
    margin: '0 auto',
  },
  uploadButton: {
    textAlign: 'center',
  },
}));

const AvatarUpload = ({ avatar, setAvatar, isSubmitting, setAvatarTouched }) => {
  const classes = useStyles();
  const { t } = useTranslation('users');

  const handleImageUpload = (e) => {
    setAvatarTouched(true);

    const reader = new FileReader();
    const file = e.target.files[0];

    // Callback for load event
    reader.onloadend = () => {
      // Returns image in base64
      setAvatar(reader.result);
    };

    // Read image
    reader.readAsDataURL(file);
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <Avatar className={classes.avatar} src={avatar} />
      </CardContent>
      <Divider />
      <CardActions>
        <input
          className={classes.input}
          id="avatar-input"
          onChange={handleImageUpload}
          disabled={isSubmitting}
          accept="image/*"
          type="file"
        />
        <label className={classes.label} htmlFor="avatar-input">
          <Button
            className={classes.uploadButton}
            variant="text"
            color="primary"
            component="span"
            disabled={isSubmitting}
          >
            {t('buttons.avatar')}
          </Button>
        </label>
      </CardActions>
    </Card>
  );
};

AvatarUpload.propTypes = {
  avatar: PropTypes.string,
  setAvatar: PropTypes.func,
  isSubmitting: PropTypes.bool,
  setAvatarTouched: PropTypes.func,
};

export default AvatarUpload;
