import { Card, CardContent, CardHeader, Container, Divider, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import AlertDialog from 'src/components/AlertDialog';
import Loader from 'src/components/Loader';
import Page from 'src/components/Page';
import CreateEditPresentationForm from 'src/components/Presentations/CreateEditPresentationForm';
import { useGoBack } from 'src/hooks/common';
import { useGetPresentation, useUpdatePresentation } from 'src/hooks/presentations';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const EditPresentationView = () => {
  const { t } = useTranslation('presentations');
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const goBack = useGoBack('/app/presentations');
  const [
    updatePresentation,
    { isLoading: isLoadingUpdate, data: updatedData, isError: isErrorUpdate, error: errorUpdate },
  ] = useUpdatePresentation();
  const history = useHistory();
  const { presentationId } = useParams();
  const {
    isLoading: isLoadingPresentation,
    data: presentation,
    isError: isErrorPresentation,
    error: errorPresentation,
  } = useGetPresentation(presentationId);

  const handleSubmit = async ({ presentation, values }) => {
    const res = await updatePresentation({ presentation: presentation.data.id, data: values });

    if (res) {
      goBack();
    }
  };

  const handleCancel = () => {
    history.push('/app/presentations', { replace: true });
  };

  useEffect(() => {
    if (!isLoadingUpdate && !isErrorUpdate && updatedData) {
      enqueueSnackbar(t('modals.success.text_update_presentation'), { variant: 'success' });
    }
  }, [enqueueSnackbar, isErrorUpdate, isLoadingUpdate, t, updatedData]);

  if (isErrorPresentation) {
    return (
      <AlertDialog
        title={t('modals.error.title_get_presentation')}
        text={errorPresentation?.message || t('modals.error.text_get_presentation')}
        type="error"
        acceptAction={() => history.push('/app/presentations', { replace: true })}
      />
    );
  }

  return (
    <Page className={classes.root}>
      <Container maxWidth="lg">
        <Card>
          <CardHeader
            subheader={t('labels.updatePresentation_subtitle')}
            title={t('labels.updatePresentation_title')}
          />
          <Divider />
          <CardContent>
            {isLoadingPresentation && <Loader />}

            {!isLoadingPresentation && (
              <CreateEditPresentationForm
                handleFormSubmit={handleSubmit}
                handleCancel={handleCancel}
                presentation={presentation}
                action="edit"
              />
            )}

            {isErrorUpdate && (
              <AlertDialog
                title={t('modals.error.title_update_presentation')}
                text={errorUpdate?.message || t('modals.error.text_update_presentation')}
                type="error"
              />
            )}
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default EditPresentationView;
