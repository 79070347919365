import { Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import PatientDetails from './components/PatientDetails';
import { useHistory, useParams } from 'react-router-dom';
import Loader from 'src/components/Loader';
import { useTranslation } from 'react-i18next';
import { useGetPatient } from 'src/hooks/patients';
import { useGetCompany } from 'src/hooks/company';
import AlertDialog from 'src/components/AlertDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const DetailsPatientView = () => {
  const classes = useStyles();
  const { t } = useTranslation('patients');
  const history = useHistory();
  const { patientId } = useParams();
  const { isLoading, data: patient, isError, error } = useGetPatient(patientId);
  const {
    isLoading: isLoadingCompany,
    data: company,
    isError: isErrorCompany,
    error: errorCompany,
  } = useGetCompany(patient?.data.companyId, null, !isLoading && !isError);

  if (isError || isErrorCompany) {
    return (
      <AlertDialog
        title={t('modals.error.title_get_patient')}
        text={error?.message || errorCompany?.message || t('modals.error.text_get_patient')}
        type="error"
        acceptAction={() => history.push('/app/patients', { replace: true })}
      />
    );
  }

  return (
    <Page className={classes.root}>
      {(isLoading || isLoadingCompany) && <Loader />}

      {!isLoading && !isLoadingCompany && (
        <Container maxWidth="lg">
          <PatientDetails patient={patient.data} company={company.data} />
        </Container>
      )}
    </Page>
  );
};

export default DetailsPatientView;
