import { useQuery } from 'react-query';
import { getInvitationUse } from 'src/services/api/invitations';

function useGetInvitationUse(token) {
  return useQuery(['useInvitation', token], (key, token) => getInvitationUse(token), {
    enabled: token,
    cacheTime: 5 * 60 * 1000,
  });
}

export default useGetInvitationUse;
