import { Box, Container, LinearProgress, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Page from 'src/components/Page';
import { useListDemands } from 'src/hooks/demands';
import { usePagination } from 'src/hooks/pagination';
import { useGetRoles } from 'src/hooks/roles';
import { setCenterEntity, setDoctorEntity } from 'src/store/reducers/entity';
import { setFilters } from 'src/store/reducers/lists';
import {
  isCenterSelected as isCenterSelectedSelector,
  isCompanySelected as isCompanySelectedSelector,
  isDoctorSelected as isDoctorSelectedSelector,
} from 'src/store/selectors/entity';
import {
  isAssistant as isAssistantSelector,
  isConsultant as isConsultantSelector,
  isManager as isManagerSelector,
  isRepresentive as isRepresentiveSelector,
  isSupervisor as isSupervisorSelector,
} from 'src/store/selectors/user';

import Results from './components/Results';
import Toolbar from './components/Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const ListDemandsView = () => {
  const classes = useStyles();
  const { t } = useTranslation('demands', 'roles');
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const isManager = useSelector(isManagerSelector);
  const isConsultant = useSelector(isConsultantSelector);
  const isRepresentive = useSelector(isRepresentiveSelector);
  const isAssistant = useSelector(isAssistantSelector);
  const isSupervisor = useSelector(isSupervisorSelector);
  const doctorSelected = useSelector(isDoctorSelectedSelector);
  const isCompanySelected = useSelector(isCompanySelectedSelector);
  const centerSelected = useSelector(isCenterSelectedSelector);
  const filters = useSelector((state) => state.lists.demands?.filters);
  const {
    page,
    pageSize,
    setPage,
    setPageSize,
    resetPage,
    fitsInPage,
    navigateToLastPage,
  } = usePagination(filters?.page, filters?.pageSize);

  const [reference, setReference] = useState(filters?.reference || '');
  const [centerPrescriptor, setCenterPrescriptor] = useState(
    centerSelected || filters?.centerPrescriptor || null,
  );
  const [centerRealizator, setCenterRealizator] = useState(filters?.centerRealizator || null);
  const [zone, setZone] = useState(filters?.zone || null);
  const [company, setCompany] = useState(filters?.company || isCompanySelected || null);
  const [doctor, setDoctor] = useState(doctorSelected || filters?.doctor || null);
  const [patient, setPatient] = useState(filters?.patient || null);
  const [status, setStatus] = useState(filters?.status || '');
  const [pendingDemands, setPendingDemands] = useState(
    filters?.pendingDemands || !!isManager || !!isRepresentive || !!isConsultant,
  );
  const [sort, setSort] = useState(filters?.sort || '-lastDemand');

  const { isFetching, resolvedData: demands, isError, refetch } = useListDemands({
    page,
    pageSize,
    centerPrescriptorId: centerPrescriptor?.id,
    centerRealizatorId: centerRealizator?.id,
    zoneId: zone?.id,
    companyId: company?.id,
    doctorId: doctor?.id,
    patientId: patient?.id,
    reference,
    status,
    pendingDemands: pendingDemands || undefined,
    sort,
    include:
      'centerPrescriptor,centerRealizator,patient,company,doctor,radiotherapyTreatments,chemotherapySessions',
  });
  const rolesQuery = useGetRoles({ all: true });

  if (demands?.data.count && !fitsInPage(demands.data.count)) {
    navigateToLastPage(demands.data.count);
  }

  useEffect(() => {
    if (doctorSelected) {
      setDoctor(doctorSelected);
    }
  }, [doctorSelected]);

  useEffect(() => {
    if (centerSelected) {
      setCenterPrescriptor(centerSelected);
    }
  }, [centerSelected]);

  useEffect(() => {
    if (isCompanySelected) {
      setCompany(isCompanySelected);
    }
  }, [isCompanySelected]);

  useEffect(() => {
    if (isAssistant || isSupervisor) {
      dispatch(setDoctorEntity({ doctor }));
    }
  }, [dispatch, doctor, isAssistant, isSupervisor]);

  useEffect(() => {
    if (isAssistant || isSupervisor) {
      dispatch(setCenterEntity({ center: centerPrescriptor }));
    }
  }, [centerPrescriptor, dispatch, isAssistant, isSupervisor]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(t('modals.error.title_list_demands'), { variant: 'error' });
    }
  }, [enqueueSnackbar, isError, t]);

  useEffect(() => {
    if (rolesQuery.isError) {
      enqueueSnackbar(t('roles:modals.error.title_get_roles'), { variant: 'error' });
    }
  }, [enqueueSnackbar, rolesQuery.isError, t]);

  useEffect(() => {
    dispatch(
      setFilters({
        list: 'demands',
        filters: {
          page,
          pageSize,
          centerPrescriptor,
          centerRealizator,
          zone,
          company,
          doctor,
          patient,
          reference,
          status,
          pendingDemands,
          sort,
        },
      }),
    );
  }, [
    centerPrescriptor,
    centerRealizator,
    company,
    dispatch,
    doctor,
    page,
    pageSize,
    patient,
    reference,
    sort,
    status,
    pendingDemands,
    zone,
  ]);

  if ((isManager && !isCompanySelected) || (isAssistant && !doctorSelected)) {
    return (
      <Redirect
        to={{
          pathname: '/app/home',
          state: { referrer: 'demands' },
        }}
      />
    );
  }

  return (
    <Page className={classes.root}>
      <Container maxWidth={false}>
        <Toolbar
          resetPage={resetPage}
          reference={reference}
          centerPrescriptor={centerPrescriptor}
          centerRealizator={centerRealizator}
          zone={zone}
          company={company}
          doctor={doctor}
          patient={patient}
          status={status}
          pendingDemands={pendingDemands}
          setReference={setReference}
          setCenterPrescriptor={setCenterPrescriptor}
          setCenterRealizator={setCenterRealizator}
          setZone={setZone}
          setCompany={setCompany}
          setDoctor={setDoctor}
          setPatient={setPatient}
          setStatus={setStatus}
          setPendingDemands={setPendingDemands}
          rolesQuery={rolesQuery}
        />

        <Box mt={3}>
          {isFetching && <LinearProgress color="primary" />}

          <Results
            demands={demands?.data}
            roles={rolesQuery.data?.data?.rows}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            resetPage={resetPage}
            sort={sort}
            setSort={setSort}
            isLoading={isFetching}
            refetchDemands={refetch}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default ListDemandsView;
