import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  TextField,
  InputAdornment,
  SvgIcon,
  Grid,
  Box,
  Card,
  CardContent,
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import { STATUS } from 'src/constants/invitations';

const Container = ({ children }) => {
  return (
    <Box mt={3}>
      <Card>
        <CardContent>{children}</CardContent>
      </Card>
    </Box>
  );
};

const ToolbarFilters = ({ resetPage, email, setEmail, status, setStatus }) => {
  const { t } = useTranslation('invitations');
  const [emailValue, setEmailValue] = useState(email);
  const [emailDebouncedValue] = useDebounce(emailValue, 500);

  const handleReset = () => {
    resetPage();
    setEmail('');
    setEmailValue('');
    setStatus('');
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
    resetPage();
  };

  useEffect(() => {
    setEmail(emailDebouncedValue);
    resetPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailDebouncedValue, setEmail]);

  return (
    <Container>
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs={12} sm={8} md={7}>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="primary">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            placeholder={t('fields.search')}
            value={emailValue}
            onBlur={(e) => setEmail(e.target.value)}
            onChange={(e) => {
              setEmailValue(e.target.value);
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <TextField
            fullWidth
            select
            name="status"
            label={t('fields.status.name')}
            SelectProps={{ native: true }}
            value={status}
            onChange={handleStatusChange}
            variant="outlined"
          >
            <option value="" hidden />
            <option value={STATUS.PENDING}>{t('fields.status.PENDING')}</option>
            <option value={STATUS.ACCEPTED}>{t('fields.status.ACCEPTED')}</option>
            <option value={STATUS.REJECTED}>{t('fields.status.REJECTED')}</option>
            <option value={STATUS.USED}>{t('fields.status.USED')}</option>
          </TextField>
        </Grid>

        <Grid item xs={12} md={2}>
          <Button fullWidth color="primary" variant="contained" onClick={handleReset}>
            {t('buttons.invitations_list_clear_filters')}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

ToolbarFilters.propTypes = {
  resetPage: PropTypes.func,
  email: PropTypes.string,
  setEmail: PropTypes.func,
  status: PropTypes.string,
  setStatus: PropTypes.func,
};

export default ToolbarFilters;
