import { PropTypes } from 'prop-types';
import { IconButton } from '@material-ui/core';
import {
  ArrowRightCircle as ArrowRightCircleIcon,
  Calendar as CalendarIcon,
  Copy as CopyIcon,
  Edit as EditIcon,
  Eye as EyeIcon,
  Settings as SettingsIcon,
  Trash as TrashIcon,
  Paperclip as PaperclipIcon,
  XCircle as XCircleIcon,
  PlusCircle as PlusIcon,
  CheckCircle as CheckIcon,
} from 'react-feather';
import { STATUS, STATUS_FILTER } from 'src/constants/demands';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const DemandsActionsButtons = ({
  demand,
  isLoading,
  isDoctor,
  isAssistant,
  isManager,
  isAdmin,
  isSuperAdmin,
  listActions,
  displayContinueAction,
  displayChangeDatesAction,
  displayDuplicateAction,
  displayEditAction,
  displayFinishAction,
  displayManageModal,
  displayCancelModal,
  setManageDemandModalOpen,
  setChangeDatesModalOpen,
  setDuplicateModalOpen,
  setDuplicateAllModalOpen,
  setDeleteModalOpen,
  setCancelModalOpen,
  setIncreaseCyclesModalOpen,
  setFinishModalOpen,
  setUploadAttachmentModalOpen
}) => {
  const { t } = useTranslation('demands');

  return (
    <>
      {displayEditAction(demand) && (
        <IconButton
          color="primary"
          value={demand.id}
          component={RouterLink}
          to={`/app/demands/${demand.id}/edit`}
          title={t('labels.edit_demand')}
          disabled={isLoading}
        >
          <EditIcon />
        </IconButton>
      )}
      {(isDoctor || isAssistant) && displayChangeDatesAction(demand) && (
        <IconButton
          color="primary"
          onClick={() => setChangeDatesModalOpen(true)}
          title={t('labels.change_dates_demand')}
          disabled={isLoading}
        >
          <CalendarIcon />
        </IconButton>
      )}
      {(isDoctor || isAssistant) &&
        demand.tratamientoQuimioterapico &&
        demand.displayStatus === STATUS_FILTER.SENDED_ACCEPTED &&
        demand.quimioterapicoCiclo === demand.quimioterapicoNumeroCiclos && (
          <IconButton
            color="primary"
            onClick={() => setIncreaseCyclesModalOpen(true)}
            title={t('labels.increase_cycles')}
            disabled={isLoading}
          >
            <PlusIcon />
          </IconButton>
        )}
      {(isDoctor || isAssistant) && displayContinueAction(demand) && (
        <IconButton
          color="primary"
          value={demand.id}
          component={RouterLink}
          to={`/app/demands/${demand.id}/edit`}
          title={t('labels.continue_demand')}
          disabled={isLoading}
        >
          <ArrowRightCircleIcon />
        </IconButton>
      )}
      {(isDoctor || isAssistant) && displayDuplicateAction(demand) && (
        <IconButton
          color="primary"
          value={demand.uuid}
          title={t('labels.duplicate_demand')}
          onClick={() => setDuplicateModalOpen(true)}
          disabled={isLoading}
        >
          <CopyIcon />
        </IconButton>
      )}
      {(isDoctor || isAssistant) && false && displayDuplicateAction(demand) && (
        <IconButton
          color="primary"
          value={demand.uuid}
          title={t('labels.duplicate_all_demand')}
          onClick={() => setDuplicateAllModalOpen(true)}
          disabled={isLoading}
        >
          <CopyIcon />
        </IconButton>
      )}
      {listActions && (
        <IconButton
          color="primary"
          value={demand.id}
          component={RouterLink}
          to={`/app/demands/${demand.id}`}
          title={t('labels.view_details')}
          disabled={isLoading}
        >
          <EyeIcon />
        </IconButton>
      )}
      {(isDoctor || isAssistant) && demand.status === STATUS.DRAFT && (
        <IconButton
          color="primary"
          onClick={() => setDeleteModalOpen(true)}
          title={t('labels.delete_demand')}
          disabled={isLoading}
        >
          <TrashIcon />
        </IconButton>
      )}
      {displayManageModal(demand) && (
        <IconButton
          color="primary"
          onClick={() => setManageDemandModalOpen(true)}
          title={t('labels.manage_demand')}
          disabled={isLoading}
        >
          <SettingsIcon />
        </IconButton>
      )}
      {displayCancelModal(demand) && (
        <IconButton
          color="primary"
          onClick={() => setCancelModalOpen(true)}
          title={t('labels.cancel_demand')}
          disabled={isLoading}
        >
          <XCircleIcon />
        </IconButton>
      )}

      {displayFinishAction(demand) && (
        <IconButton
          color="primary"
          onClick={() => setFinishModalOpen(true)}
          title={t('labels.finish_demand')}
          disabled={isLoading}
        >
          <CheckIcon />
        </IconButton>
      )}

      {(isManager) && (
        <IconButton
          color="primary"
          onClick={() => setUploadAttachmentModalOpen(true)}
          title={t('labels.upload_file')}
          disabled={isLoading}
        >
          <PaperclipIcon />
        </IconButton>
      )}
    </>
  );
};

DemandsActionsButtons.propTypes = {
  demand: PropTypes.object,
  isLoading: PropTypes.bool,
  isDoctor: PropTypes.bool,
  isAssistant: PropTypes.bool,
  isManager: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  listActions: PropTypes.bool,
  displayContinueAction: PropTypes.func,
  displayChangeDatesAction: PropTypes.func,
  displayDuplicateAction: PropTypes.func,
  displayEditAction: PropTypes.func,
  displayFinishAction: PropTypes.func,
  displayManageModal: PropTypes.func,
  displayCancelModal: PropTypes.func,
  setManageDemandModalOpen: PropTypes.func,
  setChangeDatesModalOpen: PropTypes.func,
  setDuplicateModalOpen: PropTypes.func,
  setDeleteModalOpen: PropTypes.func,
  setCancelModalOpen: PropTypes.func,
  setIncreaseCyclesModalOpen: PropTypes.func,
  setFinishModalOpen: PropTypes.func,
  setUploadAttachmentModalOpen: PropTypes.func,
};

export default DemandsActionsButtons;
