import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  FormHelperText,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { FieldArray } from 'formik';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import { useSnackbar } from 'notistack';
import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import { MinusCircle as MinusCircleIcon, PlusCircle as PlusCircleIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';
import DrugLetter from 'src/components/Demands/DrugLetter';
import { ADMINISTRATION, DOSAGE } from 'src/constants/drugs';
import useGetAllPresentations from 'src/hooks/presentations/useGetAllPresentations';
import useGetDrugCatalog from 'src/hooks/catalogs/useGetDrugCatalog';
import useListDrugsCatalog from 'src/hooks/catalogs/useListDrugsCatalog';
import { generateLetterFromIndex } from 'src/utils/demands';

const useStyles = makeStyles((theme) => ({
  cardMargin: {
    margin: theme.spacing(2),
    marginTop: 0,
  },
  iconMargin: {
    marginRight: theme.spacing(1),
  },
  removeDrugButton: {
    marginLeft: 'auto',
    color: theme.palette.error.main,
  },
  drugsError: {
    color: theme.palette.error.main,
  },
  highlight: {
    color: '#ea5e76',
  },
}));

const EMPTY_DRUG = {
  drugId: '',
  dosificacion: '',
  tipoDosificacion: '',
  formaAdministracion: '',
  administrarEnSesiones: [],
  price: '0',
  amount: 1,
  presentationId: '',
};

const DrugsField = ({
  values,
  errors,
  touched,
  handleBlur,
  setFieldTouched,
  handleChange,
  setFieldValue,
  disabled,
  isLoading,
  drugs,
  refetchDrugs,
  isError,
  DRUGS_LETTERS,
  isContinue,
  isChangeDates,
  drugsTreatmentActive,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['demands', 'drugs']);

  const [drugsAndPresentations, setDrugsAndPresentations] = useState({});
  const [filterDrugsAndPresentations, setFilterDrugsAndPresentations] = useState([]);
  const { data: presentations, isLoading: isLoadingPresentations, refetch: refetchPresentations } = useGetAllPresentations();
  
  const [filterDrugs, setFilterDrugs] = useState('');
  const { data: optionsDrugs, isLoading: isLoadingDrugs, refetch: refetchCatalogDrugs } = useListDrugsCatalog({ q: filterDrugs},drugs);
  
  
  const [inProcessDrugIndex, setInProcessDrugIndex] = useState();
  const [selectedDrugId, setSelectedDrugId] = useState();
  const [selectedDrugType, setSelectedDrugType] = useState();
  const [selectedDrug, setSelectedDrug] = useState();
  const { data: selectedDrugFromVademecum, refetch: refetchLoadDrugById } = useGetDrugCatalog(selectedDrugId, {type: selectedDrugType});

  const sessions = [];

  for (let i = 1; i <= values.quimioterapicoNumeroSesionesCiclo; i++) {
    sessions.push(i);
  }

  const getDrug = (drugId) => {
    return drugs?.rows?.find(({ id }) => drugId === id);
  };

  const getPresentation = (presentationId) => {
    return presentations?.data?.rows?.find((p) => p.id === presentationId);
  };

  const canRemoveFarmaco = (drug) => {
    let cantRemove = isContinue || isChangeDates;

    if (cantRemove) {
      const numSessions = drug?.administrarEnSesiones.length;
      let res = 0;
      for (let i = 0; i <= numSessions; i++) {
        const session = drug?.administrarEnSesiones[i];

        for (let j = 0; j <= values?.quimioterapicoFarmacos.length; j++) {
          const d = values?.quimioterapicoFarmacos[j];
          if (d?.drugId !== drug?.drugId) {
            const repeat = d?.administrarEnSesiones?.findIndex((s) => s === session);
            if (repeat >= 0) {
              res++;
            }
          }
          if (res >= numSessions) {
            cantRemove = false;
          }
        }
      }
    }

    return cantRemove;
  };

  const handleFilterDrugs = async (index, drugId) => {
    let arr = filterDrugsAndPresentations;
    console.log("ANTES");
    console.log(filterDrugsAndPresentations);
    arr.splice(index, 1, drugsAndPresentations[drugId]);
    console.log('Presentaciones disponibles para drugId: ', arr);

    

    setFilterDrugsAndPresentations(arr);
    console.log("DESPUES");
    console.log(filterDrugsAndPresentations);
    console.log(filterDrugsAndPresentations.length);

    console.log("INDICE: "+index);
  };

  useEffect(() => {
    if (presentations && drugs) {
      let res = {};

      drugs.rows.forEach((d) => {
        res[d.id] = presentations.data.rows.filter((p) => p.drugId === d.id && p.active);
      });
      setDrugsAndPresentations(res);
    }
  }, [presentations, drugs]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(t('drugs:modals.list_drugs.error.title'), { variant: 'error' });
    }
  }, [enqueueSnackbar, isError, t]);

  useEffect(() => {
    setTimeout(() => {
    if (
      Object.keys(drugsAndPresentations).length > 0 &&
      values?.quimioterapicoFarmacos?.length > 0
    ) {
      values?.quimioterapicoFarmacos?.forEach((f, index) => {
        const drugId = f.drugId;

        let arr = filterDrugsAndPresentations;
        console.log(index);
        arr.splice(index, 1, drugsAndPresentations[drugId]);
        console.log("ENTRA a recargar");
        console.log(drugsAndPresentations[drugId]);
        
        console.log(drugsAndPresentations);
        console.log("ID DRUG:")
        console.log(drugId);
        setFilterDrugsAndPresentations(arr);
      });
    }
    }, 1000);
    // eslint-disable-next-line
  }, [drugsAndPresentations, values]);

  useEffect(() => {
    if (true || filterDrugs?.length >= 3 ) { 
      setTimeout(()=>refetchCatalogDrugs(), 1000);     
    }
  }, [filterDrugs]);

  useEffect(() => {
    console.log("SE SELECCIONA DRUG");
    console.log(selectedDrug);
    if (selectedDrug) {
      let selectedDrugTemp = selectedDrug;
      if(selectedDrug.data != undefined){
        selectedDrugTemp = selectedDrug.data;
      }  
      setFieldValue(`quimioterapicoFarmacos[${inProcessDrugIndex}].drugId`, selectedDrugTemp.id);
      setFieldValue(`quimioterapicoFarmacos[${inProcessDrugIndex}].tipoDosificacion`, selectedDrugTemp.dosage);
      setFieldValue(`quimioterapicoFarmacos[${inProcessDrugIndex}].formaAdministracion`, selectedDrugTemp.administration);
      setFieldValue(`quimioterapicoFarmacos[${inProcessDrugIndex}].presentationId`, '');
    }
  }, [selectedDrug]);

  useEffect(() => {
    if (selectedDrugFromVademecum && selectedDrugFromVademecum.data) {
      setSelectedDrug(selectedDrugFromVademecum);
      refetchDrugs();        
      refetchPresentations();
      console.log("DrugId obtenido desde load-drug: ",  selectedDrug?.data?.id);
      setTimeout(()=>handleFilterDrugs(indexLoad, selectedDrug?.data?.id),1000);      
    }
    
  }, [selectedDrugFromVademecum]);
  
  useEffect(() => {
    if (selectedDrugId) { 
      refetchLoadDrugById();
    }
  }, [selectedDrugId]);

  let indexLoad;
  
  const loadDrug = async (index, drug) => {
    console.log("Drug seleccionado: ", drug);
    if(drug) {
      setInProcessDrugIndex(index);
      if(drug.code) {
        console.log("DrugId seleccionado desde vademecum: ",  drug.id);
        setSelectedDrugType(drug.type);
        setSelectedDrugId(drug.id);
        indexLoad = index;
        refetchLoadDrugById();
        
      } else {
        console.log("No se obtiene drug de vademecum, sino de memoria: ", drug.id);
        setSelectedDrug(drugs?.rows?.find(({ id }) => drug.id === id));
        handleFilterDrugs(index, drug.id);
      }
    }    
  };

  return (
    <Card>
      <CardHeader
        disableTypography
        title={
          <>
            <Typography>{t('fields.quimioterapicoFarmacos')}</Typography>

            {!drugsTreatmentActive &&
              touched?.quimioterapicoFarmacos &&
              typeof errors?.quimioterapicoFarmacos === 'string' && (
                <FormHelperText error={true}>{errors.quimioterapicoFarmacos}</FormHelperText>
              )}
          </>
        }
      />

      <CardContent>
        <FieldArray
          name="quimioterapicoFarmacos"
          render={(arrayHelpers) => (
            <>
              {values.quimioterapicoFarmacos.map((drug, index) => (
                <Card className={classes.cardMargin} key={index}>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs={2} sm={1}>
                        <DrugLetter letter={generateLetterFromIndex(index)} />
                      </Grid>
                      <Grid item xs={10} sm={11}>
                        <Autocomplete
                            openOnFocus={false}
                            selectOnFocus={false}
                            freeSolo={true}
                            disableClearable
                            options={optionsDrugs?.data || []}
                            onInputChange={(e, value) => {
                              setFilterDrugs(value)
                            }}
                            getOptionLabel={(option) => option?.name || ""}
                            loading={isLoadingDrugs}
                            name={`quimioterapicoFarmacos[${index}].drugId`}
                            value={ getDrug(drug.drugId) || null}
                            onChange={(e, value) => {
                              loadDrug(index, value);
                            }}
                            onBlur={() =>
                              setFieldTouched(`quimioterapicoFarmacos[${index}].drugId`, true)
                            }
                            renderOption={(props) => {
                              const { name } = props;
                              return (
                                <p>
                                  {name}
                                </p>
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t('fields.quimioterapicoFarmacos_labels.farmaco')}
                                variant="outlined"
                                required
                                error={Boolean(
                                  get(touched, `quimioterapicoFarmacos[${index}].drugId`) &&
                                    get(errors, `quimioterapicoFarmacos[${index}].drugId`),
                                )}
                                helperText={
                                  get(touched, `quimioterapicoFarmacos[${index}].drugId`) &&
                                  get(errors, `quimioterapicoFarmacos[${index}].drugId`)
                                }
                                inputProps={{
                                  ...params.inputProps,
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {isLoading ? (
                                        <CircularProgress color="inherit" size={20} />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </>
                                  ),
                                }}
                              />
                            )}
                          />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          type="number"
                          inputProps={{ min: 0, step: '.01' }}
                          error={Boolean(
                            get(touched, `quimioterapicoFarmacos[${index}].dosificacion`) &&
                              get(errors, `quimioterapicoFarmacos[${index}].dosificacion`),
                          )}
                          helperText={
                            get(touched, `quimioterapicoFarmacos[${index}].dosificacion`) &&
                            get(errors, `quimioterapicoFarmacos[${index}].dosificacion`)
                          }
                          fullWidth
                          required
                          label={t('fields.quimioterapicoFarmacos_labels.dosificacion')}
                          name={`quimioterapicoFarmacos[${index}].dosificacion`}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          disabled={disabled || isContinue || isChangeDates}
                          value={drug.dosificacion}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          select
                          error={Boolean(
                            get(touched, `quimioterapicoFarmacos[${index}].tipoDosificacion`) &&
                              get(errors, `quimioterapicoFarmacos[${index}].tipoDosificacion`),
                          )}
                          helperText={
                            get(touched, `quimioterapicoFarmacos[${index}].tipoDosificacion`) &&
                            get(errors, `quimioterapicoFarmacos[${index}].tipoDosificacion`)
                          }
                          required
                          label={t('fields.quimioterapicoFarmacos_labels.tipoDosificacion')}
                          name={`quimioterapicoFarmacos[${index}].tipoDosificacion`}
                          onBlur={handleBlur}
                          SelectProps={{ native: true }}
                          onChange={handleChange}
                          disabled={disabled || isContinue || isChangeDates}
                          value={drug.tipoDosificacion}
                          variant="outlined"
                        >
                          <option value="" hidden />          
                          <option value={DOSAGE.MGM2}>{DOSAGE.MGM2}</option>
                          <option value={DOSAGE.MGKG}>{DOSAGE.MGKG}</option>
                          <option value={DOSAGE.AUC}>{DOSAGE.AUC}</option>
                          <option value={DOSAGE.MG}>{DOSAGE.MG}</option>
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Autocomplete
                          openOnFocus
                          disableCloseOnSelect
                          options={sessions}
                          getOptionLabel={(option) => String(option)}
                          getOptionSelected={(option, value) => option === value}
                          multiple
                          value={drug.administrarEnSesiones}
                          name={`quimioterapicoFarmacos[${index}].administrarEnSesiones`}
                          disabled={disabled || isContinue || isChangeDates}
                          onChange={(e, value) => {
                            setFieldValue(
                              `quimioterapicoFarmacos[${index}].administrarEnSesiones`,
                              sortBy(value),
                            );
                          }}
                          onBlur={() =>
                            setFieldTouched(
                              `quimioterapicoFarmacos[${index}].administrarEnSesiones`,
                              true,
                            )
                          }
                          renderOption={(option, { selected }) => (
                            <>
                              <Checkbox
                                className={classes.iconMargin}
                                checked={selected}
                                size="small"
                              />
                              {option}
                            </>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t(
                                'fields.quimioterapicoFarmacos_labels.administrarEnSesiones',
                              )}
                              variant="outlined"
                              required
                              error={Boolean(
                                get(
                                  touched,
                                  `quimioterapicoFarmacos[${index}].administrarEnSesiones`,
                                ) &&
                                  get(
                                    errors,
                                    `quimioterapicoFarmacos[${index}].administrarEnSesiones`,
                                  ),
                              )}
                              helperText={
                                get(
                                  touched,
                                  `quimioterapicoFarmacos[${index}].administrarEnSesiones`,
                                ) &&
                                get(
                                  errors,
                                  `quimioterapicoFarmacos[${index}].administrarEnSesiones`,
                                )
                              }
                              InputProps={{
                                ...params.InputProps,
                                readOnly: true,
                              }}
                            />
                          )}
                        />
                      </Grid>
                    {/*
                      <Grid item xs={12} sm={6}>
                        <TextField
                          type="number"
                          inputProps={{ min: 0, step: '.01' }}
                          error={Boolean(
                            get(touched, `quimioterapicoFarmacos[${index}].price`) &&
                              get(errors, `quimioterapicoFarmacos[${index}].price`),
                          )}
                          helperText={
                            get(touched, `quimioterapicoFarmacos[${index}].price`) &&
                            get(errors, `quimioterapicoFarmacos[${index}].price`)
                          }
                          fullWidth
                          label={t('fields.quimioterapicoFarmacos_labels.price')}
                          name={`quimioterapicoFarmacos[${index}].price`}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          disabled={disabled || isContinue || isChangeDates}
                          value={drug.price || 0}
                          variant="outlined"
                        />
                      </Grid>
                        

                      <Grid item xs={12} sm={6}>
                        <TextField
                          type="number"
                          inputProps={{ min: 0, step: '1' }}
                          error={Boolean(
                            get(touched, `quimioterapicoFarmacos[${index}].amount`) &&
                              get(errors, `quimioterapicoFarmacos[${index}].amount`),
                          )}
                          helperText={
                            get(touched, `quimioterapicoFarmacos[${index}].amount`) &&
                            get(errors, `quimioterapicoFarmacos[${index}].amount`)
                          }
                          fullWidth
                          required
                          label={t('fields.quimioterapicoFarmacos_labels.amount')}
                          name={`quimioterapicoFarmacos[${index}].amount`}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          disabled={disabled || isContinue || isChangeDates}
                          value={drug.amount}
                          variant="outlined"
                        />
                      </Grid>
                      */}

                      <Grid item md={12} xs={12}>
                        <Autocomplete
                          openOnFocus={true}
                          selectOnFocus={false}
                          options={filterDrugsAndPresentations[index] || []}
                          getOptionLabel={(option) => option.presentation}
                          getOptionSelected={(option, value) => option.id === value.id}
                          loading={isLoadingPresentations}
                          name={`quimioterapicoFarmacos[${index}].presentationId`}
                         // disabled={disabled || isContinue || isChangeDates}
                          value={getPresentation(drug.presentationId) || null}
                          onChange={(e, value) =>
                            setFieldValue(
                              `quimioterapicoFarmacos[${index}].presentationId`,
                              value.id,
                            )
                          }
                          onBlur={() =>
                            setFieldTouched(`quimioterapicoFarmacos[${index}].presentationId`, true)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t('fields.quimioterapicoFarmacos_labels.presentation')}
                              variant="outlined"
                              required
                              error={Boolean(touched.presentationId && errors.presentationId)}
                              helperText={touched.presentationId && errors.presentationId}
                              inputProps={{
                                ...params.inputProps,
                                style: { cursor: 'pointer' },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {isLoadingPresentations ? (
                                      <CircularProgress color="inherit" size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                ),
                              }}
                            />
                          )}
                        />
                      </Grid>

                      <Button
                        className={classes.removeDrugButton}
                        size="small"
                        onClick={() => {
                          arrayHelpers.remove(index);
                          if (values.quimioterapicoFarmacos?.length === 1) {
                            setTimeout(() => {
                              setFieldTouched('quimioterapicoFarmacos', true);
                            }, 0);
                          }
                        }}
                        disabled={disabled || canRemoveFarmaco(drug)}
                      >
                        <MinusCircleIcon className={classes.iconMargin} size={18} />
                        {t('buttons.remove_drug')}
                      </Button>
                    </Grid>
                  </CardContent>
                </Card>
              ))}

              <Button
                onClick={() => {
                  arrayHelpers.push(EMPTY_DRUG);
                  if (!values.quimioterapicoFarmacos?.length) {
                    setTimeout(() => {
                      setFieldTouched('quimioterapicoFarmacos', true);
                    }, 0);
                  }
                }}
                disabled={
                  disabled ||
                  isContinue ||
                  isChangeDates
                }
              >
                <PlusCircleIcon className={classes.iconMargin} /> {t('buttons.add_drug')}
              </Button>
            </>
          )}
        />
      </CardContent>
    </Card>
  );
};

DrugsField.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleBlur: PropTypes.func,
  setFieldTouched: PropTypes.func,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  drugs: PropTypes.object,
  refetchDrugs: PropTypes.func,
  isError: PropTypes.bool,
  isContinue: PropTypes.bool,
  isChangeDates: PropTypes.bool,
  DRUGS_LETTERS: PropTypes.array,
  drugsTreatmentActive: PropTypes.bool,
};

export default DrugsField;
