import { Avatar, Box, Card, CardContent, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { getInitials } from 'src/utils/getInitials';
import { getMediaUrl } from 'src/utils/getMediaUrl';

const useStyles = makeStyles(() => ({
  root: {},
  companyDetails: {
    wordBreak: 'break-all',
  },
  media: {
    height: 100,
    width: 100,
    fontSize: 40,
  },
  logo: {
    maxWidth: 200,
    maxHeight: 200,
    marginBottom: 16,
  },
  favicon: {
    maxWidth: 130,
    maxHeight: 130,
  },
}));

const Profile = ({ className, company, media, ...rest }) => {
  const classes = useStyles();
  const companyData = company.data;
  const mediaSrc = getMediaUrl(media === 'logo' ? companyData?.logo?.id : companyData?.favicon?.id);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Box alignItems="center" display="flex" flexDirection="column">
          {mediaSrc && (
            <img
              alt={media === 'logo' ? 'Logo' : 'Favicon'}
              className={clsx(classes[media], className)}
              src={mediaSrc}
            />
          )}
          {!mediaSrc && <Avatar className={classes.media}>{getInitials(companyData.name)}</Avatar>}

          {media === 'logo' && (
            <>
              <Typography color="textPrimary" gutterBottom variant="h3" align="center">
                {companyData.name}
              </Typography>
              <Typography
                className={clsx(classes.companyDetails, className)}
                color="textSecondary"
                variant="body1"
                align="center"
              >
                {companyData.slogan}
              </Typography>
            </>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
  companyData: PropTypes.object,
  media: PropTypes.string,
};

export default Profile;
