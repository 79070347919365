import { Card, CardContent, CardHeader, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import CompanyDetailsForm from './components';

const ProfileDetails = ({ company }) => {
  const { t } = useTranslation('companies');
  const history = useHistory();

  const handleCancel = () => {
    history.push('/app/companies', { replace: true });
  };

  return (
    <Card>
      <CardHeader
        subheader={t('labels.viewCompany_subtitle')}
        title={t('labels.viewCompany_title')}
      />
      <Divider />
      <CardContent>
        <CompanyDetailsForm company={company.data} handleCancel={handleCancel} />
      </CardContent>
    </Card>
  );
};

ProfileDetails.propTypes = {
  company: PropTypes.object,
};

export default ProfileDetails;
