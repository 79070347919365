import { combineReducers } from 'redux';

import appReducer from './app';
import authReducer from './auth';
import entityReducer from './entity';
import userReducer from './user';
import listsReducer from './lists';

export default combineReducers({
  app: appReducer,
  auth: authReducer,
  entity: entityReducer,
  user: userReducer,
  lists: listsReducer,
});
