import PropTypes from 'prop-types';
import { TableCell, TableSortLabel } from '@material-ui/core';

const TableSortCell = ({ handler, sortKey, sort, children }) => {
  return (
    <TableCell>
      <TableSortLabel
        active={sort === sortKey || sort === `-${sortKey}`}
        direction={sort === sortKey ? 'asc' : 'desc'}
        onClick={() => handler(sortKey)}
      >
        {children}
      </TableSortLabel>
    </TableCell>
  );
};

TableSortCell.propTypes = {
  handler: PropTypes.func,
  sortKey: PropTypes.string,
  sort: PropTypes.string,
  label: PropTypes.string,
};

export default TableSortCell;
