import PropTypes from 'prop-types';

import ToolbarActions from './components/ToolbarActions';
import ToolbarFilters from './components/ToolbarFilters';

const Toolbar = ({
  resetPage,
  registerNumber,
  setRegisterNumber,
  nationalCode,
  setNationalCode,
  presentation,
  setPresentation,
}) => {
  return (
    <div>
      <ToolbarActions />

      <ToolbarFilters
        resetPage={resetPage}
        registerNumber={registerNumber}
        setRegisterNumber={setRegisterNumber}
        nationalCode={nationalCode}
        setNationalCode={setNationalCode}
        presentation={presentation}
        setPresentation={setPresentation}
      />
    </div>
  );
};

Toolbar.propTypes = {
  resetPage: PropTypes.func,
  name: PropTypes.string,
  setName: PropTypes.func,
  company: PropTypes.object,
  setCompany: PropTypes.func,
  cardNumber: PropTypes.string,
  setCardNumber: PropTypes.func,
};

export default Toolbar;
