import { Box, Dialog, DialogTitle, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isDoctorSelected } from 'src/store/selectors/entity';
import { isAssistant as isAssistantSelector } from 'src/store/selectors/user';
import IncreaseCyclesForm from './components/IncreaseCyclesForm';

const useStyles = makeStyles({
  dialog: {
    margin: '1.5rem',
  },
});

const initialValues = {
  quimioterapicoNumeroCiclos: '',
};

const IncreaseCyclesModal = ({ cycles, demandId, updateDemandCycles, closeDialog }) => {
  const classes = useStyles();
  const { t } = useTranslation('demands');
  const doctor = useSelector(isDoctorSelected);
  const isAssistant = useSelector(isAssistantSelector);
  const history = useHistory();

  const handleIncreaseCycles = async (values) => {
    const newCycles = cycles + values.quimioterapicoNumeroCiclos;
    const data = {
      quimioterapicoNumeroCiclos: newCycles,
    };

    const response = await updateDemandCycles({
      demand: demandId,
      data,
      doctorId: isAssistant ? doctor?.id : undefined,
    });

    closeDialog();

    if (response) {
      history.push(`/app/demands/${demandId}/edit`);
    }
  };

  return (
    <Dialog open={true}>
      <Box className={classes.dialog} display="flex" flexDirection="column">
        <DialogTitle disableTypography={true}>
          <Typography variant="h4" align="center">
            {t('labels.increase_cycles_modal')}
          </Typography>
        </DialogTitle>
        <Typography variant="h6" align="center">
          {t('labels.cycles_completed', { cycles: cycles })}
        </Typography>

        <IncreaseCyclesForm
          initialValues={initialValues}
          handleIncreaseCycles={handleIncreaseCycles}
          closeDialog={closeDialog}
        />
      </Box>
    </Dialog>
  );
};

IncreaseCyclesModal.propTypes = {
  demandId: PropTypes.number,
  updateDemandCycles: PropTypes.func,
  closeDialog: PropTypes.func,
  cycles: PropTypes.number,
};

export default IncreaseCyclesModal;
