import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import Footer from 'src/layouts/Footer';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingBottom: '0 !important',
  },
});

// eslint-disable-next-line react/display-name
const Page = forwardRef(({ children, footer = true, className, ...rest }, ref) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} ref={ref} {...rest}>
      {children}
      {footer && <Footer />}
    </div>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  footer: PropTypes.bool,
  className: PropTypes.string,
};

export default Page;
