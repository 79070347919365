import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, CardHeader, Container, Divider, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import { useCreateUser } from 'src/hooks/users';
import CreateUserForm from './components';
import { useGoBack } from 'src/hooks/common';
import { useSnackbar } from 'notistack';
import AlertDialog from 'src/components/AlertDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const initialValues = {
  email: '',
  dni: '',
  name: '',
  password: '',
  confirmPassword: '',
  membershipNumber: '',
  role: null,
  companyId: null,
  companies: [],
  zones: [],
  centers: [],
  doctors: [],
  assistants: [],
};

const CreateUserView = () => {
  const { t } = useTranslation('users');
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const goBack = useGoBack('/app/users');
  const [createUser, { isLoading, data, isError, error }] = useCreateUser();

  const handleSubmit = (values) => createUser(values);

  useEffect(() => {
    if (!isLoading && !isError && data) {
      enqueueSnackbar(t('modals.success.text'), { variant: 'success' });

      goBack();
    }
  }, [data, enqueueSnackbar, goBack, isError, isLoading, t]);

  return (
    <Page className={classes.root}>
      <Container maxWidth="lg">
        <Card>
          <CardHeader
            subheader={t('labels.createUser_subtitle')}
            title={t('labels.createUser_title')}
          />
          <Divider />
          <CardContent>
            <CreateUserForm initialValues={initialValues} handleFormSubmit={handleSubmit} />

            {isError && (
              <AlertDialog
                title={t('modals.error.title')}
                text={error?.message || t('modals.error.text')}
                type="error"
              />
            )}
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default CreateUserView;
