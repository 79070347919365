const RESOURCES_TO_LINK = {
  DEMANDS: 'demands',
};

export const getLink = ({ resource, resourceId }) => {
  if (!resource || !resourceId) return;

  // Check if resource is valid
  if (!RESOURCES_TO_LINK.hasOwnProperty(resource)) return;

  return `/app/${RESOURCES_TO_LINK[resource]}/${resourceId}`;
};
