import { useMutation, useQueryCache } from 'react-query';
import { removeDoctors } from 'src/services/api/users';

function useRemoveDoctors() {
  const queryCache = useQueryCache();

  return useMutation(({ user, data }) => removeDoctors(user, data), {
    onSuccess: (data) => queryCache.setQueryData(['user', data.data?.id], data),
  });
}

export default useRemoveDoctors;
