import { Card, CardContent, CardHeader, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DetailsTable from './components';

const InfoDemand = ({ demand, version, roles }) => {
  const { t } = useTranslation(['demands', 'common']);

  return (
    <Card>
      <CardHeader
        title={t('demands:labels.viewDemand_title')}
        subheader={t('demands:labels.viewDemand_subtitle')}
      />
      <Divider />
      <CardContent>
        <DetailsTable demand={demand} version={version} roles={roles?.data?.rows} />
      </CardContent>
    </Card>
  );
};

InfoDemand.propTypes = {
  demand: PropTypes.object,
  version: PropTypes.object,
  roles: PropTypes.object,
};

export default InfoDemand;
