import { useQuery } from 'react-query';
import { getCompanyById } from 'src/services/api/companies';

function useGetCompany(company, params, enabled = true) {
  return useQuery(['company', company], (key, company) => getCompanyById(company, params), {
    enabled,
  });
}

export default useGetCompany;
