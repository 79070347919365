import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Paper,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ADMINISTRATION, DOSAGE } from 'src/constants/drugs';

const DetailsTable = ({ drug }) => {
  const { t } = useTranslation('drugs');

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.name')}</Typography>
            </TableCell>
            <TableCell>{drug.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.activePrinciple')}</Typography>
            </TableCell>
            <TableCell>{drug.activePrinciple}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.dosage')}</Typography>
            </TableCell>
            <TableCell>{t(`names.dosage.${DOSAGE[drug.dosage]}`)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.administration')}</Typography>
            </TableCell>
            <TableCell>
              {t(
                `names.administration.${Object.keys(ADMINISTRATION).find(
                  (key) => ADMINISTRATION[key] === drug.administration,
                )}`,
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

DetailsTable.propTypes = {
  drug: PropTypes.object,
};

export default DetailsTable;
