import { getRequest, postRequest, putRequest } from 'src/utils/http';

import { centersController } from './consts';

export const getCenterById = async (id, params) => getRequest(`${centersController}/${id}`, params);

export const createCenter = async (body) => postRequest(`${centersController}`, body);

export const updateCenter = async (id, data) => putRequest(`${centersController}/${id}`, data);

export const listCenters = async (params) => getRequest(`${centersController}`, params);
