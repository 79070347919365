import {
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { Calendar as CalendarIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';
import EmptyList from 'src/components/EmptyList';
import SessionRow from './SessionRow';

const SessionsField = ({
  edit,
  values,
  errors,
  touched,
  setFieldTouched,
  setFieldValue,
  disabled,
  drugs
}) => {
  const { t } = useTranslation(['demands', 'drugs']);
  const sessions = [];
  const isDetails = !drugs; // True if this component is shown in the demand details. False if is on the wizard

  const useStyles = makeStyles((theme) => ({
    card: {
      paddingTop: 0,
      paddingBottom: '16px !important',
    },
    header: {
      paddingBottom: 4,
    },
    table: {
      '& th': {
        padding: isDetails ? '4px 5px' : '5px',
      },
      '& td': {
        padding: isDetails ? '4px 5px' : '5px',
        color: theme.palette.tertiary.main,
      },
      '& th, & th strong, & td': isDetails
        ? {
            lineHeight: 1,
          }
        : {},
    },
    noShadow: {
      boxShadow: 'none',
    },
  }));
  const classes = useStyles();

  for (let i = 1; i <= values.quimioterapicoNumeroSesionesCiclo; i++) {
    sessions.push(i);
  }

  return (
    <Card className={classes.noShadow}>
      <CardHeader className={classes.header} title={t('fields.sessions_list')} />

      <CardContent className={classes.card}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{t('fields.sessions_list_session')}</TableCell>
              <TableCell>{t('fields.sessions_list_drug')}</TableCell>
              <TableCell>{t('fields.sessions_list_date')}</TableCell>
              <TableCell>{t('fields.sessions_list_graph')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sessions.map((session) => (
              <SessionRow
                key={session}
                edit={edit}
                values={values}
                errors={errors}
                touched={touched}
                session={session}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                disabled={disabled}
                drugs={drugs}
              />
            ))}
          </TableBody>
        </Table>

        {!sessions.length && (
          <EmptyList text={t('labels.empty_list_sessions_text')} textSize="15px">
            <CalendarIcon size={17} />
          </EmptyList>
        )}
      </CardContent>
    </Card>
  );
};

SessionsField.propTypes = {
  edit: PropTypes.bool,
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  disabled: PropTypes.bool,
  drugs: PropTypes.object
};

export default SessionsField;
