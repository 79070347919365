const POLICY_TYPES = {
  LEGAL_NOTICE: 'legal_notice',
  PRIVACY_POLICY: 'privacy_policy',
  TERMS_CONDITIONS: 'terms_conditions',
};

const COMPANY_IDS = {
  FIATC: 2,
};

export { POLICY_TYPES, COMPANY_IDS };
