import { Box, Container, LinearProgress, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Page from 'src/components/Page';
import { usePagination } from 'src/hooks/pagination';
import { useDeletePresentation, useListPresentations } from 'src/hooks/presentations';
import { setFilters } from 'src/store/reducers/lists';
import AlertDialog from 'src/components/AlertDialog';
import Results from './components/Results';
import Toolbar from './components/Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const PresentationsListView = () => {
  const classes = useStyles();
  const { t } = useTranslation('presentations');
  const { enqueueSnackbar } = useSnackbar();
  const [deleteModal, setDeleteModal] = useState({ show: false, presentationId: null });
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.lists.presentations?.filters);
  const {
    page,
    pageSize,
    setPage,
    setPageSize,
    resetPage,
    fitsInPage,
    navigateToLastPage,
  } = usePagination(filters?.page, filters?.pageSize);
  const [
    deletePresentation,
    { isLoading: isLoadingDelete, data: dataDelete, isError: isErrorDelete, error: errorDelete },
  ] = useDeletePresentation();
  const [registerNumber, setRegisterNumber] = useState(filters?.registerNumber || '');
  const [nationalCode, setNationalCode] = useState(filters?.nationalCode || null);
  const [presentation, setPresentation] = useState(filters?.presentation || '');
  const [sort, setSort] = useState(filters?.sort || '');
  const { isFetching, resolvedData: presentations, isError, refetch } = useListPresentations({
    page,
    pageSize,
    sort,
    registerNumber,
    nationalCode,
    presentation,
  });

  if (presentations?.data.count && !fitsInPage(presentations.data.count)) {
    navigateToLastPage(presentations.data.count);
  }

  const handleDeletePresentation = () => {
    setDeleteModal({ show: false, presentationId: null });
    deletePresentation(deleteModal.presentationId);
  };

  useEffect(() => {
    dispatch(
      setFilters({
        list: 'presentations',
        filters: { page, pageSize, sort, registerNumber, nationalCode, presentation },
      }),
    );
  }, [nationalCode, presentation, dispatch, registerNumber, page, pageSize, sort]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(t('modals.error.title_list_presentations'), { variant: 'error' });
    }
  }, [enqueueSnackbar, isError, t]);

  useEffect(() => {
    if (!isLoadingDelete && dataDelete && !isErrorDelete) {
      enqueueSnackbar(t('modals.delete.success_title'), { variant: 'success' });
      refetch();
    }
  }, [dataDelete, enqueueSnackbar, isErrorDelete, isLoadingDelete, refetch, t]);

  return (
    <Page className={classes.root}>
      <Container maxWidth={false}>
        <Toolbar
          resetPage={resetPage}
          registerNumber={registerNumber}
          setRegisterNumber={setRegisterNumber}
          nationalCode={nationalCode}
          setNationalCode={setNationalCode}
          presentation={presentation}
          setPresentation={setPresentation}
        />
        <Box mt={3}>
          {isFetching && <LinearProgress color="primary" />}

          <Results
            presentations={presentations?.data}
            page={page}
            setPage={setPage}
            resetPage={resetPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            sort={sort}
            setSort={setSort}
            isLoading={isFetching}
            deletePresentation={(presentationId) => setDeleteModal({ show: true, presentationId })}
          />

          {deleteModal.show && (
            <AlertDialog
              title={t('modals.delete.title')}
              text={t('modals.delete.subtitle')}
              type="warning"
              showAcceptButton={false}
              showDeleteButton={true}
              deleteAction={handleDeletePresentation}
              showCancelButton={true}
              cancelAction={() => setDeleteModal({ show: false, presentationId: null })}
            />
          )}
          {isErrorDelete && (
            <AlertDialog
              title={t('modals.delete.error_title')}
              text={errorDelete?.message || t('modals.delete.error_subtitle')}
              type="error"
            />
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default PresentationsListView;
