import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControlLabel,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { Formik } from 'formik';
import pickBy from 'lodash/pickBy';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { POLICY_TYPES } from 'src/constants/companies';
import MUIRichTextEditor from 'mui-rte';
import * as Yup from 'yup';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { getDraftFromHTML, getHTMLFromDraft } from 'src/utils/draft-js';
import CustomSwitch from 'src/components/CustomSwitch';

const MUI_EDITOR_CONTROLS = [
  'title',
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'undo',
  'redo',
  'link',
  'numberList',
  'bulletList',
  'quote',
  'code',
  'clear',
];

const useStyles = makeStyles(() => ({
  swatch: {
    marginTop: '8px',
    padding: '5px',
    background: '#fff',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
  },
  color: {
    paddingTop: '5px',
    width: '30px',
    height: '25px',
    borderRadius: '2px',
  },
  accordionActions: {
    height: 20,
  },
}));

const CompanyDetailsForm = ({ handleFormSubmit, handleCancel, company }) => {
  const classes = useStyles();
  const { t } = useTranslation('companies');

  const legalNotice = company.policies?.find(({ type }) => type === POLICY_TYPES.LEGAL_NOTICE);
  const privacyPolicy = company.policies?.find(({ type }) => type === POLICY_TYPES.PRIVACY_POLICY);
  const termsConditions = company.policies?.find(
    ({ type }) => type === POLICY_TYPES.TERMS_CONDITIONS,
  );

  const initialValues = {
    name: company.name,
    slogan: company.slogan || '',
    tramitByZones: company.tramitByZones || false,
    primaryColor: company.primaryColor || '',
    secondaryColor: company.secondaryColor || '',
    tertiaryColor: company.tertiaryColor || '',
    legalNotice: legalNotice ? getDraftFromHTML(legalNotice.content) : null,
    privacyPolicy: privacyPolicy ? getDraftFromHTML(privacyPolicy.content) : null,
    termsConditions: termsConditions ? getDraftFromHTML(termsConditions.content) : null,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().min(3, t('errors.name.min')).max(30, t('errors.name.max')),
    slogan: Yup.string().max(255, t('errors.slogan.max')),
    tramitByZones: Yup.boolean(),
    primaryColor: Yup.string()
      .trim()
      .matches(/^([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/, t('errors.color.regex')),
    secondaryColor: Yup.string()
      .trim()
      .matches(/^([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/, t('errors.color.regex')),
    tertiaryColor: Yup.string()
      .trim()
      .matches(/^([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/, t('errors.color.regex')),
    legalNotice: Yup.string(),
    privacyPolicy: Yup.string(),
    termsConditions: Yup.string(),
  });

  const onSubmit = (values, formikBag) => {
    const data = pickBy(values);
    data.tramitByZones = values.tramitByZones;

    return handleFormSubmit(company.id, data, formikBag);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        errors,
        handleBlur,
        setFieldTouched,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.name && errors.name)}
                fullWidth
                helperText={touched.name && errors.name}
                label={t('fields.name')}
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting}
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.slogan && errors.slogan)}
                fullWidth
                multiline
                rows={4}
                helperText={touched.slogan && errors.slogan}
                label={t('fields.slogan')}
                name="slogan"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting}
                value={values.slogan}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <CustomSwitch
                    name="tramitByZones"
                    checked={values.tramitByZones}
                    onChange={handleChange}
                  />
                }
                label={t('fields.tramitByZones')}
                labelPlacement="start"
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="row">
                <Grid item xs={1}>
                  <Box className={classes.swatch}>
                    <Box
                      className={classes.color}
                      style={{ background: `#${values.primaryColor}` }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={11}>
                  <TextField
                    error={Boolean(touched.primaryColor && errors.primaryColor)}
                    fullWidth
                    helperText={touched.primaryColor && errors.primaryColor}
                    label={t('fields.primaryColor')}
                    name="primaryColor"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={isSubmitting}
                    value={values.primaryColor}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container direction="row">
                <Grid item xs={1}>
                  <Box className={classes.swatch}>
                    <Box
                      className={classes.color}
                      style={{ background: `#${values.secondaryColor}` }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={11}>
                  <TextField
                    error={Boolean(touched.secondaryColor && errors.secondaryColor)}
                    fullWidth
                    helperText={touched.secondaryColor && errors.secondaryColor}
                    label={t('fields.secondaryColor')}
                    name="secondaryColor"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={isSubmitting}
                    type="secondaryColor"
                    value={values.secondaryColor}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container direction="row">
                <Grid item xs={1}>
                  <Box className={classes.swatch}>
                    <Box
                      className={classes.color}
                      style={{ background: `#${values.tertiaryColor}` }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={11}>
                  <TextField
                    error={Boolean(touched.tertiaryColor && errors.tertiaryColor)}
                    fullWidth
                    helperText={touched.tertiaryColor && errors.tertiaryColor}
                    label={t('fields.tertiaryColor')}
                    name="tertiaryColor"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={isSubmitting}
                    value={values.tertiaryColor}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{t('fields.legal_notice')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <MUIRichTextEditor
                    defaultValue={initialValues.legalNotice}
                    label={t('labels.start_writing')}
                    inlineToolbar={true}
                    controls={MUI_EDITOR_CONTROLS}
                    onChange={(state) => {
                      setFieldValue('legalNotice', getHTMLFromDraft(state));
                    }}
                    onBlur={() => {
                      setFieldTouched('legalNotice', true);
                    }}
                  />
                </AccordionDetails>
                <AccordionActions className={classes.accordionActions} />
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{t('fields.privacy_policy')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <MUIRichTextEditor
                    defaultValue={initialValues.privacyPolicy}
                    label={t('labels.start_writing')}
                    controls={MUI_EDITOR_CONTROLS}
                    inlineToolbar={true}
                    onChange={(state) => {
                      setFieldValue('privacyPolicy', getHTMLFromDraft(state));
                    }}
                    onBlur={() => {
                      setFieldTouched('privacyPolicy', true);
                    }}
                  />
                </AccordionDetails>

                <AccordionActions className={classes.accordionActions} />
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{t('fields.terms_conditions')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <MUIRichTextEditor
                    defaultValue={initialValues.termsConditions}
                    label={t('labels.start_writing')}
                    controls={MUI_EDITOR_CONTROLS}
                    inlineToolbar={true}
                    onChange={(state) => {
                      setFieldValue('termsConditions', getHTMLFromDraft(state));
                    }}
                    onBlur={() => {
                      setFieldTouched('termsConditions', true);
                    }}
                  />
                </AccordionDetails>

                <AccordionActions className={classes.accordionActions} />
              </Accordion>
            </Grid>

            <Box p={2}>
              <Button
                color="primary"
                disabled={isSubmitting}
                variant="outlined"
                onClick={handleCancel}
              >
                {t('buttons.cancel')}
              </Button>
            </Box>
            <Box p={2}>
              <Button color="primary" disabled={isSubmitting} type="submit" variant="contained">
                {t('buttons.update')}
              </Button>
            </Box>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

CompanyDetailsForm.propTypes = {
  handleFormSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  company: PropTypes.object,
};

export default CompanyDetailsForm;
