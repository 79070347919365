import { Container, Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import AlertDialog from 'src/components/AlertDialog';
import Loader from 'src/components/Loader';
import Page from 'src/components/Page';
import { useGetCompany } from 'src/hooks/company';
import Profile from './components/Profile';
import ProfileDetails from './components/ProfileDetails';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const PageContainer = ({ children }) => {
  const classes = useStyles();

  return (
    <Page className={classes.root}>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          {children}
        </Grid>
      </Container>
    </Page>
  );
};

const CompanyEdit = () => {
  const { t } = useTranslation('companies');
  const history = useHistory();
  const { companyId } = useParams();
  const { isLoading, data: company, isError, error } = useGetCompany(companyId, {
    include: 'policies,favicon',
  });

  if (isError) {
    return (
      <AlertDialog
        title={t('modals.error.title_get_company')}
        text={error?.message || t('modals.error.text_get_company')}
        type="error"
        acceptAction={() => history.push('/app/companies', { replace: true })}
      />
    );
  }

  if (isLoading) {
    return (
      <PageContainer>
        <Loader />
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <Grid item lg={4} md={6} xs={12}>
        <Grid container spacing={3}>
          <Grid item md={12} sm={6} xs={12}>
            <Profile company={company} media="logo" />
          </Grid>
          <Grid item md={12} sm={6} xs={12}>
            <Profile company={company} media="favicon" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={8} md={6} xs={12}>
        <ProfileDetails company={company} />
      </Grid>
    </PageContainer>
  );
};

export default CompanyEdit;
