import { Container, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import AlertDialog from 'src/components/AlertDialog';
import Loader from 'src/components/Loader';
import Page from 'src/components/Page';
import { useGetDemand } from 'src/hooks/demands';
import { isDoctorSelected } from 'src/store/selectors/entity';
import { isAssistant as isAssistantSelector } from 'src/store/selectors/user';

import DemandDetails from './components/DemandDetails';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const DetailsDemandView = () => {
  const classes = useStyles();
  const { t } = useTranslation('demands');
  const isAssistant = useSelector(isAssistantSelector);
  const doctor = useSelector(isDoctorSelected);
  const history = useHistory();
  const { demandId, versionId } = useParams();

  const { isLoading, data: demand, isError, error, refetch } = useGetDemand(demandId, {
    doctorId: isAssistant ? doctor?.id : undefined,
    include:
      'centerPrescriptor,centerRealizator,patient,company,versions,doctor,user,role,createdBy,attachments,previousTreatments,radiotherapyTreatments,chemotherapySessions,chemotherapyDrugs',
  });

  let version;

  if (versionId) {
    version = demand?.data?.versions?.find(({ id }) => id === Number(versionId));
  }

  if (isAssistant && !doctor) {
    return (
      <Redirect
        to={{
          pathname: '/app/home',
          state: { referrer: 'demands' },
        }}
      />
    );
  }

  if (isError) {
    return (
      <AlertDialog
        title={t('modals.error.title_get_demand')}
        text={error?.message || t('modals.error.text_get_demand')}
        type="error"
        acceptAction={() => history.push('/app/demands', { replace: true })}
      />
    );
  }

  return (
    <Page className={classes.root}>
      {isLoading && <Loader />}

      {!isLoading && (
        <Container maxWidth="lg">
          <DemandDetails demand={demand?.data} version={version} refetch={refetch} />
        </Container>
      )}
    </Page>
  );
};

export default DetailsDemandView;
