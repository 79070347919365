import { Button, makeStyles } from '@material-ui/core';
import { FieldArray } from 'formik';
import PropTypes from 'prop-types';
import { PlusCircle as PlusCircleIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';
import TreatmentCard from './components/TreatmentCard';

const useStyles = makeStyles((theme) => ({
  iconMargin: {
    marginRight: theme.spacing(1),
  },
}));

const TreatmentField = ({
  treatment: treatmentType,
  EMPTY_TREATMENT,
  values,
  touched,
  errors,
  handleChange,
  setFieldValue,
  arrayHelpers,
  handleBlur,
  setFieldTouched,
  disabled,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('demands');

  return (
    <FieldArray
      name={`tratamientosPrevios.${treatmentType}`}
      render={() => (
        <>
          {values.tratamientosPrevios
            .filter(({ type }) => type === treatmentType)
            .map((treatment, treatmentIndex) => (
              <TreatmentCard
                key={treatmentIndex}
                treatmentIndex={values.tratamientosPrevios.indexOf(treatment)}
                touched={touched}
                errors={errors}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                treatment={treatment}
                arrayHelpers={arrayHelpers}
                handleBlur={handleBlur}
                handleChange={handleChange}
                disabled={disabled}
              />
            ))}

          <Button
            onClick={() => arrayHelpers.push({ ...EMPTY_TREATMENT, type: treatmentType })}
            disabled={disabled}
          >
            <PlusCircleIcon className={classes.iconMargin} /> {t('buttons.add_previous_treatment')}
          </Button>
        </>
      )}
    />
  );
};

TreatmentField.propTypes = {
  treatment: PropTypes.string,
  EMPTY_TREATMENT: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  arrayHelpers: PropTypes.object,
  handleBlur: PropTypes.func,
  setFieldTouched: PropTypes.func,
  disabled: PropTypes.bool,
};

export default TreatmentField;
