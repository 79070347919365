import { Box, Button, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TYPE } from 'src/constants/centers';
import { useGetProvinces } from 'src/hooks/provinces';
import * as Yup from 'yup';

const CreateEditCenterForm = ({
  center,
  users,
  isLoadingDoctors,
  handleFormSubmit,
  handleCancel,
  operation,
}) => {
  const { t } = useTranslation(['centers', 'provinces']);
  const { enqueueSnackbar } = useSnackbar();
  const provincesQuery = useGetProvinces({ all: true, sort: 'name' });

  const initialValues = {
    name: '',
    address: '',
    locality: '',
    provinceId: null,
    type: '',
  };

  const getCenterData = () => {
    if (center?.data) {
      return {
        name: center.data.name,
        address: center.data.address,
        locality: center.data.locality || initialValues.locality,
        provinceId: center.data.provinceId || initialValues.provinceId,
        type: center.data.type,
        doctors: center?.data.doctors?.map(({ id, name }) => ({ id, name })) || [],
      };
    }

    return initialValues;
  };

  const validationSchema = Yup.object().shape({
    type: Yup.string().required(t('errors.type.required')),
    name: Yup.string()
      .min(3, t('errors.name.min'))
      .max(255, t('errors.name.max'))
      .required(t('errors.name.required')),
    address: Yup.string()
      .min(3, t('errors.address.min'))
      .max(255, t('errors.address.max'))
      .required(t('errors.address.required')),
    locality: Yup.string(),
    provinceId: Yup.string().nullable(),
    doctors: Yup.array().of(Yup.object()),
  });

  useEffect(() => {
    if (provincesQuery.isError) {
      enqueueSnackbar(t('provinces:modals.error.title_get_provinces'), { variant: 'error' });
    }
  }, [enqueueSnackbar, provincesQuery.isError, t]);

  return (
    <Formik
      enableReinitialize
      initialValues={getCenterData()}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
        setFieldTouched,
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.name && errors.name)}
                fullWidth
                helperText={touched.name && errors.name}
                label={t('fields.name')}
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting}
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.address && errors.address)}
                fullWidth
                helperText={touched.address && errors.address}
                label={t('fields.address')}
                name="address"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting}
                value={values.address}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                error={Boolean(touched.locality && errors.locality)}
                fullWidth
                helperText={touched.locality && errors.locality}
                label={t('fields.locality')}
                name="locality"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting}
                value={values.locality}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                openOnFocus={true}
                options={provincesQuery.data?.data?.rows || []}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                value={
                  provincesQuery.data?.data?.rows?.find(({ id }) => id === values.provinceId) ||
                  null
                }
                name="provinceId"
                loading={provincesQuery.isLoading}
                disabled={isSubmitting}
                onChange={(e, value) => setFieldValue('provinceId', value?.id || null)}
                onBlur={() => setFieldTouched('provinceId', true)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('fields.province')}
                    variant="outlined"
                    error={Boolean(touched.provinceId && errors.provinceId)}
                    helperText={touched.provinceId && errors.provinceId}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.type && errors.type)}
                helperText={touched.type && errors.type}
                fullWidth
                label={t('fields.type')}
                name="type"
                onChange={handleChange}
                disabled={isSubmitting}
                select
                SelectProps={{ native: true }}
                value={values.type}
                variant="outlined"
                onBlur={handleBlur}
              >
                <option value="" hidden />
                <option value={TYPE.AMBOS}>{t('fields.type_select.ambos')}</option>
                <option value={TYPE.PRESCRIPTOR}>{t('fields.type_select.prescriptor')}</option>
                <option value={TYPE.REALIZADOR}>{t('fields.type_select.realizador')}</option>
              </TextField>
            </Grid>
            {operation === 'edit' && values.type !== TYPE.REALIZADOR ? (
              <Grid item xs={12}>
                <Autocomplete
                  openOnFocus={true}
                  options={users?.data?.rows || []}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) => option.id === value.id}
                  filterSelectedOptions
                  multiple
                  value={values.doctors}
                  name="doctors"
                  loading={isLoadingDoctors}
                  disabled={isSubmitting}
                  onChange={(e, value) => setFieldValue('doctors', value)}
                  onBlur={() => setFieldTouched('doctors', true)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('fields.doctors')}
                      variant="outlined"
                      error={Boolean(touched.doctors && errors.doctors)}
                      helperText={touched.doctors && errors.doctors}
                    />
                  )}
                />
              </Grid>
            ) : null}
            <Box p={2}>
              <Button
                color="primary"
                fullWidth
                disabled={isSubmitting}
                size="large"
                variant="outlined"
                onClick={handleCancel}
              >
                {t('buttons.cancel')}
              </Button>
            </Box>
            <Box p={2}>
              <Button
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                {t(`buttons.${operation === 'create' ? 'create_center' : 'save'}`)}
              </Button>
            </Box>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

CreateEditCenterForm.propTypes = {
  initialValues: PropTypes.object,
  handleFormSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  operation: PropTypes.string,
  isLoadingDoctors: PropTypes.bool,
  users: PropTypes.object,
};

export default CreateEditCenterForm;
