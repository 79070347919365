import { Box, Container, LinearProgress, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Page from 'src/components/Page';
import { usePagination } from 'src/hooks/pagination';
import { useListPatients } from 'src/hooks/patients';
import { setFilters } from 'src/store/reducers/lists';

import Results from './components/Results';
import Toolbar from './components/Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const PatientsListView = () => {
  const classes = useStyles();
  const { t } = useTranslation('patients');
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.lists.patients?.filters);
  const {
    page,
    pageSize,
    setPage,
    setPageSize,
    resetPage,
    fitsInPage,
    navigateToLastPage,
  } = usePagination(filters?.page, filters?.pageSize);
  const [name, setName] = useState(filters?.name || '');
  const [company, setCompany] = useState(filters?.company || null);
  const [cardNumber, setCardNumber] = useState(filters?.cardNumber || '');
  const [sort, setSort] = useState(filters?.sort || '');
  const { isFetching, resolvedData: patients, isError } = useListPatients({
    page,
    pageSize,
    sort,
    name,
    companyId: company?.id,
    cardNumber,
    include: 'company',
  });

  if (patients?.data.count && !fitsInPage(patients.data.count)) {
    navigateToLastPage(patients.data.count);
  }

  useEffect(() => {
    dispatch(
      setFilters({
        list: 'patients',
        filters: { page, pageSize, sort, name, company, cardNumber },
      }),
    );
  }, [cardNumber, company, dispatch, name, page, pageSize, sort]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(t('modals.error.title_list_patients'), { variant: 'error' });
    }
  }, [enqueueSnackbar, isError, t]);

  return (
    <Page className={classes.root}>
      <Container maxWidth={false}>
        <Toolbar
          resetPage={resetPage}
          name={name}
          setName={setName}
          company={company}
          setCompany={setCompany}
          cardNumber={cardNumber}
          setCardNumber={setCardNumber}
        />
        <Box mt={3}>
          {isFetching && <LinearProgress color="primary" />}

          <Results
            patients={patients?.data}
            page={page}
            setPage={setPage}
            resetPage={resetPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            sort={sort}
            setSort={setSort}
            isLoading={isFetching}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default PatientsListView;
