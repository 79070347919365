import PropTypes from 'prop-types';

import ToolbarActions from './components/ToolbarActions';
import ToolbarFilters from './components/ToolbarFilters';

const Toolbar = ({ setPage, company, setCompany, resetPage }) => {
  return (
    <div>
      <ToolbarActions />

      <ToolbarFilters company={company} setCompany={setCompany} resetPage={resetPage} />
    </div>
  );
};

Toolbar.propTypes = {
  setPage: PropTypes.func,
  company: PropTypes.object,
  setCompany: PropTypes.func,
};

export default Toolbar;
