const getMediaUrl = (mediaId) => {
  if (!mediaId) {
    return null;
  }

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  return `${BASE_URL}/medias/${mediaId}`;
};

export { getMediaUrl };
