import { withStyles } from '@material-ui/styles';
import { Switch } from '@material-ui/core';

const CustomSwitch = withStyles((theme) => ({
  switchBase: {
    color: theme.palette.primary.main,
    '&$checked': {
      color: theme.palette.primary.main,
    },
    '&$disabled': {
      color: theme.palette.primary.main,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  checked: {},
  disabled: {},
  track: {},
}))(Switch);

export default CustomSwitch;
