import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TYPE } from 'src/constants/demands';
import { getLocationTranslation } from '../../../../../../../../utils/demands';

const useStyles = makeStyles((theme) => ({
  multiline: {
    whiteSpace: 'pre-line',
  },
  table_no_border: {
    '& th': {
      padding: '4px 5px',
      borderBottom: 'none',
      width: '40%',
    },
    '& td': {
      padding: '4px 5px',
      borderBottom: 'none',
      color: theme.palette.tertiary.main,
    },
    '& th, & th strong, & td': {
      lineHeight: 1,
    },
  },
}));

const ValoracionGeriatrica = ({ demand }) => {
  const classes = useStyles();
  const { t } = useTranslation('demands');

  if (!demand.valoracionGeriatrica) {
    return null;
  }

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <Typography component="strong">{t('fields.valoracionGeriatrica')}</Typography>
      </TableCell>
      <TableCell className={classes.multiline}>{demand.valoracionGeriatrica}</TableCell>
    </TableRow>
  );
};

const DiagnosticoHistologico = ({ demand }) => {
  const classes = useStyles();
  const { t } = useTranslation('demands');

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <Typography component="strong">{t('fields.diagnosticoHistologico')}</Typography>
      </TableCell>
      <TableCell className={classes.multiline}>{demand.diagnosticoHistologico}</TableCell>
    </TableRow>
  );
};

const DetailsTable = ({ demand, type }) => {
  const classes = useStyles();
  const { t } = useTranslation('demands');

  const splitTable = ![TYPE.OVARIO, TYPE.PANCREAS].includes(type);

  return (
    <Box>
      <Box display="flex">
        <TableContainer>
          <Table className={classes.table_no_border}>
            <TableBody>
              <ValoracionGeriatrica demand={demand} />
              <DiagnosticoHistologico demand={demand} />
              <TableRow>
                <TableCell component="th" scope="row">
                  <Typography component="strong">{t('fields.type')}</Typography>
                </TableCell>
                <TableCell>{t(getLocationTranslation(type))}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Typography component="strong">{t('fields.t')}</Typography>
                </TableCell>
                <TableCell>{demand.t}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Typography component="strong">{t('fields.n')}</Typography>
                </TableCell>
                <TableCell>{demand.n}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Typography component="strong">{t('fields.m')}</Typography>
                </TableCell>
                <TableCell>{demand.m}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Typography component="strong">{t('fields.estadio')}</Typography>
                </TableCell>
                <TableCell>{demand.estadio}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Typography component="strong">{t('fields.ecog')}</Typography>
                </TableCell>
                <TableCell>{demand.ecog}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {splitTable && (
          <TableContainer>
            <Table className={classes.table_no_border}>
              <TableBody>
                {type === TYPE.MAMA && (
                  <>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography component="strong">{t('fields.mamaTipo')}</Typography>
                      </TableCell>
                      <TableCell>{demand.mamaTipo}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography component="strong">{t('fields.mamaEr')}</Typography>
                      </TableCell>
                      <TableCell>{demand.mamaEr}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography component="strong">{t('fields.mamaEp')}</Typography>
                      </TableCell>
                      <TableCell>{demand.mamaEp}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography component="strong">{t('fields.mamaHer2neu')}</Typography>
                      </TableCell>
                      <TableCell>{demand.mamaHer2neu}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography component="strong">{t('fields.mamaFishHer2neu')}</Typography>
                      </TableCell>
                      <TableCell>{demand.mamaFishHer2neu}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography component="strong">{t('fields.mamaKi67')}</Typography>
                      </TableCell>
                      <TableCell>{demand.mamaKi67 + '%'}</TableCell>
                    </TableRow>
                  </>
                )}
                {type === TYPE.GASTRICO && (
                  <>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography component="strong">{t('fields.gastricoHerceptest')}</Typography>
                      </TableCell>
                      <TableCell>{demand.gastricoHerceptest}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography component="strong">{t('fields.gastricoFishHer2')}</Typography>
                      </TableCell>
                      <TableCell>{demand.gastricoFishHer2}</TableCell>
                    </TableRow>
                  </>
                )}
                {type === TYPE.COLORECTAL && (
                  <>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography component="strong">{t('fields.colorectalTipo')}</Typography>
                      </TableCell>
                      <TableCell>{demand.colorectalTipo}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography component="strong">{t('fields.colorectalKras')}</Typography>
                      </TableCell>
                      <TableCell>{demand.colorectalKras}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography component="strong">{t('fields.colorectalNras')}</Typography>
                      </TableCell>
                      <TableCell>{demand.colorectalNras}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography component="strong">{t('fields.colorectalBraf')}</Typography>
                      </TableCell>
                      <TableCell>{demand.colorectalBraf}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography component="strong">{t('fields.colorectalMmr')}</Typography>
                      </TableCell>
                      <TableCell>{demand.colorectalMmr}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography component="strong">{t('fields.colorectalMi')}</Typography>
                      </TableCell>
                      <TableCell>{demand.colorectalMi}</TableCell>
                    </TableRow>
                  </>
                )}
                {type === TYPE.PULMON && (
                  <>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography component="strong">{t('fields.pulmonTipo')}</Typography>
                      </TableCell>
                      <TableCell>{demand.pulmonTipo}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography component="strong">{t('fields.pulmonEgfr')}</Typography>
                      </TableCell>
                      <TableCell>{demand.pulmonEgfr}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography component="strong">{t('fields.pulmonAlk')}</Typography>
                      </TableCell>
                      <TableCell>{demand.pulmonAlk}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography component="strong">{t('fields.pulmonRosI')}</Typography>
                      </TableCell>
                      <TableCell>{demand.pulmonRosI}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography component="strong">{t('fields.pulmonPdLi')}</Typography>
                      </TableCell>
                      <TableCell>{demand.pulmonPdLi}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography component="strong">{t('fields.pulmonBrafV600E')}</Typography>
                      </TableCell>
                      <TableCell>{demand.pulmonBrafV600E}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography component="strong">{t('fields.pulmonNtrk1')}</Typography>
                      </TableCell>
                      <TableCell>{demand.pulmonNtrk1}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography component="strong">{t('fields.pulmonNtrk2')}</Typography>
                      </TableCell>
                      <TableCell>{demand.pulmonNtrk2}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography component="strong">{t('fields.pulmonNtrk3')}</Typography>
                      </TableCell>
                      <TableCell>{demand.pulmonNtrk3}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography component="strong">{t('fields.pulmonMetEx14')}</Typography>
                      </TableCell>
                      <TableCell>{demand.pulmonMetEx14}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography component="strong">{t('fields.pulmonRet')}</Typography>
                      </TableCell>
                      <TableCell>{demand.pulmonRet}</TableCell>
                    </TableRow>
                  </>
                )}
                {type === TYPE.PROSTATA && (
                  <>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography component="strong">{t('fields.prostataTipo')}</Typography>
                      </TableCell>
                      <TableCell>{demand.prostataTipo}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography component="strong">{t('fields.prostataPsaActual')}</Typography>
                      </TableCell>
                      <TableCell>{demand.prostataPsaActual}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography component="strong">{t('fields.prostataPsaPrevio')}</Typography>
                      </TableCell>
                      <TableCell>{demand.prostataPsaPrevio}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography component="strong">
                          {t('fields.prostataGradoGleason')}
                        </Typography>
                      </TableCell>
                      <TableCell>{demand.prostataGradoGleason}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography component="strong">
                          {t('fields.prostataEstratificacionRiesgo')}
                        </Typography>
                      </TableCell>
                      <TableCell>{demand.prostataEstratificacionRiesgo}</TableCell>
                    </TableRow>
                  </>
                )}
                {type === TYPE.MIELOPROLIFERATIVOS && (
                  <>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography component="strong">
                          {t('fields.mieloproliferativosCd20')}
                        </Typography>
                      </TableCell>
                      <TableCell>{demand.mieloproliferativosCd20}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography component="strong">
                          {t('fields.mieloproliferativosSds')}
                        </Typography>
                      </TableCell>
                      <TableCell>{demand.mieloproliferativosSds}</TableCell>
                    </TableRow>
                  </>
                )}
                {type === TYPE.VEJIGA && (
                  <>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography component="strong">{t('fields.vejigaTipo')}</Typography>
                      </TableCell>
                      <TableCell>{demand.vejigaTipo}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography component="strong">
                          {t('fields.vejigaDescripcionHistologica')}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.multiline}>
                        {demand.vejigaDescripcionHistologica}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography component="strong">{t('fields.vejigaSubtipo')}</Typography>
                      </TableCell>
                      <TableCell className={classes.multiline}>{demand.vejigaSubtipo}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography component="strong">{t('fields.vejigaPdLi')}</Typography>
                      </TableCell>
                      <TableCell className={classes.multiline}>{demand.vejigaPdLi}</TableCell>
                    </TableRow>
                  </>
                )}
                {type === TYPE.OTROS && (
                  <>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography component="strong">{t('fields.otrosDescription')}</Typography>
                      </TableCell>
                      <TableCell>{demand.otrosDescription}</TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Box>
  );
};

DetailsTable.propTypes = {
  demand: PropTypes.object,
  type: PropTypes.string,
};

export default DetailsTable;
