import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CustomSwitch from 'src/components/CustomSwitch';
import DrugLetter from 'src/components/Demands/DrugLetter';
import SessionsField from 'src/components/Demands/SessionsField';
import { useGetAllPresentations } from 'src/hooks/presentations';
import { generateLetterFromIndex } from 'src/utils/demands';

const useStyles = makeStyles((theme) => ({
  card: {
    paddingTop: 0,
    paddingBottom: '0 16px !important',
  },
  header: {
    paddingBottom: 0,
  },
  table_no_border: {
    '& th': {
      padding: '4px 5px',
      borderBottom: 'none',
      width: '40%',
    },
    '& td': {
      padding: '4px 5px',
      borderBottom: 'none',
      color: theme.palette.tertiary.main,
    },
    '& th, & th strong, & td': {
      lineHeight: 1,
    },
  },
  table: {
    '& th': {
      padding: '4px 5px',
    },
    '& td': {
      padding: '4px 5px',
      color: theme.palette.tertiary.main,
    },
    '& th, & th strong, & td': {
      lineHeight: 1,
    },
  },
  noShadow: {
    boxShadow: 'none',
  },
  tableText: {
    display: 'block',
    width: '400px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const getSupCorporal = (weight, height) => {
  const supCorporal = Math.pow((weight * height) / 3600, 0.5);

  // Round to 2 decimals
  return Math.round(supCorporal * 100) / 100;
};

const getDiasCiclo = (frecuenciaSesiones, nSesionesCiclo) => {
  return frecuenciaSesiones * (nSesionesCiclo - 1) + nSesionesCiclo;
};

const DetailsTratamientoQuimio = ({ demand }) => {
  const classes = useStyles();
  const { t } = useTranslation('demands');
  const { data: presentations } = useGetAllPresentations();
  demand.quimioterapicoFechasSesiones = demand.chemotherapySessions.map(({ date }) => date);

  const getPresentationName = (id) => {
    return presentations?.data.rows?.find((p) => p.id === id)?.presentation;
  };

  const getNationalDrugCode = (id) => {
    return presentations?.data.rows?.find((p) => p.id === id)?.nationalCode;
  };

  return (
    <Grid>
      {demand.tratamientoQuimioterapico && (
        <Grid item xs={12}>
          <Card className={classes.noShadow}>
            <CardHeader
              title={t('labels.viewDemand_proposedTreatments_tratamientoQuimioterapico_title')}
            />
            <CardContent className={classes.card}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Card className={classes.noShadow}>
                    <CardContent>
                      <TableContainer>
                        <Table className={classes.table_no_border}>
                          <TableBody>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <Typography component="strong">
                                  {t('fields.quimioterapicoIntencion')}
                                </Typography>
                              </TableCell>
                              <TableCell>{demand.quimioterapicoIntencion}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <Typography component="strong">
                                  {t('fields.quimioterapicoTalla')}
                                </Typography>
                              </TableCell>
                              <TableCell>{`${demand.quimioterapicoTalla} cm`}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <Typography component="strong">
                                  {t('fields.quimioterapicoPeso')}
                                </Typography>
                              </TableCell>
                              <TableCell>{`${demand.quimioterapicoPeso} kg`}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <Typography component="strong">
                                  {t('fields.quimioterapicoSupCorporal')}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {`${getSupCorporal(
                                  demand.quimioterapicoPeso,
                                  demand.quimioterapicoTalla,
                                )} m2`}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <Typography component="strong">
                                  {t('fields.quimioterapicoCiclo')}
                                </Typography>
                              </TableCell>
                              <TableCell>{`${demand.quimioterapicoCiclo} ${t(
                                'fields.quimioterapicoCicloPreposition',
                              )} ${demand.quimioterapicoNumeroCiclos}`}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <Typography component="strong">
                                  {t('fields.quimioterapicoIntervaloCiclos')}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {demand.quimioterapicoIntervaloCiclos} {t('labels.days')}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <Typography component="strong">
                                  {t('fields.quimioterapicoNumeroSesionesCiclo')}
                                </Typography>
                              </TableCell>
                              <TableCell>{demand.quimioterapicoNumeroSesionesCiclo}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <Typography component="strong">
                                  {t('fields.quimioterapicoFrecuenciaSesiones')}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {demand.quimioterapicoFrecuenciaSesiones} {t('labels.days')}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <Typography component="strong">
                                  {t('fields.quimioterapicoDiasCiclo')}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {getDiasCiclo(
                                  demand.quimioterapicoFrecuenciaSesiones,
                                  demand.quimioterapicoNumeroSesionesCiclo,
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <Typography component="strong">
                                  {t('fields.quimioterapicoFechaPrimeraSesion')}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {format(
                                  new Date(demand.quimioterapicoFechaPrimeraSesion),
                                  'dd/MM/yyyy',
                                )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={6}>
                  <Card className={classes.noShadow}>
                    <CardContent>
                      <TableContainer>
                        <Table className={classes.table_no_border}>
                          <TableBody>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <Typography component="strong">
                                  {t('fields.quimioterapicoAntiemeticos')}
                                </Typography>
                              </TableCell>
                              <TableCell>{demand.quimioterapicoAntiemeticos}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <Typography component="strong">
                                  {t('fields.quimioterapicoHeparinizacion')}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <CustomSwitch
                                  name="quimioterapicoHeparinizacion"
                                  size="small"
                                  checked={demand.quimioterapicoHeparinizacion}
                                  disabled={true}
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <Typography component="strong">
                                  {t('fields.quimioterapicoImplantacionInfusor')}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {demand.quimioterapicoImplantacionInfusor &&
                                  format(
                                    new Date(demand.quimioterapicoImplantacionInfusor),
                                    'dd/MM/yyyy',
                                  )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <Typography component="strong">
                                  {t('fields.quimioterapicoRetiradaInfusor')}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {demand.quimioterapicoRetiradaInfusor &&
                                  format(
                                    new Date(demand.quimioterapicoRetiradaInfusor),
                                    'dd/MM/yyyy',
                                  )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <Typography component="strong">
                                  {t('fields.quimioterapicoAntihistaminicos')}
                                </Typography>
                              </TableCell>
                              <TableCell>{demand.quimioterapicoAntihistaminicos}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <Typography component="strong">
                                  {t('fields.quimioterapicoCorticoides')}
                                </Typography>
                              </TableCell>
                              <TableCell>{demand.quimioterapicoCorticoides}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <Typography component="strong">
                                  {t('fields.quimioterapicoEritropoyetinas')}
                                </Typography>
                              </TableCell>
                              <TableCell>{demand.quimioterapicoEritropoyetinas}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <Typography component="strong">
                                  {t('fields.quimioterapicoOtrosBifosfonatos')}
                                </Typography>
                              </TableCell>
                              <TableCell>{demand.quimioterapicoOtrosBifosfonatos}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <Typography component="strong">
                                  {t('fields.quimioterapicoEnsayoClinico')}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <CustomSwitch
                                  name="quimioterapicoEnsayoClinico"
                                  size="small"
                                  checked={demand.quimioterapicoEnsayoClinico}
                                  disabled={true}
                                />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Card>
                </Grid>

                {demand.chemotherapyDrugs?.length > 0 && (
                  <Grid item xs={12}>
                    <Card className={classes.noShadow}>
                      <CardHeader
                        className={classes.header}
                        title={t('labels.viewDemand_proposedTreatments_farmaco_title')}
                      />
                      <CardContent className={classes.card}>
                        <TableContainer>
                          <Table className={classes.table}>
                            <TableHead>
                              <TableRow>
                                <TableCell></TableCell>
                                <TableCell align="right">
                                  {t('fields.quimioterapicoFarmacos')}
                                </TableCell>
                                <TableCell align="right">
                                  {t('fields.quimioterapicoFarmacos_labels.dosificacion')}
                                </TableCell>
                                <TableCell align="right">
                                  {t('fields.quimioterapicoFarmacos_labels.tipoDosificacion')}
                                </TableCell>
                                <TableCell align="right">
                                  {t('fields.quimioterapicoFarmacos_labels.formaAdministracion')}
                                </TableCell>
                                <TableCell align="right">
                                  {t('fields.quimioterapicoFarmacos_labels.codigoNacional')}
                                </TableCell>
                                <TableCell align="right">
                                  {t('fields.quimioterapicoFarmacos_labels.price')}
                                </TableCell>
                                {/*
                                <TableCell align="right">
                                  {t('fields.quimioterapicoFarmacos_labels.amount')}
                                </TableCell>
                                */}
                                <TableCell align="right">
                                  {t('fields.quimioterapicoFarmacos_labels.presentation')}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {demand.chemotherapyDrugs.map((row, i) => (
                                <TableRow key={i}>
                                  <TableCell align="right">
                                    <Box key={generateLetterFromIndex(i)} pr={1}>
                                      <DrugLetter letter={generateLetterFromIndex(i)} />
                                    </Box>
                                  </TableCell>
                                  <TableCell align="right">{row.nombre}</TableCell>
                                  <TableCell align="right">{row.dosificacion}</TableCell>
                                  <TableCell align="right">{row.tipoDosificacion}</TableCell>
                                  <TableCell align="right">{row.formaAdministracion}</TableCell>
                                  <TableCell align="right">{getNationalDrugCode(row.presentationId)}</TableCell>
                                  <TableCell align="right">{row.price}</TableCell>
                                  {/*
                                    <TableCell align="right">{row.amount}</TableCell>
                                    */}
                                  <TableCell align="right">
                                    <div
                                      className={classes.tableText}
                                      title={getPresentationName(row.presentationId)}
                                    >
                                      {getPresentationName(row.presentationId)}
                                    </div>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </CardContent>
                    </Card>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <SessionsField values={demand} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};

DetailsTratamientoQuimio.propTypes = {
  demand: PropTypes.object,
};

export default DetailsTratamientoQuimio;
