import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  makeStyles,
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

const useStyles = makeStyles({
  container: {
    minHeight: 50,
    overflow: 'hidden',
  },
  actions: {
    justifyContent: 'center',
  },
});

const InsertClosingCommentForm = ({ initialValues, handleInsertClosingComment, closeDialog }) => {
  const classes = useStyles();
  const { t } = useTranslation(['demands', 'common']);

  const validationSchema = Yup.object().shape({
    closingReason: Yup.string().required(t('errors.closing_demands_reason.required')),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleInsertClosingComment}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Box className={classes.container} p={1}>
              <TextField
                fullWidth
                multiline
                rows={2}
                error={Boolean(touched.closingReason && errors.closingReason)}
                helperText={touched.closingReason && errors.closingReason}
                label={t('fields.closing_demands_reason')}
                name="closingReason"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting}
                value={values.closingReason}
                variant="outlined"
              />
            </Box>
          </DialogContent>

          <DialogActions className={classes.actions}>
            <Button variant="contained" onClick={closeDialog}>
              {t('common:buttons.cancel')}
            </Button>

            <Button color="primary" variant="contained" onClick={handleSubmit}>
              {t('buttons.finish_demand')}
            </Button>
          </DialogActions>
        </form>
      )}
    </Formik>
  );
};

InsertClosingCommentForm.propTypes = {
  initialValues: PropTypes.object,
  handleInsertClosingComment: PropTypes.func,
  closeDialog: PropTypes.func,
};

export default InsertClosingCommentForm;
