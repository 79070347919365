export const authController = '/auth';
export const companiesController = '/companies';
export const rolesController = '/roles';
export const usersController = '/users';
export const invitationsController = '/invitations';
export const drugsController = '/drugs';
export const presentationsController = '/presentations';
export const centersController = '/centers';
export const catalogsController = '/catalogs';
export const patientsController = '/patients';
export const notificationsController = '/notifications';
export const demandsController = '/demands';
export const mediasController = '/medias';
export const provincesController = '/provinces';
export const codesController = '/codes';
export const utilsController = '/utils';

/**
 * Pagination const
 */
export const pageDefault = '1';
export const pageSizeDefault = '10';
export const fieldSearchDefault = '';
