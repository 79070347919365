import { Card, CardContent, CardHeader, Container, Divider, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AlertDialog from 'src/components/AlertDialog';
import CreateEditCodeForm from 'src/components/Codes/CreateEditCodeForm';
import Page from 'src/components/Page';
import { useCreateCode } from 'src/hooks/codes';
import { useGoBack } from 'src/hooks/common';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const CreateCodeView = () => {
  const { t } = useTranslation('codes');
  const goBack = useGoBack('/app/codes');
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const history = useHistory();

  const [createCode, { isloading, data, isError, error }] = useCreateCode();

  const handleSubmit = (values) => {
    const data = {
      ...values,
      companyId: values.companyId.id,
    };
    createCode(data);
  };

  const handleCancel = () => {
    history.push('/app/codes', { replace: true });
  };

  useEffect(() => {
    if (!isloading && !isError && data) {
      enqueueSnackbar(t('modals.success.text_create_code'), {
        variant: 'success',
      });

      goBack();
    }
  }, [data, enqueueSnackbar, goBack, isError, isloading, t]);

  return (
    <Page className={classes.root}>
      <Container maxWidth="lg">
        <Card>
          <CardHeader
            subheader={t('labels.createCode_subtitle')}
            title={t('labels.createCode_title')}
          />
          <Divider />
          <CardContent>
            <CreateEditCodeForm
              handleFormSubmit={handleSubmit}
              handleCancel={handleCancel}
              operation="create"
            />

            {isError && (
              <AlertDialog
                title={t('modals.error.title_create_code')}
                text={error?.message || t('modals.error.text_create_code')}
                type="error"
              />
            )}
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default CreateCodeView;
