import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { removeBase64Data } from 'src/utils/removeBase64Data';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100,
  },
  avatarLabel: {
    marginLeft: '0 !important',
    width: '100%',
  },
  avatarInput: {
    display: 'none',
  },
}));

const AvatarUpload = ({ handleAvatarUpload, isUploading }) => {
  const classes = useStyles();
  const { t } = useTranslation('account');

  const handleImageUpload = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];

    // Callback for load event
    reader.onloadend = () => {
      const imageBase64 = reader.result;
      handleAvatarUpload(removeBase64Data(imageBase64));
    };

    // Read image
    reader.readAsDataURL(file);
  };

  return (
    <>
      <input
        className={classes.avatarInput}
        id="avatar-input"
        onChange={handleImageUpload}
        disabled={isUploading}
        accept="image/*"
        type="file"
      />
      <label className={classes.avatarLabel} htmlFor="avatar-input">
        <Button variant="text" color="primary" fullWidth component="span" disabled={isUploading}>
          {t('buttons.avatarUpload')}
        </Button>
      </label>
    </>
  );
};

AvatarUpload.propTypes = {
  handleAvatarUpload: PropTypes.func,
  isUploading: PropTypes.bool,
};

export default AvatarUpload;
