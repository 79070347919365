import { Container, Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import AlertDialog from 'src/components/AlertDialog';
import Loader from 'src/components/Loader';
import Page from 'src/components/Page';
import { useGetUser } from 'src/hooks/users';
import Profile from './components/Profile';
import ProfileDetails from './components/ProfileDetails';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const UserProfileView = () => {
  const classes = useStyles();
  const { t } = useTranslation('account');
  const history = useHistory();
  const { userId } = useParams();
  const { isLoading, data: user, isError, error } = useGetUser(userId, {
    include:
      'avatar,role,company,assistants,doctors,permissions,supervisorDoctors,supervisorCenters,centers,doctors,provinces',
  });

  if (isError) {
    return (
      <AlertDialog
        title={error.message || t('modals.get_user.error.text')}
        text={error.message || t('modals.get_user.error.text')}
        type="error"
        acceptAction={() => history.push('/app/users', { replace: true })}
      />
    );
  }

  return (
    <Page className={classes.root}>
      {isLoading && <Loader />}

      {!isLoading && (
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item lg={4} md={6} xs={12}>
              <Profile user={user.data} />
            </Grid>
            <Grid item lg={8} md={6} xs={12}>
              <ProfileDetails user={user.data} />
            </Grid>
          </Grid>
        </Container>
      )}
    </Page>
  );
};

export default UserProfileView;
