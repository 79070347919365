import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Hidden,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Formik } from 'formik';
import get from 'lodash/get';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ID_CARD_REGEX, PASSWORD_REGEX } from 'src/constants/regex';
import { ROLES } from 'src/constants/roles';
import { useListCenters } from 'src/hooks/centers';
import { useGoBack } from 'src/hooks/common';
import { useListCompanies } from 'src/hooks/company';
import { useGetProvinces } from 'src/hooks/provinces';
import { useGetRoles } from 'src/hooks/roles';
import { useListUsers } from 'src/hooks/users';
import { removeBase64Data } from 'src/utils/removeBase64Data';
import { TYPE as CENTER_TYPES } from 'src/constants/centers';
import * as Yup from 'yup';

import AvatarUpload from './components';

const useStyles = makeStyles((theme) => ({
  avatarItem: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const CreateUserForm = ({ initialValues, handleFormSubmit }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector((state) => state.user.user);
  const goBack = useGoBack('/app/users');
  const { t } = useTranslation([
    'users',
    'rolesNames',
    'roles',
    'companies',
    'provinces',
    'centers',
  ]);
  const [selectedCompany, setSelectedCompany] = useState(initialValues.companyId);
  const { isLoading: isLoadingRoles, data: roles, isError: isErrorRoles } = useGetRoles({
    all: true,
  });
  const {
    isLoading: isLoadingCompanies,
    data: companies,
    isError: isErrorCompanies,
  } = useListCompanies({ all: true, default: false });
  const { data: defaultCompanies, isError: isErrorDefaultCompanies } = useListCompanies({
    default: true,
    all: true,
  });
  const provincesQuery = useGetProvinces({ all: true, sort: 'name' });
  const centersQuery = useListCenters({ all: true, type: CENTER_TYPES.PRESCRIPTOR });
  const [avatar, setAvatar] = useState(null);

  const getRoleByPermissions = (role) => {
    return roles?.data?.rows.find(({ permissions }) => permissions === role);
  };

  const doctorsQuery = useListUsers(
    {
      all: true,
      roleId: getRoleByPermissions(ROLES.DOCTOR)?.id,
      companyId: selectedCompany?.id,
    },
    selectedCompany && !isLoadingRoles && !isErrorRoles && roles.data?.rows.length,
  );

  const assistantsQuery = useListUsers(
    {
      all: true,
      roleId: getRoleByPermissions(ROLES.ASSISTANT)?.id,
      companyId: selectedCompany?.id,
    },
    selectedCompany && !isLoadingRoles && !isErrorRoles && roles.data?.rows.length,
  );

  const isRole = (role, permissions) => {
    return role?.id === getRoleByPermissions(permissions)?.id;
  };

  const validationSchema = Yup.object().shape({
    role: Yup.object().nullable().required(t('errors.role.required')),
    companyId: Yup.object()
      .nullable()
      .when('role', (role, schema) =>
        isRole(role, ROLES.REPRESENTATIVE) ? schema.required(t('errors.company.required')) : schema,
      )
      .when('role', (role, schema) =>
        isRole(role, ROLES.DOCTOR) ? schema.required(t('errors.company.required')) : schema,
      )
      .when('role', (role, schema) =>
        isRole(role, ROLES.ASSISTANT) ? schema.required(t('errors.company.required')) : schema,
      ),
    companies: Yup.array()
      .of(Yup.object())
      .when('role', (role, schema) =>
        isRole(role, ROLES.MANAGER) ? schema.required(t('errors.companies.required')) : schema,
      ),
    zones: Yup.array().of(Yup.object()),
    centers: Yup.array().of(Yup.object()),
    doctors: Yup.array().of(Yup.object()),
    assistants: Yup.array().of(Yup.object()),
    email: Yup.string()
      .email(t('errors.email.valid'))
      .max(255, t('errors.email.max'))
      .required(t('errors.email.required')),
    name: Yup.string()
      .min(3, t('errors.name.min'))
      .max(200, t('errors.name.max', { max: 200 }))
      .required(t('errors.name.required')),
    dni: Yup.string()
      .matches(ID_CARD_REGEX, t('errors.id_card.pattern'))
      .required(t('errors.id_card.required')),
    membershipNumber: Yup.string(),
    password: Yup.string()
      .min(8, t('errors.password.min', { min: 8 }))
      .max(12, t('errors.password.max', { max: 12 }))
      .matches(PASSWORD_REGEX, t('errors.password.pattern'))
      .required(t('errors.password.required')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], t('errors.confirmPassword.match'))
      .required(t('errors.confirmPassword.required')),
  });

  const getRolesOptions = () => {
    if (!isLoadingRoles && !isErrorRoles && roles.data.rows.length) {
      const availableRoles = roles.data.rows.filter(
        (role) =>
          [
            ROLES.MANAGER,
            ROLES.CONSULTANT,
            ROLES.REPRESENTATIVE,
            ROLES.SUPERVISOR,
            ROLES.DOCTOR,
            ROLES.ASSISTANT,
          ].includes(role.permissions) ||
          (user?.role.permissions === ROLES.SUPERADMIN && role.permissions === ROLES.ADMIN),
      );

      const translatedAvailableRoles = availableRoles.map((role) => {
        const name = t(`rolesNames:${role.permissions}`);
        return { ...role, name };
      });

      return translatedAvailableRoles;
    }

    return [];
  };

  const onSubmit = async (values, formikBag) => {
    const data = {
      ...pick(values, ['name', 'email', 'dni', 'password']),
      roleId: values.role.id,
      companyId:
        isRole(values.role, ROLES.REPRESENTATIVE) ||
        isRole(values.role, ROLES.SUPERVISOR) ||
        isRole(values.role, ROLES.DOCTOR) ||
        isRole(values.role, ROLES.ASSISTANT)
          ? values.companyId.id
          : get(defaultCompanies, 'data.rows[0].id', ''),
      companies: isRole(values.role, ROLES.MANAGER) ? values.companies.map(({ id }) => id) : null,
      zones:
        isRole(values.role, ROLES.REPRESENTATIVE) && values.companyId?.tramitByZones
          ? values.zones.map(({ id }) => id)
          : null,
      centers:
        isRole(values.role, ROLES.SUPERVISOR) || isRole(values.role, ROLES.DOCTOR)
          ? values.centers.map(({ id }) => id)
          : null,
      doctors:
        isRole(values.role, ROLES.SUPERVISOR) || isRole(values.role, ROLES.ASSISTANT)
          ? values.doctors.map(({ id }) => id)
          : null,
      assistants: isRole(values.role, ROLES.DOCTOR) ? values.assistants.map(({ id }) => id) : null,
      avatarImage: avatar ? removeBase64Data(avatar) : null,
    };

    if (isRole(values.role, ROLES.DOCTOR)) {
      data.membershipNumber = values.membershipNumber;
    }

    return handleFormSubmit(pickBy(data), formikBag);
  };

  const onReset = () => {
    setAvatar(null);
  };

  useEffect(() => {
    if (isErrorRoles) {
      enqueueSnackbar(t('roles:modals.error.title_get_roles'), { variant: 'error' });
    }
  }, [enqueueSnackbar, isErrorRoles, t]);

  useEffect(() => {
    if (isErrorCompanies || isErrorDefaultCompanies) {
      enqueueSnackbar(t('companies:modals.error.title_get_companies'), { variant: 'error' });
    }
  }, [enqueueSnackbar, isErrorCompanies, isErrorDefaultCompanies, t]);

  useEffect(() => {
    if (provincesQuery.isError) {
      enqueueSnackbar(t('provinces:modals.error.title_get_provinces'), { variant: 'error' });
    }
  }, [enqueueSnackbar, provincesQuery.isError, t]);

  useEffect(() => {
    if (centersQuery.isError) {
      enqueueSnackbar(t('centers:modals.error.title_list_centers'), { variant: 'error' });
    }
  }, [enqueueSnackbar, centersQuery.isError, t]);

  useEffect(() => {
    if (doctorsQuery.isError) {
      enqueueSnackbar(t('modals.error.title_list_users'), { variant: 'error' });
    }
  }, [enqueueSnackbar, doctorsQuery.isError, t]);

  useEffect(() => {
    if (assistantsQuery.isError) {
      enqueueSnackbar(t('modals.error.title_list_users'), { variant: 'error' });
    }
  }, [enqueueSnackbar, assistantsQuery.isError, t]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onReset={onReset}
    >
      {({
        errors,
        handleBlur,
        setFieldTouched,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid xs={12} sm={9} md={10} item>
              <Grid spacing={3} direction="column" container>
                <Grid item>
                  <Grid container spacing={3}>
                    <Grid
                      item
                      md={
                        !isLoadingRoles &&
                        !isErrorRoles &&
                        (isRole(values.role, ROLES.MANAGER) ||
                          isRole(values.role, ROLES.REPRESENTATIVE) ||
                          isRole(values.role, ROLES.SUPERVISOR) ||
                          isRole(values.role, ROLES.DOCTOR) ||
                          isRole(values.role, ROLES.ASSISTANT))
                          ? 6
                          : 12
                      }
                      xs={12}
                    >
                      <Autocomplete
                        openOnFocus={true}
                        selectOnFocus={false}
                        options={getRolesOptions()}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) => option.id === value.id}
                        loading={isLoadingRoles}
                        name="role"
                        value={values.role}
                        onChange={(e, value) => setFieldValue('role', value)}
                        onBlur={() => setFieldTouched('role', true)}
                        disabled={isSubmitting}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t('fields.select_role')}
                            variant="outlined"
                            error={Boolean(touched.role && errors.role)}
                            helperText={touched.role && errors.role}
                            inputProps={{
                              ...params.inputProps,
                              style: { cursor: 'pointer' },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              readOnly: true,
                              endAdornment: (
                                <>
                                  {isLoadingRoles ? (
                                    <CircularProgress color="inherit" size={20} />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                      />
                    </Grid>
                    {!isLoadingRoles && !isErrorRoles && isRole(values.role, ROLES.MANAGER) && (
                      <Grid item md={6} xs={12}>
                        <Autocomplete
                          openOnFocus={true}
                          selectOnFocus={false}
                          options={companies?.data?.rows || []}
                          getOptionLabel={(option) => option.name}
                          getOptionSelected={(option, value) => option.id === value.id}
                          filterSelectedOptions
                          multiple
                          loading={isLoadingCompanies}
                          name="companies"
                          value={values.companies}
                          onChange={(e, value) => setFieldValue('companies', value)}
                          onBlur={() => setFieldTouched('companies', true)}
                          disabled={isSubmitting}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t('fields.companies')}
                              variant="outlined"
                              error={Boolean(touched.companies && errors.companies)}
                              helperText={touched.companies && errors.companies}
                              inputProps={{
                                ...params.inputProps,
                                style: { cursor: 'pointer' },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                readOnly: true,
                                endAdornment: (
                                  <>
                                    {isLoadingCompanies ? (
                                      <CircularProgress color="inherit" size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                ),
                              }}
                            />
                          )}
                        />
                      </Grid>
                    )}
                    {!isLoadingRoles &&
                      !isErrorRoles &&
                      (isRole(values.role, ROLES.REPRESENTATIVE) ||
                        isRole(values.role, ROLES.SUPERVISOR) ||
                        isRole(values.role, ROLES.DOCTOR) ||
                        isRole(values.role, ROLES.ASSISTANT)) && (
                        <Grid item md={6} xs={12}>
                          <Autocomplete
                            openOnFocus={true}
                            selectOnFocus={false}
                            options={companies?.data?.rows || []}
                            getOptionLabel={(option) => option.name}
                            loading={isLoadingCompanies}
                            name="companyId"
                            value={values.companyId}
                            onChange={(e, value) => {
                              setFieldValue('companyId', value);
                              setFieldValue('doctors', initialValues.doctors);
                              setSelectedCompany(value);
                            }}
                            onBlur={() => setFieldTouched('companyId', true)}
                            disabled={isSubmitting}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t('fields.select_company')}
                                variant="outlined"
                                error={Boolean(touched.companyId && errors.companyId)}
                                helperText={touched.companyId && errors.companyId}
                                inputProps={{
                                  ...params.inputProps,
                                  style: { cursor: 'pointer' },
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  readOnly: true,
                                  endAdornment: (
                                    <>
                                      {isLoadingCompanies ? (
                                        <CircularProgress color="inherit" size={20} />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </>
                                  ),
                                }}
                              />
                            )}
                          />
                        </Grid>
                      )}
                  </Grid>
                </Grid>
                <Grid item>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label={t('fields.email')}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={isSubmitting}
                    type="email"
                    value={values.email}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Hidden xsDown>
              <Grid item xs={4} sm={3} md={2}>
                <Grid container justify="flex-end">
                  <Grid item xs={12} className={classes.avatarItem}>
                    <AvatarUpload
                      avatar={avatar}
                      setAvatar={setAvatar}
                      isSubmitting={isSubmitting}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Hidden>

            <Grid item sm={isRole(values.role, ROLES.DOCTOR) ? 6 : 8} xs={12}>
              <TextField
                error={Boolean(touched.name && errors.name)}
                fullWidth
                helperText={touched.name && errors.name}
                label={t('fields.name')}
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting}
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid item sm={isRole(values.role, ROLES.DOCTOR) ? 3 : 4} xs={12}>
              <TextField
                error={Boolean(touched.dni && errors.dni)}
                fullWidth
                helperText={touched.dni && errors.dni}
                label={t('fields.id_card')}
                name="dni"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting}
                value={values.dni}
                variant="outlined"
              />
            </Grid>
            {isRole(values.role, ROLES.DOCTOR) && (
              <Grid item sm={3} xs={12}>
                <TextField
                  error={Boolean(touched.membershipNumber && errors.membershipNumber)}
                  fullWidth
                  helperText={touched.membershipNumber && errors.membershipNumber}
                  label={t('fields.membership_number')}
                  name="membershipNumber"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={isSubmitting}
                  value={values.membershipNumber}
                  variant="outlined"
                />
              </Grid>
            )}
            <Grid item md={6} xs={12}>
              <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                label={t('fields.password')}
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting}
                type="password"
                value={values.password}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                fullWidth
                helperText={touched.confirmPassword && errors.confirmPassword}
                label={t('fields.confirmPassword')}
                name="confirmPassword"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting}
                type="password"
                value={values.confirmPassword}
                variant="outlined"
              />
            </Grid>
            {!isLoadingRoles &&
              !isErrorRoles &&
              isRole(values.role, ROLES.REPRESENTATIVE) &&
              values.companyId?.tramitByZones && (
                <Grid item xs={12}>
                  <Autocomplete
                    openOnFocus={true}
                    options={provincesQuery.data?.data?.rows || []}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => option.id === value.id}
                    multiple
                    filterSelectedOptions
                    value={values.zones}
                    name="zones"
                    loading={provincesQuery.isLoading}
                    disabled={isSubmitting}
                    onChange={(e, value) => setFieldValue('zones', value)}
                    onBlur={() => setFieldTouched('zones', true)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('fields.zones')}
                        variant="outlined"
                        error={Boolean(touched.zones && errors.zones)}
                        helperText={touched.zones && errors.zones}
                      />
                    )}
                  />
                </Grid>
              )}
            {!isLoadingRoles &&
              !isErrorRoles &&
              (isRole(values.role, ROLES.SUPERVISOR) || isRole(values.role, ROLES.DOCTOR)) && (
                <Grid item xs={12}>
                  <Autocomplete
                    openOnFocus={true}
                    options={centersQuery.data?.data?.rows || []}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => option.id === value.id}
                    multiple
                    filterSelectedOptions
                    value={values.centers}
                    name="centers"
                    loading={centersQuery.isLoading}
                    disabled={isSubmitting}
                    onChange={(e, value) => setFieldValue('centers', value)}
                    onBlur={() => setFieldTouched('centers', true)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('fields.centers')}
                        variant="outlined"
                        error={Boolean(touched.centers && errors.centers)}
                        helperText={touched.centers && errors.centers}
                      />
                    )}
                  />
                </Grid>
              )}
            {isRole(values.role, ROLES.DOCTOR) && (
              <Grid item xs={12}>
                <Autocomplete
                  openOnFocus={true}
                  options={assistantsQuery.latestData?.data?.rows || []}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) => option.id === value.id}
                  filterSelectedOptions
                  multiple
                  value={values.assistants}
                  name="assistants"
                  loading={assistantsQuery.isFetching}
                  disabled={isSubmitting}
                  onChange={(e, value) => setFieldValue('assistants', value)}
                  onBlur={() => setFieldTouched('assistants', true)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('fields.assistants')}
                      variant="outlined"
                      error={Boolean(touched.assistants && errors.assistants)}
                      helperText={touched.assistants && errors.assistants}
                    />
                  )}
                />
              </Grid>
            )}
            {!isLoadingRoles &&
              !isErrorRoles &&
              (isRole(values.role, ROLES.SUPERVISOR) || isRole(values.role, ROLES.ASSISTANT)) && (
                <Grid item xs={12}>
                  <Autocomplete
                    openOnFocus={true}
                    options={doctorsQuery.latestData?.data?.rows || []}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => option.id === value.id}
                    multiple
                    filterSelectedOptions
                    value={values.doctors}
                    name="doctors"
                    loading={doctorsQuery.isFetching}
                    disabled={isSubmitting}
                    onChange={(e, value) => setFieldValue('doctors', value)}
                    onBlur={() => setFieldTouched('doctors', true)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('fields.select_doctors')}
                        variant="outlined"
                        error={Boolean(touched.doctors && errors.doctors)}
                        helperText={touched.doctors && errors.doctors}
                      />
                    )}
                  />
                </Grid>
              )}
            <Hidden smUp>
              <Grid item xs={12}>
                <AvatarUpload avatar={avatar} setAvatar={setAvatar} isSubmitting={isSubmitting} />
              </Grid>
            </Hidden>

            <Box display="flex" p={2}>
              <Button className={classes.button} variant="outlined" onClick={goBack}>
                {t('common:buttons.back')}
              </Button>

              <Button
                className={classes.button}
                color="primary"
                disabled={isSubmitting}
                type="submit"
                variant="contained"
              >
                {t(`buttons.create`)}
              </Button>
            </Box>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

CreateUserForm.propTypes = {
  initialValues: PropTypes.object,
  handleFormSubmit: PropTypes.func,
};

export default CreateUserForm;
