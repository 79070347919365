import { Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import CenterDetails from './components/CenterDetails';
import { useHistory, useParams } from 'react-router-dom';
import Loader from 'src/components/Loader';
import { useTranslation } from 'react-i18next';
import { useGetCenter } from 'src/hooks/centers';
import AlertDialog from 'src/components/AlertDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const ViewCenterView = () => {
  const classes = useStyles();
  const { t } = useTranslation('centers');
  const history = useHistory();
  const { centerId } = useParams();
  const { isLoading, data: center, isError, error } = useGetCenter(centerId, {
    include: 'province',
  });

  if (isError) {
    return (
      <AlertDialog
        title={t('modals.error.title_get_center')}
        text={error?.message || t('modals.error.text_get_center')}
        type="error"
        acceptAction={() => history.push('/app/centers', { replace: true })}
      />
    );
  }

  return (
    <Page className={classes.root}>
      {isLoading && <Loader />}

      {!isLoading && (
        <Container maxWidth="lg">
          <CenterDetails center={center.data} />
        </Container>
      )}
    </Page>
  );
};

export default ViewCenterView;
