import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';

import Step3Form from './components/Step3Form';

const Step3 = ({ demand, setDemand, disableForm, setDisableNext, isContinue, isChangeDates }) => {
  const [isFullfilled] = useState(true);

  const step3Data = demand.step3;
  const initialValues = {
    tratamientosPrevios: step3Data.tratamientosPrevios || [],
  };

  const [previousTreatments, setPreviousTreatments] = useState(demand.step3 || initialValues);

  useEffect(() => {
    setDemand({ ...demand, step3: previousTreatments });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousTreatments, setDemand]);

  return (
    <Step3Form
      initialValues={initialValues}
      setPreviousTreatments={setPreviousTreatments}
      isFullfilled={isFullfilled}
      disableForm={disableForm || isContinue || isChangeDates}
      setDisableNext={setDisableNext}
    />
  );
};

Step3.propTypes = {
  demand: PropTypes.object,
  setDemand: PropTypes.func,
  disableForm: PropTypes.bool,
  setDisableNext: PropTypes.func,
  isContinue: PropTypes.bool,
  isChangeDates: PropTypes.bool,
};

export default Step3;
