import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { isAuthenticated as isAuthenticatedSelector } from 'src/store/selectors/auth';
import { getRoleCurrentUser as getRoleCurrentUserSelector } from 'src/store/selectors/user';

const PrivateRoute = ({
  children,
  roles,
  withPasswordExpired,
  withResetPassword,
  withTermsPending,
  ...rest
}) => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const userRole = useSelector(getRoleCurrentUserSelector);
  const passwordExpired = useSelector((state) => state.app.passwordExpired);
  const requireResetPassword = useSelector((state) => state.app.requireResetPassword);
  const requireAcceptTerms = useSelector((state) => state.app.requireAcceptTerms);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (isAuthenticated) {
          if (
            (passwordExpired && !withPasswordExpired) ||
            (requireResetPassword && !withResetPassword) ||
            (requireAcceptTerms && !withTermsPending)
          ) {
            return (
              <Redirect
                to={{
                  pathname: '/app/account',
                  state: { from: location },
                }}
              />
            );
          } else if (roles) {
            if (roles.includes(userRole)) {
              return children;
            }

            return (
              <Redirect
                to={{
                  pathname: '/app/home',
                  state: { from: location },
                }}
              />
            );
          }

          return children;
        }

        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.object,
  roles: PropTypes.array,
  withPasswordExpired: PropTypes.bool,
  withResetPassword: PropTypes.bool,
  withTermsPending: PropTypes.bool,
};

export default PrivateRoute;
