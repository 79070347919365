import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  makeStyles,
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

const useStyles = makeStyles({
  container: {
    minHeight: 50,
    overflow: 'hidden',
  },
  actions: {
    justifyContent: 'center',
  },
});

const IncreaseCyclesForm = ({ initialValues, handleIncreaseCycles, closeDialog }) => {
  const classes = useStyles();
  const { t } = useTranslation(['demands', 'common']);

  const validationSchema = Yup.object().shape({
    quimioterapicoNumeroCiclos: Yup.number()
      .integer(t('errors.increase_cycles.integer'))
      .min(1, t('errors.increase_cycles.min', { min: 1 }))
      .required(t('errors.increase_cycles.required')),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleIncreaseCycles}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Box className={classes.container} p={1}>
              <TextField
                type="number"
                inputProps={{ min: 1, step: '1' }}
                error={Boolean(
                  touched.quimioterapicoNumeroCiclos && errors.quimioterapicoNumeroCiclos,
                )}
                fullWidth
                helperText={touched.quimioterapicoNumeroCiclos && errors.quimioterapicoNumeroCiclos}
                label={t('fields.num_cycles')}
                name="quimioterapicoNumeroCiclos"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting}
                value={values.quimioterapicoNumeroCiclos}
                variant="outlined"
              />
            </Box>
          </DialogContent>

          <DialogActions className={classes.actions}>
            <Button variant="contained" onClick={closeDialog}>
              {t('common:buttons.cancel')}
            </Button>

            <Button color="primary" variant="contained" onClick={handleSubmit}>
              {t('buttons.increase_cycles')}
            </Button>
          </DialogActions>
        </form>
      )}
    </Formik>
  );
};

IncreaseCyclesForm.propTypes = {
  initialValues: PropTypes.object,
  handleChangeDatesDemand: PropTypes.func,
  closeDialog: PropTypes.func,
};

export default IncreaseCyclesForm;
