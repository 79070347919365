import PropTypes from 'prop-types';

import ToolbarFilters from './components/ToolbarFilters';

const Toolbar = ({ resetPage, name, setName, defaultCompany, setDefaultCompany }) => {
  return (
    <div>
      <ToolbarFilters
        resetPage={resetPage}
        name={name}
        setName={setName}
        defaultCompany={defaultCompany}
        setDefaultCompany={setDefaultCompany}
      />
    </div>
  );
};

Toolbar.propTypes = {
  resetPage: PropTypes.func,
  name: PropTypes.string,
  setName: PropTypes.func,
  defaultCompany: PropTypes.string,
  setDefaultCompany: PropTypes.func,
};

export default Toolbar;
