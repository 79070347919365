import { useMutation, useQueryCache } from 'react-query';
import { acceptTermsConditions } from 'src/services/api/users';

function useAcceptTermsConditions() {
  const queryCache = useQueryCache();

  return useMutation(() => acceptTermsConditions(), {
    onSuccess: (data) => queryCache.setQueryData(['user', data.data?.id], data),
  });
}

export default useAcceptTermsConditions;
