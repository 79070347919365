import { useMutation, useQueryCache } from 'react-query';
import { createPresentation } from 'src/services/api/presentations';

function useCreatePresentation() {
  const queryCache = useQueryCache();

  return useMutation((data) => createPresentation(data), {
    onSuccess: (data) => queryCache.setQueryData(['presentation', data.data?.id], data),
  });
}

export default useCreatePresentation;
