const STATUS_COLORS = {
  FINISHED: '#a8f375',
  PENDING: '#ffc089',
  P_ANALYSIS: '#0c66e4',
  CANCELED: '#ff7e74',
  ACTIVE: '#a29bfe',
  DRAFT: '#ececec',
};

export { STATUS_COLORS }
