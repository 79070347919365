import { Container, Grid, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Page from 'src/components/Page';
import {
  isCenterSelected as isCenterSelectedSelector,
  isDoctorSelected as isDoctorSelectedSelector,
  isCompanySelected as isCompanySelectedSelector,
} from 'src/store/selectors/entity';
import {
  isAssistant as isAssistantSelector,
  isSupervisor as isSupervisorSelector,
} from 'src/store/selectors/user';
import { isDoctor as isDoctorSelector } from 'src/store/selectors/user';
import { isManager as isManagerSelector } from 'src/store/selectors/user';

import CenterPicker from './components/CenterPicker';
import DoctorPicker from './components/DoctorPicker';
import ManagerCompanyPicker from './components/ManagerCompanyPicker';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const HomeView = () => {
  const classes = useStyles();
  const isDoctorSelected = useSelector(isDoctorSelectedSelector);
  const isCenterSelected = useSelector(isCenterSelectedSelector);
  const isCompanySelected = useSelector(isCompanySelectedSelector);
  const isAssistant = useSelector(isAssistantSelector);
  const isDoctor = useSelector(isDoctorSelector);
  const isManager = useSelector(isManagerSelector);
  const isSupervisor = useSelector(isSupervisorSelector);

  const displaySelectors = {
    doctor: (isAssistant || isSupervisor) && (!isDoctorSelected || !isCenterSelected),
    center:
      (isDoctor && !isCenterSelected) ||
      ((isAssistant || isSupervisor) && (!isDoctorSelected || !isCenterSelected)),
    company: isManager && !isCompanySelected,
  };

  return (
    <Page className={classes.root}>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          {displaySelectors.doctor && (
            <Grid item>
              <DoctorPicker />
            </Grid>
          )}
          {displaySelectors.center && (
            <Grid item>
              <CenterPicker />
            </Grid>
          )}
          {displaySelectors.company && (
            <Grid item>
              <ManagerCompanyPicker />
            </Grid>
          )}
          {!displaySelectors.company && !displaySelectors.doctor && !displaySelectors.center && (
            <Redirect to="/app/demands" />
          )}
        </Grid>
      </Container>
    </Page>
  );
};

export default HomeView;
