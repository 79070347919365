import { Button, Card, CardContent, CardHeader, Divider, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useGoBack } from 'src/hooks/common';

import DetailsTable from './components';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const ProfileDetails = ({ user }) => {
  const classes = useStyles();
  const { t } = useTranslation(['account', 'common']);
  const goBack = useGoBack('/app/users');

  return (
    <Card>
      <CardHeader
        title={t('labels.userProfile_title')}
        subheader={t('labels.userProfile_subtitle')}
      />
      <Divider />
      <CardContent>
        <DetailsTable user={user} />

        <Button className={classes.button} variant="outlined" onClick={goBack}>
          {t('common:buttons.back')}
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          component={RouterLink}
          to={`/app/users/${user.id}/edit`}
        >
          {t('buttons.edit')}
        </Button>
      </CardContent>
    </Card>
  );
};

ProfileDetails.propTypes = {
  user: PropTypes.object,
};

export default ProfileDetails;
