import { fade, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { MapPin as MapPinIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { TYPE } from 'src/constants/centers';
import { ROLES } from 'src/constants/roles';
import useListCenters from 'src/hooks/centers/useListCenters';
import { setCenterEntity } from 'src/store/reducers/entity';
import { isDoctorSelected as isDoctorSelectedSelector } from 'src/store/selectors/entity';
import { getRoleCurrentUser as getRoleCurrentUserSelector } from 'src/store/selectors/user';
import { isSupervisor as isSupervisorSelector } from 'src/store/selectors/user';

import CenterSelectorForm from './components/CenterSelectorForm';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  centerIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '20ch',
      '&:focus': {
        width: '28ch',
      },
    },
  },
}));

const CenterSelector = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation('centers');
  const { enqueueSnackbar } = useSnackbar();
  const centerSelected = useSelector((state) => state.entity?.center);
  const [center, setCenter] = useState(centerSelected);
  const userRole = useSelector(getRoleCurrentUserSelector);
  const isSupervisor = useSelector(isSupervisorSelector);
  const isDoctorSelected = useSelector(isDoctorSelectedSelector);
  const supervisorCenters = useSelector((state) => state.user?.user?.supervisorCenters);
  const [name, setName] = useState();
  const query = { name, doctorId: isDoctorSelected?.id, type: TYPE.PRESCRIPTOR, all: true };
  const { isLoading, resolvedData: centers } = useListCenters(
    query,
    !isSupervisor && (userRole === ROLES.DOCTOR || !!isDoctorSelected),
  );
  const centersList = isSupervisor ? supervisorCenters : centers?.data?.rows;

  const handleOnChangeCenter = () => {
    enqueueSnackbar(t('modals.success.center_selected'), { variant: 'success' });
  };

  useEffect(() => {
    dispatch(setCenterEntity({ center }));
  }, [dispatch, center]);

  useEffect(() => {
    setCenter(centerSelected);
  }, [centerSelected]);

  // Auto select the first center if the user changes doctor selected
  useEffect(() => {
    if (
      !isSupervisor &&
      center &&
      centersList?.length &&
      !centersList.find(({ id }) => id === center.id)
    ) {
      dispatch(setCenterEntity({ center: centersList[0] }));
    }
  }, [center, centersList, dispatch, isSupervisor]);

  return (
    <div className={classes.search}>
      <div className={classes.centerIcon}>
        <MapPinIcon />
      </div>

      <CenterSelectorForm
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        isLoadingCenters={isLoading}
        centers={centersList || []}
        setCenter={setCenter}
        handleOnChangeCenter={handleOnChangeCenter}
        center={center}
        setName={setName}
      />
    </div>
  );
};

export default CenterSelector;
