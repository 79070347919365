import PropTypes from 'prop-types';

import ToolbarActions from './components/ToolbarActions';
import ToolbarFilters from './components/ToolbarFilters';

const Toolbar = ({
  resetPage,
  reference,
  centerPrescriptor,
  centerRealizator,
  zone,
  company,
  doctor,
  patient,
  status,
  pendingDemands,
  setReference,
  setCenterPrescriptor,
  setCenterRealizator,
  setZone,
  setCompany,
  setDoctor,
  setPatient,
  setStatus,
  setPendingDemands,
  rolesQuery,
}) => {
  return (
    <div>
      <ToolbarActions />

      <ToolbarFilters
        resetPage={resetPage}
        reference={reference}
        centerPrescriptor={centerPrescriptor}
        centerRealizator={centerRealizator}
        zone={zone}
        company={company}
        doctor={doctor}
        patient={patient}
        status={status}
        pendingDemands={pendingDemands}
        setReference={setReference}
        setCenterPrescriptor={setCenterPrescriptor}
        setCenterRealizator={setCenterRealizator}
        setZone={setZone}
        setCompany={setCompany}
        setDoctor={setDoctor}
        setPatient={setPatient}
        setStatus={setStatus}
        setPendingDemands={setPendingDemands}
        rolesQuery={rolesQuery}
      />
    </div>
  );
};

Toolbar.propTypes = {
  resetPage: PropTypes.func,
  reference: PropTypes.string,
  centerPrescriptor: PropTypes.object,
  setCenterPrescriptor: PropTypes.func,
  centerRealizator: PropTypes.object,
  setCenterRealizator: PropTypes.func,
  zone: PropTypes.object,
  setZone: PropTypes.func,
  company: PropTypes.object,
  status: PropTypes.string,
  pendingDemands: PropTypes.bool,
  setReference: PropTypes.func,
  setCompany: PropTypes.func,
  doctor: PropTypes.object,
  setDoctor: PropTypes.func,
  patient: PropTypes.object,
  setPatient: PropTypes.func,
  setStatus: PropTypes.func,
  setPendingDemands: PropTypes.func,
  rolesQuery: PropTypes.object,
};

export default Toolbar;
