import { Box, Button, Container, makeStyles, TextField, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, Redirect, useHistory } from 'react-router-dom';
import AlertDialog from 'src/components/AlertDialog';
import Page from 'src/components/Page';
import { ID_CARD_REGEX } from 'src/constants/regex';
import { useForgotPassword } from 'src/hooks/auth';
import { isAuthenticated as isAuthenticatedSelector } from 'src/store/selectors/auth';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const initialValues = {
  email: '',
  dni: '',
};

const ForgotPasswordView = () => {
  const classes = useStyles();
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const history = useHistory();
  const { t } = useTranslation('auth');
  const [apiError, setApiError] = useState(null);
  const [forgotPassword, { isLoading, data, isError, error }] = useForgotPassword();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t('errors.email.valid')).required(t('errors.email.required')),
    dni: Yup.string()
      .matches(ID_CARD_REGEX, t('errors.id_card.pattern'))
      .required(t('errors.id_card.required')),
  });

  const handleSubmit = async (values, formikBag) => {
    const res = await forgotPassword(values);

    if (res) {
      setApiError(null);
      formikBag.resetForm();
    }
  };

  useEffect(() => {
    if (isError) {
      setApiError(error?.message || t('errors.common.forgot_password'));
    }
  }, [error?.message, isError, t]);

  if (isAuthenticated) {
    return <Redirect to="/app" />;
  }

  return (
    <Page className={classes.root}>
      <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
        <Container maxWidth="sm">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography color="textPrimary" variant="h2">
                    {t('labels.forgot_password_title')}
                  </Typography>
                  <Typography color="textSecondary" gutterBottom variant="body2">
                    {t('labels.forgot_password_subtitle')}
                  </Typography>
                </Box>

                {apiError && (
                  <Box mb={3}>
                    <Alert variant="filled" severity="error">
                      {apiError}
                    </Alert>
                  </Box>
                )}

                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label={t('fields.email')}
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                  disabled={isSubmitting}
                />
                <TextField
                  error={Boolean(touched.dni && errors.dni)}
                  fullWidth
                  helperText={touched.dni && errors.dni}
                  label={t('fields.id_card')}
                  margin="normal"
                  name="dni"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.dni}
                  variant="outlined"
                  disabled={isSubmitting}
                />

                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {t('buttons.forgot_password')}
                  </Button>
                </Box>

                <Typography color="textSecondary" variant="body1">
                  <Link to="/login" variant="h6">
                    {t('labels.signin_title')}
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>

          {!isLoading && !isError && data && (
            <AlertDialog
              title={t('modals.success.title_forgot_password')}
              text={data.data?.msg || t('modals.success.text_forgot_password')}
              type="success"
              acceptAction={() => history.push('/', { replace: true })}
            />
          )}
        </Container>
      </Box>
    </Page>
  );
};

export default ForgotPasswordView;
