import { useMutation, useQueryCache } from 'react-query';
import { updateUser } from 'src/services/api/users';

function useUpdateUser() {
  const queryCache = useQueryCache();

  return useMutation(({ user, data }) => updateUser(user, data), {
    onSuccess: (data) => queryCache.setQueryData(['user', data.data?.email], data),
  });
}

export default useUpdateUser;
