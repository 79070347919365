import { useMutation, useQueryCache } from 'react-query';
import { updateUserMe } from 'src/services/api/users';

function useUpdateUserMe() {
  const queryCache = useQueryCache();

  return useMutation((data) => updateUserMe(data), {
    onSuccess: (data) => queryCache.setQueryData(['user me'], data),
  });
}

export default useUpdateUserMe;
