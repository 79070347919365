import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import AttachmentsTable from './components';

const useStyles = makeStyles({
  card: {
    padding: '0 16px !important',
  },
  empty: {
    margin: 20,
  },
});

const DemandAttachments = ({ attachments }) => {
  const classes = useStyles();
  const { t } = useTranslation(['demands', 'common']);

  return (
    <Card>
      <CardHeader
        title={t('demands:labels.viewDemand_attachments_title')}
        subheader={t('demands:labels.viewDemand_attachments_subtitle')}
      />
      <Divider />
      <CardContent className={classes.card}>
        {attachments.length > 0 ? (
          <AttachmentsTable attachments={attachments} />
        ) : (
          <Box className={classes.empty} display="flex" flexDirection="column" alignItems="center">
            <Typography color="secondary" variant="h5">
              {t('labels.viewDemand_empty_text')}
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

DemandAttachments.propTypes = {
  attachments: PropTypes.array,
};

export default DemandAttachments;
