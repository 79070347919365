import { Grid, InputBase } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const CenterSelectorForm = ({
  classes,
  isLoadingCenters,
  centers,
  center,
  setCenter,
  handleOnChangeCenter,
  setName,
}) => {
  const { t } = useTranslation('centers');

  return (
    <Grid className={classes.inputRoot}>
      <Autocomplete
        name="center"
        options={centers}
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option, value) => option.id === value.id}
        value={center}
        loading={isLoadingCenters}
        onChange={(e, value) => {
          value ? setCenter(value) : setCenter(null);
          handleOnChangeCenter();
        }}
        renderInput={(params) => (
          <InputBase
            placeholder={t('labels.center_selection')}
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            classes={{
              root: classes.root,
              input: classes.input,
            }}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        )}
      />
    </Grid>
  );
};

CenterSelectorForm.propTypes = {
  classes: PropTypes.object,
  isLoadingCenters: PropTypes.bool,
  centers: PropTypes.array,
  center: PropTypes.object,
  setCenter: PropTypes.func,
  handleOnChangeCenter: PropTypes.func,
  setName: PropTypes.func,
};

export default CenterSelectorForm;
