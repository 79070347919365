import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import startOfToday from 'date-fns/startOfToday';
import { TRATAMIENTO_RADIOTERAPICO_INTENCION } from 'src/constants/demands';
import { FieldArray } from 'formik';
import { MinusCircle as MinusCircleIcon, PlusCircle as PlusCircleIcon } from 'react-feather';
import get from 'lodash/get';
import { KeyboardDatePicker } from '@material-ui/pickers';
import CustomSwitch from 'src/components/CustomSwitch';

const useStyles = makeStyles((theme) => ({
  cardMargin: {
    margin: theme.spacing(2),
    marginTop: 0,
  },
  iconMargin: {
    marginRight: theme.spacing(1),
  },
  removeTreatmentButton: {
    marginLeft: 'auto',
    color: theme.palette.error.main,
  },
  treatmentsError: {
    color: theme.palette.error.main,
  },
  switchMargins: {
    marginLeft: 0,
    marginRight: 0,
  },
}));

const EMPTY_RADIOTERAPICO = {
  intencion: '',
  codigo: [],
  descripcionDetallada: '',
  dosisTotal: '',
  fraccionamientoDia: '',
  fechaInicio: null,
  numeroSesiones: ''
};

const TratamientoRadioterapicoSwitch = ({
  values,
  handleChange,
  handleBlur,
  setFieldTouched,
  disabled,
  marginLeft,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('demands');

  return (
    <FormControlLabel
      control={
        <CustomSwitch
          name="tratamientoRadioterapico"
          checked={values.tratamientoRadioterapico}
          onChange={(e) => {
            setFieldTouched('tratamientoRadioterapico', true);
            return handleChange(e);
          }}
          onBlur={handleBlur}
          disabled={disabled}
        />
      }
      label={t('fields.tratamientoRadioterapico')}
      labelPlacement="start"
      className={!marginLeft ? classes.switchMargins : ''}
    />
  );
};

const RadioterapicoField = ({
  values,
  codes,
  isLoading,
  errors,
  touched,
  handleChange,
  setFieldValue,
  handleBlur,
  setFieldTouched,
  disabled,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('demands');

  if (!values.tratamientoRadioterapico) {
    return (
      <TratamientoRadioterapicoSwitch
        values={values}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldTouched={setFieldTouched}
        disabled={disabled}
        marginLeft={true}
      />
    );
  }

  return (
    <Card>
      <CardHeader
        disableTypography
        title={
          <TratamientoRadioterapicoSwitch
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldTouched={setFieldTouched}
            disabled={disabled}
            marginLeft={false}
          />
        }
        subheader={
          <Typography className={classes.treatmentsError}>
            {!values.tratamientosRadioterapicos.length
              ? t('errors.tratamientosRadioterapicos.required')
              : ''}
          </Typography>
        }
      />
      <CardContent>
        <FieldArray
          name="tratamientosRadioterapicos"
          render={(arrayHelpers) => (
            <>
              {values.tratamientosRadioterapicos.map((treatment, index) => (
                <Card key={index} className={classes.cardMargin}>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          error={Boolean(
                            get(touched, `tratamientosRadioterapicos[${index}].intencion`) &&
                              get(errors, `tratamientosRadioterapicos[${index}].intencion`),
                          )}
                          fullWidth
                          helperText={
                            get(touched, `tratamientosRadioterapicos[${index}].intencion`) &&
                            get(errors, `tratamientosRadioterapicos[${index}].intencion`)
                          }
                          required
                          select
                          SelectProps={{ native: true }}
                          label={t('fields.tratamientosRadioterapicos_fields.intencion')}
                          name={`tratamientosRadioterapicos[${index}].intencion`}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          disabled={disabled}
                          value={treatment.intencion}
                          variant="outlined"
                        >
                          <option value="" hidden></option>
                          <option value={TRATAMIENTO_RADIOTERAPICO_INTENCION.NEO_ADYUVANTE}>
                            {t(
                              'fields.tratamientosRadioterapicos_fields.intencion_options.neo_adyuvante',
                            )}
                          </option>
                          <option value={TRATAMIENTO_RADIOTERAPICO_INTENCION.ADYUVANTE}>
                            {t(
                              'fields.tratamientosRadioterapicos_fields.intencion_options.adyuvante',
                            )}
                          </option>
                          <option value={TRATAMIENTO_RADIOTERAPICO_INTENCION.PALIATIVO}>
                            {t(
                              'fields.tratamientosRadioterapicos_fields.intencion_options.paliativo',
                            )}
                          </option>
                          <option value={TRATAMIENTO_RADIOTERAPICO_INTENCION.CURATIVO}>
                            {t(
                              'fields.tratamientosRadioterapicos_fields.intencion_options.curativo',
                            )}
                          </option>
                          <option value={TRATAMIENTO_RADIOTERAPICO_INTENCION.RADICAL}>
                            {t(
                              'fields.tratamientosRadioterapicos_fields.intencion_options.radical',
                            )}
                          </option>
                          <option value={TRATAMIENTO_RADIOTERAPICO_INTENCION.NO_SE_INDICA}>
                            {t(
                              'fields.tratamientosRadioterapicos_fields.intencion_options.no_se_indica',
                            )}
                          </option>
                        </TextField>
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          openOnFocus={true}
                          options={codes || []}
                          getOptionLabel={(option) => {
                            const codeL = codes
                            ? codes.find((c) => c.id === option || c.id === option.id)
                            : '';
                            return codeL ? codeL.code + ' - ' + codeL.type : '';
                          }}
                          getOptionSelected={(option, value) => option.id === value?.id}
                          value={codes?.find((c) => c.id === treatment.codigo) || null}
                          filterSelectedOptions
                          name={`tratamientosRadioterapicos[${index}].codigo`}
                          loading={isLoading}
                          disabled={disabled}
                          onChange={(e, value) => {
                              const codeSelected = typeof value === 'object' ? value?.id : value;
                              /*
                              // TODO: Crear conciertos por hospitales
                              if(
                                  [4].includes(values.companyId) &&
                                  codeSelected !== null && 
                                  (
                                    
                                    [1,2,3,4,5,7,40].includes(values.centerPrescriptorId)
                                    && !([1,2].includes(values.centerPrescriptorId) && [21, 15, 16, 17, 25, 19, 20, 18].includes(codeSelected))
                                    && !([2,3].includes(values.centerPrescriptorId) && [21, 25, 19, 20, 18].includes(codeSelected))
                                    && !([3,4].includes(values.centerPrescriptorId) && [21, 15, 16, 17, 25, 18].includes(codeSelected))
                                    && !([4,5,7,40].includes(values.centerPrescriptorId) && [25].includes(codeSelected))
                                  )
                              ) {
                                alert("Esté código de radioterapia no se encuentra en los conciertos establecidos para su hospital.");
                                codeSelected = null;
                              } else {
                              */  
                                setFieldValue(
                                  `tratamientosRadioterapicos[${index}].codigo`,
                                  codeSelected,
                                );
                              // }
                          }}
                          onBlur={() =>
                            setFieldTouched(`tratamientosRadioterapicos[${index}].codigo`, true)
                          }
                          renderInput={(params) => (
                            <TextField
                              required
                              {...params}
                              label={t('fields.tratamientosRadioterapicos_fields.codigo')}
                              variant="outlined"
                              error={Boolean(
                                get(touched, `tratamientosRadioterapicos[${index}].codigo`) &&
                                  get(errors, `tratamientosRadioterapicos[${index}].codigo`),
                              )}
                              helperText={
                                get(touched, `tratamientosRadioterapicos[${index}].codigo`) &&
                                get(errors, `tratamientosRadioterapicos[${index}].codigo`)
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          error={Boolean(
                            get(
                              touched,
                              `tratamientosRadioterapicos[${index}].descripcionDetallada`,
                            ) &&
                              get(
                                errors,
                                `tratamientosRadioterapicos[${index}].descripcionDetallada`,
                              ),
                          )}
                          multiline
                          fullWidth
                          required
                          helperText={
                            get(
                              touched,
                              `tratamientosRadioterapicos[${index}].descripcionDetallada`,
                            ) &&
                            get(errors, `tratamientosRadioterapicos[${index}].descripcionDetallada`)
                          }
                          label={t('fields.tratamientosRadioterapicos_fields.descripcionDetallada')}
                          name={`tratamientosRadioterapicos[${index}].descripcionDetallada`}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          disabled={disabled}
                          value={treatment.descripcionDetallada}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          type="number"
                          inputProps={{ min: 0, step: '.01' }}
                          error={Boolean(
                            get(touched, `tratamientosRadioterapicos[${index}].dosisTotal`) &&
                              get(errors, `tratamientosRadioterapicos[${index}].dosisTotal`),
                          )}
                          fullWidth
                          required
                          helperText={
                            get(touched, `tratamientosRadioterapicos[${index}].dosisTotal`) &&
                            get(errors, `tratamientosRadioterapicos[${index}].dosisTotal`)
                          }
                          label={t('fields.tratamientosRadioterapicos_fields.dosisTotal')}
                          name={`tratamientosRadioterapicos[${index}].dosisTotal`}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          disabled={disabled}
                          value={treatment.dosisTotal}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          type="number"
                          inputProps={{ min: 0, step: '1' }}
                          error={Boolean(
                            get(
                              touched,
                              `tratamientosRadioterapicos[${index}].fraccionamientoDia`,
                            ) &&
                              get(
                                errors,
                                `tratamientosRadioterapicos[${index}].fraccionamientoDia`,
                              ),
                          )}
                          fullWidth
                          required
                          helperText={
                            get(
                              touched,
                              `tratamientosRadioterapicos[${index}].fraccionamientoDia`,
                            ) &&
                            get(errors, `tratamientosRadioterapicos[${index}].fraccionamientoDia`)
                          }
                          label={t('fields.tratamientosRadioterapicos_fields.fraccionamientoDia')}
                          name={`tratamientosRadioterapicos[${index}].fraccionamientoDia`}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          disabled={disabled}
                          value={treatment.fraccionamientoDia}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <KeyboardDatePicker
                          fullWidth
                          required
                          disableToolbar
                          format="dd/MM/yyyy"
                          minDate={startOfToday()}
                          label={t('fields.tratamientosRadioterapicos_fields.fechaInicio')}
                          error={Boolean(
                            get(touched, `tratamientosRadioterapicos[${index}].fechaInicio`) &&
                              get(errors, `tratamientosRadioterapicos[${index}].fechaInicio`),
                          )}
                          helperText={
                            get(touched, `tratamientosRadioterapicos[${index}].fechaInicio`) &&
                            get(errors, `tratamientosRadioterapicos[${index}].fechaInicio`)
                          }
                          name={`tratamientosRadioterapicos[${index}].fechaInicio`}
                          value={treatment.fechaInicio}
                          onChange={(date) =>
                            setFieldValue(`tratamientosRadioterapicos[${index}].fechaInicio`, date)
                          }
                          onBlur={() =>
                            setFieldTouched(
                              `tratamientosRadioterapicos[${index}].fechaInicio`,
                              true,
                            )
                          }
                          disabled={disabled}
                          variant="inline"
                          inputVariant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          type="number"
                          inputProps={{ min: 0, step: '1' }}
                          error={Boolean(
                            get(touched, `tratamientosRadioterapicos[${index}].numeroSesiones`) &&
                              get(errors, `tratamientosRadioterapicos[${index}].numeroSesiones`),
                          )}
                          fullWidth
                          required
                          helperText={
                            get(touched, `tratamientosRadioterapicos[${index}].numeroSesiones`) &&
                            get(errors, `tratamientosRadioterapicos[${index}].numeroSesiones`)
                          }
                          label={t('fields.tratamientosRadioterapicos_fields.numeroSesiones')}
                          name={`tratamientosRadioterapicos[${index}].numeroSesiones`}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          disabled={disabled}
                          value={treatment.numeroSesiones}
                          variant="outlined"
                        />
                      </Grid>
                      <Button
                        className={classes.removeTreatmentButton}
                        size="small"
                        onClick={() => arrayHelpers.remove(index)}
                        disabled={disabled}
                      >
                        <MinusCircleIcon className={classes.iconMargin} size={18} />
                        {t('buttons.remove_radioterapico')}
                      </Button>
                    </Grid>
                  </CardContent>
                </Card>
              ))}

              <Button
                onClick={() => {
                  arrayHelpers.push(EMPTY_RADIOTERAPICO);
                }}
                disabled={disabled}
              >
                <PlusCircleIcon className={classes.iconMargin} /> {t('buttons.add_radioterapico')}
              </Button>
            </>
          )}
        />
      </CardContent>
    </Card>
  );
};

RadioterapicoField.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  handleBlur: PropTypes.func,
  setFieldTouched: PropTypes.func,
  disabled: PropTypes.bool,
  marginLeft: PropTypes.bool,
  codes: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default RadioterapicoField;
