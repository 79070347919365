import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SearchPatient from 'src/components/Patients/SearchPatient';
import { isCenterSelected, isDoctorSelected } from 'src/store/selectors/entity';

const Step1 = ({ 
  demand, 
  setDemand, 
  disableForm, 
  setDisableNext, 
  isContinue, 
  isChangeDates,
  demandExtras,
  setDemandExtras,
  status}) => {
  const [patientId, setPatientId] = useState(demand.step1.patientId || '');
  const [ficherosAdjuntos, setFicherosAdjuntos] = useState(demand.step1.ficherosAdjuntos || []);
  const [eliminarFicherosAdjuntos, setEliminarFicherosAdjuntos] = useState(demand.step1.eliminarFicherosAdjuntos || []);
  const doctor = useSelector(isDoctorSelected);
  const center = useSelector(isCenterSelected);
  
  useEffect(() => {
    setDemand({
      ...demand,
      step1: {
        doctorId: doctor?.id || undefined,
        centerPrescriptorId: center?.id,
        patientId,
        ficherosAdjuntos,
        eliminarFicherosAdjuntos,
        status
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center?.id, doctor?.id, patientId, ficherosAdjuntos, eliminarFicherosAdjuntos, setDemand]);

  return (
    <SearchPatient
      patientId={patientId}
      setPatientId={setPatientId}
      demandExtras={demandExtras}
      setDemandExtras={setDemandExtras}
      disableForm={disableForm || isContinue || isChangeDates}
      setDisableNext={setDisableNext}
      ficherosAdjuntos={ficherosAdjuntos}
      setFicherosAdjuntos={setFicherosAdjuntos}
      eliminarFicherosAdjuntos={eliminarFicherosAdjuntos}
      setEliminarFicherosAdjuntos={setEliminarFicherosAdjuntos}
      status={status}
    />
    
  );
};

Step1.propTypes = {
  demand: PropTypes.object,
  setDemand: PropTypes.func,
  demandExtras: PropTypes.object,
  setDemandExtras: PropTypes.func,
  disableForm: PropTypes.bool,
  setDisableNext: PropTypes.func,
  isContinue: PropTypes.bool,
  isChangeDates: PropTypes.bool,
  status: PropTypes.string,
};

export default Step1;
