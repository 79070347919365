import {
  Box,
  Card,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Clipboard as ClipboardIcon,
  Edit as EditIcon,
  Eye as EyeIcon,
  Trash as TrashIcon,
} from 'react-feather';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink } from 'react-router-dom';
import EmptyList from 'src/components/EmptyList';

const useStyles = makeStyles((theme) => ({
  root: {},
  table_list: {
    '& th': {
      padding: '5px',
    },
    '& td': {
      padding: '5px',
      color: theme.palette.tertiary.main,
      '& a,button': {
        padding: '0px 5px',
      },
    },
  },
}));

const Results = ({
  className,
  presentations,
  page,
  setPage,
  resetPage,
  pageSize,
  setPageSize,
  sort,
  setSort,
  isLoading,
  deletePresentation,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('presentations');
  const { t: tCommon } = useTranslation('common');

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleSortChange = (order) => {
    resetPage();

    if (sort === order) {
      setSort(`-${order}`);
    } else {
      setSort(order);
    }
  };

  return (
    <Card className={clsx(classes.root, className)}>
      <PerfectScrollbar>
        <Box>
          <Table className={classes.table_list}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={sort === 'registerNumber' || sort === '-registerNumber'}
                    direction={sort === 'registerNumber' ? 'asc' : 'desc'}
                    onClick={() => {
                      handleSortChange('registerNumber');
                    }}
                  >
                    {t('fields.registerNumber')}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sort === 'nationalCode' || sort === '-nationalCode'}
                    direction={sort === 'nationalCode' ? 'asc' : 'desc'}
                    onClick={() => {
                      handleSortChange('nationalCode');
                    }}
                  >
                    {t('fields.nationalCode')}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sort === 'presentation' || sort === '-presentation'}
                    direction={sort === 'presentation' ? 'asc' : 'desc'}
                    onClick={() => {
                      handleSortChange('presentation');
                    }}
                  >
                    {t('fields.presentation')}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="right">{tCommon('tables.rows.actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {presentations?.rows.map((presentation) => (
                <TableRow hover key={presentation.id}>
                  <TableCell>{presentation.registerNumber}</TableCell>
                  <TableCell>{presentation.nationalCode}</TableCell>
                  <TableCell>{presentation.presentation}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      color="primary"
                      value={presentation.id}
                      component={RouterLink}
                      to={`/app/presentations/${presentation.id}/edit`}
                      disabled={isLoading}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="primary"
                      value={presentation.id}
                      component={RouterLink}
                      to={`/app/presentations/${presentation.id}`}
                      disabled={isLoading}
                    >
                      <EyeIcon />
                    </IconButton>
                    <IconButton
                      color="primary"
                      onClick={() => deletePresentation(presentation.id)}
                      disabled={isLoading}
                    >
                      <TrashIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {!isLoading && !presentations?.rows.length && (
            <EmptyList text={t('labels.empty_list_text')}>
              <ClipboardIcon />
            </EmptyList>
          )}
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={presentations?.count || 0}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePageSizeChange}
        page={presentations?.count ? page - 1 : 0}
        rowsPerPage={pageSize}
        rowsPerPageOptions={[25, 50, 100]}
      />
    </Card>
  );
};

Results.propTypes = {
  presentations: PropTypes.object,
  page: PropTypes.number,
  setPage: PropTypes.func,
  resetPage: PropTypes.func,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
  sort: PropTypes.string,
  setSort: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default Results;
