import { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import useUpdateUserMe from 'src/hooks/users/useUpdateUserMe';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from 'src/store/reducers/user';
import { setPasswordExpired, setRequireResetPassword } from 'src/store/reducers/app';
import omit from 'lodash/omit';
import get from 'lodash/get';
import AccountDetailsForm from './components';
import { useSnackbar } from 'notistack';
import AlertDialog from 'src/components/AlertDialog';

const ProfileDetails = ({ user }) => {
  const { t } = useTranslation('account');
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [updateUserMe, { isLoading, data, isError, error }] = useUpdateUserMe();
  const previousRequireResetPasswordValue = useRef(!!user?.requireResetPassword);
  const passwordExpired = useSelector((state) => state.app.passwordExpired);
  const mustResetPassword = useSelector((state) => state.app.requireResetPassword);

  const handleSubmit = async (values, formikBag) => {
    const res = await updateUserMe(values);

    if (res?.data) {
      const requireResetPassword = !!res.data.requireResetPassword;
      dispatch(updateUser(omit({ ...values, requireResetPassword }, 'password')));

      if (passwordExpired && values.password) {
        dispatch(setPasswordExpired(false));
      }
      if (mustResetPassword && values.password) {
        dispatch(setRequireResetPassword(false));
      }

      formikBag.resetForm({
        values: {
          ...omit(values, ['password', 'confirmPassword']),
          showPasswordForm: false,
        },
      });
    }
  };

  useEffect(() => {
    if (!isLoading && !isError && data) {
      enqueueSnackbar(t('modals.user.success.text'), { variant: 'success' });

      if (
        previousRequireResetPasswordValue.current &&
        !get(data, 'data.requireResetPassword', false)
      ) {
        const from = get(location, 'state.from', '/app/home');
        history.push(from);
      }
    }
  }, [data, enqueueSnackbar, history, isError, isLoading, location, t]);

  return (
    <Card>
      <CardHeader
        subheader={t('labels.updateUser_subtitle')}
        title={t('labels.updateUser_title')}
      />
      <CardContent>
        <AccountDetailsForm handleFormSubmit={handleSubmit} user={user} />

        {isError && (
          <AlertDialog
            title={t('modals.user.error.title')}
            text={error?.message || t('modals.user.error.text')}
            type="error"
          />
        )}
      </CardContent>
    </Card>
  );
};

ProfileDetails.propTypes = {
  user: PropTypes.object,
};

export default ProfileDetails;
