import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Check as CheckIcon, X as XIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {},
  swatch: {
    marginTop: '8px',
    padding: '5px',
    background: '#fff',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
  },
  color: {
    paddingTop: '5px',
    width: '30px',
    height: '25px',
    borderRadius: '2px',
  },
  check: {
    marginTop: '8px',
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const CompanyDetailsForm = ({ handleCancel, company }) => {
  const classes = useStyles();
  const { t } = useTranslation('companies');

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5" component="h4">
          {t('fields.subdomain')}
        </Typography>
        <Typography variant="h5" component="h4" color="secondary">
          {company?.subdomain}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" component="h4">
          {t('fields.slogan')}
        </Typography>
        <Typography variant="h5" component="h4" color="secondary">
          {company?.slogan}
        </Typography>
      </Grid>
      <Grid align="center" item xs={4}>
        <Typography variant="h5" component="h4">
          {t('fields.primaryColor')}
        </Typography>
        <Box className={classes.swatch}>
          <Box className={classes.color} style={{ background: `#${company?.primaryColor}` }} />
        </Box>
      </Grid>
      <Grid align="center" item xs={4}>
        <Typography variant="h5" component="h4">
          {t('fields.secondaryColor')}
        </Typography>
        <Box className={classes.swatch}>
          <Box className={classes.color} style={{ background: `#${company?.secondaryColor}` }} />
        </Box>
      </Grid>
      <Grid align="center" item xs={4}>
        <Typography variant="h5" component="h4">
          {t('fields.tertiaryColor')}
        </Typography>
        <Box className={classes.swatch}>
          <Box className={classes.color} style={{ background: `#${company?.tertiaryColor}` }} />
        </Box>
      </Grid>
      <Grid align="center" item xs={4}>
        <Typography variant="h5" component="h4">
          {t('fields.active')}
          <Grid item xs={12} className={classes.check}>
            {company?.active ? <CheckIcon></CheckIcon> : <XIcon></XIcon>}
          </Grid>
        </Typography>
      </Grid>
      <Grid align="center" item xs={4}>
        <Typography variant="h5" component="h4">
          {t('fields.default')}
          <Grid item xs={12} className={classes.check}>
            {company?.default ? <CheckIcon></CheckIcon> : <XIcon></XIcon>}
          </Grid>
        </Typography>
      </Grid>
      <Grid align="center" item xs={4}>
        <Typography variant="h5" component="h4">
          {t('fields.tramitByZones')}
          <Grid item xs={12} className={classes.check}>
            {company?.tramitByZones ? <CheckIcon></CheckIcon> : <XIcon></XIcon>}
          </Grid>
        </Typography>
      </Grid>
      <Box p={2}>
        <Button
          className={classes.button}
          color="primary"
          variant="outlined"
          onClick={handleCancel}
        >
          {t('buttons.cancel')}
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          component={RouterLink}
          to={`/app/companies/${company.id}/edit`}
        >
          {t('buttons.edit_company')}
        </Button>
      </Box>
    </Grid>
  );
};

CompanyDetailsForm.propTypes = {
  handleCancel: PropTypes.func,
  company: PropTypes.object,
};

export default CompanyDetailsForm;
