import { useMutation, useQueryCache } from 'react-query';
import { updatePresentation } from 'src/services/api/presentations';

function useUpdatePresentation() {
  const queryCache = useQueryCache();

  return useMutation(({ presentation, data }) => updatePresentation(presentation, data), {
    onSuccess: (data) => queryCache.setQueryData(['presentation', data.data?.id], data),
  });
}

export default useUpdatePresentation;
