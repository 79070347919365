import { Box, makeStyles, Typography } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import format from 'date-fns/format';
import {
  RefreshCcw as RefreshCcwIcon,
  Save as SaveIcon,
  XCircle as XCircleIcon,
} from 'react-feather';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  text: {
    marginLeft: theme.spacing(1),
    fontSize: '0.9rem',
  },
  error: {
    color: theme.palette.error.main,
  },
}));

const SavingStatus = ({ pendingChanges, isDemandCreated, isError }) => {
  const classes = useStyles();
  const { t } = useTranslation('demands');
  const [lastSavedTime, setLastSavedTime] = useState(new Date());

  useEffect(() => {
    if (!pendingChanges && !isError) {
      setLastSavedTime(new Date());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingChanges]);

  return (
    <Box
      className={isError && !pendingChanges ? classes.error : null}
      mr={2}
      mt={2}
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
    >
      {isDemandCreated && pendingChanges && (
        <>
          <RefreshCcwIcon size={20} />
          <Typography className={classes.text}>{t('labels.saving')}</Typography>
        </>
      )}
      {!isError && (!isDemandCreated || (!pendingChanges && lastSavedTime)) && (
        <>
          <SaveIcon size={20} />
          <Typography className={classes.text}>
            {t('labels.last_save_time', { time: format(lastSavedTime, 'HH:mm') })}
          </Typography>
        </>
      )}
      {isError && !pendingChanges && (
        <>
          <XCircleIcon size={20} />
          <Typography className={classes.text}>{t('labels.save_draft_error')}</Typography>
        </>
      )}
    </Box>
  );
};

SavingStatus.propTypes = {
  pendingChanges: PropTypes.bool,
  isDemandCreated: PropTypes.bool,
  isError: PropTypes.bool,
};

export default SavingStatus;
