import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { getMediaUrl } from 'src/utils/getMediaUrl';

const DEFAULT_LOGO = '/static/logo.svg';
const useStyles = makeStyles(() => ({
  companyLogo: {
    maxHeight: '47px',
  },
}));

const Logo = ({ className, ...props }) => {
  const classes = useStyles();
  const appState = useSelector((state) => state.app);
  let logoSrc = DEFAULT_LOGO;

  const logo = get(appState, 'data.logo.id');
  if (logo) {
    logoSrc = getMediaUrl(logo);
  }

  return (
    <img alt="Logo" className={clsx(classes.companyLogo, className)} src={logoSrc} {...props} />
  );
};

Logo.propTypes = {
  className: PropTypes.string,
};

export default Logo;
