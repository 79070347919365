import { useQuery } from 'react-query';
import { getCompanyPolicy } from 'src/services/api/companies';

function useGetCompanyPolicy(policy) {
  return useQuery(['company_policy', policy], (key, policy) => getCompanyPolicy(policy), {
    cacheTime: 5 * 60 * 1000,
  });
}

export default useGetCompanyPolicy;
