import { useSnackbar } from 'notistack';
import { PropTypes } from 'prop-types';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AlertDialog from 'src/components/AlertDialog';
import {
  useCreatePatient,
  useGetPatient,
  useListPatients,
  useUpdatePatient,
} from 'src/hooks/patients';

import SearchPatientForm from './components';

const SearchPatient = ({
  patientId: step1Patient,
  setPatientId: setStep1Patient,
  disableForm,
  setDisableNext,
  demandExtras,
  setDemandExtras,
  ficherosAdjuntos,
  setFicherosAdjuntos,
  eliminarFicherosAdjuntos,
  setEliminarFicherosAdjuntos,
  status
}) => {
  const { t } = useTranslation('patients');
  const { enqueueSnackbar } = useSnackbar();
  const userCompanyId = useSelector((state) => state.user.user?.company?.id);
  const [cardNumber, setCardNumber] = useState();
  const [patient, setPatient] = useState();
  const { isLoading: isLoadingPatient, data, isError: isErrorPatient } = useGetPatient(
    step1Patient,
    { include: 'company' },
    Boolean(step1Patient && !patient),
  );
  const {
    isFetching: isFetchingPatients,
    refetch: refetchPatients,
    resolvedData: patients,
    isError: isErrorPatients,
  } = useListPatients(
    {
      companyId: userCompanyId,
      cardNumber,
      all: true,
      include: 'company',
    },
    !!cardNumber?.length,
  );
  const [
    updatePatient,
    { isLoading: isLoadingUpdate, data: updatedData, isError: isErrorUpdate },
  ] = useUpdatePatient();
  const [
    createPatient,
    { isLoading: isLoadingCreate, data: newPatient, isError: isErrorCreate },
  ] = useCreatePatient();

  const handleSubmit = async (patientId, values) => {
    setDisableNext(true);

    if (patientId) {
      const res = await updatePatient({ patient: patientId, data: values });

      if (res.data) {
        setDisableNext(false);
      }
    } else {
      const res = await createPatient(values);

      if (res.data) {
        setPatient(res.data);
        setDisableNext(false);
        await refetchPatients();
      }
    }
  };

  // Auto set patient when user navigates back from step 2
  useEffect(() => {
    if (!isLoadingPatient && !isErrorPatient && data) {
      setPatient(data.data);
    }
  }, [data, isErrorPatient, isLoadingPatient]);

  // Save patient id in demand data on every patient change
  useEffect(() => {
    if (!isLoadingPatient) {
      setStep1Patient(patient?.id);

      setDisableNext(!patient);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient, setStep1Patient]);

  // Modals and toasts
  useEffect(() => {
    if (isErrorPatient) {
      enqueueSnackbar(t('modals.error.title_get_patient'), {
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, isErrorPatient, t]);

  useEffect(() => {
    if (isErrorPatients) {
      enqueueSnackbar(t('modals.error.title_list_patients'), {
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, isErrorPatients, t]);

  useEffect(() => {
    if (!isLoadingUpdate && !isErrorUpdate && updatedData) {
      enqueueSnackbar(t('modals.success.text_update_patient'), {
        variant: 'success',
      });
    }
  }, [enqueueSnackbar, isErrorUpdate, isLoadingUpdate, t, updatedData]);

  useEffect(() => {
    if (!isLoadingCreate && !isErrorCreate && newPatient) {
      enqueueSnackbar(t('modals.success.text_create_patient'), {
        variant: 'success',
      });
    }
  }, [enqueueSnackbar, isErrorCreate, isLoadingCreate, newPatient, t]);

  return (
    <>
      <SearchPatientForm
        isLoadingPatients={isFetchingPatients}
        patients={patients?.data?.rows || []}
        handleFormSubmit={handleSubmit}
        setCardNumber={setCardNumber}
        setPatient={setPatient}
        setStep1Patient={setStep1Patient}
        patient={patient}
        disableForm={disableForm}
        demandExtras={demandExtras}
        setDemandExtras={setDemandExtras}
        ficherosAdjuntos={ficherosAdjuntos}
        setFicherosAdjuntos={setFicherosAdjuntos}
        eliminarFicherosAdjuntos={eliminarFicherosAdjuntos}
        setEliminarFicherosAdjuntos={setEliminarFicherosAdjuntos}
        status={status}
      />

      {isErrorUpdate && (
        <AlertDialog
          title={t('modals.error.title_update_patient')}
          text={t('modals.error.text_update_patient')}
          type="error"
        />
      )}
      {isErrorCreate && (
        <AlertDialog
          title={t('modals.error.title_create_patient')}
          text={t('modals.error.text_create_patient')}
          type="error"
        />
      )}
    </>
  );
};

SearchPatient.propTypes = {
  step1Patient: PropTypes.object,
  setStep1Patient: PropTypes.func,
  disableForm: PropTypes.bool,
  setDisableNext: PropTypes.func,
  ficherosAdjuntos: PropTypes.array,
  setFicherosAdjuntos: PropTypes.func,
  demandExtras: PropTypes.object,
  setDemandExtras: PropTypes.func,
  status: PropTypes.string,
};

export default SearchPatient;
