import { useEffect, useState } from 'react';
import { Box, Container, LinearProgress, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './components/Results';
import Toolbar from './components/Toolbar';
import {
  useValidateInvitation,
  useRejectInvitation,
  useListInvitations,
} from 'src/hooks/invitations';
import { usePagination } from 'src/hooks/pagination';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { setFilters } from 'src/store/reducers/lists';
import AlertDialog from 'src/components/AlertDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const ListInvitationsView = () => {
  const classes = useStyles();
  const { t } = useTranslation('invitations');
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.lists.invitations?.filters);
  const {
    page,
    pageSize,
    setPage,
    setPageSize,
    resetPage,
    fitsInPage,
    navigateToLastPage,
  } = usePagination(filters?.page, filters?.pageSize);
  const [email, setEmail] = useState(filters?.email || '');
  const [status, setStatus] = useState(filters?.status || '');
  const [sort, setSort] = useState(filters?.sort || '');
  const [
    validateInvitation,
    {
      isLoading: isLoadingValidate,
      data: invitationValidated,
      isError: isErrorValidate,
      error: errorValidate,
    },
  ] = useValidateInvitation();
  const [
    rejectInvitation,
    {
      isLoading: isLoadingReject,
      data: invitationRejected,
      isError: isErrorReject,
      error: errorReject,
    },
  ] = useRejectInvitation();

  const query = { include: 'company', page, pageSize, sort, email, status };
  const {
    isFetching,
    resolvedData: invitations,
    refetch,
    isError: isErrorList,
  } = useListInvitations(query);

  if (invitations?.data.count && !fitsInPage(invitations.data.count)) {
    navigateToLastPage(invitations.data.count);
  }

  const handleValidateInvitation = async (invitation) => {
    await validateInvitation(invitation.id);
    await refetch();
  };

  const handleRejectInvitation = async (invitation) => {
    await rejectInvitation(invitation.id);
    await refetch();
  };

  useEffect(() => {
    dispatch(
      setFilters({
        list: 'invitations',
        filters: { page, pageSize, sort, email, status },
      }),
    );
  }, [dispatch, email, page, pageSize, sort, status]);

  useEffect(() => {
    if (isErrorList) {
      enqueueSnackbar(t('modals.error.title_list_invitations'), { variant: 'error' });
    }
  }, [enqueueSnackbar, isErrorList, t]);

  useEffect(() => {
    if (!isLoadingValidate && !isErrorValidate && invitationValidated) {
      enqueueSnackbar(t('modals.success.text_validate_invitation'), { variant: 'success' });
    }
  }, [enqueueSnackbar, invitationValidated, isErrorValidate, isLoadingValidate, t]);

  useEffect(() => {
    if (!isLoadingReject && !isErrorReject && invitationRejected) {
      enqueueSnackbar(t('modals.success.text_reject_invitation'), { variant: 'success' });
    }
  }, [enqueueSnackbar, invitationRejected, isErrorReject, isLoadingReject, t]);

  return (
    <Page className={classes.root}>
      <Container maxWidth={false}>
        <Toolbar
          setPage={setPage}
          resetPage={resetPage}
          email={email}
          setEmail={setEmail}
          status={status}
          setStatus={setStatus}
        />

        <Box mt={3}>
          {isFetching && <LinearProgress color="primary" />}
          <Results
            invitations={invitations?.data}
            page={page}
            setPage={setPage}
            resetPage={resetPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            sort={sort}
            setSort={setSort}
            validateInvitation={handleValidateInvitation}
            rejectInvitation={handleRejectInvitation}
            isLoading={isLoadingValidate || isLoadingReject || isFetching}
          />

          {isErrorValidate && (
            <AlertDialog
              title={t('modals.error.title_validate_invitation')}
              text={errorValidate?.message || t('modals.error.text_validate_invitation')}
              type="error"
            />
          )}
          {isErrorReject && (
            <AlertDialog
              title={t('modals.error.title_reject_invitation')}
              text={errorReject?.message || t('modals.error.text_reject_invitation')}
              type="error"
            />
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default ListInvitationsView;
