import {
  Avatar,
  Box,
  Card,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Eye as EyeIcon,
  Check as CheckIcon,
  Edit as EditIcon,
  X as XIcon,
  Briefcase as BriefcaseIcon,
  Users as UsersIcon,
} from 'react-feather';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import EmptyList from 'src/components/EmptyList';
import { setFilters } from 'src/store/reducers/lists';
import { isSuperAdmin as isSuperAdminSelector } from 'src/store/selectors/user';
import { getInitials } from 'src/utils/getInitials';
import { getMediaUrl } from 'src/utils/getMediaUrl';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2),
  },
  table_list: {
    '& th': {
      padding: '5px',
    },
    '& td': {
      padding: '5px',
      color: theme.palette.tertiary.main,
      '& a,button': {
        padding: '0px 5px',
      },
    },
  },
}));

const Results = ({
  className,
  companies,
  page,
  setPage,
  resetPage,
  pageSize,
  setPageSize,
  sort,
  setSort,
  isLoading,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('companies');
  const { t: tCommon } = useTranslation('common');
  const isSuperAdmin = useSelector(isSuperAdminSelector);

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleSortChange = (order) => {
    resetPage();

    if (sort === order) {
      setSort(`-${order}`);
    } else {
      setSort(order);
    }
  };

  const viewCompanyUsers = (companyData) => {
    const company = { id: companyData.id, name: companyData.name };
    dispatch(setFilters({ list: 'users', filters: { company } }));
    history.push('/app/users');
  };

  return (
    <Card className={clsx(classes.root, className)}>
      <PerfectScrollbar>
        <Box>
          <Table className={classes.table_list}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={sort === 'name' || sort === '-name'}
                    direction={sort === 'name' ? 'asc' : 'desc'}
                    onClick={() => {
                      handleSortChange('name');
                    }}
                  >
                    {t('fields.name')}
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sort === 'subdomain' || sort === '-subdomain'}
                    direction={sort === 'subdomain' ? 'asc' : 'desc'}
                    onClick={() => {
                      handleSortChange('subdomain');
                    }}
                  >
                    {t('fields.subdomain')}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center">{t('fields.default')}</TableCell>
                <TableCell align="center">{t('fields.active')}</TableCell>
                <TableCell align="center">{t('fields.tramitByZones')}</TableCell>
                <TableCell align="right">{tCommon('tables.rows.actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companies?.rows.map((company) => (
                <TableRow hover key={company.id}>
                  <TableCell>
                    <Box alignItems="center" display="flex">
                      <Avatar
                        className={classes.avatar}
                        src={company.logoId ? getMediaUrl(company.logoId) : ''}
                      >
                        {getInitials(company.name)}
                      </Avatar>
                      <Typography color="textPrimary" variant="body1">
                        {company.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{company.subdomain}</TableCell>

                  <TableCell align="center">
                    {company.default ? <CheckIcon></CheckIcon> : <XIcon></XIcon>}
                  </TableCell>
                  <TableCell align="center">
                    {company.active ? <CheckIcon></CheckIcon> : <XIcon></XIcon>}
                  </TableCell>
                  <TableCell align="center">
                    {company.tramitByZones ? <CheckIcon></CheckIcon> : <XIcon></XIcon>}
                  </TableCell>
                  <TableCell align="right">
                    {isSuperAdmin ? (
                      <IconButton
                        color="primary"
                        value={company.id}
                        component={RouterLink}
                        to={`/app/companies/${company.id}/edit`}
                        disabled={isLoading}
                      >
                        <EditIcon />
                      </IconButton>
                    ) : null}
                    <IconButton
                      color="primary"
                      value={company.id}
                      component={RouterLink}
                      to={`/app/companies/${company.id}`}
                      disabled={isLoading}
                    >
                      <EyeIcon />
                    </IconButton>
                    <IconButton
                      color="primary"
                      disabled={isLoading}
                      onClick={() => viewCompanyUsers(company)}
                    >
                      <UsersIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {!isLoading && !companies?.rows.length && (
            <EmptyList text={t('labels.empty_list_text')}>
              <BriefcaseIcon />
            </EmptyList>
          )}
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={companies?.count || 0}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePageSizeChange}
        page={companies?.count ? page - 1 : 0}
        rowsPerPage={pageSize}
        rowsPerPageOptions={[25, 50, 100]}
      />
    </Card>
  );
};

Results.propTypes = {
  companies: PropTypes.object,
  page: PropTypes.number,
  setPage: PropTypes.func,
  resetPage: PropTypes.func,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
  sort: PropTypes.string,
  setSort: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default Results;
