import { Card, CardContent, CardHeader, Container, Divider, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AlertDialog from 'src/components/AlertDialog';
import Page from 'src/components/Page';
import CreateEditPatientForm from 'src/components/Patients/CreateEditPatientForm';
import { useGoBack } from 'src/hooks/common';
import { useCreatePatient } from 'src/hooks/patients';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const CreatePatientView = () => {
  const { t } = useTranslation('patients');
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const goBack = useGoBack('/app/patients');
  const history = useHistory();
  const [createPatient, { isLoading, data, isError, error }] = useCreatePatient();

  const handleSubmit = ({ values }) => createPatient(values);

  const handleCancel = () => {
    history.push('/app/patients', { replace: true });
  };

  useEffect(() => {
    if (!isLoading && !isError && data) {
      enqueueSnackbar(t('modals.success.text_create_patient'), { variant: 'success' });

      goBack();
    }
  }, [data, enqueueSnackbar, goBack, isError, isLoading, t]);

  return (
    <Page className={classes.root}>
      <Container maxWidth="lg">
        <Card>
          <CardHeader
            subheader={t('labels.createPatient_subtitle')}
            title={t('labels.createPatient_title')}
          />
          <Divider />
          <CardContent>
            <CreateEditPatientForm
              handleFormSubmit={handleSubmit}
              handleCancel={handleCancel}
              action="create"
            />

            {isError && (
              <AlertDialog
                title={t('modals.error.title_create_patient')}
                text={error?.message || t('modals.error.text_create_patient')}
                type="error"
              />
            )}
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default CreatePatientView;
