import { useMutation, useQueryCache } from 'react-query';
import { createInvitation } from 'src/services/api/invitations';

function useCreateInvitation() {
  const queryCache = useQueryCache();

  return useMutation((data) => createInvitation(data), {
    onSuccess: (data) => queryCache.setQueryData(['invitation', data.data?.id], data),
  });
}

export default useCreateInvitation;
