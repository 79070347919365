import { useMutation } from 'react-query';
import { markAsReadNotification } from 'src/services/api/notifications';

function useMarkAsReadNotification() {
  return useMutation((id) => markAsReadNotification(id), {
    onError: (err, id) => {
      // TODO: Handle error
      console.log(`ERROR MARKING AS READ NOTIFICATION '${id}'`);
    },
  });
}

export default useMarkAsReadNotification;
