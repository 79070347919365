import { convertFromHTML, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

const getHTMLFromDraft = (editorState) => {
  const rawContentState = convertToRaw(editorState.getCurrentContent());
  return draftToHtml(rawContentState);
};

const getDraftFromHTML = (html) => {
  const contentHTML = convertFromHTML(html);

  const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap);

  return JSON.stringify(convertToRaw(state));
};

export { getHTMLFromDraft, getDraftFromHTML };
