import { Box, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import AlertDialog from 'src/components/AlertDialog';
import Loader from 'src/components/Loader';
import Page from 'src/components/Page';
import { useGetCompanyPolicy } from 'src/hooks/company';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  content: {
    margin: '0 auto',

    [theme.breakpoints.up('sm')]: {
      width: '85%',
    },
    [theme.breakpoints.up('md')]: {
      width: '75%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '60%',
    },
  },
  policyText: {
    '& h1': {
      fontSize: 25,
      marginTop: theme.spacing(2),
    },
    '& h2': {
      fontSize: 20,
      marginTop: theme.spacing(1),
    },
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
}));

const CompanyPolicyView = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation('companies');
  const { companyPolicy } = useParams();
  const { isLoading, data, isError, error } = useGetCompanyPolicy(companyPolicy);

  if (isError) {
    return (
      <AlertDialog
        title={t('modals.error.title_get_company_policy')}
        text={error?.message || t('modals.error.text_get_company_policy')}
        type="error"
        acceptAction={() => history.push('/', { replace: true })}
      />
    );
  }

  if (isLoading) {
    return (
      <Page className={classes.root}>
        <Loader />
      </Page>
    );
  }

  return (
    <Page className={classes.root}>
      <Box className={classes.content}>
        <Typography variant="h1" color="secondary" gutterBottom>
          {t(`fields.${companyPolicy}`)}
        </Typography>

        <Typography
          className={classes.policyText}
          variant="body1"
          color="primary"
          gutterBottom
          dangerouslySetInnerHTML={{ __html: data.data?.content }}
        />
      </Box>
    </Page>
  );
};

export default CompanyPolicyView;
