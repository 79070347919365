import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CodesTable from './components/CodesTable';

const useStyles = makeStyles((theme) => ({
  card: {
    paddingTop: 0,
    paddingBottom: '0 !important',
  },
  cardContent: {
    padding: '0 !important',
  },
  label: {
    width: '25%',
    minWidth: 220,
  },
  table_no_border: {
    '& th': {
      padding: '4px 5px',
      borderBottom: 'none',
      width: '40%',
    },
    '& td': {
      padding: '4px 5px',
      borderBottom: 'none',
      color: theme.palette.tertiary.main,
    },
    '& th, & th strong, & td': {
      lineHeight: 1,
    },
  },
  noShadow: {
    boxShadow: 'none',
  },
}));

const DetailsTratamientoRadio = ({ demand }) => {
  const classes = useStyles();
  const { t } = useTranslation('demands');

  return (
    <Grid>
      {demand.tratamientoRadioterapico && (
        <Grid item xs={12}>
          <Card className={classes.noShadow}>
            <CardHeader
              title={t('labels.viewDemand_proposedTreatments_tratamientoRadioterapico_title')}
            />
            <CardContent className={classes.card}>
              <Grid container alignItems="center" spacing={3}>
                {demand.radiotherapyTreatments.map((row, index) => (
                  <Grid item xs={12} key={index}>
                    <Card className={classes.noShadow}>
                      <CardContent className={classes.cardContent}>
                        <TableContainer>
                          <Table className={classes.table_no_border}>
                            <TableBody>
                              <TableRow>
                                <TableCell className={classes.label} component="th" scope="row">
                                  <Typography component="strong">
                                    {t('fields.tratamientosRadioterapicos_fields.intencion')}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">{row.intencion}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell className={classes.label} component="th" scope="row">
                                  <Typography component="strong">
                                    {t(
                                      'fields.tratamientosRadioterapicos_fields.descripcionDetallada',
                                    )}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">{row.descripcionDetallada}</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>

                        <Box display="flex">
                          <TableContainer>
                            <Table className={classes.table_no_border}>
                              <TableBody>
                                <TableRow>
                                  <TableCell component="th" scope="row">
                                    <Typography component="strong">
                                      {t('fields.tratamientosRadioterapicos_fields.dosisTotal')}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>{row.dosisTotal}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell component="th" scope="row">
                                    <Typography component="strong">
                                      {t(
                                        'fields.tratamientosRadioterapicos_fields.fraccionamientoDia',
                                      )}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>{row.fraccionamientoDia}</TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>

                          <TableContainer>
                            <Table className={classes.table_no_border}>
                              <TableBody>
                                <TableRow>
                                  <TableCell component="th" scope="row">
                                    <Typography component="strong">
                                      {t('fields.tratamientosRadioterapicos_fields.fechaInicio')}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    {format(new Date(row.fechaInicio), 'dd/MM/yyyy')}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell component="th" scope="row">
                                    <Typography component="strong">
                                      {t('fields.tratamientosRadioterapicos_fields.numeroSesiones')}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>{row.numeroSesiones}</TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      </CardContent>
                      <CodesTable codes={row.treatmentCodes} />
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};

DetailsTratamientoRadio.propTypes = {
  demand: PropTypes.object,
};

export default DetailsTratamientoRadio;
