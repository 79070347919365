import { Box, Dialog, DialogTitle, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AlertDialog from 'src/components/AlertDialog';
import UploadAttachmentForm from './components/UploadAttachmentForm';
import useUpdateDemandAttachment from 'src/hooks/demands/useUpdateDemandAttachment';

const useStyles = makeStyles((theme) => ({
  dialog: {
    margin: '1.5rem',
  },
}));

const initialValues = {
  attachments: [],
};

const UploadAttachmentModal = ({ demand, closeDialog, refetchDemands }) => {
  const classes = useStyles();
  const { t } = useTranslation(['demands', 'common']);
  const [updateDemandAttachment, updateQuery] = useUpdateDemandAttachment();

  const handleUpdateDemand = async (values) => {
    const data = {};
    data.ficherosAdjuntos = await values?.attachments?.filter((e) => !e.previous);
    const request = { demand: demand.id, data };    
    
    let res = await updateDemandAttachment(request);

    if (res) {
      closeDialog();
      refetchDemands();
    }
  };

  return (
    <Dialog open={true}>
      <Box className={classes.dialog} display="flex" flexDirection="column">
        <DialogTitle disableTypography={true}>
          <Typography variant="h4" align="center">
            {t('labels.upload_attachment_modal_title')}
          </Typography>
        </DialogTitle>

        <UploadAttachmentForm
          initialValues={initialValues}
          handleUpdateDemand={handleUpdateDemand}
          closeDialog={closeDialog}
        />

        {(updateQuery.isError) && (
          <AlertDialog
            title={t('modals.error.title_edit_demand')}
            text={
              updateQuery.error?.message ||
              t('modals.error.text_edit_demand')
            }
            type="error"
          />
        )}
      </Box>
    </Dialog>
  );
};

UploadAttachmentModal.propTypes = {
  demand: PropTypes.object,
  closeDialog: PropTypes.func,
  refetchDemands: PropTypes.func,
};

export default UploadAttachmentModal;
