const getSubdomain = (hostname) => {
  let subdomainString = hostname;

  if (hostname.indexOf('www.') === 0) {
    subdomainString = hostname.replace('www.', '');
  }

  let company = process.env.REACT_APP_DEFAULT_COMPANY;

  if (subdomainString !== undefined) {
    company = subdomainString;
  }

  return company;
};

export { getSubdomain };
