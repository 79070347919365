import { useQuery } from 'react-query';
import { getPatientById } from 'src/services/api/patients';

function useGetPatient(patient, query, enabled = true) {
  return useQuery(['patient', patient], (key, patient) => getPatientById(patient, query), {
    enabled,
  });
}

export default useGetPatient;
