import { useQuery } from 'react-query';
import { getCenterById } from 'src/services/api/centers';

function useGetCenter(center, params) {
  return useQuery(['center', center, params], (key, center, params) =>
    getCenterById(center, params),
  );
}

export default useGetCenter;
