const ROLES = {
  SUPERADMIN: 'SUPERADMIN',
  ADMIN: 'ADMIN',
  MANAGER: 'MANAGER',
  CONSULTANT: 'CONSULTANT',
  REPRESENTATIVE: 'REPRESENTATIVE',
  SUPERVISOR: 'SUPERVISOR',
  DOCTOR: 'DOCTOR',
  ASSISTANT: 'ASSISTANT',
};
const ROLES_ID = {
  SUPERADMIN: 7,
  ADMIN: 1,
  MANAGER: 5,
  CONSULTANT: 3,
  REPRESENTATIVE: 6,
  SUPERVISOR: 8,
  DOCTOR: 4,
  ASSISTANT: 2,
};

export { ROLES, ROLES_ID };
