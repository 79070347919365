import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import BellIcon from '@material-ui/icons/Notifications';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import { formatDistance } from 'date-fns';
import { es, enIN } from 'date-fns/locale';

const useStyles = makeStyles((theme) => ({
  primary: {
    color: theme.palette.text.primary,
    cursor: 'pointer',
  },
  secondary: {
    color: theme.palette.text.secondary,
  },
}));

const NotificationMessage = withStyles({
  root: {
    textTransform: 'none',
    textAlign: 'start',
    lineHeight: 1.2,
    padding: 0,
    marginBottom: 5,
  },
})(Button);

const I18N_TO_DATE_LOCALES = {
  es,
  en: enIN,
};

const formatDate = (date, currentLang = 'en') => {
  const locale = I18N_TO_DATE_LOCALES.hasOwnProperty(currentLang)
    ? I18N_TO_DATE_LOCALES[currentLang]
    : I18N_TO_DATE_LOCALES.en;

  return date && formatDistance(new Date(date), new Date(), { locale, addSuffix: true });
};

export default function NotificationItem({ notification, handleClick }) {
  const classes = useStyles();
  const { i18n } = useTranslation('notifications');

  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar>
          <BellIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        classes={{
          primary: classes.primary,
          secondary: classes.secondary,
        }}
        primary={
          <NotificationMessage disableRipple size="large" color="primary">
            {notification.translate}
          </NotificationMessage>
        }
        primaryTypographyProps={{
          onClick: handleClick,
        }}
        secondary={formatDate(notification.createdAt, i18n.language)}
      />
      <ListItemSecondaryAction>
        <IconButton edge="end" onClick={handleClick}>
          <ArrowForwardIosIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
};
