import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import { useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import { useGetPatientActiveTreatments } from 'src/hooks/patients';
import { isCenterSelected, isCompanySelected } from 'src/store/selectors/entity';

import Step4Form from './components/Step4Form';

const Step4 = ({
  demand,
  demandId,
  setDemand,
  disableForm,
  setDisableNext,
  isContinue,
  isChangeDates,
  shouldBeContinue,
  originalFirstSessionDate,
}) => {
  const [isFullfilled] = useState(!isEmpty(demand.step4));
  const center = useSelector(isCenterSelected);
  const {
    isLoading: isLoadingActiveTreatment,
    data: activeTreatmentsData,
  } = useGetPatientActiveTreatments(demand.step1.patientId, demandId, {});
  let activeTreatments;
  if (!isLoadingActiveTreatment) {
    activeTreatments = activeTreatmentsData.data;
  }

  const step4Data = demand.step4;
  const initialValues = {
    companyId : demand.company?.companyId,
    centerPrescriptorId: center?.id,
    tratamientoQuimioterapico: step4Data.tratamientoQuimioterapico || false,
    quimioterapicoIntencion: step4Data.quimioterapicoIntencion || '',
    quimioterapicoTalla: get(step4Data, 'quimioterapicoTalla', ''),
    quimioterapicoPeso: get(step4Data, 'quimioterapicoPeso', ''),
    quimioterapicoCiclo: get(step4Data, 'quimioterapicoCiclo', ''),
    quimioterapicoNumeroCiclos: get(step4Data, 'quimioterapicoNumeroCiclos', ''),
    quimioterapicoNumeroSesionesCiclo: get(step4Data, 'quimioterapicoNumeroSesionesCiclo', ''),
    quimioterapicoFrecuenciaSesiones: get(step4Data, 'quimioterapicoFrecuenciaSesiones', ''),
    quimioterapicoIntervaloCiclos: get(step4Data, 'quimioterapicoIntervaloCiclos', ''),
    quimioterapicoFechaPrimeraSesion: step4Data.quimioterapicoFechaPrimeraSesion || null,
    quimioterapicoFarmacos: step4Data.quimioterapicoFarmacos || [],
    quimioterapicoAntiemeticos: step4Data.quimioterapicoAntiemeticos || '',
    quimioterapicoHeparinizacion: step4Data.quimioterapicoHeparinizacion || false,
    quimioterapicoImplantacionInfusor: step4Data.quimioterapicoImplantacionInfusor || '',
    quimioterapicoRetiradaInfusor: step4Data.quimioterapicoRetiradaInfusor || '',
    quimioterapicoAntihistaminicos: step4Data.quimioterapicoAntihistaminicos || '',
    quimioterapicoCorticoides: step4Data.quimioterapicoCorticoides || '',
    quimioterapicoEritropoyetinas: step4Data.quimioterapicoEritropoyetinas || '',
    quimioterapicoOtrosBifosfonatos: step4Data.quimioterapicoOtrosBifosfonatos || '',
    quimioterapicoEnsayoClinico: step4Data.quimioterapicoEnsayoClinico || false,
    quimioterapicoFechasSesiones: step4Data.quimioterapicoFechasSesiones || [],

    tratamientoRadioterapico: step4Data.tratamientoRadioterapico || false,
    tratamientosRadioterapicos: get(step4Data, 'tratamientosRadioterapicos', []),
  };
  const [proposedTreatments, setProposedTreatments] = useState(demand.step4 || initialValues);

  useEffect(() => {
    if (!isFullfilled) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }
  }, [isFullfilled, setDisableNext]);

  useEffect(() => {
    // Omit empty strings, but accept 0's
    const step4 = pickBy(
      proposedTreatments,
      (value) => value?.length || value?.length === undefined,
    );
    if (proposedTreatments.quimioterapicoFarmacos?.length === 0) {
      step4.quimioterapicoFarmacos = [];
    }
    if (proposedTreatments.tratamientosRadioterapicos?.length === 0) {
      step4.tratamientosRadioterapicos = [];
    }
    setDemand({ ...demand, step4 });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposedTreatments, setDemand]);

  return (
    <Step4Form
      initialValues={initialValues}
      setProposedTreatments={setProposedTreatments}
      activeTreatments={activeTreatments}
      isFullfilled={isFullfilled}
      disableForm={disableForm}
      setDisableNext={setDisableNext}
      isContinue={isContinue}
      isChangeDates={isChangeDates}
      shouldBeContinue={shouldBeContinue}
      originalFirstSessionDate={originalFirstSessionDate}
    />
  );
};

Step4.propTypes = {
  demand: PropTypes.object,
  demandId: PropTypes.number,
  setDemand: PropTypes.func,
  disableForm: PropTypes.bool,
  setDisableNext: PropTypes.func,
  isContinue: PropTypes.bool,
  isChangeDates: PropTypes.bool,
  shouldBeContinue: PropTypes.bool,
  originalFirstSessionDate: PropTypes.string,
};

export default Step4;
