import { useQuery } from 'react-query';
import { getDemandById } from 'src/services/api/demands';

function useGetDemand(demand, query, enabled = true) {
  return useQuery(['demand', demand], (key, demand) => getDemandById(demand, query), {
    enabled,
  });
}

export default useGetDemand;
