import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import format from 'date-fns/format';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Download as DownloadIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useGetMedia from 'src/hooks/demands/useGetMedia';
import {
  isAdmin as isAdminSelector,
  isManager as isManagerSelector,
  isRepresentive as isRepresentiveSelector,
  isSuperAdmin as isSuperAdminSelector,
} from 'src/store/selectors/user';
import { downloadFile } from 'src/utils/downloadFile';
const useStyles = makeStyles((theme) => ({
  table: {
    '& th, & td': {
      lineHeight: 1,
      padding: '4px 5px',
    },
    '& td': {
      color: theme.palette.tertiary.main,
    },
  },
  button: {
    padding: 4,
  },
}));

const HistoriesTable = ({ histories, roles }) => {
  const classes = useStyles();
  const { t } = useTranslation(['demands', 'rolesName']);
  const { enqueueSnackbar } = useSnackbar();
  const [attachment, setAttachment] = useState();
  const isSuperAdmin = useSelector(isSuperAdminSelector);
  const isAdmin = useSelector(isAdminSelector);
  const isManager = useSelector(isManagerSelector);
  const isRepresentative = useSelector(isRepresentiveSelector);
  const displayPrivateComment = isSuperAdmin || isAdmin || isManager || isRepresentative;

  const { isLoading, data: media, isError, refetch: fetchAttachment } = useGetMedia(
    attachment?.id,
    attachment?.mediaType,
  );

  const getRoleById = (role) => {
    return roles?.data.rows.find(({ id }) => id === role);
  };

  useEffect(() => {
    if (attachment) {
      fetchAttachment();
    }
  }, [attachment, fetchAttachment]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(t('modals.error.download_attachment'), { variant: 'error' });
    }
    if (!isLoading && !isError && media) {
      downloadFile(media.data, attachment.mediaType, attachment.originalFilename);
      setAttachment(null);
    }
  }, [media, enqueueSnackbar, isError, isLoading, t, attachment, setAttachment]);

  return (
    <TableContainer>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>{t('demands:fields.historyDate')}</TableCell>
            <TableCell>{t('demands:fields.author')}</TableCell>
            <TableCell>{t('demands:fields.fromRole')}</TableCell>
            <TableCell>{t('demands:fields.action')}</TableCell>
            <TableCell>{t('demands:fields.toRole')}</TableCell>
            <TableCell>{t('demands:fields.toUser')}</TableCell>
            <TableCell>{t('demands:fields.comment')}</TableCell>
            {displayPrivateComment && <TableCell>{t('demands:fields.privateComment')}</TableCell>}
            <TableCell align="right">{t('demands:fields.downloadAttachments')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {histories.map((row, i) => (
            <TableRow key={row.id}>
              <TableCell>
                {row.createdAt && format(new Date(row.createdAt), 'dd/MM/yyyy HH:mm:ss')}
              </TableCell>
              <TableCell>{row.author?.name || '-'}</TableCell>
              <TableCell>
                {t(`rolesNames:${getRoleById(row.fromRoleId)?.permissions}`) || '-'}
              </TableCell>
              <TableCell>{t(`demands:actions.${row.action}`)}</TableCell>
              <TableCell>
                {t(`rolesNames:${getRoleById(row.toRoleId)?.permissions}`) || '-'}
              </TableCell>
              <TableCell>{row.user?.name || '-'}</TableCell>
              <TableCell>{row.comment || '-'}</TableCell>
              {displayPrivateComment && <TableCell>{row.privateComment || '-'}</TableCell>}
              <TableCell align="right">
                {!row.attachments.length && '-'}
                {row.attachments.map((attachment) => (
                  <IconButton
                    key={attachment.id}
                    className={classes.button}
                    color="primary"
                    title={attachment.originalFilename}
                    onClick={async () => setAttachment(() => attachment)}
                  >
                    <DownloadIcon />
                  </IconButton>
                ))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

HistoriesTable.propTypes = {
  histories: PropTypes.array,
  roles: PropTypes.object,
};

export default HistoriesTable;
