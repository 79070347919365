import { Grid, InputAdornment, TextField } from '@material-ui/core';
import { Formik, useFormikContext } from 'formik';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ESTADIO, LOCATION, M, N, T, TYPE } from 'src/constants/demands';
import { DECIMAL_VALIDATOR } from 'src/constants/regex';
import { useDebouncedCallback } from 'use-debounce/lib';
import * as Yup from 'yup';

const TYPE_DEPENDANTS = {
  MAMA: ['mamaTipo', 'mamaEr', 'mamaEp', 'mamaHer2neu', 'mamaFishHer2neu', 'mamaKi67'],
  GASTRICO: ['gastricoHerceptest', 'gastricoFishHer2'],
  MIELOPROLIFERATIVOS: ['mieloproliferativosCd20', 'mieloproliferativosSds'],
  PULMON: [
    'pulmonTipo',
    'pulmonEgfr',
    'pulmonAlk',
    'pulmonRosI',
    'pulmonPdLi',
    'pulmonBrafV600E',
    'pulmonNtrk1',
    'pulmonNtrk2',
    'pulmonNtrk3',
    'pulmonMetEx14',
    'pulmonRet',
  ],
  COLORECTAL: [
    'colorectalTipo',
    'colorectalKras',
    'colorectalNras',
    'colorectalBraf',
    'colorectalMmr',
    'colorectalMi',
  ],
  PROSTATA: [
    'prostataTipo',
    'prostataPsaActual',
    'prostataPsaPrevio',
    'prostataEstratificacionRiesgo',
    'prostataGradoGleason',
  ],
  OVARIO: [],
  PANCREAS: [],
  VEJIGA: ['vejigaTipo', 'vejigaPdLi', 'vejigaDescripcionHistologica', 'vejigaSubtipo'],
  OTROS: ['otrosDescription'],
};
const OPTIONAL_FIELDS = ['valoracionGeriatrica', 'prostataPsaPrevio'];

const LocationCommonOptions = () => {
  const { t } = useTranslation('demands');

  return (
    <>
      <option value="" hidden></option>
      <option value={LOCATION.COMMON.NO_REALIZADO}>{t('fields.type_common.no_realizado')}</option>
      <option value={LOCATION.COMMON.POSITIVO}>{t('fields.type_common.positivo')}</option>
      <option value={LOCATION.COMMON.NEGATIVO}>{t('fields.type_common.negativo')}</option>
    </>
  );
};

const FormContext = ({ isFullfilled, setClinicalProtocol, setDisableNext }) => {
  const { values, errors, isValid, dirty, submitForm } = useFormikContext();
  const { callback: debounceUpdateDemand } = useDebouncedCallback(() => updateDemand(), 600);
  const { callback: debounceDisableNext } = useDebouncedCallback(() => updateDisableNext(), 600);

  const updateDisableNext = () => {
    setDisableNext(isFullfilled ? !isValid : !(dirty && isValid));
  };

  const updateDemand = () => {
    const errorsArray = Object.getOwnPropertyNames(errors);

    const valuesToPick = [
      'valoracionGeriatrica',
      'diagnosticoHistologico',
      't',
      'n',
      'm',
      'estadio',
      'ecog',
    ];

    // Check if any of the location dependants has errors. In that case, omit the location and the dependants
    const dependants = Object.getOwnPropertyNames(TYPE_DEPENDANTS);
    dependants.forEach((type) => {
      if (values.type === TYPE[type]) {
        let dependantError = false;

        TYPE_DEPENDANTS[type].forEach((dependant) => {
          if (errorsArray.includes(dependant)) {
            dependantError = true;
          }
        });

        if (!dependantError) {
          valuesToPick.push('type', ...TYPE_DEPENDANTS[type]);
        }
      }
    });

    const data = omit(pick(values, valuesToPick), errorsArray);
    OPTIONAL_FIELDS.forEach((field) => {
      if (data[field] === '') {
        data[field] = null;
      }
    });

    setClinicalProtocol(data);
  };

  // If user has already fullfilled the form and came back to this step, submit the form to show validation errors
  useEffect(() => {
    if (isFullfilled) {
      submitForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Disable "next" button if form has errors or is not dirty
  useEffect(() => {
    if (isValid) {
      debounceDisableNext();
    } else {
      updateDisableNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceDisableNext, dirty, isFullfilled, isValid]);

  // Update demand on every field change
  useEffect(() => {
    debounceUpdateDemand();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, setClinicalProtocol, values]);

  return null;
};

const Step2Form = ({
  initialValues,
  setClinicalProtocol,
  isFullfilled,
  disableForm,
  setDisableNext,
}) => {
  const { t } = useTranslation(['demands', 'common']);
  const validationSchema = Yup.object().shape({
    valoracionGeriatrica: Yup.string(),
    diagnosticoHistologico: Yup.string().required(t('errors.diagnosticoHistologico.required')),
    type: Yup.string().required(t('errors.type.required')),
    mamaTipo: Yup.string().when('type', (type, schema) =>
      type === TYPE.MAMA ? schema.required(t('errors.mamaTipo.required')) : schema,
    ),
    mamaEr: Yup.string().when('type', (type, schema) =>
      type === TYPE.MAMA ? schema.required(t('errors.mamaEr.required')) : schema,
    ),
    mamaEp: Yup.string().when('type', (type, schema) =>
      type === TYPE.MAMA ? schema.required(t('errors.mamaEp.required')) : schema,
    ),
    mamaHer2neu: Yup.string().when('type', (type, schema) =>
      type === TYPE.MAMA ? schema.required(t('errors.mamaHer2neu.required')) : schema,
    ),
    mamaFishHer2neu: Yup.string().when('type', (type, schema) =>
      type === TYPE.MAMA ? schema.required(t('errors.mamaFishHer2neu.required')) : schema,
    ),
    mamaKi67: Yup.number()
      .nullable()
      .min(0, t('errors.mamaKi67.min', { min: 0 }))
      .max(100, t('errors.mamaKi67.max', { max: 100 }))
      .when('type', (type, schema) =>
        type === TYPE.MAMA
          ? schema
              .required(t('common:errors.number_required'))
              .test('is-decimal', t('errors.mamaKi67.decimal'), (value) =>
                String(value).match(DECIMAL_VALIDATOR),
              )
          : schema,
      ),
    gastricoHerceptest: Yup.string().when('type', (type, schema) =>
      type === TYPE.GASTRICO ? schema.required(t('errors.gastricoHerceptest.required')) : schema,
    ),
    gastricoFishHer2: Yup.string().when('type', (type, schema) =>
      type === TYPE.GASTRICO ? schema.required(t('errors.gastricoFishHer2.required')) : schema,
    ),
    colorectalTipo: Yup.string().when('type', (type, schema) =>
      type === TYPE.COLORECTAL ? schema.required(t('errors.colorectalTipo.required')) : schema,
    ),
    colorectalKras: Yup.string().when('type', (type, schema) =>
      type === TYPE.COLORECTAL ? schema.required(t('errors.colorectalKras.required')) : schema,
    ),
    colorectalNras: Yup.string().when('type', (type, schema) =>
      type === TYPE.COLORECTAL ? schema.required(t('errors.colorectalNras.required')) : schema,
    ),
    colorectalBraf: Yup.string().when('type', (type, schema) =>
      type === TYPE.COLORECTAL ? schema.required(t('errors.colorectalBraf.required')) : schema,
    ),
    colorectalMmr: Yup.string().when('type', (type, schema) =>
      type === TYPE.COLORECTAL ? schema.required(t('errors.colorectalMmr.required')) : schema,
    ),
    colorectalMi: Yup.string().when('type', (type, schema) =>
      type === TYPE.COLORECTAL ? schema.required(t('errors.colorectalMi.required')) : schema,
    ),
    pulmonTipo: Yup.string().when('type', (type, schema) =>
      type === TYPE.PULMON ? schema.required(t('errors.pulmonTipo.required')) : schema,
    ),
    pulmonEgfr: Yup.string().when('type', (type, schema) =>
      type === TYPE.PULMON ? schema.required(t('errors.pulmonEgfr.required')) : schema,
    ),
    pulmonAlk: Yup.string().when('type', (type, schema) =>
      type === TYPE.PULMON ? schema.required(t('errors.pulmonAlk.required')) : schema,
    ),
    pulmonRosI: Yup.string().when('type', (type, schema) =>
      type === TYPE.PULMON ? schema.required(t('errors.pulmonRosI.required')) : schema,
    ),
    pulmonPdLi: Yup.string().when('type', (type, schema) =>
      type === TYPE.PULMON ? schema.required(t('errors.pulmonPdLi.required')) : schema,
    ),
    pulmonBrafV600E: Yup.string().when('type', (type, schema) =>
      type === TYPE.PULMON ? schema.required(t('errors.pulmonBrafV600E.required')) : schema,
    ),
    pulmonNtrk1: Yup.string().when('type', (type, schema) =>
      type === TYPE.PULMON ? schema.required(t('errors.pulmonNtrk1.required')) : schema,
    ),
    pulmonNtrk2: Yup.string().when('type', (type, schema) =>
      type === TYPE.PULMON ? schema.required(t('errors.pulmonNtrk2.required')) : schema,
    ),
    pulmonNtrk3: Yup.string().when('type', (type, schema) =>
      type === TYPE.PULMON ? schema.required(t('errors.pulmonNtrk3.required')) : schema,
    ),
    pulmonMetEx14: Yup.string().when('type', (type, schema) =>
      type === TYPE.PULMON ? schema.required(t('errors.pulmonMetEx14.required')) : schema,
    ),
    pulmonRet: Yup.string().when('type', (type, schema) =>
      type === TYPE.PULMON ? schema.required(t('errors.pulmonRet.required')) : schema,
    ),
    prostataTipo: Yup.string().when('type', (type, schema) =>
      type === TYPE.PROSTATA ? schema.required(t('errors.prostataTipo.required')) : schema,
    ),
    prostataPsaActual: Yup.string().when('type', (type, schema) =>
      type === TYPE.PROSTATA ? schema.required(t('errors.prostataPsaActual.required')) : schema,
    ),
    prostataPsaPrevio: Yup.string(),
    prostataEstratificacionRiesgo: Yup.string().when('type', (type, schema) =>
      type === TYPE.PROSTATA
        ? schema.required(t('errors.prostataEstratificacionRiesgo.required'))
        : schema,
    ),
    prostataGradoGleason: Yup.string().when('type', (type, schema) =>
      type === TYPE.PROSTATA ? schema.required(t('errors.prostataGradoGleason.required')) : schema,
    ),
    mieloproliferativosCd20: Yup.string().when('type', (type, schema) =>
      type === TYPE.MIELOPROLIFERATIVOS
        ? schema.required(t('errors.mieloproliferativosCd20.required'))
        : schema,
    ),
    mieloproliferativosSds: Yup.string().when('type', (type, schema) =>
      type === TYPE.MIELOPROLIFERATIVOS
        ? schema.required(t('errors.mieloproliferativosSds.required'))
        : schema,
    ),
    vejigaTipo: Yup.string().when('type', (type, schema) =>
      type === TYPE.VEJIGA ? schema.required(t('errors.vejigaTipo.required')) : schema,
    ),
    vejigaPdLi: Yup.string().when('type', (type, schema) =>
      type === TYPE.VEJIGA ? schema.required(t('errors.vejigaPdLi.required')) : schema,
    ),
    vejigaDescripcionHistologica: Yup.string().when('type', (type, schema) =>
      type === TYPE.VEJIGA
        ? schema.required(t('errors.vejigaDescripcionHistologica.required'))
        : schema,
    ),
    vejigaSubtipo: Yup.string().when('type', (type, schema) =>
      type === TYPE.VEJIGA ? schema.required(t('errors.vejigaSubtipo.required')) : schema,
    ),
    otrosDescription: Yup.string().when('type', (type, schema) =>
      type === TYPE.OTROS ? schema.required(t('errors.otrosDescription.required')) : schema,
    ),
    t: Yup.string().required(t('errors.t.required')),
    n: Yup.string().required(t('errors.n.required')),
    m: Yup.string().required(t('errors.m.required')),
    estadio: Yup.string().required(t('errors.estadio.required')),
    ecog: Yup.number()
      .integer(t('errors.ecog.integer'))
      .min(0, t('errors.ecog.min', { min: 0 }))
      .max(5, t('errors.ecog.max', { max: 5 }))
      .required(t('common:errors.number_required')),
  });

  const handleSubmit = (values, formikBag) => {
    formikBag.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.valoracionGeriatrica && errors.valoracionGeriatrica)}
                fullWidth
                multiline
                helperText={touched.valoracionGeriatrica && errors.valoracionGeriatrica}
                label={t('fields.valoracionGeriatrica')}
                name="valoracionGeriatrica"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disableForm || isSubmitting}
                value={values.valoracionGeriatrica}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.diagnosticoHistologico && errors.diagnosticoHistologico)}
                fullWidth
                multiline
                required
                helperText={touched.diagnosticoHistologico && errors.diagnosticoHistologico}
                label={t('fields.diagnosticoHistologico')}
                name="diagnosticoHistologico"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disableForm || isSubmitting}
                value={values.diagnosticoHistologico}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={Boolean(touched.type && errors.type)}
                fullWidth
                helperText={touched.type && errors.type}
                required
                select
                SelectProps={{ native: true }}
                label={t('fields.type')}
                name="type"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disableForm || isSubmitting}
                value={values.type}
                variant="outlined"
              >
                <option value="" hidden></option>
                <option value={TYPE.MAMA}>{t('fields.type_options.mama')}</option>
                <option value={TYPE.GASTRICO}>{t('fields.type_options.gastrico')}</option>
                <option value={TYPE.MIELOPROLIFERATIVOS}>
                  {t('fields.type_options.sindrome_mieloproliferativo')}
                </option>
                <option value={TYPE.PULMON}>{t('fields.type_options.pulmon')}</option>
                <option value={TYPE.COLORECTAL}>{t('fields.type_options.colo_rectal')}</option>
                <option value={TYPE.PROSTATA}>{t('fields.type_options.prostata')}</option>
                <option value={TYPE.OVARIO}>{t('fields.type_options.ovario')}</option>
                <option value={TYPE.PANCREAS}>{t('fields.type_options.pancreas')}</option>
                <option value={TYPE.VEJIGA}>{t('fields.type_options.vejiga')}</option>
                <option value={LOCATION.OTROS}>{t('fields.type_options.otros')}</option>
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                select
                fullWidth
                required
                error={Boolean(touched.t && errors.t)}
                helperText={touched.t && errors.t}
                SelectProps={{ native: true }}
                label={t('fields.t')}
                name="t"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disableForm || isSubmitting}
                value={values.t}
                variant="outlined"
              >
                <option value="" hidden />
                {Object.keys(T).map((option) => (
                  <option key={option} value={T[option]}>
                    {T[option]}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                select
                fullWidth
                required
                error={Boolean(touched.n && errors.n)}
                helperText={touched.n && errors.n}
                SelectProps={{ native: true }}
                label={t('fields.n')}
                name="n"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disableForm || isSubmitting}
                value={values.n}
                variant="outlined"
              >
                <option value="" hidden />
                {Object.keys(N).map((option) => (
                  <option key={option} value={N[option]}>
                    {N[option]}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                select
                fullWidth
                required
                error={Boolean(touched.m && errors.m)}
                helperText={touched.m && errors.m}
                SelectProps={{ native: true }}
                label={t('fields.m')}
                name="m"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disableForm || isSubmitting}
                value={values.m}
                variant="outlined"
              >
                <option value="" hidden />
                {Object.keys(M).map((option) => (
                  <option key={option} value={M[option]}>
                    {M[option]}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={Boolean(touched.estadio && errors.estadio)}
                fullWidth
                helperText={touched.estadio && errors.estadio}
                required
                select
                SelectProps={{ native: true }}
                label={t('fields.estadio')}
                name="estadio"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disableForm || isSubmitting}
                value={values.estadio}
                variant="outlined"
              >
                <option value="" hidden></option>
                <option value={ESTADIO.ZERO}>{t('fields.estadio_options.zero')}</option>
                <option value={ESTADIO.ONE}>{t('fields.estadio_options.one')}</option>
                <option value={ESTADIO.IIA}>{t('fields.estadio_options.IIA')}</option>
                <option value={ESTADIO.IIB}>{t('fields.estadio_options.IIB')}</option>
                <option value={ESTADIO.IIIA}>{t('fields.estadio_options.IIIA')}</option>
                <option value={ESTADIO.IIIB}>{t('fields.estadio_options.IIIB')}</option>
                <option value={ESTADIO.IV}>{t('fields.estadio_options.IV')}</option>
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="number"
                inputProps={{ min: 0, max: 5, step: '1' }}
                error={Boolean(touched.ecog && errors.ecog)}
                fullWidth
                required
                helperText={touched.ecog && errors.ecog}
                label={t('fields.ecog')}
                name="ecog"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={disableForm || isSubmitting}
                value={values.ecog}
                variant="outlined"
              />
            </Grid>
            {values.type === TYPE.MAMA && (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={Boolean(touched.mamaTipo && errors.mamaTipo)}
                    fullWidth
                    required
                    helperText={touched.mamaTipo && errors.mamaTipo}
                    label={t('fields.mamaTipo')}
                    name="mamaTipo"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disableForm || isSubmitting}
                    value={values.mamaTipo}
                    variant="outlined"
                  />
                </Grid>
                {['mamaEr', 'mamaEp'].map((field, index) => (
                  <Grid item xs={12} sm={index < 2 ? 3 : 4} key={field}>
                    <TextField
                      error={Boolean(touched[field] && errors[field])}
                      fullWidth
                      helperText={touched[field] && errors[field]}
                      required
                      select
                      SelectProps={{ native: true }}
                      label={t(`fields.${field}`)}
                      name={field}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={disableForm || isSubmitting}
                      value={values[field]}
                      variant="outlined"
                    >
                      <LocationCommonOptions />
                    </TextField>
                  </Grid>
                ))}
                <Grid item xs={12} sm={4}>
                  <TextField
                    error={Boolean(touched.mamaHer2neu && errors.mamaHer2neu)}
                    fullWidth
                    helperText={touched.mamaHer2neu && errors.mamaHer2neu}
                    required
                    select
                    SelectProps={{ native: true }}
                    label={t('fields.mamaHer2neu')}
                    name="mamaHer2neu"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disableForm || isSubmitting}
                    value={values.mamaHer2neu}
                    variant="outlined"
                  >
                    <option value="" hidden></option>
                    <option value={LOCATION.COMMON.NO_REALIZADO}>
                      {t('fields.type_common.no_realizado')}
                    </option>
                    <option value={LOCATION.COMMON.NEGATIVO}>
                      {t('fields.type_common.negativo')}
                    </option>
                    <option value={LOCATION.COMMON.POSITIVO}>
                      {t('fields.type_common.positivo')}
                    </option>
                    <option value={LOCATION.MAMA.HER_2_NEU.PLUSPLUS}>
                      {t('fields.mamaHer2neu_options.plusplus')}
                    </option>
                    <option value={LOCATION.MAMA.HER_2_NEU.PLUSPLUSPLUS}>
                      {t('fields.mamaHer2neu_options.plusplusplus')}
                    </option>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    error={Boolean(touched.mamaFishHer2neu && errors.mamaFishHer2neu)}
                    fullWidth
                    helperText={touched.mamaFishHer2neu && errors.mamaFishHer2neu}
                    required
                    select
                    SelectProps={{ native: true }}
                    label={t('fields.mamaFishHer2neu')}
                    name="mamaFishHer2neu"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disableForm || isSubmitting}
                    value={values.mamaFishHer2neu}
                    variant="outlined"
                  >
                    <LocationCommonOptions />
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    type="number"
                    inputProps={{ min: 0, max: 100, step: '.01' }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                    error={Boolean(touched.mamaKi67 && errors.mamaKi67)}
                    fullWidth
                    required
                    helperText={touched.mamaKi67 && errors.mamaKi67}
                    label={t('fields.mamaKi67')}
                    name="mamaKi67"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disableForm || isSubmitting}
                    value={values.mamaKi67}
                    variant="outlined"
                  />
                </Grid>
              </>
            )}
            {values.type === TYPE.GASTRICO && (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={Boolean(touched.gastricoHerceptest && errors.gastricoHerceptest)}
                    fullWidth
                    helperText={touched.gastricoHerceptest && errors.gastricoHerceptest}
                    required
                    select
                    SelectProps={{ native: true }}
                    label={t('fields.gastricoHerceptest')}
                    name="gastricoHerceptest"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disableForm || isSubmitting}
                    value={values.gastricoHerceptest}
                    variant="outlined"
                  >
                    <option value="" hidden></option>
                    <option value={LOCATION.GASTRICO.HERCEPTEST.NO_REALIZADO}>
                      {t('fields.gastricoHerceptest_options.no_realizado')}
                    </option>
                    <option value={LOCATION.GASTRICO.HERCEPTEST.MINUS}>
                      {t('fields.gastricoHerceptest_options.minus')}
                    </option>
                    <option value={LOCATION.GASTRICO.HERCEPTEST.PLUS}>
                      {t('fields.gastricoHerceptest_options.plus')}
                    </option>
                    <option value={LOCATION.GASTRICO.HERCEPTEST.PLUSPLUS}>
                      {t('fields.gastricoHerceptest_options.plusplus')}
                    </option>
                    <option value={LOCATION.GASTRICO.HERCEPTEST.PLUSPLUSPLUS}>
                      {t('fields.gastricoHerceptest_options.plusplusplus')}
                    </option>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={Boolean(touched.gastricoFishHer2 && errors.gastricoFishHer2)}
                    fullWidth
                    helperText={touched.gastricoFishHer2 && errors.gastricoFishHer2}
                    required
                    select
                    SelectProps={{ native: true }}
                    label={t('fields.gastricoFishHer2')}
                    name="gastricoFishHer2"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disableForm || isSubmitting}
                    value={values.gastricoFishHer2}
                    variant="outlined"
                  >
                    <option value="" hidden></option>
                    <option value={LOCATION.GASTRICO.FISH_HER2.NO_REALIZADO}>
                      {t('fields.gastricoFishHer2_options.no_realizado')}
                    </option>
                    <option value={LOCATION.GASTRICO.FISH_HER2.MINUS}>
                      {t('fields.gastricoFishHer2_options.minus')}
                    </option>
                    <option value={LOCATION.GASTRICO.FISH_HER2.PLUS}>
                      {t('fields.gastricoFishHer2_options.plus')}
                    </option>
                  </TextField>
                </Grid>
              </>
            )}
            {values.type === TYPE.COLORECTAL && (
              <>
                <Grid item xs={12} sm={4}>
                  <TextField
                    error={Boolean(touched.colorectalTipo && errors.colorectalTipo)}
                    fullWidth
                    helperText={touched.colorectalTipo && errors.colorectalTipo}
                    required
                    select
                    SelectProps={{ native: true }}
                    label={t('fields.colorectalTipo')}
                    name="colorectalTipo"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disableForm || isSubmitting}
                    value={values.colorectalTipo}
                    variant="outlined"
                  >
                    <option value="" hidden></option>
                    <option value={LOCATION.COLORECTAL.TIPO.NO_ESPECIFICADO}>
                      {t('fields.colorectalTipo_options.no_especificado')}
                    </option>
                    <option value={LOCATION.COLORECTAL.TIPO.COLON_DERECHO}>
                      {t('fields.colorectalTipo_options.colon_derecho')}
                    </option>
                    <option value={LOCATION.COLORECTAL.TIPO.COLON_IZQUIERDO}>
                      {t('fields.colorectalTipo_options.colon_izquierdo')}
                    </option>
                    <option value={LOCATION.COLORECTAL.TIPO.RECTO}>
                      {t('fields.colorectalTipo_options.recto')}
                    </option>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    error={Boolean(touched.colorectalKras && errors.colorectalKras)}
                    fullWidth
                    helperText={touched.colorectalKras && errors.colorectalKras}
                    required
                    select
                    SelectProps={{ native: true }}
                    label={t('fields.colorectalKras')}
                    name="colorectalKras"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disableForm || isSubmitting}
                    value={values.colorectalKras}
                    variant="outlined"
                  >
                    <LocationCommonOptions />
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    error={Boolean(touched.colorectalNras && errors.colorectalNras)}
                    fullWidth
                    helperText={touched.colorectalNras && errors.colorectalNras}
                    required
                    select
                    SelectProps={{ native: true }}
                    label={t('fields.colorectalNras')}
                    name="colorectalNras"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disableForm || isSubmitting}
                    value={values.colorectalNras}
                    variant="outlined"
                  >
                    <LocationCommonOptions />
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    error={Boolean(touched.colorectalBraf && errors.colorectalBraf)}
                    fullWidth
                    helperText={touched.colorectalBraf && errors.colorectalBraf}
                    required
                    select
                    SelectProps={{ native: true }}
                    label={t('fields.colorectalBraf')}
                    name="colorectalBraf"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disableForm || isSubmitting}
                    value={values.colorectalBraf}
                    variant="outlined"
                  >
                    <LocationCommonOptions />
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    error={Boolean(touched.colorectalMmr && errors.colorectalMmr)}
                    fullWidth
                    helperText={touched.colorectalMmr && errors.colorectalMmr}
                    required
                    select
                    SelectProps={{ native: true }}
                    label={t('fields.colorectalMmr')}
                    name="colorectalMmr"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disableForm || isSubmitting}
                    value={values.colorectalMmr}
                    variant="outlined"
                  >
                    <LocationCommonOptions />
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    error={Boolean(touched.colorectalMi && errors.colorectalMi)}
                    fullWidth
                    helperText={touched.colorectalMi && errors.colorectalMi}
                    required
                    select
                    SelectProps={{ native: true }}
                    label={t('fields.colorectalMi')}
                    name="colorectalMi"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disableForm || isSubmitting}
                    value={values.colorectalMi}
                    variant="outlined"
                  >
                    <LocationCommonOptions />
                  </TextField>
                </Grid>
              </>
            )}
            {values.type === TYPE.PULMON && (
              <>
                <Grid item xs={12} sm={8}>
                  <TextField
                    error={Boolean(touched.pulmonTipo && errors.pulmonTipo)}
                    fullWidth
                    helperText={touched.pulmonTipo && errors.pulmonTipo}
                    required
                    select
                    SelectProps={{ native: true }}
                    label={t('fields.pulmonTipo')}
                    name="pulmonTipo"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disableForm || isSubmitting}
                    value={values.pulmonTipo}
                    variant="outlined"
                  >
                    <option value="" hidden></option>
                    <option value={LOCATION.PULMON.TIPO.MICROCITICO}>
                      {t('fields.pulmonTipo_options.microcitico')}
                    </option>
                    <option value={LOCATION.PULMON.TIPO.NO_MICROCITICO_ADENOCARCINOMA}>
                      {t('fields.pulmonTipo_options.no_microcitico_adenocarcinoma')}
                    </option>
                    <option value={LOCATION.PULMON.TIPO.NO_MICROCITICO_ESCAMOSO}>
                      {t('fields.pulmonTipo_options.no_microcitico_escamoso')}
                    </option>
                    <option value={LOCATION.PULMON.TIPO.NO_MICROCITICO_ADENOESCAMOSO}>
                      {t('fields.pulmonTipo_options.no_microcitico_adenoescamoso')}
                    </option>
                    <option value={LOCATION.PULMON.TIPO.NO_MICROCITICO_LARGE_CELL}>
                      {t('fields.pulmonTipo_options.no_microcitico_large_cell')}
                    </option>
                    <option value={LOCATION.PULMON.TIPO.NO_MICROCITICO_SARCOMATOIDE}>
                      {t('fields.pulmonTipo_options.no_microcitico_sarcomatoide')}
                    </option>
                    <option value={LOCATION.PULMON.TIPO.OTROS}>
                      {t('fields.pulmonTipo_options.otros')}
                    </option>
                  </TextField>
                </Grid>
                {['pulmonEgfr', 'pulmonAlk', 'pulmonRosI'].map((field) => (
                  <Grid item xs={12} sm={4} key={field}>
                    <TextField
                      error={Boolean(touched[field] && errors[field])}
                      fullWidth
                      helperText={touched[field] && errors[field]}
                      required
                      select
                      SelectProps={{ native: true }}
                      label={t(`fields.${field}`)}
                      name={field}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={disableForm || isSubmitting}
                      value={values[field]}
                      variant="outlined"
                    >
                      <LocationCommonOptions />
                    </TextField>
                  </Grid>
                ))}
                <Grid item xs={12} sm={4}>
                  <TextField
                    error={Boolean(touched.pulmonPdLi && errors.pulmonPdLi)}
                    fullWidth
                    helperText={touched.pulmonPdLi && errors.pulmonPdLi}
                    required
                    select
                    SelectProps={{ native: true }}
                    label={t('fields.pulmonPdLi')}
                    name="pulmonPdLi"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disableForm || isSubmitting}
                    value={values.pulmonPdLi}
                    variant="outlined"
                  >
                    <option value="" hidden></option>
                    <option value={LOCATION.PULMON.PD_LI.LESS_1}>
                      {t('fields.pulmonPdLi_options.less_1')}
                    </option>
                    <option value={LOCATION.PULMON.PD_LI.MORE_1_49}>
                      {t('fields.pulmonPdLi_options.more_1_49')}
                    </option>
                    <option value={LOCATION.PULMON.PD_LI.MORE_50}>
                      {t('fields.pulmonPdLi_options.more_50')}
                    </option>
                  </TextField>
                </Grid>
                {[
                  'pulmonBrafV600E',
                  'pulmonNtrk1',
                  'pulmonNtrk2',
                  'pulmonNtrk3',
                  'pulmonMetEx14',
                  'pulmonRet',
                ].map((field) => (
                  <Grid item xs={12} sm={4} key={field}>
                    <TextField
                      error={Boolean(touched[field] && errors[field])}
                      fullWidth
                      helperText={touched[field] && errors[field]}
                      required
                      select
                      SelectProps={{ native: true }}
                      label={t(`fields.${field}`)}
                      name={field}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={disableForm || isSubmitting}
                      value={values[field]}
                      variant="outlined"
                    >
                      <LocationCommonOptions />
                    </TextField>
                  </Grid>
                ))}
              </>
            )}
            {values.type === TYPE.PROSTATA && (
              <>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(touched.prostataTipo && errors.prostataTipo)}
                    fullWidth
                    helperText={touched.prostataTipo && errors.prostataTipo}
                    required
                    select
                    SelectProps={{ native: true }}
                    label={t('fields.prostataTipo')}
                    name="prostataTipo"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disableForm || isSubmitting}
                    value={values.prostataTipo}
                    variant="outlined"
                  >
                    <option value="" hidden></option>
                    <option value={LOCATION.PROSTATA.TIPO.ADENOCARCINOMA}>
                      {t('fields.prostataTipo_options.adenocarcinoma')}
                    </option>
                    <option value={LOCATION.PROSTATA.TIPO.SMALL_CELL_NEUROENDOCRINO}>
                      {t('fields.prostataTipo_options.smallCell_Neuroendocrino')}
                    </option>
                    <option value={LOCATION.PROSTATA.TIPO.OTROS}>
                      {t('fields.prostataTipo_options.otros')}
                    </option>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={Boolean(touched.prostataPsaActual && errors.prostataPsaActual)}
                    fullWidth
                    required
                    helperText={touched.prostataPsaActual && errors.prostataPsaActual}
                    label={t('fields.prostataPsaActual')}
                    name="prostataPsaActual"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disableForm || isSubmitting}
                    value={values.prostataPsaActual}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={Boolean(touched.prostataPsaPrevio && errors.prostataPsaPrevio)}
                    fullWidth
                    helperText={touched.prostataPsaPrevio && errors.prostataPsaPrevio}
                    label={t('fields.prostataPsaPrevio')}
                    name="prostataPsaPrevio"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disableForm || isSubmitting}
                    value={values.prostataPsaPrevio}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={Boolean(touched.prostataGradoGleason && errors.prostataGradoGleason)}
                    fullWidth
                    required
                    helperText={touched.prostataGradoGleason && errors.prostataGradoGleason}
                    label={t('fields.prostataGradoGleason')}
                    name="prostataGradoGleason"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disableForm || isSubmitting}
                    value={values.prostataGradoGleason}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={Boolean(
                      touched.prostataEstratificacionRiesgo && errors.prostataEstratificacionRiesgo,
                    )}
                    fullWidth
                    required
                    helperText={
                      touched.prostataEstratificacionRiesgo && errors.prostataEstratificacionRiesgo
                    }
                    label={t('fields.prostataEstratificacionRiesgo')}
                    name="prostataEstratificacionRiesgo"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disableForm || isSubmitting}
                    value={values.prostataEstratificacionRiesgo}
                    variant="outlined"
                  />
                </Grid>
              </>
            )}
            {values.type === TYPE.MIELOPROLIFERATIVOS && (
              <>
                <Grid item xs={12} sm={5}>
                  <TextField
                    error={Boolean(
                      touched.mieloproliferativosCd20 && errors.mieloproliferativosCd20,
                    )}
                    fullWidth
                    helperText={touched.mieloproliferativosCd20 && errors.mieloproliferativosCd20}
                    required
                    select
                    SelectProps={{ native: true }}
                    label={t('fields.mieloproliferativosCd20')}
                    name="mieloproliferativosCd20"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disableForm || isSubmitting}
                    value={values.mieloproliferativosCd20}
                    variant="outlined"
                  >
                    <option value="" hidden></option>
                    <option value={LOCATION.MIELOPROLIFERATIVOS.CD20.NO_REALIZADO}>
                      {t('fields.mieloproliferativosCd20_options.no_realizado')}
                    </option>
                    <option value={LOCATION.MIELOPROLIFERATIVOS.CD20.MINUS}>
                      {t('fields.mieloproliferativosCd20_options.minus')}
                    </option>
                    <option value={LOCATION.MIELOPROLIFERATIVOS.CD20.PLUS}>
                      {t('fields.mieloproliferativosCd20_options.plus')}
                    </option>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <TextField
                    error={Boolean(touched.mieloproliferativosSds && errors.mieloproliferativosSds)}
                    required
                    fullWidth
                    helperText={touched.mieloproliferativosSds && errors.mieloproliferativosSds}
                    label={t('fields.mieloproliferativosSds')}
                    name="mieloproliferativosSds"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disableForm || isSubmitting}
                    value={values.mieloproliferativosSds}
                    variant="outlined"
                  />
                </Grid>
              </>
            )}
            {values.type === TYPE.VEJIGA && (
              <>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(touched.vejigaTipo && errors.vejigaTipo)}
                    fullWidth
                    helperText={touched.vejigaTipo && errors.vejigaTipo}
                    required
                    select
                    SelectProps={{ native: true }}
                    label={t('fields.vejigaTipo')}
                    name="vejigaTipo"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disableForm || isSubmitting}
                    value={values.vejigaTipo}
                    variant="outlined"
                  >
                    <option value="" hidden></option>
                    <option value={LOCATION.VEJIGA.TIPO.T_UROTELIALES}>
                      {t('fields.vejigaTipo_options.t_uroteliales')}
                    </option>
                    <option value={LOCATION.VEJIGA.TIPO.ESCAMOSO}>
                      {t('fields.vejigaTipo_options.escamoso')}
                    </option>
                    <option value={LOCATION.VEJIGA.TIPO.GLANDULAR}>
                      {t('fields.vejigaTipo_options.glandular')}
                    </option>
                    <option value={LOCATION.VEJIGA.TIPO.URACHAL}>
                      {t('fields.vejigaTipo_options.urachal')}
                    </option>
                    <option value={LOCATION.VEJIGA.TIPO.MULLERIANO}>
                      {t('fields.vejigaTipo_options.mulleriano')}
                    </option>
                    <option value={LOCATION.VEJIGA.TIPO.NEUROENDOCRINO}>
                      {t('fields.vejigaTipo_options.neuroendocrino')}
                    </option>
                    <option value={LOCATION.VEJIGA.TIPO.MELANOCITICO}>
                      {t('fields.vejigaTipo_options.melanocitico')}
                    </option>
                    <option value={LOCATION.VEJIGA.TIPO.MESENQUIMAL}>
                      {t('fields.vejigaTipo_options.mesenquimal')}
                    </option>
                    <option value={LOCATION.VEJIGA.TIPO.HEMATOPOYETICO_LINFOIDE}>
                      {t('fields.vejigaTipo_options.hematopoyetico_linfoide')}
                    </option>
                    <option value={LOCATION.VEJIGA.TIPO.MISCELAREOS}>
                      {t('fields.vejigaTipo_options.miscelareos')}
                    </option>
                    <option value={LOCATION.VEJIGA.TIPO.OTROS}>
                      {t('fields.vejigaTipo_options.otros')}
                    </option>
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(touched.vejigaPdLi && errors.vejigaPdLi)}
                    fullWidth
                    helperText={touched.vejigaPdLi && errors.vejigaPdLi}
                    required
                    select
                    SelectProps={{ native: true }}
                    label={t('fields.vejigaPdLi')}
                    name="vejigaPdLi"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disableForm || isSubmitting}
                    value={values.vejigaPdLi}
                    variant="outlined"
                  >
                    <option value="" hidden></option>
                    <option value={LOCATION.VEJIGA.PD_LI.LESS_1}>
                      {t('fields.vejigaPdLi_options.less_1')}
                    </option>
                    <option value={LOCATION.VEJIGA.PD_LI.MORE_1_49}>
                      {t('fields.vejigaPdLi_options.more_1_49')}
                    </option>
                    <option value={LOCATION.VEJIGA.PD_LI.MORE_50}>
                      {t('fields.vejigaPdLi_options.more_50')}
                    </option>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.vejigaDescripcionHistologica && errors.vejigaDescripcionHistologica,
                    )}
                    fullWidth
                    multiline
                    required
                    helperText={
                      touched.vejigaDescripcionHistologica && errors.vejigaDescripcionHistologica
                    }
                    label={t('fields.vejigaDescripcionHistologica')}
                    name="vejigaDescripcionHistologica"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disableForm || isSubmitting}
                    value={values.vejigaDescripcionHistologica}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(touched.vejigaSubtipo && errors.vejigaSubtipo)}
                    fullWidth
                    multiline
                    required
                    helperText={touched.vejigaSubtipo && errors.vejigaSubtipo}
                    label={t('fields.vejigaSubtipo')}
                    name="vejigaSubtipo"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disableForm || isSubmitting}
                    value={values.vejigaSubtipo}
                    variant="outlined"
                  />
                </Grid>
              </>
            )}
            {values.type === TYPE.OTROS && (
              <Grid item xs={12}>
                <TextField
                  error={Boolean(touched.otrosDescription && errors.otrosDescription)}
                  fullWidth
                  required
                  helperText={touched.otrosDescription && errors.otrosDescription}
                  label={t('fields.otrosDescription')}
                  name="otrosDescription"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={disableForm || isSubmitting}
                  value={values.otrosDescription}
                  variant="outlined"
                />
              </Grid>
            )}
          </Grid>

          <FormContext
            isFullfilled={isFullfilled}
            setClinicalProtocol={setClinicalProtocol}
            setDisableNext={setDisableNext}
          />
        </form>
      )}
    </Formik>
  );
};

Step2Form.propTypes = {
  initialValues: PropTypes.object,
  isFullfilled: PropTypes.bool,
  setClinicalProtocol: PropTypes.func,
  disableForm: PropTypes.bool,
  setDisableNext: PropTypes.func,
};

export default Step2Form;
