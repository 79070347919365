import {
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import format from 'date-fns/format';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Download as DownloadIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';
import useGetMedia from 'src/hooks/demands/useGetMedia';
import { downloadFile } from 'src/utils/downloadFile';

const useStyles = makeStyles((theme) => ({
  cell: {
    padding: 0,
  },
  table: {
    '& th': {
      padding: '4px 5px',
    },
    '& td': {
      padding: '4px 5px',
      color: theme.palette.tertiary.main,
    },
    '& th, & th strong, & td': {
      lineHeight: 1,
    },
  },
  button: {
    padding: 4,
  },
}));

const AttachmentsTable = ({ attachments }) => {
  const classes = useStyles();
  const { t } = useTranslation(['demands']);
  const { enqueueSnackbar } = useSnackbar();
  const [attachment, setAttachment] = useState(null);

  const { isLoading, data: media, isError, refetch: fetchAttachment } = useGetMedia(
    attachment?.id,
    attachment?.mediaType,
  );

  useEffect(() => {
    if (attachment) {
      fetchAttachment();
    }
  }, [attachment, fetchAttachment]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(t('modals.error.download_attachment'), { variant: 'error' });
    }
    if (!isLoading && !isError && media) {
      downloadFile(media.data, attachment.mediaType, attachment.originalFilename);
      setAttachment(null);
    }
  }, [
    media,
    enqueueSnackbar,
    isError,
    isLoading,
    t,
    attachment?.mediaType,
    attachment?.originalFilename,
  ]);

  return (
    <TableContainer>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>{t('demands:fields.historyDate')}</TableCell>
            <TableCell>{t('demands:fields.ficherosAdjuntos_labels.descripcion')}</TableCell>
            <TableCell>{t('demands:fields.ficherosAdjuntos_labels.filename')}</TableCell>
            <TableCell align="right">{t('demands:fields.download')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {attachments.map((row, i) => (
            <TableRow key={row.id}>
              <TableCell>
                {row.createdAt && format(new Date(row.createdAt), 'dd/MM/yyyy')}
              </TableCell>
              <TableCell>{row.description}</TableCell>
              <TableCell>{row.originalFilename}</TableCell>
              <TableCell align="right" className={classes.cell}>
                <IconButton
                  className={classes.button}
                  color="primary"
                  title={row.originalFilename}
                  onClick={async () => setAttachment(row)}
                >
                  <DownloadIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

AttachmentsTable.propTypes = {
  attachments: PropTypes.array,
};

export default AttachmentsTable;
