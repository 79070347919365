import 'react-perfect-scrollbar/dist/css/styles.css';
import 'src/mixins/chartjs';

import { ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SnackbarProvider } from 'notistack';
import { useSelector } from 'react-redux';
import GlobalStyles from 'src/components/GlobalStyles';
import RoutesList from 'src/routes';
import generateTheme from 'src/theme';
import Loader from './components/Loader';
import { getMediaUrl } from './utils/getMediaUrl';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';

const getFaviconElement = () => {
  return document.getElementById('favicon');
};

const App = () => {
  const { t } = useTranslation('common');
  const appState = useSelector((state) => state.app);
  const companyLogoId = get(appState, 'data.logo.id');
  const companyFaviconId = get(appState, 'data.favicon.id');
  const companyName = get(appState, 'data.name');

  useEffect(() => {
    const faviconEl = getFaviconElement();

    if (faviconEl && (companyFaviconId || companyLogoId)) {
      faviconEl.href = getMediaUrl(companyFaviconId || companyLogoId);
    }
  }, [companyFaviconId, companyLogoId]);

  useEffect(() => {
    if (companyName) {
      document.title = t('labels.app_title', { companyName });
    }
  }, [companyName, t]);

  if (appState.loading) {
    return <Loader />;
  }

  const theme = generateTheme(appState.data);

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        <SnackbarProvider maxSnack={5}>
          <GlobalStyles />
          <RoutesList />
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default App;
