import {
  Avatar,
  Box,
  Card,
  CardContent,
  colors,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Briefcase as BriefcaseIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';
import ManagerCompanySelector from 'src/components/Company/ManagerCompanySelector';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  avatar: {
    backgroundColor: colors.red[600],
    height: 56,
    width: 56,
  },
}));

const ManagerCompanyPicker = ({ className, ...rest }) => {
  const classes = useStyles();
  const { t } = useTranslation('companies');

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              {t('labels.company_title').toUpperCase()}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <BriefcaseIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ManagerCompanySelector />
        </Grid>
        <Box mt={2} display="flex" alignItems="center">
          <Typography color="textSecondary" variant="body1">
            {t('texts.company_selector')}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

ManagerCompanyPicker.propTypes = {
  className: PropTypes.string,
};

export default ManagerCompanyPicker;
