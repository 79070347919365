import { useMutation, useQueryCache } from 'react-query';
import { updateCenter } from 'src/services/api/centers';

function useUpdateCenter() {
  const queryCache = useQueryCache();

  return useMutation(({ center, data }) => updateCenter(center, data), {
    onSuccess: (data) => queryCache.setQueryData(['center', data.data?.id], data),
  });
}

export default useUpdateCenter;
