import { Box, Button, CircularProgress, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import { useListDrugs } from 'src/hooks/drugs';

const CreateEditPresentationForm = ({ presentation, handleFormSubmit, handleCancel, action }) => {
  const { t } = useTranslation(['presentations', 'companies']);
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading: isLoadingDrugs, resolvedData: drugs, isError: isErrorDrugs } = useListDrugs({
    all: true,
  });

  const initialValues = {
    registerNumber: '',
    nationalCode: '',
    presentation: '',
    drugId: null,
  };

  const getPresentationData = () => {
    if (presentation?.data) {
      return {
        registerNumber: presentation.data.registerNumber,
        nationalCode: presentation.data.nationalCode,
        presentation: presentation.data.presentation,
        drugId: drugs?.data.rows.find(({ id }) => presentation.data.drugId === id) || null,
      };
    }

    return initialValues;
  };

  const onSubmit = (values, formikBag) => {
    const data = omitBy(values, isEmpty);
    data.drugId = values.drugId.id;
    data.drugUuid = values.drugId.uuid;

    if (presentation) {
      delete data.drugId;
      delete data.drugUuid;
    }

    return handleFormSubmit({ presentation, values: data, formikBag });
  };

  const validationSchema = Yup.object().shape({
    drugId: Yup.object().nullable().required(t('errors.drug.required')),
    presentation: Yup.string().required(t('errors.presentation.required')),
    registerNumber: Yup.string().required(t('errors.registerNumber.required')),
    nationalCode: Yup.string().required(t('errors.nationalCode.required')),
  });

  useEffect(() => {
    if (isErrorDrugs) {
      enqueueSnackbar(t('companies:modals.error.title_get_companies'), { variant: 'error' });
    }
  }, [enqueueSnackbar, isErrorDrugs, t]);

  return (
    <Formik
      enableReinitialize
      initialValues={getPresentationData()}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        errors,
        handleBlur,
        setFieldTouched,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                error={Boolean(touched.registerNumber && errors.registerNumber)}
                fullWidth
                helperText={touched.registerNumber && errors.registerNumber}
                label={t('fields.registerNumber')}
                name="registerNumber"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting}
                value={values.registerNumber}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={Boolean(touched.nationalCode && errors.nationalCode)}
                fullWidth
                helperText={touched.nationalCode && errors.nationalCode}
                label={t('fields.nationalCode')}
                name="nationalCode"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting}
                value={values.nationalCode}
                variant="outlined"
              />
            </Grid>

            <Grid item md={8} xs={12}>
              <TextField
                error={Boolean(touched.presentation && errors.presentation)}
                fullWidth
                helperText={touched.presentation && errors.presentation}
                label={t('fields.presentation')}
                name="presentation"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting}
                value={values.presentation}
                variant="outlined"
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <Autocomplete
                openOnFocus={true}
                selectOnFocus={false}
                options={drugs?.data?.rows || []}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                loading={isLoadingDrugs}
                name="drugId"
                value={values.drugId}
                onChange={(e, value) => setFieldValue('drugId', value)}
                onBlur={() => setFieldTouched('drugId', true)}
                disabled={Boolean(presentation?.data?.drugId) || isSubmitting}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('fields.select_drug')}
                    variant="outlined"
                    error={Boolean(touched.drugId && errors.drugId)}
                    helperText={touched.drugId && errors.drugId}
                    inputProps={{
                      ...params.inputProps,
                      style: { cursor: 'pointer' },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      readOnly: true,
                      endAdornment: (
                        <>
                          {isLoadingDrugs ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Box p={2}>
              <Button
                color="primary"
                fullWidth
                disabled={isSubmitting}
                size="large"
                variant="outlined"
                onClick={handleCancel}
              >
                {t('buttons.cancel')}
              </Button>
            </Box>
            <Box p={2}>
              <Button
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                {t(`buttons.${action}`)}
              </Button>
            </Box>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

CreateEditPresentationForm.propTypes = {
  initialValues: PropTypes.object,
  handleFormSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  action: PropTypes.string,
};

export default CreateEditPresentationForm;
