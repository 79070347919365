import PropTypes from 'prop-types';
import { Box, Button, Grid, TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import CustomSwitch from 'src/components/CustomSwitch';
import { ID_CARD_REGEX, PASSWORD_REGEX } from 'src/constants/regex';
import { useSelector } from 'react-redux';
import {
  isAdmin as isAdminSelector,
  isRepresentive as isRepresentiveSelector,
  isSuperAdmin as isSuperAdminSelector,
} from 'src/store/selectors/user';

const AccountDetailsForm = ({ handleFormSubmit, user }) => {
  const { t } = useTranslation('account');
  const isSuperAdmin = useSelector(isSuperAdminSelector);
  const isAdmin = useSelector(isAdminSelector);
  const isRepresentive = useSelector(isRepresentiveSelector);

  const initialValues = {
    name: user?.name,
    dni: user?.dni,
    emailNotifications: user?.emailNotifications,
    password: '',
    confirmPassword: '',
    showPasswordForm: false,
  };

  let dniValidation = Yup.string().matches(ID_CARD_REGEX, t('errors.id_card.pattern'));
  if (isSuperAdmin || isAdmin) {
    dniValidation = dniValidation.required(t('errors.id_card.required'));
  }
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .max(3, t('errors.name.min'))
      .max(200, t('errors.name.max', { max: 200 }))
      .required(t('errors.name.required')),
    dni: dniValidation,
    emailNotifications: Yup.boolean(),
    password: Yup.string()
      .when('showPasswordForm', (showPasswordForm, schema) =>
        showPasswordForm ? schema.required(t('errors.password.required')) : schema,
      )
      .min(8, t('errors.password.min', { min: 8 }))
      .max(12, t('errors.password.max', { max: 12 }))
      .matches(PASSWORD_REGEX, t('errors.password.pattern')),
    confirmPassword: Yup.string().when('showPasswordForm', (showPasswordForm, schema) =>
      showPasswordForm
        ? schema
            .oneOf([Yup.ref('password'), null], t('errors.confirmPassword.match'))
            .required(t('errors.confirmPassword.required'))
        : schema,
    ),
  });

  const onSubmit = (values, formikBag) => {
    // Omit confirmPassword and empty values
    const data = pickBy(omit(values, 'confirmPassword', 'showPasswordForm'));
    data.emailNotifications = values.emailNotifications;

    return handleFormSubmit(data, formikBag);
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item sm={8} xs={12}>
              <TextField
                error={Boolean(touched.name && errors.name)}
                fullWidth
                helperText={touched.name && errors.name}
                label={t('fields.name')}
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting}
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                error={Boolean(touched.dni && errors.dni)}
                fullWidth
                helperText={touched.dni && errors.dni}
                label={t('fields.id_card')}
                name="dni"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={isSubmitting || (!isSuperAdmin && !isAdmin)}
                value={values.dni}
                variant="outlined"
              />
            </Grid>
            {isRepresentive && (
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="medium"
                      name="emailNotifications"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={isSubmitting}
                      checked={values.emailNotifications}
                    />
                  }
                  label={t('fields.emailNotifications')}
                  labelPlacement="start"
                />
              </Grid>
            )}
            {window.location.hostname.indexOf('orgoa.plataformaoncologia.es') == -1 &&
             window.location.hostname.indexOf('dev-pgo.orgaconsulting.es') == -1  && (
              <>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <CustomSwitch
                        name="showPasswordForm"
                        checked={values.showPasswordForm}
                        onChange={handleChange}
                      />
                    }
                    label={t('fields.changePassword')}
                    labelPlacement="start"
                  />
                </Grid>
              </>
            )}
            {values.showPasswordForm && (
              <>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label={t('fields.password')}
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={isSubmitting}
                    type="password"
                    value={values.password}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                    fullWidth
                    helperText={touched.confirmPassword && errors.confirmPassword}
                    label={t('fields.confirmPassword')}
                    name="confirmPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={isSubmitting}
                    type="password"
                    value={values.confirmPassword}
                    variant="outlined"
                  />
                </Grid>
              </>
            )}

            <Box p={2}>
              <Button
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                {t('buttons.save')}
              </Button>
            </Box>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

AccountDetailsForm.propTypes = {
  handleFormSubmit: PropTypes.func,
  user: PropTypes.object,
};

export default AccountDetailsForm;
