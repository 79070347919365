import { Box, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  isAdmin as isAdminSelector,
  isManager as isManagerSelector,
  isRepresentive as isRepresentiveSelector,
  isSuperAdmin as isSuperAdminSelector,
} from 'src/store/selectors/user';
import Comment from '../Comment';

const CommentCards = ({ histories }) => {
  const isSuperAdmin = useSelector(isSuperAdminSelector);
  const isAdmin = useSelector(isAdminSelector);
  const isManager = useSelector(isManagerSelector);
  const isRepresentative = useSelector(isRepresentiveSelector);
  const displayPrivateComment = isSuperAdmin || isAdmin || isManager || isRepresentative;

  return (
    <Box>
      {histories &&
        histories.map((h, i) => {
          return (
            <>
              <Comment
                key={h.id}
                author={h.author.name}
                comment={h.comment}
                privateComment={h.privateComment}
                displayPrivateComment={displayPrivateComment}
                date={h.createdAt}
              />
              {histories.length - 1 !== i && <Divider />}
            </>
          );
        })}
    </Box>
  );
};

CommentCards.propTypes = {
  histories: PropTypes.array,
};

export default CommentCards;
