import { Box, Button, Container, makeStyles, TextField, Typography } from '@material-ui/core';
import { Formik } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import Page from 'src/components/Page';
import { useGetInvitationUse, usePostInvitationUse } from 'src/hooks/invitations';
import { isAuthenticated as isAuthenticatedSelector } from 'src/store/selectors/auth';
import Loader from 'src/components/Loader';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import omit from 'lodash/omit';
import AlertDialog from 'src/components/AlertDialog';
import { PASSWORD_REGEX, ID_CARD_REGEX } from 'src/constants/regex';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const PageContainer = ({ children }) => {
  const classes = useStyles();

  return (
    <Page className={classes.root}>
      <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
        <Container maxWidth="sm">{children}</Container>
      </Box>
    </Page>
  );
};

const UseInvitationView = () => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const history = useHistory();
  const [
    postInvitationUse,
    { isLoading: isLoadingUse, data: invitationUsed, isError: isErrorUse, error: errorUse },
  ] = usePostInvitationUse();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('invitations');
  const { token } = useParams();
  const { isLoading, data: invitationInfo, error, isError } = useGetInvitationUse(token);

  const handleSubmit = async (values) => {
    const data = omit(values, 'email', 'passwordConfirmation');
    await postInvitationUse({ token, data });
  };

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(error?.message || t('errors.common.error_load_invitation'), {
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, error, isError, t]);

  if (isAuthenticated) {
    return <Redirect to="/app" />;
  }

  if (isLoading) {
    return (
      <PageContainer>
        <Loader />
      </PageContainer>
    );
  }

  if (isError) {
    return <Redirect to="/login" />;
  }

  return (
    <PageContainer>
      <Formik
        initialValues={{
          email: invitationInfo.data.email || '',
          name: invitationInfo.data.name || '',
          dni: '',
          password: '',
          passwordConfirmation: '',
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email(t('errors.email.valid')).required(t('errors.email.required')),
          name: Yup.string()
            .min(3, t('errors.name.min'))
            .max(200, t('errors.name.max', { max: 200 }))
            .required(t('errors.name.required')),
          dni: Yup.string()
            .matches(ID_CARD_REGEX, t('errors.id_card.pattern'))
            .required(t('errors.id_card.required')),
          password: Yup.string()
            .min(8, t('errors.password.min', { min: 8 }))
            .max(12, t('errors.password.max', { max: 12 }))
            .matches(PASSWORD_REGEX, t('errors.password.pattern'))
            .required(t('errors.password.required')),
          passwordConfirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], t('errors.password.must_match'))
            .required(t('errors.passwordConfirmation.required')),
        })}
        onSubmit={handleSubmit}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form onSubmit={handleSubmit}>
            <Box mb={3}>
              <Typography color="textPrimary" variant="h2">
                {t('labels.invitations_use_title')}
              </Typography>
              <Typography color="textSecondary" gutterBottom variant="body2">
                {t('labels.invitations_use_subtitle')}
              </Typography>
            </Box>
            <TextField
              fullWidth
              margin="normal"
              name="email"
              type="email"
              value={values.email}
              variant="outlined"
              disabled
            />
            <TextField
              error={Boolean(touched.name && errors.name)}
              fullWidth
              helperText={touched.name && errors.name}
              label={t('fields.name')}
              margin="normal"
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.name}
              variant="outlined"
              disabled={isSubmitting}
            />
            <TextField
              error={Boolean(touched.dni && errors.dni)}
              fullWidth
              helperText={touched.dni && errors.dni}
              label={t('fields.id_card')}
              margin="normal"
              name="dni"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.dni}
              variant="outlined"
              disabled={isSubmitting}
            />
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              label={t('fields.password')}
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.password}
              variant="outlined"
              disabled={isSubmitting}
            />
            <TextField
              error={Boolean(touched.passwordConfirmation && errors.passwordConfirmation)}
              fullWidth
              helperText={touched.passwordConfirmation && errors.passwordConfirmation}
              label={t('fields.passwordConfirmation')}
              margin="normal"
              name="passwordConfirmation"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.passwordConfirmation}
              variant="outlined"
              disabled={isSubmitting}
            />
            <Box my={2}>
              <Button
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                {t('buttons.create')}
              </Button>
            </Box>
          </form>
        )}
      </Formik>

      {!isLoadingUse && !isErrorUse && invitationUsed && (
        <AlertDialog
          title={t('modals.success.title_invitation_use')}
          text={t('modals.success.text_invitation_use')}
          type="success"
          acceptAction={() => history.push('/login')}
        />
      )}
      {isErrorUse && (
        <AlertDialog
          title={t('modals.error.title_invitation_use')}
          text={errorUse?.message || t('modals.error.text_invitation_use')}
          type="error"
        />
      )}
    </PageContainer>
  );
};

export default UseInvitationView;
