import { useQuery } from 'react-query';
import { getPatientActiveTreatments } from 'src/services/api/patients';

function useGetPatientActiveTreatments(patient, demandId, query, enabled = true) {
  return useQuery(
    ['patient', patient],
    (key, patient) => getPatientActiveTreatments(patient, demandId, query),
    { enabled },
  );
}

export default useGetPatientActiveTreatments;
