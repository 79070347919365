import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import AlertDialog from 'src/components/AlertDialog';
import useUpdateCompany from 'src/hooks/company/useUpdateCompany';
import { fetchAppData } from 'src/store/reducers/app';
import { getInitials } from 'src/utils/getInitials';
import { getMediaUrl } from 'src/utils/getMediaUrl';

import MediaUpload from './components';

const useStyles = makeStyles(() => ({
  root: {},
  companyDetails: {
    wordBreak: 'break-all',
  },
  media: {
    height: 100,
    width: 100,
    fontSize: '40px',
  },
  companyMedia: {
    marginBottom: 16,
  },
  logo: {
    maxWidth: 200,
    maxHeight: 200,
  },
  favicon: {
    maxWidth: 130,
    maxHeight: 130,
  },
}));

const Profile = ({ className, company, media, ...rest }) => {
  const classes = useStyles();
  const { t } = useTranslation('companies');
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const companyData = company.data;
  const [updateCompany, { isLoading, data: updatedCompany, isError, error }] = useUpdateCompany();
  const [isUploading, setUploading] = useState(false);
  const mediaUrl = getMediaUrl(media === 'logo' ? companyData?.logo?.id : companyData?.favicon?.id);

  const handleMediaUpload = async (image) => {
    setUploading(true);
    const res = await updateCompany({
      company: companyData.id,
      data: { [media === 'logo' ? 'logoImage' : 'faviconImage']: image },
    });

    if (res) {
      dispatch(fetchAppData());
    }

    setUploading(false);
  };

  useEffect(() => {
    if (!isLoading && !isError && updatedCompany) {
      enqueueSnackbar(t(`modals.success.text_update_company_${media}`), { variant: 'success' });
    }
  }, [enqueueSnackbar, isError, isLoading, media, t, updatedCompany]);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Box alignItems="center" display="flex" flexDirection="column">
          {mediaUrl && (
            <img
              alt={media === 'logo' ? 'Logo' : 'Favicon'}
              className={clsx(classes.companyMedia, classes[media], className)}
              src={mediaUrl}
            />
          )}
          {!mediaUrl && <Avatar className={classes.media}>{getInitials(companyData.name)}</Avatar>}

          {media === 'logo' && (
            <>
              <Typography color="textPrimary" gutterBottom variant="h3" align="center">
                {companyData.name}
              </Typography>
              <Typography
                className={clsx(classes.companyDetails, className)}
                color="textSecondary"
                variant="body1"
                align="center"
              >
                {companyData.slogan}
              </Typography>
            </>
          )}
        </Box>
      </CardContent>

      <Divider />

      <CardActions>
        <MediaUpload
          handleMediaUpload={handleMediaUpload}
          isUploading={isUploading}
          media={media}
        />
      </CardActions>

      {isError && (
        <AlertDialog
          title={t(`modals.error.title_update_company_${media}`)}
          text={error?.message || t(`modals.error.text_update_company_${media}`)}
          type="error"
        />
      )}
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
  companyData: PropTypes.object,
  media: PropTypes.string,
};

export default Profile;
