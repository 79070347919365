import PropTypes from 'prop-types';
import { Grid, InputBase } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

const CompanySelectorForm = ({ classes, companies, company, handleChange }) => {
  const { t } = useTranslation('companies');

  return (
    <Grid className={classes.inputRoot}>
      <Autocomplete
        name="company"
        options={companies}
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option, value) => option.id === value.id}
        value={company}
        onChange={handleChange}
        renderInput={(params) => (
          <InputBase
            placeholder={t('labels.company_selection')}
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            classes={{
              root: classes.root,
              input: classes.input,
            }}
          />
        )}
      />
    </Grid>
  );
};

CompanySelectorForm.propTypes = {
  classes: PropTypes.object,
  companies: PropTypes.array,
  company: PropTypes.object,
  handleChange: PropTypes.func,
};

export default CompanySelectorForm;
